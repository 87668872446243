<ng-container *transloco="let t;">
    <div class="notifWrapper">
        <p class="notifGenTitle">{{t('notification.genTitle')}}</p>
    </div>
    <div *ngIf="data.notification.image" class="notifImg">
        <img src="{{data.notification.image}}">
    </div>
    <div class="notifTitle">
        {{ data.notification.title }}
    </div>
    <div class="notifMessage">
        {{ data.notification.body }}
    </div>
</ng-container>