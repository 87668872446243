<ng-container *ngIf="users$| async as users">
  <ng-container *ngIf="paginationOption$ | async as paginationOption">
    <ng-container *ngIf="userFilter$ | async as userFilter">
    <div class="header row align-items-center">
      <div class="col-auto me-auto ms-3">{{'users.title'| transloco}}
      </div>
      <button mat-raised-button class="h-75 col-auto ms-3 me-5 justify-content-end helveticaBold13 " routerLink="edit/">
        {{'users.addUser' | transloco}}
      </button>
    </div>
    <div class=" ms-auto row mt-3 mb-3 me-3" [formGroup]="filter" >
      <div class="title col-auto align-self-baseline justify-content-start ms-3 me-auto">
        {{'users.list' | transloco}}
      </div>
      <app-searchbar class="col-4" (valueChange)="searchNameChanged($event)" [search$]="userFilterName$" [placeholder]="'users.search' | transloco" ></app-searchbar>
      <mat-form-field appearance="fill" class="col-2" *ngIf="!isManager && !isSecretaire">
        <mat-select multiple formControlName="profileFormControl" [placeholder]="'users.profiles.ALL' | transloco">
          <mat-select-trigger>
            <ng-container *ngIf="userFilter?.profile?.length == profileEnum.length ; else specificOption "> {{ 'users.profiles.ALL' | transloco }}</ng-container>
            <ng-template #specificOption>
              <span>{{ 'users.role' | transloco}}: </span>
              <span>{{ userFilter.profile?.length ? ('users.profiles.'+userFilter?.profile![0] | transloco) : ''}}</span>
              <span class="numberFilter">{{ userFilter?.profile?.length && userFilter?.profile?.length! > 1 ? '+'+(userFilter?.profile?.length! -1) :  '' }}</span>
            </ng-template>
          </mat-select-trigger>
          <mat-option *ngFor="let profile of profileEnum" [value]="profile">{{'users.profiles.'+profile | transloco}}</mat-option>
          <div class="mt-1 mb-1 d-flex flex-column justify-content-center align-items-center resetFilter">
            <label *ngIf="this.filter.get('profileFormControl')?.value?.length>0" (click)="resetFilter()"> {{'samples.resetFilter' | transloco }}</label>
          </div>
        </mat-select>

      </mat-form-field>
    </div>
  <ng-container *ngIf="paginationOption$ | async as paginationOption">
    <div class="row m-3">
      <div class="col-2 table-header d-flex flex-row" (click)="updateSort('lastname')" [ngClass]="{'sortBy': paginationOption.sortByColumnName=== 'lastname'}">
        <span>{{'users.table.user' | transloco }} </span>
        <mat-icon *ngIf="paginationOption.sortByColumnName=== 'lastname' && paginationOption.sortDirection === 'DESC'; else asc" class="arrow">arrow_downward</mat-icon>
        <ng-template #asc>
          <mat-icon class="arrow">arrow_upward</mat-icon>
        </ng-template>
      </div>
      <div class="col-2 table-header d-flex flex-row" (click)="updateSort('ipp')" [ngClass]="{'sortBy': paginationOption.sortByColumnName=== 'ipp'}">
        <span>{{'users.table.ipp' | transloco}}</span>
        <mat-icon *ngIf="paginationOption.sortByColumnName=== 'ipp' && paginationOption.sortDirection === 'DESC'; else asc" class="arrow">arrow_downward</mat-icon>
      </div>
      <div class="col-2 table-header d-flex flex-row" (click)="updateSort('birthDate')" [ngClass]="{'sortBy': paginationOption.sortByColumnName=== 'birthDate'}">
        <span>{{'users.table.birthdate' | transloco }} </span>
        <mat-icon *ngIf="paginationOption.sortByColumnName=== 'birthDate' && paginationOption.sortDirection === 'DESC'; else asc" class="arrow">arrow_downward</mat-icon>
      </div>
      <div class="col-1 table-header d-flex flex-row" (click)="updateSort('profile')" [ngClass]="{'sortBy': paginationOption.sortByColumnName=== 'profile'}">
        <span>{{'users.table.profile' | transloco }} </span>
        <mat-icon *ngIf="paginationOption.sortByColumnName=== 'profile' && paginationOption.sortDirection === 'DESC'; else asc" class="arrow">arrow_downward</mat-icon>
      </div>
      <div class="col-3 table-header d-flex flex-row justify-content-center">
        <span>{{'users.table.labo' | transloco }} </span>
      </div>
      <div class="col-1 table-header d-flex flex-row"></div>
      <div class="col-auto ms-auto table-header">{{'users.table.details' | transloco }}</div>
    </div>
    <ng-container *ngIf="filteredUsersLoaded$ | async else loadingUsersBlock">
      <ng-container *ngIf="users.length > 0 else noUsers">
        <div *ngFor="let user of users" role="button" class="row row-table ms-3 me-3 mt-1 mb-1 align-items-center" [routerLink]="'edit/'+ user.id">
          <div class="col-2 fw-bold">{{user.lastname && user.firstname ? user.firstname + ' ' + user.lastname.toUpperCase() : ''}}</div>
            <div class="col-2">{{user.ipp}}</div>
            <div class="col-2">{{user?.birthDate}}</div>
            <div class="col-1">{{'users.profiles.' + user?.profile | transloco}}</div>
            <div class="col-3 d-flex flex-row justify-content-center">{{getLaboNames(user).join(', ')}}</div>
            <div class="col-1 create-sample" *ngIf="user.profile === ProfileEnum.PATIENT">
              <button mat-button (click)="createSample(user.id)">{{'users.samples.create' | transloco}}</button>
            </div>
            <div class="col-auto ms-auto">
              <button mat-icon-button [routerLink]="'edit/'+ user.id">
                <mat-icon>arrow_forward</mat-icon>
              </button>
            </div>
        </div>

    <mat-paginator [length]="paginationOption?.length" [pageSize]="paginationOption.pageSize"
                   [pageIndex]="paginationOption.pageIndex"  [pageSizeOptions]="paginationOption?.pageSizeOption || [10,20,50]"
                   (page)="onChangePage($event)"></mat-paginator>
  </ng-container>
  <ng-template #noUsers>
    <div class="w-100 d-flex justify-content-center noresult">
      {{'users.noresult' | transloco}}
    </div>
  </ng-template>
</ng-container>
<ng-template #loadingUsersBlock>
  <div class="w-100 d-flex justify-content-center usersLoader">
    <div class="loader">
    </div>
  </div>
</ng-template>
</ng-container>
    </ng-container>
</ng-container>
