import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Notification } from '@domain/notification/notification';
import { Registration } from '@domain/notification/registration';
import { environment } from '@environment/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {

    constructor(public http: HttpClient) {}

    register(registration: Registration): Observable<void> {
        return this.http.post<void>(environment.api + '/registrations', registration);
    }

    subscribeToTopic(token: string, topic: string, key: string) {
        return this.http.post<void>(environment.firebase.iidUrl.replace('${token}', token).replace('${topic}', topic),
            null,
            {
                headers: {
                    'Authorization': 'key=' + key
                }
            }
        );
    }

    getLatestNotifications(size: number): Observable<Notification[]> {
        return this.http.get<Notification[]>(environment.api + '/notifications/latest?size=' + size);
    }
}