import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProfileEnum} from '@domain/user/user';
import {AuthenticatedGuard} from '@security/authenticated.guard';

const routes: Routes = [
  {path: 'labos', canActivate: [AuthenticatedGuard], data:{roles:[ProfileEnum.ADMIN, ProfileEnum.PERSONNEL_LABO, ProfileEnum.TOURNEE_MANAGER]}, loadChildren: () => import('@features/labo/labo.module').then(m => m.LaboModule)},
  {path: 'users', canActivate: [AuthenticatedGuard], data:{roles:[ProfileEnum.ADMIN, ProfileEnum.PERSONNEL_LABO, ProfileEnum.TOURNEE_MANAGER, ProfileEnum.SECRETAIRE]}, loadChildren: () => import('@features/users/users.module').then(m => m.UserModule)},
  {path: 'samples', canActivate: [AuthenticatedGuard], data:{roles:[ProfileEnum.ADMIN, ProfileEnum.TOURNEE_MANAGER, ProfileEnum.SECRETAIRE]}, loadChildren: () => import('@features/samples/samples.module').then(m => m.SamplesModule)},
  {path: 'cabinets', canActivate: [AuthenticatedGuard], data:{roles:[ProfileEnum.ADMIN, ProfileEnum.PERSONNEL_LABO, ProfileEnum.TOURNEE_MANAGER]}, loadChildren: () => import('@features/cabinets/cabinets.module').then(m => m.CabinetsModule)},
  {path: 'nurses', canActivate: [AuthenticatedGuard], data:{roles:[ProfileEnum.ADMIN, ProfileEnum.PERSONNEL_LABO]}, loadChildren: () => import('@features/nurses/nurses.module').then(m => m.NurseModule)},
  {path: 'home', canActivate: [AuthenticatedGuard], loadChildren: () => import('@features/home/home.module').then(m => m.HomeModule)},
  {path: '403', canActivate: [AuthenticatedGuard], loadChildren: () => import('@features/unauthorized/unauthorized.module').then(m => m.UnauthorizedModule)},
  {path: '', redirectTo: 'labos', pathMatch: 'full'},
  {path: '**', redirectTo: 'labos' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}


