import { Notification } from '@domain/notification/notification';
import { NotificationsActions } from './notifications.actions.enum';

const PREFIX: string = '[Notifications] ';

export class LoadLatestNotifcations {
  static readonly type: string = PREFIX + NotificationsActions.LOAD_LATEST_NOTIFICATIONS;
}

export class LoadLatestNotifcationsSuccess {
  static readonly type: string = PREFIX + NotificationsActions.LOAD_LATEST_NOTIFICATIONS_SUCCESS;

  constructor(public notifications: Notification[]) {}
}

export class LoadLatestNotifcationsFailure {
  static readonly type: string = PREFIX + NotificationsActions.LOAD_LATEST_NOTIFICATIONS_FAILURE;

  constructor(public error: string) {}
}
