import { Notification } from '@domain/notification/notification';
import { Registration } from '@domain/notification/registration';
import {HashMap} from '@ngneat/transloco';
import {GlobalActions} from '@states/global/global.actions.enum';
import {Message} from '@states/global/global.state';

const PREFIX: string = '[Global] ';

export class ShowMessage {
  static readonly type: string = PREFIX + GlobalActions.SHOW_MESSAGE;

  constructor(public message: Message, public scope: string, public data?: HashMap) {}
}

export class LoadUserProfile {
  static readonly type: string = PREFIX + GlobalActions.LOAD_USER_PROFILE;
}

export class LoadUserProfileSuccess {
  static readonly type: string = PREFIX + GlobalActions.LOAD_USER_PROFILE_SUCCESS;

  constructor(public profile: Keycloak.KeycloakProfile) {}
}

export class LoadUserProfileFailure {
  static readonly type: string = PREFIX + GlobalActions.LOAD_USER_PROFILE_FAILURE;

  constructor(public error: string) {}
}

export class LoadUserRoleFailure {
  static readonly type: string = PREFIX + GlobalActions.LOAD_USER_PROFILE_FAILURE;

  constructor(public error: string) {}
}

export class Logout {
  static readonly type: string = PREFIX + GlobalActions.LOGOUT;
}

export class EndInitialization {
  static readonly type: string = PREFIX + GlobalActions.END_INITIALIZATION;
}

export class RegisterForNotification {
  static readonly type: string = PREFIX + GlobalActions.REGISTER_FOR_NOTIFICATION;
  constructor(public registration: Registration) {}
}
export class RegisterForNotificationFailure {
  static readonly type: string = PREFIX + GlobalActions.REGISTER_FOR_NOTIFICATION_FAILURE;

  constructor(public error: string) {}
}
export class ShowNotification {
  static readonly type: string = PREFIX + GlobalActions.SHOW_NOTIFICATION;

  constructor(public notification: Notification) {}
}
export class SubscribeToTopic {
  static readonly type: string = PREFIX + GlobalActions.SUBSCRIBE_TO_TOPIC;

  constructor(public token: string, public topic: string, public key: string) {}
}