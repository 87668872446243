import {HttpClient} from '@angular/common/http';
import {NO_SNACKBAR_CONTEXT} from '@utils/interceptor/error.interceptor';
import {KeycloakEventType, KeycloakService} from 'keycloak-angular';
import {KeycloakInitOptions} from 'keycloak-js';
import {environment} from '@environment/environment';
import {merge} from 'lodash';
import {lastValueFrom} from 'rxjs';

export async function initializeKeycloak(keycloak: KeycloakService, http: HttpClient) {
  // Get environment configuration from server and override local properties.
  // Keep bootstraping the app if the configuration cannot be fetched from server and
  // use bundled environment file.
  try {
    const config: Object = await lastValueFrom(http.get('/webconfig.json', {context: NO_SNACKBAR_CONTEXT}));
    merge(environment, config);
  } catch (e) {
    console.log(e);
    // ignored
  }

  const initOptions: KeycloakInitOptions = {
    checkLoginIframe: false,
    adapter: 'default',
  };

  keycloak.keycloakEvents$.subscribe({
    next: (e) => {
      if (e.type == KeycloakEventType.OnTokenExpired) {
        keycloak.updateToken().then(res => {
          if (!res) {
            keycloak.login()
          }
        }).catch(_ => {
          keycloak.login()
        });
      }
    }
  });

  return keycloak.init({
    config: {
      url: environment.keycloak.url,
      realm: environment.keycloak.realm,
      clientId: environment.keycloak.client,
    },
    initOptions,
    enableBearerInterceptor: true,
    loadUserProfileAtStartUp: true,
    bearerExcludedUrls: ['/assets', 'https://iid.googleapis.com/iid/v1/'],
  });
}
