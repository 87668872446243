import { Pipe, PipeTransform } from '@angular/core';
import {Sample} from '@domain/samples/sample';
import {uniq} from 'lodash';

@Pipe({
  name: 'findOrderInJournee',
})
export class FindOrderInJourneePipe implements PipeTransform {

  transform(samples: Sample[], id: string): number {
    return samples.findIndex(r => r.id === id) + 1
  }



}
