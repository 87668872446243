import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {Component, OnInit, ChangeDetectionStrategy, OnDestroy} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {PageEvent} from '@angular/material/paginator';
import {ActivatedRoute, Router} from '@angular/router';
import {Cabinet, PreleveurCabinet} from '@domain/cabinet/cabinet';
import {translate} from '@ngneat/transloco';
import {Select, Store} from '@ngxs/store';
import {
  AddPreleveurToCabinet, DeleteOneCabinet,
  LoadFilteredPreleveurs,
  LoadOneCabinet,
  LoadOneNewCabinet, RemovePreleveurFromCabinet, SaveOneCabinet,
  UpdatePreleveursFilter, UpdatePreleveursPaginationOption,
} from '@states/cabinets/cabinets.actions';
import {CabinetsStateSelectors} from '@states/cabinets/cabinets.selectors';
import {DialogConfirmationComponent} from '@utils/confirmation-dialog/dialog-confirmation.component';
import {Pagination} from '@utils/pagination/Pagination';
import {PaginationOption} from '@utils/pagination/PaginationOption';
import {Observable, Subject, takeUntil} from 'rxjs';

@Component({
  selector: 'app-cabinet-edit',
  templateUrl: './cabinet-edit.component.html',
  styleUrls: ['./cabinet-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CabinetEditComponent implements OnInit, OnDestroy {
  private destroy: Subject<boolean> = new Subject<boolean>();
  @Select(CabinetsStateSelectors.cabinet) cabinet$: Observable<Cabinet>;
  @Select(CabinetsStateSelectors.preleveursFilter) preleveursFilter$: Observable<string>;
  @Select(CabinetsStateSelectors.filteredPreleveurs) filteredPreleveurs$: Observable<Pagination<PreleveurCabinet>>;
  @Select(CabinetsStateSelectors.cabinetPreleveurs) cabinetPreleveurs$: Observable<PreleveurCabinet[]>;
  @Select(CabinetsStateSelectors.preleveursPaginationOption) paginationOption$: Observable<PaginationOption>;
  constructor(private router: Router, private store: Store, private route: ActivatedRoute, public dialog: MatDialog) {}

  name: FormControl;
  ngOnInit(): void {
    this.route.paramMap.subscribe(val => {
      const id: string | null = val.get('id');
      if (id) {
        this.store.dispatch(new LoadOneCabinet(id));
      } else {
        this.store.dispatch(new LoadOneNewCabinet());
      }
      this.store.dispatch(new LoadFilteredPreleveurs());
      this.cabinet$.pipe(takeUntil(this.destroy)).subscribe(cabinet => {
        if (cabinet) {
          this.name?.setValue(cabinet.name);
        }
      });
    });

    this.name = new FormControl(null, Validators.required);
  }

  goBack(): void {
    this.router.navigate(['/cabinets']);
  }

  deleteCabinet(id: string): void {
    const dialogRef: MatDialogRef<DialogConfirmationComponent> = this.dialog.open(DialogConfirmationComponent, {
      data: {
        title: translate('modal.confirm.title.deleteConfirm'),
        content: translate('modal.confirm.body.deleteCabinet'),
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        if (id) {
          this.store.dispatch(new DeleteOneCabinet(id));
        }
      }
    });
  }

  onSubmit(): void {
    this.store.dispatch(new SaveOneCabinet({name: this.name.value}));
  }

  updateFilter($event: string): void {
    this.store.dispatch(new UpdatePreleveursFilter($event));
  }

  drop(event: CdkDragDrop<PreleveurCabinet[]>): void {
    this.store.dispatch(new AddPreleveurToCabinet(event.item.data));
  }
  notInPredicate(item: PreleveurCabinet, cabinetPreleveurs: PreleveurCabinet[] | null): boolean {
    if (cabinetPreleveurs) {
      return cabinetPreleveurs.filter(preleveur => preleveur.id === item.id).length > 0;
    }
    return false;
  }

  deletePreleveur(id: string): void {
    const dialogRef: MatDialogRef<DialogConfirmationComponent> = this.dialog.open(DialogConfirmationComponent, {
      data: {
        title: translate('modal.confirm.title.deleteConfirm'),
        content: translate('modal.confirm.body.deletePreleveurCabinet'),
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        if (id) {
          this.store.dispatch(new RemovePreleveurFromCabinet(id));
        }
      }
    });
  }
  onChangePage(pe: PageEvent): void {
    this.store.dispatch(
      new UpdatePreleveursPaginationOption({
        pageIndex: pe.pageIndex,
        pageSize: pe.pageSize,
      })
    );
  }
  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }
}
