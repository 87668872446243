import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { catchError, map, Observable } from 'rxjs';
import { NotificationService } from '@service/notification.service';
import { LoadLatestNotifcations, LoadLatestNotifcationsFailure, LoadLatestNotifcationsSuccess } from './notifications.actions';
import { Notification } from '@domain/notification/notification';
import { MessageLevel } from '@states/global/global.state';
import { ShowMessage } from '@states/global/global.actions';
import { environment } from '@environment/environment';

export interface NotificationsStateModel {
  latestNotifications: Notification[]
}

@State<NotificationsStateModel>({
  name: 'notifications',
  defaults: {
    latestNotifications: []
  },
})
@Injectable()
export class NotificationsState {
  constructor(private notificationService: NotificationService) {}

  @Action(LoadLatestNotifcations)
  loadLatestNotifcations(ctx: StateContext<NotificationsStateModel>) {
    return this.notificationService.getLatestNotifications(environment.latestNotificationsSize).pipe(
      map(notifications => ctx.dispatch(new LoadLatestNotifcationsSuccess(notifications))),
      catchError((error: any) => ctx.dispatch(new LoadLatestNotifcationsFailure(error)))
    )
  }

  @Action(LoadLatestNotifcationsSuccess)
  loadLatestNotifcationsSuccess(ctx: StateContext<NotificationsStateModel>, {notifications}: LoadLatestNotifcationsSuccess): void {
    ctx.setState({
      latestNotifications: notifications
    });
  }

  @Action(LoadLatestNotifcationsFailure)
  loadLatestNotifcationsFailure(ctx: StateContext<NotificationsStateModel>, {error}: LoadLatestNotifcationsFailure): void {
    ctx.dispatch(new ShowMessage({content: {title: 'error_loading_latest_notifications', details: error}, level: MessageLevel.ERROR}, 'notifications'));
  }
}
