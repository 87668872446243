
import {Labo} from '@domain/labo/Labo';
import {Sample, Document} from '@domain/samples/sample';
import {User, UserForm, UserZone, Adress, ResultDiffusion} from '@domain/user/user';
import {UserFilter} from '@features/users/users-filter';
import {UserActions} from '@states/user/users.actions.enum';
import {Pagination} from '@utils/pagination/Pagination';
import {PaginationOption} from '@utils/pagination/PaginationOption';

const PREFIX: string = '[Users] ';

export class LoadOneUser {
  static readonly type: string = PREFIX + UserActions.LOAD_ONE_USER;
  constructor(public id: string) {}
}
export class LoadOneUserSuccess {
  static readonly type: string = PREFIX + UserActions.LOAD_ONE_USER_SUCCESS;
  constructor(public user: User) {}
}

export class LoadOneUserFailure {
  static readonly type: string = PREFIX + UserActions.LOAD_ONE_USER_FAIL;
  constructor(public error: String) {}
}

export class LoadOneNewUser {
  static readonly type: string = PREFIX + UserActions.LOAD_ONE_NEW_USER;
}

export class LoadFilteredUsers {
  static readonly type: string = PREFIX + UserActions.LOAD_FILTERED_USERS;

  constructor() {
  }
}

export class LoadFilteredUsersSuccess {
  static readonly type: string = PREFIX + UserActions.LOAD_FILTERED_USERS_SUCCESS;
  constructor(public users: Pagination<User>) {}
}

export class LoadFilteredUsersFailure {
  static readonly type: string = PREFIX + UserActions.LOAD_FILTERED_USERS_FAIL;
  constructor(public error: string) {}
}

export class SaveOneUser {
  static readonly type: string = PREFIX + UserActions.SAVE_ONE_USER;
  constructor(public user: UserForm) {}
}

export class SaveOneUserSuccess {
  static readonly type: string = PREFIX + UserActions.SAVE_ONE_USER_SUCCESS;
}

export class SaveOneUserFailure {
  static readonly type: string = PREFIX + UserActions.SAVE_ONE_USER_FAIL;
  constructor(public error: string) {}
}

export class DeleteOneUser {
  static readonly type: string = PREFIX + UserActions.DELETE_ONE_USER;
  constructor(public id: string) {}
}

export class DeleteOneUserSuccess {
  static readonly type: string = PREFIX + UserActions.DELETE_ONE_USER_SUCCESS;
}

export class DeleteOneUserFailure {
  static readonly type: string = PREFIX + UserActions.DELETE_ONE_USER_FAIL;
  constructor(public error: string) {}
}

export class ChangePossibleLabo {
  static readonly type: string = PREFIX + UserActions.CHANGE_POSSIBLE_LABO;
  constructor(public filter: string) {}
}
export class ChangePossibleLaboSuccess {
  static readonly type: string = PREFIX + UserActions.CHANGE_POSSIBLE_LABO_SUCCESS;
  constructor(public labos: Pagination<Labo>) {}
}
export class ChangePossibleLaboFailure {
  static readonly type: string = PREFIX + UserActions.CHANGE_POSSIBLE_LABO_FAILURE;
  constructor(public error: string) {}
}

export class SuspendOneUser {
  static readonly type: string = PREFIX + UserActions.SUSPEND_ONE_USER;
  constructor(public id: string) {}
}

export class SuspendOneUserSuccess {
  static readonly type: string = PREFIX + UserActions.SUSPEND_ONE_USER_SUCCESS;
  constructor(public id: string) {}
}

export class SuspendOneUserFailure {
  static readonly type: string = PREFIX + UserActions.SUSPEND_ONE_USER_FAILURE;
  constructor(public error: string) {}
}

export class AddSelectedLabos {
  static readonly type: string = PREFIX + UserActions.ADD_SELECTED_LABOS;
  constructor(public labo: Labo) {}
}
export class RemoveSelectedLabos {
  static readonly type: string = PREFIX + UserActions.REMOVE_SELECTED_LABOS;
  constructor(public id: string) {}
}

export class UpdateUsersPagination {
  static readonly type: string = PREFIX + UserActions.UPDATE_USERS_PAGINATION;

  constructor(public pagination: PaginationOption) {
  }
}

export class UpdateUserFilter {
  static readonly type: string = PREFIX + UserActions.UPDATE_USER_FILTER
  constructor(public filter: UserFilter, public pagination?: PaginationOption) {
  }
}
export class UpdateUserSort {
  static readonly type: string = PREFIX + UserActions.UPDATE_USER_SORT;
  constructor(public sortByColumnName: string) {}
}

export class AddAffectedZone {
  static readonly type: string = PREFIX + UserActions.ADD_AFFECTED_ZONE;
  constructor(public zone: UserZone) {}
}

export class RemoveAffectedZone {
  static readonly type: string = PREFIX + UserActions.REMOVE_AFFECTED_ZONE;
  constructor(public id: string) {}
}

export class SaveAffectedZone {
  static readonly type: string = PREFIX + UserActions.SAVE_AFFECTED_ZONE;
}

export class SaveAffectedZoneSuccess {
  static readonly type: string = PREFIX + UserActions.SAVE_AFFECTED_ZONE_SUCCESS;
}

export class SaveAffectedZoneFailure {
  static readonly type: string = PREFIX + UserActions.SAVE_AFFECTED_ZONE_FAILURE;
  constructor(public error: string) {}
}

export class UpdateZone {
  static readonly type: string = PREFIX + UserActions.UPDATE_ZONE;
  constructor(public id: string, public zone: UserZone) {}
}

export class GetDomicileAddress {
  static readonly type: string = PREFIX + UserActions.GET_DOMICILE_ADDRESS;
  constructor(public id: string) {}
}

export class GetDomicileAddressFailure {
  static readonly type: string = PREFIX + UserActions.GET_DOMICILE_ADDRESS_FAILURE;
  constructor(public error: string) {}
}

export class SetDomicileAddress {
  static readonly type: string = PREFIX + UserActions.SET_DOMICILE_ADDRESS;
  constructor(public address: Adress) {}
}

export class GetUserSamples {
  static readonly type: string = PREFIX + UserActions.GET_USER_SAMPLES;
  constructor(public userId: string, public paginationOption: PaginationOption) {}
}

export class GetUserSamplesSuccess {
  static readonly type: string = PREFIX + UserActions.GET_USER_SAMPLES_SUCCESS;
  constructor(public samples: Pagination<Sample>) {}
}

export class GetUserSamplesFailure {
  static readonly type: string = PREFIX + UserActions.GET_USER_SAMPLES_FAILURE;
  constructor(public error: string) {}
}

export class GetUserDocuments {
  static readonly type: string = PREFIX + UserActions.GET_USER_DOCUMENTS;
  constructor(public id: string) {}
}

export class GetUserDocumentsSuccess {
  static readonly type: string = PREFIX + UserActions.GET_USER_DOCUMENTS_SUCCESS;
  constructor(public documents: Document[]) {}
}

export class GetUserDocumentsFailure {
  static readonly type: string = PREFIX + UserActions.GET_USER_DOCUMENTS_FAILURE;
  constructor(public error: string) {}
}


export class GetUserResultDiffusion {
  static readonly type: string = PREFIX + UserActions.GET_USER_RESULT_DIFFUSION;
  constructor(public id: string) {}
}

export class GetUserResultDiffusionSuccess {
  static readonly type: string = PREFIX + UserActions.GET_USER_RESULT_DIFFUSION_SUCCESS;
  constructor(public resultDiffusion: ResultDiffusion) {}
}

export class GetUserResultDiffusionFailure {
  static readonly type: string = PREFIX + UserActions.GET_USER_RESULT_DIFFUSION_FAILURE;
  constructor(public error: string) {}
}

export class GetFilteredPreleveurs {
  static readonly type: string = PREFIX + UserActions.GET_FILTERED_PRELEVEURS;
  constructor() {}
}
export class GetFilteredPreleveursSuccess {
  static readonly type: string = PREFIX + UserActions.GET_FILTERED_PRELEVEURS_SUCCESS;
  constructor(public preleveurs: Pagination<User>) {}
}

export class GetFilteredPreleveursFailure {
  static readonly type: string = PREFIX + UserActions.GET_FILTERED_PRELEVEURS_FAILURE;
  constructor(public error: string) {}
}

export class UpdatePreleveursFilter {
  static readonly type: string = PREFIX + UserActions.UPDATE_PRELEVEURS_FILTER;
  constructor(public filter: string) {}
}

export class UpdatePreleveursPaginationOption {
  static readonly type: string = PREFIX + UserActions.UPDATE_PRELEVEURS_PAGINATION;
  constructor(public pagination: PaginationOption) {}
}

export class AssociatePreleveur {
  static readonly type: string = PREFIX + UserActions.ASSOCIATE_PRELEVEUR;
  constructor(public preleveur: User | null | undefined) {}
}

export class DissociatePreleveur {
  static readonly type: string = PREFIX + UserActions.DISSOCIATE_PRELEVEUR;
}

export class SavePreleveurAssociation {
  static readonly type: string = PREFIX + UserActions.SAVE_PRELEVEUR_ASSOCIATION;
  constructor() {}
}

export class SavePreleveurAssociationSuccess {
  static readonly type: string = PREFIX + UserActions.SAVE_PRELEVEUR_ASSOCIATION_SUCCESS;
  constructor(public updatedUser: User) {}
}

export class SavePreleveurAssociationFailure {
  static readonly type: string = PREFIX + UserActions.SAVE_PRELEVEUR_ASSOCIATION_FAILURE;
  constructor(public error: string) {}
}

