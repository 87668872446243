import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { ProfileEnum } from '@domain/user/user';
import {AuthenticatedGuard} from '@security/authenticated.guard';
import { AnnuaireEditComponent } from './annuaire-edit/annuaire-edit.component';
import { AnnuaireListComponent } from './annuaire-list/annuaire-list.component';

const routes: Routes = [
    {path: '', canActivate: [AuthenticatedGuard], data:{roles:[ProfileEnum.ADMIN, ProfileEnum.PERSONNEL_LABO]}, component: AnnuaireListComponent},
    {path: 'edit', canActivate: [AuthenticatedGuard], data:{roles:[ProfileEnum.ADMIN, ProfileEnum.PERSONNEL_LABO]}, component: AnnuaireEditComponent},
    {path: 'edit/:id', canActivate: [AuthenticatedGuard], data:{roles:[ProfileEnum.ADMIN, ProfileEnum.PERSONNEL_LABO]}, component: AnnuaireEditComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes),],
  exports: [RouterModule],
})
export class NursesRoutingModule{}