import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl} from '@angular/platform-browser';

@Pipe({
  name: 'safizer',
})
export class SafizerPipe implements PipeTransform {
  /**
   * Constructor
   * @param _sanitized
   */
  constructor(private _sanitized: DomSanitizer) {}

  /**
   * Safe everything
   * @param value
   * @param args
   */
  transform(
    value: string,
    args?: 'html' | 'style' | 'url' | 'resource' | 'script'
  ): SafeHtml | SafeUrl | SafeStyle | SafeScript | SafeResourceUrl {
    switch (args) {
      case 'html':
        return this._sanitized.bypassSecurityTrustHtml(value);
      case 'style':
        return this._sanitized.bypassSecurityTrustStyle(value);
      case 'url':
        return this._sanitized.bypassSecurityTrustUrl(value);
      case 'script':
        return this._sanitized.bypassSecurityTrustScript(value);
      case 'resource':
      default:
        return this._sanitized.bypassSecurityTrustResourceUrl(value);
    }
  }
}
