import {HttpClient, HttpContext, HttpParams, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LaboRdvStatusCount} from '@domain/labo/Labo';
import {Sample, SamplesFilter, StatusSampleEnum, Document, Check, Timeslot, SampleInfos} from '@domain/samples/sample';
import {AssociationModeEnum} from '@domain/user/user';
import {environment} from '@environment/environment';
import {IS_ERROR_SNACKBAR_ENABLED, NO_SNACKBAR_CONTEXT} from '@utils/interceptor/error.interceptor';
import {Pagination} from '@utils/pagination/Pagination';
import {PaginationOption, SortDirectionEnum} from '@utils/pagination/PaginationOption';
import {DateTime} from 'luxon';
import {Observable, take} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SampleService {
  constructor(public http: HttpClient) {
  }

  get(id: string): Observable<Sample> {
    return this.http.get(environment.api + '/samples/' + id, {context: NO_SNACKBAR_CONTEXT});
  }

  findByFilterPaging(filter: SamplesFilter, paginationOption: PaginationOption): Observable<Pagination<Sample>> {
    let params: string[] = [];
    if (filter.inLabo != null) {
      params.push('inLabo:' + filter.inLabo);
    }
    if (filter.date) {
      params.push('dateSample:' + filter.date);
    }
    if (filter.laboId) {
      params.push('laboId:' + filter.laboId);
    }
    if(filter.preleveurId) {
      params.push("preleveurId:" + filter.preleveurId)
    }
    if (filter.status) {
      params.push('status:[' + filter.status + ']');
    }
    if (filter.patientName && filter.patientName.length > 0) {
      params.push('patientName~' + filter.patientName);
    }
    let httpParams: HttpParams = new HttpParams()
      .set('q', params.join(','))
      .set('page', paginationOption.pageIndex!)
      .set('size', paginationOption.pageSize!);

    if (paginationOption.sortByColumnName) {
      httpParams = httpParams.set('sort', (paginationOption.sortDirection === SortDirectionEnum.ASC ? paginationOption.sortByColumnName : '-' + paginationOption.sortByColumnName))
    }

    return this.http.get<Pagination<Sample>>(environment.api + '/samples', {
      params: httpParams,
      context: NO_SNACKBAR_CONTEXT,
    });
  }

  findByPatientId(userId: string, paginationOption: PaginationOption): Observable<Pagination<Sample>> {
    let httpParams: HttpParams = new HttpParams()
      .set('q', 'patientId:' + userId)
      .set('page', paginationOption.pageIndex!)
      .set('size', paginationOption.pageSize!);
    if (paginationOption.sortByColumnName) {
      httpParams = httpParams.set('sort', paginationOption.sortDirection === SortDirectionEnum.ASC ? paginationOption.sortByColumnName : '-' + paginationOption.sortByColumnName);
    }
    return this.http.get<Pagination<Sample>>(environment.api + '/samples', {params: httpParams});
  }

  updateStatus(sampleId: string, status: StatusSampleEnum): Observable<Sample> {
    const params: HttpParams = new HttpParams().set('status', status);
    return this.http.post(environment.api + '/samples/update-status/' + sampleId, {}, {params, context: NO_SNACKBAR_CONTEXT});
  }

  getLaboRdvStatusCount(fromDate: Date, toDate: Date): Observable<LaboRdvStatusCount[]> {
    return this.http.get<LaboRdvStatusCount[]>(environment.api + '/samples/sample-counts',
      {
        params: {
          fromDate: DateTime.fromJSDate(fromDate).toFormat('yyyy-MM-dd'),
          toDate: DateTime.fromJSDate(toDate).toFormat('yyyy-MM-dd'),
        },
        context: new HttpContext().set(IS_ERROR_SNACKBAR_ENABLED, true),
      },
    )
  }

  confirmSample(sample: Sample): Observable<Sample> {
    return this.http.patch<Sample>(environment.api + `/samples/${sample.id}/confirm`, sample,
      { context: new HttpContext().set(IS_ERROR_SNACKBAR_ENABLED, true) })
  }

  validateSamplesForDay(laboId: string, date: string): Observable<void> {
    const params = new HttpParams().set('date', date)

    return this.http.put<void>(environment.api + `/samples/${laboId}/validate`, null,
      {
        context: new HttpContext().set(IS_ERROR_SNACKBAR_ENABLED, true),
        params,
      })
  }

  savePlanning(samples: Sample[]): Observable<Sample[]> {
    return this.http.patch<Sample[]>(environment.api + `/samples/save-planning`, samples, {
      context: new HttpContext().set(IS_ERROR_SNACKBAR_ENABLED, true)
    });
  }

  downloadPrescription(id: string): Observable<HttpResponse<Blob>> {
    return this.http.get(environment.api + '/documents/' + id, {
      observe: 'response',
      responseType: 'blob',
    });
  }

  getCheck(laboId: string): Observable<Pagination<Check>> {
    let httpParams: HttpParams = new HttpParams().set('q', 'laboId:' + laboId);
    return this.http.get<Pagination<Check>>(environment.api + '/checks', {params: httpParams});
  }

  getSampleTimeslot(lat: string, lng: string, dates: string[], patientId: string, searchMode: AssociationModeEnum): Observable<Timeslot[]> {
    const params: HttpParams = new HttpParams().set('lat', lat).set('lon', lng).set('dates', dates.join(',')).set('patient', patientId).set('searchMode', searchMode);
    return this.http.get<Timeslot[]>(environment.api + '/samples/timeslots', {params});
  }

  create(sample: Sample): Observable<Sample> {
    return this.http.post(environment.api + '/samples/create', sample);
  }

  getSamplesByDay(filter: SamplesFilter): Observable<SampleInfos[]> {
    let params: string[] = [];
    if (filter.date) {
      params.push('dateSample:' + filter.date);
    }
    if (filter.inLabo !== undefined) {
      params.push('inLabo:' + filter.inLabo);
    }
    if (filter.search) {
      params.push('search~' + filter.search)
    }
    const httpParams: HttpParams = new HttpParams().set('q', params.join(','));
    return this.http.get<SampleInfos[]>(environment.api + '/samples/day-planning', {params: httpParams, context: NO_SNACKBAR_CONTEXT});
  }

  changeSaveStatus(id: string, jeun?: boolean, consignePatient?: string, consignePreleveur?: string): Observable<SampleInfos> {
    const sample: Sample = {
      id: id,
      saved: true,
      jeun: jeun,
      consignePreleveur: consignePreleveur,
      consignePatient: consignePatient,
    }
    return this.http.patch<SampleInfos>(environment.api + '/samples/' + id + '/save-status', sample);
  }

  toBase64(file: Blob | File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader: FileReader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

}

