// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import {version} from '../../package.json';

// eslint-disable-next-line @typescript-eslint/typedef
export const environment = {
  production: false,
  version,
  //api: 'http://bff.kantysbio.local/api', // Url d'appel vers le server
  api: 'http://localhost:8080/api',
  //api: 'https://kantysbio-bff.preprod.kantysbio-prelevement.fr/api',
  apiNominatim: 'https://nominatim.openstreetmap.org',
  mapTiles: 'googleWithoutPoi', // 'google' or 'googleWithoutPoi' or 'osm'
  keycloak: {
    url: 'http://auth.kantysbio.local',
    //url: 'https://kantysbio-id.preprod.kantysbio-prelevement.fr',
    realm: 'kantysbio',
    client: 'kantysbio-web-client',
  },
  firebase: {
    config: {
      apiKey: "AIzaSyDOXrelgawWw27iprKcTLeDit8RjrYdvHs",
      authDomain: "kantysbio-4bd7d.firebaseapp.com",
      projectId: "kantysbio-4bd7d",
      storageBucket: "kantysbio-4bd7d.appspot.com",
      messagingSenderId: "48173497884",
      appId: "1:48173497884:web:f40c0985c25367300d29d9",
      measurementId: "G-XNCGRZDQ3F"
    },
    iidUrl: "https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}",
    iidToken: "AAAACzddPhw:APA91bGkOc4pDl1Xv01mp0wT_X9Yyo7CigqGr3xlHXJnm75lruWOYqmy-5r1DMcX0gF2TfUYIX1kWefEvlV0wwTtqL-pjCDAIiA7coc9_L-kgKCVKIdYKxGP0v5clw3R6eKnmYCA4yZt",
    vapidKey: "BLZynZS7W9mQT0Ctxex1AVOpdOhXysug9x9IrZYtDwWfBnIXIzZGh0DDIfD5X_qvfI36xkk1sJroIxYEpY_bVqI",
    topics_autosubscribe: ["test_topic", "test_topic2"],
    refresh_registration_days: 5
  },
  latestNotificationsSize: 20
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
