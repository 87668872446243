<ng-container *ngIf="user$ | async as userDetails">
  <ng-container *ngIf="chosenLabos$ | async as chosenLabos">
    <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
      <div class="header row align-items-center">
        <button type="button" class="col-auto px-3" mat-button (click)="goBack()">
          <mat-icon aria-hidden="false" aria-label="go back icon">arrow_back</mat-icon>
        </button>
        <div class="col-auto me-auto" *ngIf="!this.readonly">{{'users.'+(userDetails?.id ? 'updateHeader': 'creationHeader') | transloco}}
        </div>
        <div class="col-auto">
          <button mat-button type="button" class="col-auto align-items-center" (click)="associatePreleveur(userDetails.associatedPreleveur)" *ngIf="userDetails.informations?.profile === profileEnum.PATIENT && userDetails.id && !readonly">
            <mat-icon class="align-self-center mb-1" >group</mat-icon>
            {{'users.association.associatePreleveur' | transloco}}
          </button>
        </div>
        <div class="col-auto">
          <button mat-button type="button" class="col-auto align-items-center" *ngIf="userDetails.informations?.profile === profileEnum.PATIENT && userDetails.id && !readonly" [routerLink]="['/users','edit', userDetails.id, 'samples']">
            <mat-icon class="align-self-center mb-1" >settings</mat-icon>
            {{'users.samples.view' | transloco}}
          </button>
        </div>
        <div class="col-auto">
          <button mat-button type="button" class="col-auto align-items-center" *ngIf="userDetails.informations?.profile === profileEnum.PATIENT && userDetails.id && !readonly" (click)="createSample(userDetails.id, userDetails?.adresses, userDetails.associatedPreleveur)">
            <mat-icon class="align-self-center mb-1" >settings</mat-icon>
            {{'users.samples.create' | transloco}}
          </button>
        </div>
        <div class="col-auto">
          <button mat-button type="button" class="col-auto align-items-center" *ngIf="userDetails.informations?.profile === profileEnum.PRELEVEUR && !isManager && userDetails.id && !readonly" [routerLink]="['/users','edit', userDetails.id, 'zones']">
            <mat-icon class="align-self-center mb-1" >architecture</mat-icon>
            {{'users.affectation' | transloco}}
          </button>
        </div>
        <div class="col-auto">
          <button mat-button type="button" class="col-auto align-items-center" *ngIf="userDetails?.id && userDetails.id && !userDetails.suspended && !readonly && !isManager && !isSecretaire" (click)="suspend(userDetails.id)">
            <mat-icon class="align-self-center mb-1">pause_circle</mat-icon>
            {{'users.suspend' | transloco}}
          </button>
        </div>
        <div class="col-auto">
          <button mat-button type="button" class="col-auto delete align-items-center " *ngIf="userDetails?.id && userDetails.id && !readonly && !isManager && !isSecretaire" (click)="deleteUser(userDetails.id)">
            <mat-icon class="align-self-center mb-1" >delete</mat-icon>
            {{'app.delete' | transloco}}
          </button>
        </div>
        <button *ngIf="!readonly" mat-flat-button class="height-75 col-auto ms-3 me-5 justify-content-end helveticaBold13Blue"
                [disabled]="userForm.invalid" type="submit" >{{'app.save' | transloco}}</button>
      </div>
      <div *ngIf="userDetails?.id && userDetails.id && userDetails.suspended" class="row align-items-center suspended">
        <div class="ms-5 col-auto me-auto">
          <mat-label>{{'users.suspended' | transloco}}</mat-label>
        </div>
        <div class="col-auto me-5">
          <button mat-button class="col-auto align-items-center" type="button" (click)="suspend(userDetails.id)">
            <mat-icon>play_circle</mat-icon>
            {{'users.activate' | transloco}}
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="formGroup p-3">
            <div class="title p-3 align-self-center" > {{'users.formTitleRole' | transloco}}</div>
          <mat-form-field appearance="fill" class="w-75" *ngIf="(!isSecretaire || !isManager) && userDetails.id || isAdmin" formGroupName="informations">
            <mat-select formControlName="profile" [placeholder]="'users.role' | transloco" (selectionChange)="changeProfil($event)">
              <mat-option *ngIf="isAdmin || isManager || isSecretaire" [value] = "profileEnum.PATIENT">{{'users.profiles.PATIENT' | transloco}}</mat-option>
              <mat-option *ngFor="let profile of profilesWithoutAdmin" [value]="profile">{{'users.profiles.'+profile | transloco}}</mat-option>
              <mat-option *ngIf="roles?.includes('ADMIN')" [value]="profileEnum.ADMIN">{{'users.profiles.ADMIN' | transloco}}</mat-option>
              <mat-option *ngIf="roles?.includes('ADMIN') || roles?.includes('SECRETAIRE') " [value]="profileEnum.SECRETAIRE">{{'users.profiles.SECRETAIRE' | transloco}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      </div>
      <div class="row justify-content-around">
        <div class="col-6" formGroupName="identity">
          <div class="formGroup p-3">
            <div class="title p-3 align-self-center" > {{'users.formTitleIdentity' | transloco}}</div>
            <div>
              <label class="mb-1 civilite" id="civilite-label">{{'users.civility' | transloco}}</label>
              <mat-radio-group aria-labelledby="civilite-label" formControlName="civility" class="d-flex flex-row mb-3" [disabled]="userDetails.id != null && userDetails.id!= undefined">
                <mat-radio-button value="1">M.</mat-radio-button>
                <mat-radio-button value="2" class="px-4">Mme</mat-radio-button>
                <mat-radio-button value="3" class="px-4">Mlle</mat-radio-button>
              </mat-radio-group>
            </div>
            <div>
              <label class="mb-1 civilite" id="gender-label">{{'users.gender.title' | transloco}}</label>
              <mat-radio-group aria-labelledby="gender-label" formControlName="gender" class="d-flex flew-row mb-3" [disabled]="userDetails.id !== null && userDetails.id!= undefined">
                <mat-radio-button value="1">{{'users.gender.man' | transloco}}</mat-radio-button>
                <mat-radio-button value="2" class="px-4">{{'users.gender.woman' | transloco}}</mat-radio-button>
                <mat-radio-button value="3" class="px-4">{{'users.gender.neutral' | transloco}}</mat-radio-button>
              </mat-radio-group>
            </div>
            <mat-form-field appearance="fill" class="w-75">
              <mat-label >{{'users.lastname' | transloco}}</mat-label>
              <input matInput formControlName="lastname" placeholder="{{'users.lastname' | transloco }}">
            </mat-form-field>
            <mat-form-field appearance="fill" class="w-75">
              <mat-label >{{'users.firstname' | transloco}}</mat-label>
              <input matInput formControlName="firstname" placeholder="{{'users.firstname' | transloco }}">
            </mat-form-field>
            <mat-form-field appearance="fill" class="w-75">
              <mat-label >{{'users.birthName' | transloco}}</mat-label>
              <input matInput formControlName="birthName" placeholder="{{'users.birthName' | transloco }}">
            </mat-form-field>
            <mat-form-field appearance="fill" class="w-75" *ngIf="userForm.get('informations')?.get('profile')?.value === profileEnum.ADMIN || userForm.get('informations')?.get('profile')?.value === profileEnum.PRELEVEUR">
              <mat-label>{{'users.raisonSociale' | transloco}}</mat-label>
              <input matInput formControlName="raisonSociale" placeholder="{{'users.raisonSociale' | transloco}}">
            </mat-form-field>
            <mat-form-field appearance="fill" class="w-75 pb-2" *ngIf="userForm.get('informations')?.get('profile')?.value === profileEnum.PATIENT">
              <mat-label>{{'users.numeroSecu' | transloco}}</mat-label>
              <input matInput formControlName="numeroSecu" [pattern]="secuPattern" [placeholder]="'users.numeroSecu' | transloco">
            </mat-form-field>
            <mat-form-field appearance="fill" class="w-75">
              <mat-label>{{'users.birthDate' | transloco}}</mat-label>
              <input matInput type="date" placeholder="{{'users.birthDate' | transloco}}" formControlName="birthDate" required [readonly]="userDetails.id">
            </mat-form-field>
          </div>
        </div>


        <div class="col-6" formArrayName="adresses">
          <div class="formGroup p-3">
            <div class="title p-3 align-self-center" > {{'users.formTitleAdresse' | transloco}}</div>
            <div class="w-100">
              <app-adresse-form class="d-block w-75" [formGroup]="this.userForm?.get('adresses') | toFormGroup" (formChanged)="updateAddressRequired()"></app-adresse-form>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6" formGroupName="informations">
          <div class="formGroup p-3">
            <div class="title p-3 align-self-center" > {{'users.formTitle' | transloco}}</div>
            <div class="ms-1 mb-2 helveticaBold13Black" *ngIf="[profileEnum.PRELEVEUR, profileEnum.PATIENT].includes(userForm.get('informations')?.get('profile')?.value)"> {{'users.login_required' | transloco}}</div>
            <mat-form-field appearance="fill" class="w-75">
              <mat-label>{{'users.email' | transloco}}</mat-label>
              <input matInput [placeholder]="'users.email' | transloco" formControlName="email" [pattern]="emailPattern">
              <mat-error *ngIf="userForm.get('informations')?.get('email')?.hasError('emailExists')">{{'users.email_exists' | transloco}} <br/></mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" class="w-75" *ngIf="userForm.get('informations')?.get('profile')?.value !== profileEnum.TOURNEE_MANAGER">
              <mat-label>{{'users.phone' | transloco}}</mat-label>
              <input matInput [placeholder]="'users.phone' | transloco" formControlName="phone"  [pattern]="telPattern" >
              <mat-error *ngIf="userForm.get('informations')?.get('phone')?.hasError('phoneExists')"> {{'users.phone_exists' | transloco}} <br/></mat-error>
            </mat-form-field>
            <app-laboratory-select *ngIf="userDetails.informations?.profile !== profileEnum.PATIENT && userForm.get('informations')?.get('profile')?.value !== profileEnum.PATIENT" [listLabo]="chosenLabos" class=" d-block w-75"></app-laboratory-select>
          </div>
          </div>

        <div class="col-6" formGroupName="resultDiffusion" *ngIf="userForm.get('informations')?.get('profile')?.value === profileEnum.PATIENT">
          <div class="formGroup p-3">
            <div class="title p-3 align-self-center" > {{'users.formTitleResultDiffusion' | transloco}}</div>
            <div class="px-3">
              <mat-label id="internet-server-label" class="fw-bold">{{'users.resultDiffusion.internetServer' | transloco}}</mat-label>
              <mat-radio-group formControlName="resultByInternetServer" aria-labelledby="internet-server-label" class="d-flex flew-row mb-3">
                <mat-radio-button [value]="true">{{'app.yes' | transloco}}</mat-radio-button>
                <mat-radio-button [value]="false" class="px-4">{{'app.no' | transloco}}</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="px-3">
              <mat-label id="mail-label" class="fw-bold">{{'users.resultDiffusion.mail' | transloco}}</mat-label>
              <mat-radio-group formControlName="resultByMail" aria-labelledby="mail-label" class="d-flex flew-row mb-3">
                <mat-radio-button [value]="true">{{'app.yes' | transloco}}</mat-radio-button>
                <mat-radio-button [value]="false" class="px-4">{{'app.no' | transloco}}</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="px-3">
              <mat-label id="postal-label" class="fw-bold">{{'users.resultDiffusion.postal' | transloco}}</mat-label>
              <mat-radio-group formControlName="resultByPostal" aria-labelledby="postal-label" class="d-flex flew-row mb-3">
                <mat-radio-button [value]="true">{{'app.yes' | transloco}}</mat-radio-button>
                <mat-radio-button [value]="false" class="px-4">{{'app.no' | transloco}}</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="px-3">
              <mat-label id="nurse-diffusion-label" class="fw-bold">{{'users.resultDiffusion.nurseDiffusion' | transloco}}</mat-label>
              <mat-radio-group formControlName="resultDiffusionNurse" aria-labelledby="nurse-diffusion-label" class="d-flex flew-row mb-3">
                <mat-radio-button [value]="true">{{'app.yes' | transloco}}</mat-radio-button>
                <mat-radio-button [value]="false" class="px-4">{{'app.no' | transloco}}</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>

        <div class="col-6" *ngIf="userForm.get('informations')?.get('profile')?.value === profileEnum.PRELEVEUR">
          <div class="formGroup p-3">
            <div class="title p-3 align-self-center" > {{'users.formAssociationMode' | transloco}}</div>
            <div class="px-3">
              <mat-radio-group formControlName="associationMode" aria-labelledby="internet-server-label" class="d-flex flex-column">
                <mat-radio-button [value]="associationModeEnum.STRICT" class="my-3">
                  <span class="fw-bold">{{'users.association.strict' | transloco}} </span>
                  {{'users.association.strictLegend' | transloco}}
                </mat-radio-button>
                <mat-radio-button [value]="associationModeEnum.MIXED" class="my-3">
                  <span class="fw-bold">{{'users.association.mixed' | transloco}}</span>
                  {{'users.association.mixedLegend' | transloco}}
                </mat-radio-button>
                <mat-radio-button [value] = "associationModeEnum.OPEN" class="my-3">
                  <span class="fw-bold">{{'users.association.open' | transloco}}</span>
                  {{'users.association.openLegend' | transloco}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>
        </div>
    </form>
  </ng-container>
  </ng-container>

