
<div class="header row align-items-center">
  <button type="button" class="col-auto px-3" mat-button (click)="goBack()">
    <mat-icon aria-hidden="false" aria-label="go back icon">arrow_back</mat-icon>
  </button>
  <div class="col-auto me-auto">{{'users.zones.affectation' | transloco}}</div>
  <ng-container *ngIf="affectedZones$ | async">
    <ng-container *ngIf="isAdmin || isPersonnelLabo">
      <button mat-flat-button class="height-75 col-auto ms-3 me-5 justify-content-end helveticaBold13" (click)="save()" >{{'app.save' | transloco}}</button>
    </ng-container>
  </ng-container>
</div>

<div class="row">
  <div id="mapZones" class="col-8">
  </div>
  <div class="col-4">
    <ng-container *ngIf="affectedZones$ | async as zones  ; else loadingZonesBlock">
      <ng-container *ngIf="zones.length > 0; else noZoneBlock">
        <div class="title d-flex justify-content-center pt-1">Zones affectées au préleveur</div>
       <ng-container *ngIf="affectedZones$ | async as affectedZones">
         <ng-container *ngFor="let zone of affectedZones; let i = index">
           <div class="zoneNames">
             <div class="d-flex flex-row align-items-center justify-content-between">
               <div class="title d-flex flex-row justify-content-start align-items-center">
                 <div class="colorView me-3" [ngStyle]="{'background-color': zone.color}"></div>
                <div>{{zone.laboName}} - {{zone.name}}</div>
               </div>
               <button mat-icon-button type="button" (click)="removeZone(zone)">
                 <mat-icon color="warn">close</mat-icon>
               </button>
             </div>
           </div>
         </ng-container>
       </ng-container>
      </ng-container>
      <ng-template #noZoneBlock>
        <div class="legend d-flex flex-column justify-content-center align-items-center">
          <div>{{'users.zones.affect_on_map' | transloco}}</div>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #loadingZonesBlock>
      <div class="w-100 d-flex flex-column justify-content-center align-items-center laboLoader">
        <div class="loader">
        </div>
      </div>
    </ng-template>
  </div>
</div>


