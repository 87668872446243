import {Cabinet, PreleveurCabinet} from '@domain/cabinet/cabinet';
import {Selector} from '@ngxs/store';
import {CabinetsState, CabinetsStateModel} from '@states/cabinets/cabinets.state';
import {Pagination} from '@utils/pagination/Pagination';
import {PaginationOption} from '@utils/pagination/PaginationOption';

export class CabinetsStateSelectors {
  @Selector([CabinetsState])
  static filteredCabinets(state: CabinetsStateModel): Pagination<Cabinet> {
    return state.filteredCabinets;
  }
  @Selector([CabinetsState])
  static cabinetsFilter(state: CabinetsStateModel): string {
    return state.cabinetsFilter;
  }
  @Selector([CabinetsState])
  static cabinetsPaginationOption(state: CabinetsStateModel): PaginationOption {
    return state.cabinetsPaginationOption;
  }
  @Selector([CabinetsState])
  static cabinet(state: CabinetsStateModel): Cabinet | undefined {
    return state.currentCabinet;
  }

  @Selector([CabinetsState])
  static preleveursFilter(state: CabinetsStateModel): string {
    return state.preleveursFilter;
  }

  @Selector([CabinetsState])
  static cabinetPreleveurs(state: CabinetsStateModel): PreleveurCabinet[] {
    return state.currentCabinetPreleveurs;
  }

  @Selector([CabinetsState])
  static filteredPreleveurs(state: CabinetsStateModel): Pagination<PreleveurCabinet> {
    return state.filteredPreleveurs;
  }

  @Selector([CabinetsState])
  static preleveursPaginationOption(state: CabinetsStateModel): PaginationOption {
    return state.preleveursPaginationOption;
  }
}
