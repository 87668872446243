import { Pipe, PipeTransform } from '@angular/core';
import {Disponibility} from '@utils/pipe/cut-hours.pipe';

@Pipe({
  name: 'sampleCount'
})
export class SampleCountPipe implements PipeTransform {

  transform(creneaux: Disponibility[], zoneId: string): number {
    
    return creneaux.reduce((previousValue, currentValue) => previousValue + currentValue.samples?.filter(s => s.zoneId === zoneId).length!, 0);
  }

}
