import {HttpClient, HttpContext} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@environment/environment';
import {AddressNormalized, SearchAddressResult} from '@utils/coordinates/coordinates';
import { IS_ERROR_SNACKBAR_ENABLED } from '@utils/interceptor/error.interceptor';
import { Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  constructor(public http: HttpClient) {}

  searchByAddress(coord: AddressNormalized): Observable<SearchAddressResult[]> {

    if (!coord.city && !coord.postalCode) {
      return of();
    }

    let searchQuery: string[] = [];
    if (coord?.address1) {
      searchQuery.push("street=" + coord.address1);
    }
    if (coord?.postalCode) {
      searchQuery.push("postalcode=" + coord.postalCode);
    }
    if (coord?.city) {
      searchQuery.push("city=" + coord.city);
    }
    if (coord?.country) {
      searchQuery.push("country=" + coord.country);
    }

    return this.http.get<SearchAddressResult[]>(environment.apiNominatim + '/search?' + searchQuery.join('&') + '&format=json', {context: new HttpContext().set(IS_ERROR_SNACKBAR_ENABLED, true)})
  }

}
