import { environment } from '@environment/environment';
import { Store } from '@ngxs/store';
import { RegisterForNotification, ShowNotification, SubscribeToTopic } from '@states/global/global.actions';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { KeycloakService } from 'keycloak-angular';
import { CookieService } from 'ngx-cookie-service';

export async function initializeNotifications(store: Store, keycloakService: KeycloakService, cookieService: CookieService) {

    const app = initializeApp(environment.firebase.config);
    const messaging = getMessaging(app);

    const shouldTriggerRegistration = () => {
        return !cookieService.get('fbreg')
    }
    
    const setRegistrationDone = () => {
        // set a cookie to profit of its expiration to refresh token regularly
        cookieService.set('fbreg', 'true', { expires: environment.firebase.refresh_registration_days })
    }

    if (shouldTriggerRegistration()) {
        // get token for the user and device
        const currentToken = await getToken(messaging, { vapidKey: environment.firebase.vapidKey });

        // automatically subscribe to some topics (async)
        const topics=environment.firebase.topics_autosubscribe;
        if (topics) {
            topics.forEach(topic => store.dispatch(new SubscribeToTopic(currentToken, topic, environment.firebase.iidToken)));
        }

        // store registration for the kantys user in the backend (async)
        store.dispatch(new RegisterForNotification({userId: keycloakService.getKeycloakInstance().profile?.id, tokenId: currentToken, topics}))
            .subscribe( () => setRegistrationDone() );
    }

    // handle incoming messages
    onMessage(messaging, (payload) => {
        store.dispatch(new ShowNotification(payload));
    });
}