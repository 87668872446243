<div class="planing">
    <div class="header d-flex justify-content-between w-100">
        <div class="d-flex">
            <div class="p-3">
                {{'labos.list.headerTitle' | transloco}}
            </div>
            <div class="py-2 mx-4" *ngIf="isAdmin">
                <button mat-flat-button class="height-75 col-auto ms-3 me-5 justify-content-end" routerLink="/labos/edit">{{'labos.create' | transloco}}</button>
            </div>
        </div>
        <ng-container *ngIf="labosRdvStatusCountsLoaded$ | async">
            <div *ngIf="labosRdvStatusCounts$ | async as labosRdvStatusCounts" class="py-2 px-5">
                <span class="rdvPending">{{ "labos.list.pending" | transloco }}</span> <span class="rdvPendingNumber">{{getRDVATraiter(labosRdvStatusCounts)}}</span>
            </div>
        </ng-container>
    </div>

    <div class="weekHeader d-flex justify-content-between">
        <div class="weekSelector py-3 d-flex justify-content-between">
            <div class="weekNumber" (click)="picker.open()">
                {{ 'labos.list.weekShort' | transloco }} {{ range.controls['start'].value | date:'ww' }}
            </div>
            <div class="weekSelected" (click)="picker.open()" >
                <span>
                    {{ range.controls['start'].value | date:'EEEEEE dd/LL' }} - {{ range.controls['end'].value | date:'EEEEEE dd/LL' }}
                </span>
                <img class="icon" src="assets/img/carret.svg" class="weekCarret">
            </div>
            <div class="weekPaginator">
                <img class="icon" src="assets/img/left.svg" class="weekPaginatorLeft" (click)="prevWeek()">
                |
                <img class="icon" src="assets/img/right.svg" class="weekPaginatorRight" (click)="nextWeek()">
            </div>
        </div>
        <div *ngFor="let day of weekRange()" class="weekDay pt-3 d-flex w-100">
            <div class="d-flex flex-column w-100 align-items-center" >
                <div class="pb-1">
                    {{ day | date:'EEEEEE dd' | uppercase}}
                </div>
                <div class="d-flex justify-content-around w-100">
                    <img class="icon" src="assets/img/check.svg" >
                    <img class="icon" src="assets/img/three-points.svg" >
                </div>
            </div>
        </div>
    </div>
    <mat-date-range-input [formGroup]="range" [rangePicker]="picker" class="hide-datepicker-input">
        <input matStartDate formControlName="start"  (dateChange)="startDateSelection()">
        <input matEndDate formControlName="end" (dateChange)="endDateSelection()" >
    </mat-date-range-input>
    <mat-date-range-picker #picker></mat-date-range-picker>
    <ng-container *ngIf="labosLoaded$ | async ; else loadingLabosBlock">
        <ng-container *ngIf="labos$ | async as labos">
            <div *ngFor="let labo of labos" class="d-flex justify-content-between">
                <div routerLink="/labos/edit/{{labo.id}}" class="labo-name d-flex flex-column justify-content-center px-3">{{ labo.name }}</div>
                <ng-container *ngIf="labosRdvStatusCountsLoaded$ | async ; else loadingCountBlock">
                    <div class="d-flex w-100" *ngIf="labosRdvStatusCounts$ | async as labosRdvStatusCounts">
                        <div *ngFor="let day of weekRange()" class="py-2 d-flex w-100 justify-content-between weekCounts" [class.clickable]="isAdmin || isManager" [routerLink]="isAdmin || isManager ? [labo.id, 'planning'] : null" [queryParams]=" {date: day | date:'yyyy-MM-dd'}">
                            <div class="py-1 w-50 weekCountPlanified">
                                <span [class.countZero]="getPlanifiedStatus(labosRdvStatusCounts, labo, day) === 0">
                                    {{ getPlanifiedStatus(labosRdvStatusCounts, labo, day) }}
                                </span>
                            </div>
                            <div class="py-1 w-50 weekCountPending">
                                <span [class.countZero]="getPendingStatus(labosRdvStatusCounts, labo, day) === 0">
                                    {{ getPendingStatus(labosRdvStatusCounts, labo, day) }}
                                </span>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-template #loadingCountBlock>
                    <div class="w-100 d-flex justify-content-center countLoader">
                        <div class="loader">
                        </div>
                    </div>
                </ng-template>
            </div>
        </ng-container>
    </ng-container>
    <ng-template #loadingLabosBlock>
        <div class="w-100 d-flex justify-content-center labosLoader">
            <div class="loader">
            </div>
        </div>
    </ng-template>
</div>
