import {DragDropModule} from '@angular/cdk/drag-drop';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';
import {CabinetEditComponent} from '@features/cabinets/cabinet-edit/cabinet-edit.component';
import {CabinetsRoutingModule} from '@features/cabinets/cabinets-routing.module';
import {TranslocoModule} from '@ngneat/transloco';
import {NgxsModule} from '@ngxs/store';
import {CabinetsState} from '@states/cabinets/cabinets.state';
import {SharedModule} from '@utils/shared.module';
import { CabinetListComponent } from './cabinet-list/cabinet-list.component';

@NgModule({
  declarations: [CabinetEditComponent, CabinetListComponent],
  imports: [CommonModule, CabinetsRoutingModule, TranslocoModule, MatIconModule, MatButtonModule, SharedModule, NgxsModule.forFeature([CabinetsState]), MatFormFieldModule, MatInputModule, DragDropModule, MatPaginatorModule],
  providers: [],
})
export class CabinetsModule {}
