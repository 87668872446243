import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSelectModule} from '@angular/material/select';
import {NurseAdresseFormComponent} from '@features/nurses/adresse-form/nurse-adresse-form.component';
import {TranslocoModule} from '@ngneat/transloco';
import {NgxsModule} from '@ngxs/store';
import {NursesState} from '@states/nurse/nurses.state';
import {SharedModule} from '@utils/shared.module';
import {AnnuaireListComponent} from './annuaire-list/annuaire-list.component';
import {AnnuaireEditComponent} from './annuaire-edit/annuaire-edit.component';
import { NursesRoutingModule } from './nurses-routing.module';

@NgModule({
  declarations: [NurseAdresseFormComponent, AnnuaireListComponent, AnnuaireEditComponent],
  imports: [
    CommonModule,
    NursesRoutingModule,
    NgxsModule.forFeature([NursesState]),
    ReactiveFormsModule,
    TranslocoModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    FormsModule,
    SharedModule,
    MatDialogModule,
  ],
  providers: [],
})
export class NurseModule {}
