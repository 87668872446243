import {DayEnum, Labo} from '@domain/labo/Labo';

export interface User {
  id?: string;
  firstname?: string;
  lastname?: string;
  birthDate?: Date;
  email?: string;
  profile?: ProfileEnum;
  laboratory?: Labo[];
  phone?: string;
  birthName?: string;
  gender?: string;
  civility?: string;
  adresses?: Adress[];
  disponibilities?: UserSchedule[];
  aggregatedDisponibilities?: AggregatedUserSchedule[];
  exceptions?: UserScheduleException[];
  aggregatedExceptions?: AggregatedUserScheduleException[];
  ipp?: string;
  raisonSociale?: string;
  suspended?: boolean;
  zones?: UserZone[];
  firstLogin?: boolean;
  notificationsEnabled?: boolean;
  laboFavorite?: string;
  numeroSecu?: string;
  resultDiffusion?: ResultDiffusion;
  associatedPreleveur?: User | null;
  associationMode?: AssociationModeEnum;
  relativePreleveurs?: string[];
}

export interface Adress {
  id?: string;
  adr1?: string;
  adr2?: string;
  cp?: string;
  ville?: string;
  pays?: string;
  commentaire?: string;
  domicile?: boolean;
}

export interface NoZoneSchedule {
  zones: string[];
  schedule: string[];
}

export interface UserSchedule {
  id?: string;
  day: DayEnum;
  start?: string;
  end?: string;
  zoneId?: string;
  schedule: string[][];
}

export interface AggregatedUserSchedule {
  day: DayEnum;
  schedule: string[][];
  zoneId: string;
}

export interface UserScheduleException {
  id?: string;
  date: string;
  start?: string;
  end?: string;
  zoneId?: string;
  forced?: boolean;
  schedule?: string[][];
}

export interface AggregatedUserScheduleException {
  date: string;
  schedule: string[][];
  zoneId: string;
}

export interface ResultDiffusion {
  id?: string;
  resultByInternetServer?: boolean;
  resultByMail?: boolean;
  resultByPostal?: boolean;
  resultDiffusionNurse?: boolean;
}

export interface UserForm {
  id?: string;
  suspended?: boolean;
  identity?: IdentityForm;
  adresses?: Adress[];
  informations?: InformationsForm;
  disponibilities?: UserSchedule[];
  exceptions?: UserScheduleException[];
  firstLogin?: boolean;
  notificationsEnabled?: boolean;
  laboFavorite?: string;
  resultDiffusion?: ResultDiffusion;
  associatedPreleveur?: User | null;
  associationMode?: AssociationModeEnum;
}

export interface IdentityForm {
  firstname?: string;
  lastname?: string;
  birthName?: string;
  gender?: string;
  civility?: string;
  birthDate?: Date;
  raisonSociale?: string;
  numeroSecu?: string;
}

export interface InformationsForm {
  email?: string;
  phone?: string;
  profile?: ProfileEnum;
  laboratory?: LaboFormUser[];
  ipp?: string;
}
export interface LaboFormUser {
  id?: string;
  name?: string;
}
export enum ProfileEnum {
  PATIENT = 'PATIENT',
  PRELEVEUR = 'PRELEVEUR',
  PERSONNEL_LABO = 'PERSONNEL_LABO',
  TOURNEE_MANAGER = 'TOURNEE_MANAGER',
  ADMIN = 'ADMIN',
  SECRETAIRE = 'SECRETAIRE',
}

export class UserZone {
  id?: string;
  zoneId?: string;
  name?: string;
  laboId?: string;
  laboName?: string;
  color?: string;
}

export enum AssociationModeEnum {
  OPEN = 'OPEN',
  MIXED = 'MIXED',
  STRICT = 'STRICT',
}
