<ng-container *ngIf="labo$ | async as labo">
  <form [formGroup]="laboForm" (ngSubmit)="onSubmit()">
    <div class="header row align-items-center">
      <button type="button" class="col-auto px-3" mat-button (click)="goBack()">
        <mat-icon aria-hidden="false" aria-label="go back icon">arrow_back</mat-icon>
      </button>
      <div class="col-auto me-auto">{{'labos.' + (labo?.id ? 'updateHeader' : 'creationHeader') | transloco}}
        <label *ngIf="labo?.id"> {{labo.name}}</label>
      </div>
      <div class="col-auto" *ngIf="isAdmin">
        <button mat-button class="col-auto delete align-items-center " *ngIf="labo?.id && labo.id"
                [routerLink]="['/labos', 'edit', labo.id, 'zonesPreleveurs']">
          <mat-icon class="align-self-center mb-1">architecture</mat-icon>
          {{'labos.zonesPreleveurs.manageZonesPreleveurs' | transloco}}
        </button>
        <button mat-button class="col-auto delete align-items-center " *ngIf="labo?.id && labo.id && isAdmin"
                [routerLink]="['/labos', labo.id, 'bilans']">
          <mat-icon class="align-self-center mb-1">edit</mat-icon>
          {{'labos.checks.manageCheck' | transloco}}
        </button>
        <button mat-button class="col-auto delete align-items-center " *ngIf="labo?.id && labo.id"
                (click)="deleteLabo(labo.id)">
          <mat-icon class="align-self-center mb-1">delete</mat-icon>
          {{'labos.delete' | transloco}}
        </button>
      </div>
      <button mat-flat-button class="height-75 col-auto ms-3 me-5 justify-content-end helveticaBold13"
              [disabled]="!laboForm.valid" *ngIf="isAdmin" type="submit">{{'app.save' | transloco}}</button>
    </div>


    <div class="formGroup row align-items-center">
      <div class="col-auto icon mt-3 mb-3 ms-4 pt-2 col-1">
        <img src="assets/img/laboratory.svg" alt="icon">
      </div>
      <mat-form-field appearance="fill" class="col-3 mt-3 ms-5">
        <mat-label>{{'labos.name' | transloco}}</mat-label>
        <input matInput formControlName="name" placeholder="{{'labos.name' | transloco }}">
      </mat-form-field>
    </div>
    <div class="d-flex flex-row">
      <div class=" d-flex flex-column justify-content-around">
        <div formGroupName="coord">
          <div class="formGroup p-3">
            <div class="title pb-3"> {{'labos.coordForm.title' | transloco}}</div>
            <mat-form-field appearance="fill" class="width-75">
              <mat-label>{{'labos.coordForm.address' | transloco}}</mat-label>
              <input matInput placeholder="{{'labos.coordForm.address' | transloco}}" formControlName="address1"
                     required>
            </mat-form-field>
            <mat-form-field appearance="fill" class="width-75">
              <mat-label>{{'labos.coordForm.address' | transloco}}</mat-label>
              <input matInput placeholder="{{'labos.coordForm.address' | transloco}}" formControlName="address2">
            </mat-form-field>
            <mat-form-field appearance="fill" class="width-75">
              <mat-label>{{'labos.coordForm.zipCode' | transloco}}</mat-label>
              <input matInput placeholder="{{'labos.coordForm.zipCode' | transloco}}" required
                     [matAutocomplete]="autoCp" (input)="inputCpSearch($event)" formControlName="postalCode">
              <mat-autocomplete #autoCp="matAutocomplete">
                <mat-option *ngFor="let suggest of autosuggestCp$ | async" (onSelectionChange)="select(suggest)"
                            [value]="suggest.codesPostaux[0]">
                  {{suggest.nom}} - {{suggest.codesPostaux.length ? suggest.codesPostaux[0] : ''}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field appearance="fill" class="width-75">
              <mat-label>{{'labos.coordForm.city' | transloco}}</mat-label>
              <input matInput placeholder="{{'labos.coordForm.city' | transloco}}" [matAutocomplete]="auto"
                     (input)="inputVilleSearch($event)" required formControlName="city">
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let suggest of autosuggestVille$ | async" (onSelectionChange)="select(suggest)"
                            [value]="suggest.nom">
                  {{suggest.nom}} - {{suggest.codesPostaux.length ? suggest.codesPostaux[0] : ''}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field appearance="fill" class="width-75">
              <mat-label>{{'labos.coordForm.country' | transloco}}</mat-label>
              <input matInput placeholder="{{'labos.coordForm.country' | transloco}}" required
                     formControlName="country">
            </mat-form-field>
            <mat-form-field appearance="fill" class="width-75">
              <mat-label>{{'labos.coordForm.phoneNumber' | transloco}}</mat-label>
              <input matInput placeholder="{{'labos.coordForm.phoneNumber' | transloco}}"
                     (blur)="formatTel()"
                     pattern="[0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2}" required formControlName="phone">
            </mat-form-field>
          </div>
        </div>
        <div class="formGroup" formGroupName="schedule">
          <label class="title p-3">{{'labos.scheduleForm.title' | transloco}}</label>
          <p class="legend px-3 pb-3"> {{'labos.scheduleForm.legend' | transloco}}</p>
          <div class="px-3 schedule-row" *ngFor="let day of week" formGroupName="{{day}}">
            <div class="row-schedule">
              <mat-checkbox class="col-2" formControlName="checkbox"
                            (change)="resetSchedule(day)"> {{'labos.scheduleForm.days.' + day | transloco}}</mat-checkbox>
              <ng-container *ngIf="laboForm.value.schedule[day].checkbox" class="row-schedule">
                <mat-form-field appearance="fill" class="hourInput mt-auto">
                  <input matInput type="time" formControlName="startTime">
                </mat-form-field>
                <label class="px-3 py-3"> - </label>
                <mat-form-field appearance="fill" class="hourInput mt-auto">
                  <input matInput type="time" formControlName="endTime">
                </mat-form-field>
                <ng-container *ngIf="!getAddButtonStatus(day); else otherTimePeriodAdded">
                  <button type="button" *ngIf="isAdmin || isPersonnelLabo" (click)="setButtonChange(day, true)"
                          class="add col-auto px-3">
                    <mat-icon color="primary">add</mat-icon>
                  </button>
                </ng-container>
                <ng-template #otherTimePeriodAdded class="row-schedule">
                  <p class="px-3 py-3 col-auto mt-auto"> et </p>
                  <mat-form-field appearance="fill" class="hourInput mt-auto">
                    <input matInput type="time" formControlName="startTime2">
                  </mat-form-field>
                  <label class="px-3 py-3"> - </label>
                  <mat-form-field appearance="fill" class="hourInput mt-auto">
                    <input matInput type="time" formControlName="endTime2">
                  </mat-form-field>
                  <button *ngIf="isAdmin || isPersonnelLabo" type="button" class="add px-3 col-auto"
                          (click)="setButtonChange(day, false)">
                    <mat-icon [ngStyle]="{'color':'#DE3F3F'}">close</mat-icon>
                  </button>
                </ng-template>
              </ng-container>
            </div>

          </div>
        </div>
      </div>

      <div class="col-6">
        <div class="settings">
          <label class="title p-3">{{'labos.settings' | transloco}}</label>
          <p class="legend px-3"> {{'labos.settingsLegend' | transloco}}</p>
          <div>
            <div>
              <label class="sub-title p-3">{{'labos.limitsTimeSlots' | transloco}}</label>
            </div>
            <mat-form-field appearance="fill" class="col-4 mt-3 ms-3">
              <mat-label>{{'labos.lowerLimitPerTimeSlot' | transloco}}</mat-label>
              <input matInput type="number" [formControl]="laboForm.get('lowerLimitPerTimeSlot')! | toFormControl"
                     placeholder="{{'labos.lowerLimitPerTimeSlot' | transloco }}">
            </mat-form-field>
            <mat-form-field appearance="fill" class="col-4 mt-3 ms-3">
              <mat-label>{{'labos.upperLimitPerTimeSlot' | transloco}}</mat-label>
              <input matInput type="number" [formControl]="laboForm.get('upperLimitPerTimeSlot')! | toFormControl"
                     placeholder="{{'labos.upperLimitPerTimeSlot' | transloco }}">
            </mat-form-field>
            <div>
              <label class="sub-title p-3">{{'labos.noHomeRxPrice' | transloco}}</label>
            </div>
            <mat-form-field appearance="fill" class="col-4 mt-3 ms-3">
              <input matInput type="number" [min]="0" [formControl]="laboForm.get('noHomeRxPrice')! | toFormControl"
                     [placeholder]="'labos.noHomeRxPrice' | transloco">
            </mat-form-field>
            <div>
              <label class="sub-title p-3">{{'labos.samplingManual' | transloco}}</label>
            </div>
            <mat-form-field appearance="fill" class="col-8 mt-3 ms-3">
              <input matInput [formControl]="laboForm.get('samplingManual')! | toFormControl" [pattern]="sitePattern"
                     [placeholder]="'labos.samplingManual' | transloco">
              <mat-error>{{'messages.error.labos.sampling_manual_error' | transloco}}</mat-error>
            </mat-form-field>
            <mat-checkbox class="sub-title p-3" (change)="autoriseFixedScheduleChange($event)"
                          formControlName="autoriseFixedSchedule">{{'labos.fixedSchedule' | transloco}}</mat-checkbox>
            <div *ngIf="laboForm.get('autoriseFixedSchedule')?.value" class="d-flex flex-column justify-content-end">
              <div formArrayName="fixedSchedule">
                <div *ngFor="let fixedScheduleForm of fixedSchedule.controls;let i = index">
                  <div [formGroupName]="i">
                    <mat-form-field appearance="fill" class="col-2 mt-3 ms-3">
                      <mat-label>{{'labos.delay' | transloco}}</mat-label>
                      <input [min]="0" formControlName="delay" matInput type="number"
                             [placeholder]="'labos.delay' | transloco">
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="col-2 mt-3 ms-3">
                      <mat-label>{{'labos.price' | transloco}}</mat-label>
                      <input [min]="0" formControlName="price" matInput type="number"
                             [placeholder]="'labos.price' | transloco">
                    </mat-form-field>
                    <button type="button" class="align-self-center" mat-icon-button
                            (click)="removeFixedScheduleOption(i)">
                      <mat-icon color="warn">close</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <div class="ms-4 col-4 mb-3">
                <button class="d-block ms-auto me-auto" [disabled]="fixedSchedule.invalid"
                        *ngIf="fixedSchedule.length > 0 && fixedSchedule.length <3" type="button" mat-icon-button
                        (click)="addFixedScheduleOption()">
                  <mat-icon color="primary">add</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-container>
