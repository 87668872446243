<ng-container *ngIf="currentNurse$ | async as nurse">
    <form [formGroup]="nurseForm" (ngSubmit)="onSubmit()">
      <div class="header row align-items-center">
        <button type="button" class="col-auto px-3" mat-button (click)="goBack()">
          <mat-icon aria-hidden="false" aria-label="go back icon">arrow_back</mat-icon>
        </button>
        <div class="col-auto me-auto">{{'nurses.'+(nurse?.id ? 'updateHeader': 'creationHeader') | transloco}}
        </div>
        <div class="col-auto">
          <button mat-icon-button type="button" class="col-auto delete align-items-center " *ngIf="nurse?.id && nurse.id" (click)="deleteNurse(nurse.id)">
            <mat-icon class="align-self-center mb-1" >delete</mat-icon>
          </button>
          <label *ngIf="nurse?.id && nurse.id" (click)="deleteNurse(nurse.id)" class="col-auto align-self-center" >{{'app.delete' | transloco}}</label>
        </div>
        <button mat-flat-button class="height-75 col-auto ms-3 me-5 justify-content-end helveticaBold13" [disabled]="!nurseForm.valid" type="submit" >{{'app.save' | transloco}}</button>
      </div>

      <div class="row justify-content-around">

        <div class="col-6" formGroupName="informations">
          <div class="formGroup p-3">
            <div class="title p-3 align-self-center" > {{'nurses.formTitleInformations' | transloco}}</div>
            <div class="d-flex flex-row justify-content-start align-items-center">
              <div class="mb-3 me-3">
                <img *ngIf="nurseForm.get('profilePicture')?.value; else noImage" [src]="nurseForm.get('profilePicture')?.value | safizer" alt="profile-picture" class="icon">
                <ng-template #noImage>
                  <div class="icon d-flex flex-column justify-content-center align-items-center">
                    <mat-icon>person</mat-icon>
                  </div>
                </ng-template>
              </div>
              <app-file-upload accept="image/png, image/svg" (filesChanged)="changeProfilePicture($event)" [multiple]="false"></app-file-upload>
            </div>
            <mat-error *ngIf="this.nurseForm?.hasError('alreadyExists')">{{'nurses.already_exists' | transloco}} <br></mat-error>
            <mat-form-field appearance="fill" class="w-75">
              <mat-label >{{'nurses.name' | transloco}}</mat-label>
              <input matInput formControlName="name" placeholder="{{'nurses.name' | transloco }}">
            </mat-form-field>
            <mat-form-field appearance="fill" class="w-75">
              <mat-label>{{'nurses.email' | transloco}}</mat-label>
              <input matInput [placeholder]="'nurses.email' | transloco" formControlName="email" [pattern]="emailPattern">
            </mat-form-field>
            <mat-form-field appearance="fill" class="w-75">
              <mat-label>{{'nurses.phone' | transloco}}</mat-label>
              <input matInput [placeholder]="'nurses.phone' | transloco" formControlName="phone"  [pattern]="telPattern">
            </mat-form-field>
          </div>
        </div>
        <div class="col-6" formArrayName="address">
          <div class="formGroup p-3">
            <div class="title p-3 align-self-center" > {{'users.formTitleAdresse' | transloco}}</div>
            <div class="w-100">
              <app-nurse-adresse-form [formGroup]="getAdresseFormGroup()" (formChanged)="this.nurseForm?.get('address')?.setValue($event)"></app-nurse-adresse-form>
            </div>
          </div>
        </div>
      </div>
    </form>
</ng-container>


