import {Component, OnInit, ChangeDetectionStrategy, Inject, OnDestroy} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PageEvent} from '@angular/material/paginator';
import {SampleInfos} from '@domain/samples/sample';
import {User} from '@domain/user/user';
import {Select, Store} from '@ngxs/store';
import {
  AssociatePreleveur,
  DissociatePreleveur,
  GetFilteredPreleveurs,
  UpdatePreleveursFilter,
  UpdatePreleveursPaginationOption,
} from '@states/user/users.actions';
import {UsersStateSelector} from '@states/user/users.selectors';
import {Pagination} from '@utils/pagination/Pagination';
import {PaginationOption} from '@utils/pagination/PaginationOption';
import {Observable, Subject, takeUntil} from 'rxjs';

@Component({
  selector: 'app-associate-preleveur-dialog',
  templateUrl: './associate-preleveur-dialog.component.html',
  styleUrls: ['./associate-preleveur-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssociatePreleveurDialogComponent implements OnInit, OnDestroy {
  @Select(UsersStateSelector.filteredPreleveurs) preleveurs$: Observable<Pagination<User>>;
  @Select(UsersStateSelector.preleveursFilter) filter$: Observable<string>;
  @Select(UsersStateSelector.preleveursPaginationOption) paginationOption$: Observable<PaginationOption>;
  @Select(UsersStateSelector.currentAssociatedPreleveur) currentAssociatedPreleveur$: Observable<User>;
  private destroy: Subject<boolean> = new Subject<boolean>();
  associatedPreleveur: FormControl;
  constructor(public dialogRef: MatDialogRef<AssociatePreleveurDialogComponent>, private store: Store) {

  }

  ngOnInit(): void {
    this.associatedPreleveur = new FormControl();
    this.currentAssociatedPreleveur$.pipe(takeUntil(this.destroy)).subscribe(preleveur => {
      this.associatedPreleveur.setValue(preleveur?.id ? preleveur.id : null);
    });

    this.store.dispatch(new GetFilteredPreleveurs());
  }

  updateFilter(event: string): void {
    this.store.dispatch(new UpdatePreleveursFilter(event));
  }

  onChangePage(pe: PageEvent): void {
    this.store.dispatch(
      new UpdatePreleveursPaginationOption({
        pageIndex: pe.pageIndex,
        pageSize: pe.pageSize,
      })
    );
  }

  close() {
    this.dialogRef.close(false);
  }
  save(): void {
    this.dialogRef.close(true);
  }

  delete(): void {
    this.store.dispatch(new DissociatePreleveur());
  }

  select(preleveur: User): void {
    this.store.dispatch(new AssociatePreleveur(preleveur));
  }
  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

}
