import {Component, OnInit, ChangeDetectionStrategy, OnDestroy} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {MatDialog} from '@angular/material/dialog';
import {MatSelectChange} from '@angular/material/select';
import {DocumentTypeEnum, Sample, SampleInfos, SamplesFilter, StatusSampleEnum} from '@domain/samples/sample';
import {DialogSampleInfosComponent} from '@features/samples/dialog-sample-infos/dialog-sample-infos.component';
import {Select, Store} from '@ngxs/store';
import {DownloadPrescription, GetSamplesByDay, UpdatePlanningFilters, ValidateSaveStatus} from '@states/samples/samples.actions';
import {SamplesStateSelector} from '@states/samples/samples.selectors';
import {DateTime} from 'luxon';
import {BehaviorSubject, firstValueFrom, Observable, Subject, take, takeUntil} from 'rxjs';

@Component({
  selector: 'app-planning-secretaire',
  templateUrl: './planning-secretaire.component.html',
  styleUrls: ['./planning-secretaire.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanningSecretaireComponent implements OnInit, OnDestroy {

  date: FormControl = new FormControl(DateTime.now().startOf('day').toJSDate());
  statusEnum: typeof StatusSampleEnum = StatusSampleEnum;
  @Select(SamplesStateSelector.samplesDayPlanning) dayPlanning$: Observable<SampleInfos[]>;
  @Select(SamplesStateSelector.planningFilter) planningFilter$: Observable<SamplesFilter>;
  @Select(SamplesStateSelector.dayPlanningLoaded) userSamplesLoaded$: Observable<boolean>;
  nbSamples: number;
  private destroy: Subject<boolean> = new Subject<boolean>();
  filterSearch$: BehaviorSubject<string>;
  constructor(private store: Store, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.filterSearch$ = new BehaviorSubject('');
    this.loadSamples();
  }
  async loadSamples(): Promise<void> {
    let day: Date = new Date(this.date.value);
    day.setHours(day.getHours() - day.getTimezoneOffset() / 60);
    const day_str: string = day.toISOString().split('T')[0];
    this.store.dispatch(new UpdatePlanningFilters({date: day_str, inLabo: undefined, search: ''}));
    this.dayPlanning$.pipe(takeUntil(this.destroy)).subscribe(planning => this.nbSamples = planning.length);
  }

  prevDay() {
    let day: Date = new Date(this.date.value);
    day.setHours(day.getHours() - day.getTimezoneOffset() / 60);
    day.setDate(day.getDate() - 1);
    this.date.setValue(day);
    const day_str: string = day.toISOString().split('T')[0];
    this.store.dispatch(new UpdatePlanningFilters({date: day_str}));
  }

  nextDay() {
    let day: Date = new Date(this.date.value);
    day.setHours(day.getHours() - day.getTimezoneOffset() / 60);
    day.setDate(day.getDate() + 1);
    this.date.setValue(day);
    const day_str: string = day.toISOString().split('T')[0];
    this.store.dispatch(new UpdatePlanningFilters({date: day_str}));
  }

  getChecks(sample: SampleInfos): string | undefined {
    return sample.checks?.map(check => check.libelle).join(' + ');
  }

  hasUploadedPresciption(sample: SampleInfos): boolean {
    if (sample.prescription) {
      return sample.prescription.filter(doc => doc.documentType === DocumentTypeEnum.PRESCRIPTION).length >= 1;
    }
    return false;
  }

  downloadPrescription(sample: SampleInfos): void {
    sample.prescription.forEach(doc => {
        if(doc.id) {
          this.store.dispatch(new DownloadPrescription(doc.id, doc.filename || ''));
        }
      });
  }

  async openDetails(sample: SampleInfos, index: number): Promise<void> {
    const dialogRef = this.dialog.open(DialogSampleInfosComponent, {
      width: '1000px',
      data: {sample: sample},
      disableClose: true,
    });
    const samples: SampleInfos[] = await firstValueFrom(this.dayPlanning$);
    dialogRef.afterClosed().subscribe( (result) => {
      if (result && index + 1 < this.nbSamples) {
        this.openDetails(samples[index + 1], index + 1);
      } else {
        // TO DO: SORT LIST
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

  searchChanged(event: string): void {
    this.store.dispatch(new UpdatePlanningFilters({search: event}));
  }

  updateLocationFilter(event: boolean | undefined): void {
    this.store.dispatch(new UpdatePlanningFilters({inLabo: event}));
  }

  goToDate(event: MatDatepickerInputEvent<Date, Date | null>): void {
    if (event.value) {
      let day: Date = event.value;
      day.setHours(day.getHours() - day.getTimezoneOffset() / 60);
      const day_str: string = day.toISOString().split('T')[0];
      this.store.dispatch(new UpdatePlanningFilters({date: day_str}));
    }
  }

  updateSaveStatus(sampleId: string): void {
    this.store.dispatch(new ValidateSaveStatus(sampleId));
  }
}
