import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  pure: true
})
export class FilterPipe implements PipeTransform {

  transform(value: Array<any>, callback: any, ...args: any[]): any {
    return value.filter(v => callback(v, ...args));
  }

}
