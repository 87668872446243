<div class="planning" *ngIf="currentLabo$ | async as labo">
  <div class="header d-flex justify-content-between w-100" *ngIf="pendingSamples$ | async as pending">
    <div class="d-flex w-100 justify-content-end" *ngIf="affectedConfirmedSamples$ | async as confirmed">
      <div class="me-auto d-flex">
        <div>
          <mat-icon role="button" [routerLink]="['/labos']" class="p-3 back-arrow">arrow_backward</mat-icon>
        </div>
        <div class="p-3">
          {{ labo.name }}
        </div>
      </div>
      <div class="py-2 mx-4" *ngIf="affectedSamples$ | async as affected">
        <button mat-button class="height-75 col-auto ms-3 me-5 justify-content-end" [routerLink]="['/labos', 'edit', labo.id]">
          <mat-icon class="edit-icon"> edit</mat-icon>
          {{ 'labos.infoLabo' | transloco }}
        </button>
        <button mat-flat-button *ngIf="affected.length > 0 && !isPast" class="height-75 col-auto ms-3 me-5 justify-content-end" (click)="validate()">{{ 'samples.validateDay' | transloco }}</button>
      </div>
      <div class="d-flex justify-content-between align-self-center h-75">
        <div class="px-3 ms-4 border-number">
          <span class="fw-normal pe-2">{{ 'samples.totalSamples' | transloco }} :</span> <span class="fs-4">{{ pending.length + confirmed.length }} </span>
        </div>
        <div class="px-3">
          <span class="fw-light pe-2">{{ 'samples.toProcess' | transloco }} :</span> <span class="fs-4">{{ pending.length }} </span>
        </div>
      </div>
    </div>
  </div>

  <div class="row" cdkDropListGroup>
    <div class="pe-4 creneaux col-9">
      <div class="day-header d-flex">
        <div class="day-selector py-3 d-flex">
          <div class="day">
            <span>
              {{ date.toJSDate() | date: 'EEEE dd LLLL' | titlecase }}
            </span>
          </div>
          <div class="day-selected" (click)="picker.open()">
            <img src="assets/img/carret.svg" class="day-carret" />
          </div>
          <div class="day-paginator">
            <img src="assets/img/left.svg" class="day-paginator-left" (click)="changeDay(-1)" />
            |
            <img src="assets/img/right.svg" class="day-paginator-right" (click)="changeDay(+1)" />
          </div>
        </div>
        <div class="d-flex w-100 justify-content-between align-items-end position-relative">
          <div *ngFor="let hour of [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21]; let last = last" [class.last]="last" class="hour-container d-flex flex-grow-1 justify-content-start pt-3 align-items-center">
            <div class="hour">{{ hour }} h</div>
          </div>
        </div>
      </div>
      <div class="hide-datepicker-input">
        <input [matDatepicker]="picker" class="hide-datepicker-input d-block" (dateChange)="dateChanged($event)" />
        <mat-datepicker #picker></mat-datepicker>
      </div>


      <ng-container *ngIf="preleveurLoaded$ | async; else preleveurLoading">
        <ng-container *ngIf="preleveurs$ | async as preleveurs">
          <ng-container *ngIf="affectedConfirmedSamples$ | async as confirmed">
            <ng-container *ngFor="let preleveur of preleveurs">
              <ng-container *ngIf="!preleveur.suspended">
                <ng-container *ngIf="(preleveur.aggregatedExceptions! | filter: filterByDate:date | filter: isHoliday).length <= 0">
                  <ng-container *ngIf="preleveur.aggregatedExceptions ! | filter: filterByDate:date as aggregated">
                    <div *ngFor="let exception of aggregated"
                         class="d-flex justify-content-between"
                         [routerLink]="(preleveur.aggregatedExceptions! | filter: filterByDate:date)[0]?.schedule?.length ? [preleveur.id] : null"
                         [queryParams]="{date: date.toFormat('yyyy-MM-dd')}"
                         role="button">
                      <div
                        class="preleveur-name d-flex flex-column justify-content-center px-3">{{ preleveur.firstname }} {{ preleveur.lastname }} {{ exception.zoneId | getLaboName: labo }}</div>
                      <ng-container *ngIf="confirmed | filter: filterByPreleveur:preleveur.id! | filter: filterCreneauByZone:exception.zoneId! as filtered">
                        <ng-container
                          *ngIf="exception?.schedule | cutHours: filtered as creneaux">
                          <ng-container *ngFor="let creneau of creneaux; let first = first">
                            <div
                              cdkDropList
                              [cdkDropListData]="creneau.samples"
                              class="position-relative"
                              (cdkDropListDropped)="dropped($event, preleveur.id!, creneau, exception.zoneId)"
                              (cdkDropListEntered)="setSelected($event.container.element.nativeElement, creneau)"
                              (cdkDropListExited)="removeSelected($event.container.element.nativeElement, creneau)"
                              [class.free]="creneau.status === status.FREE"
                              [class.away]="creneau.status === status.AWAY"
                              [class.first-creneau]="first"
                              [class.forced]="creneau.forced"
                              [cdkDropListDisabled]="(creneau.status === status.AWAY || creneau.forced) || isPast "
                              [ngStyle]="{width: 'calc(' + creneau.size + ' * (100% / 16))'}">
                              <div class="crenaux-count count"
                                   *ngIf="first">{{ creneaux | sampleCount: exception.zoneId }}</div>
                              <div
                                *ngIf="creneau.status === status.FREE"
                                class="d-flex align-items-center justify-content-center h-100"
                                [class.overbooked]="creneau.samples?.length! > labo?.samplesPerTimeSlotForPreleveur!"
                                [class.full]="creneau.samples?.length! === labo?.samplesPerTimeSlotForPreleveur!"
                                [class.normal]="creneau.samples?.length! < labo?.samplesPerTimeSlotForPreleveur! && creneau.samples?.length! > 0">
                                {{ (creneau.samples! | filter: filterCreneauByZone:exception.zoneId).length }}
                              </div>
                            </div>
                          </ng-container>
                        </ng-container>
                      </ng-container>

                    </div>
                  </ng-container>
                  <ng-container *ngIf="(preleveur.aggregatedExceptions! | filter: filterByDate:date).length <= 0">
                    <div *ngFor="let dispo of preleveur.aggregatedDisponibilities! | filter: filterByDay:day:labo"
                         class="d-flex justify-content-between"
                         [routerLink]="(preleveur.aggregatedDisponibilities! | filter: filterByDay:day:labo)[0]?.schedule?.length ? [preleveur.id] : null"
                         [queryParams]="{date: date.toFormat('yyyy-MM-dd')}"
                         role="button">
                      <div class="preleveur-name d-flex flex-row justify-content-start align-items-center px-3">
                        <div>{{ preleveur.firstname }} {{ preleveur.lastname }} {{ dispo.zoneId | getLaboName: labo }}</div>
                        <img *ngIf="isAssociatedPreleveur(preleveur)" src="assets/img/user.svg" alt="associatedPreleveurIcon" class=" ms-3 small-icon d-block">
                        <img *ngIf="isInCabinet(preleveur)" src="assets/img/users.svg" alt="sameCabinetIcon" class="small-icon d-block ms-3">
                      </div>
                      <ng-container
                        *ngIf="dispo">
                        <ng-container *ngIf="confirmed | filter: filterByPreleveur:preleveur.id! | filter: filterCreneauByZone:dispo.zoneId! as filtered">
                          <ng-container
                            *ngIf="dispo?.schedule | cutHours: filtered as creneaux">
                            <ng-container *ngFor="let creneau of creneaux; let first = first">
                              <div
                                cdkDropList
                                [cdkDropListData]="creneau.samples"
                                class="position-relative"
                                (cdkDropListDropped)="dropped($event, preleveur.id!, creneau, dispo.zoneId)"
                                (cdkDropListEntered)="setSelected($event.container.element.nativeElement, creneau)"
                                (cdkDropListExited)="removeSelected($event.container.element.nativeElement, creneau)"
                                [class.free]="creneau.status === status.FREE"
                                [class.away]="creneau.status === status.AWAY"
                                [class.first-creneau]="first"
                                [class.forced]="creneau.forced"
                                [cdkDropListDisabled]="(creneau.status === status.AWAY || creneau.forced) || isPast "
                                [ngStyle]="{width: 'calc(' + creneau.size + ' * (100% / 16))'}">
                                <div class="crenaux-count count"
                                     *ngIf="first">{{ creneaux | sampleCount: dispo.zoneId }}</div>
                                <div
                                  *ngIf="creneau.status === status.FREE"
                                  class="d-flex align-items-center justify-content-center h-100"
                                  [class.overbooked]="creneau.samples?.length! > labo?.samplesPerTimeSlotForPreleveur!"
                                  [class.full]="creneau.samples?.length! === labo?.samplesPerTimeSlotForPreleveur!"
                                  [class.normal]="creneau.samples?.length! < labo?.samplesPerTimeSlotForPreleveur! && creneau.samples?.length! > 0">
                                  {{ (creneau.samples! | filter: filterCreneauByZone:dispo.zoneId).length }}
                                </div>
                              </div>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </div>
                  </ng-container>
                </ng-container>


              </ng-container>

            </ng-container>
            <div class="d-flex justify-content-between labo-count" role="button" [routerLink]="['/samples', 'worklist', labo.id, date.toFormat('yyyy-MM-dd')]" *ngIf="inLaboSampleCount$ | async as laboCount">
              <div class="preleveur-name d-flex align-items-center px-3">
                <img src="assets/img/laboratory.svg" alt="{{ 'menu.labos' | transloco }}" title="{{ 'menu.labos' | transloco }}" />
                <div>{{ 'samples.laboCount' | transloco }}</div>
                <div class="count">{{ laboCount.pending + laboCount.confirmed }}</div>
              </div>
              <ng-container *ngIf="labo | getLaboSchedule: day as sched">
                <div [ngStyle]="{width: 'calc(' + (sched[0] - 5).toString() +' * (100% / 16))'}" class="away"></div>
                <div [ngStyle]="{width: 'calc(' + (sched[1] - sched[0]).toString() + ' * (100% / 16))'}" class="free d-flex align-items-center justify-content-between count" *ngIf="sched[1] - sched[0] > 0">
                  <div class="w-50 d-flex justify-content-center confirmed-border align-items-center">
                    <img src="assets/img/check.svg" />
                    <span class="confirmed pt-1">
                    {{ laboCount.confirmed }}
                  </span>
                  </div>
                  <div class="w-50 d-flex justify-content-center align-items-center">
                    <img src="assets/img/three-points.svg" />
                    <span class="overbooked pt-1">{{ laboCount.pending }}</span>
                  </div>
                </div>
                <div [ngStyle]="{width: 'calc(' + (21 -sched[1]).toString() + ' * (100% / 16))'}" class="away"></div>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>

    <div class="col-3 appointments" *ngIf="pendingSamples$ | async as samples">
      <div class="my-3">{{ 'samples.dragAndDrop' | transloco }}</div>
      <div class="d-flex justify-content-between align-items-center appointment-header">
        <div>
          <span> {{ 'samples.table.patient' | transloco }}</span>
          <button mat-icon-button (click)="toggleSortDirection()">
            <mat-icon *ngIf="currentSort == sortEnum.ASC"> arrow_upward</mat-icon>
            <mat-icon *ngIf="currentSort != sortEnum.ASC"> arrow_downward</mat-icon>
          </button>
        </div>
        <div>
          {{ 'samples.desiredHour' | transloco }}
        </div>
      </div>

      <ng-container *ngIf="loaded$ | async; else loadingSamples">
        <div cdkDropList [cdkDropListData]="samples" *ngIf="samples.length > 0; else noSamples" [cdkDropListDisabled]="isPast">
          <div *ngFor="let sample of samples" cdkDrag cdkDragPreviewContainer="parent" (cdkDragStarted)="selectCurrentSample(sample)" (cdkDragEnded)="removeCurrentSample()">
            <div class="appointment mt-2 p-2" [class.past]="isPast">
              <div class="d-flex align-items-center justify-content-between">
                <div class="fw-bold patient">{{ sample.user?.firstname }} {{ sample.user?.lastname }}</div>
                <mat-icon *ngIf="isPast" class="past-icon d-block me-1 align-middle"
                          #tooltip="matTooltip"
                          matTooltip="{{'samples.pastSample' | transloco}}"
                          matTooltipClass="bigger-tooltip"
                          [matTooltipPosition]="'above'">error</mat-icon>
                <div *ngIf="sample.jeun" class="jeun">{{'samples.jeun' | transloco}}</div>
                <div class="fw-bold text-end" *ngIf="sample.desiredEndTime">{{ sample.desiredStartTime! | hour: false }} - {{ sample.desiredEndTime! | hour: false }}</div>
                <div class="fw-bold text-end exact-time" *ngIf="!sample?.desiredEndTime">
                  {{ sample.desiredStartTime! | hour: true }}
                </div>
              </div>
              <div>{{sample.noSejour}}</div>
              <div class="mt-1">{{sample.adr1 + ', ' + sample.cp +' '+ sample.ville}}</div>
            </div>
            <ng-container *cdkDragPreview [ngTemplateOutlet]="previewTemplate" [ngTemplateOutletContext]="{$implicit: sample}"></ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<!-- Si on retrouve la notion de preleveur souhaite -->
<!--<ng-template #appointmentTemplate let-element>-->
<!--  <div class="d-flex align-items-center appointment mt-2 p-2">-->
<!--    <div class="position-relative me-2">-->
<!--      <img class="d-block avatar" [src]="tempImage">-->
<!--      <img class="kantys-prel" src="assets/img/kantys-bio-small.png">-->
<!--    </div>-->

<!--    <div class="fw-bold"> {{ element.preleveur.firstname }} {{ element.preleveur.lastname }}</div>-->
<!--    <div class="appoint-infos ms-auto">-->
<!--      <div class="fw-bold text-end">-->
<!--        {{ element.desiredStartTime }} - {{element.desiredEndTime}}-->
<!--      </div>-->
<!--      <div class="patient">-->
<!--        {{ element.user.firstname}} {{ element.user.lastname}}-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</ng-template>-->

<ng-template #previewTemplate let-element>
  <div class="dragdrop">
    <div class="fw-bold patient">{{ element.user?.firstname }} {{ element.user?.lastname }}</div>

    <div class="fw-bold text-end" *ngIf="element.desiredEndTime">{{ element.desiredStartTime! | hour: false }} - {{ element.desiredEndTime! | hour: false }}</div>
    <div class="fw-bold text-end exact-time" *ngIf="!element?.desiredEndTime">
      {{ element.desiredStartTime! | hour: true }}
    </div>
  </div>
</ng-template>

<ng-template #noSamples>
  <div class="pt-3 m-auto">
    <span class="fw-bold"> {{ 'samples.noSamples' | transloco }}</span>
  </div>
</ng-template>

<ng-template #loadingSamples>
  <div class="w-100 mt-5 d-flex justify-content-center sample-loader">
    <div class="loader"></div>
  </div>
</ng-template>
<ng-template #preleveurLoading>
  <div class="w-100 mt-5 d-flex justify-content-center sample-loader">
    <div class="loader"></div>
  </div>
</ng-template>
