import {Pipe, PipeTransform} from '@angular/core';
import {AbstractControl, FormControl, FormGroup} from '@angular/forms';

@Pipe({
  name: 'toFormGroup'
})
export class ToFormGroupPipe implements PipeTransform {

  transform(value: AbstractControl | null | undefined): FormGroup {
    return value as FormGroup;
  }

}
