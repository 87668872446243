import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import { LaboForm, PreleveursOnZone, ZonePreleveur } from '@domain/labo/Labo';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store} from '@ngxs/store';
import { UserInfo } from '@security/UserInfo';
import { ShowMessage } from '@states/global/global.actions';
import { MessageLevel } from '@states/global/global.state';
import { ClearPreleveursOnZone, LoadOneLabo, LoadPreleveursOnZone, SaveZonePreleveur } from '@states/labos/labos.actions';
import { LabosStateSelector } from '@states/labos/labos.selectors';
import {DialogConfirmationComponent} from '@utils/confirmation-dialog/dialog-confirmation.component';
import { KeycloakService } from 'keycloak-angular';
import {Observable, Subject, takeUntil} from 'rxjs';
import * as L from 'leaflet'
import { KantysTileLayer } from 'src/app/shared/maps/TileLayer';
import { Feature, Polygon } from 'geojson';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from '@domain/user/user';

@Component({
  selector: 'app-labo-zones-preleveurs',
  templateUrl: './labo-zones-preleveurs.component.html',
  styleUrls: ['./labo-zones-preleveurs.component.scss']
})
export class LaboZonesPreleveursComponent extends UserInfo implements OnInit, OnDestroy {

  @Select(LabosStateSelector.currentLabo) labo$: Observable<LaboForm>;
  @Select(LabosStateSelector.laboLoaded) laboLoaded$: Observable<boolean>;
  @Select(LabosStateSelector.preleveursOnZones) preleveursOnZones$: Observable<PreleveursOnZone[]>;

  private map: L.Map;
  private laboId: string;
  private destroy: Subject<boolean> = new Subject<boolean>();
  private polygonLayers = L.featureGroup();
  private laboMarker: L.Marker;

  public formGroups: FormGroup[] = [];
  private showedZoneIdPreleveurs: string[] = [];

  myIcon = L.icon({
    iconUrl: "assets/img/pin-K.svg",
    iconSize: [34, 40],
    iconAnchor: [18, 43],
    popupAnchor: [-2, -40],
  });

  colorIdx = 0;

  constructor(public override keycloakService: KeycloakService, private store: Store, private route: ActivatedRoute, private router: Router, private fb: FormBuilder, private dialog: MatDialog) {
    super(keycloakService);
  }

  ngOnInit(): void {
    this.initMap();
    this.markLabo();

    this.route.paramMap.pipe(takeUntil(this.destroy)).subscribe(val => {
      const id: string | null = val.get('id');
      if (!id) {
        this.store.dispatch([ new Navigate(['labos']), new ShowMessage({
          content: { title: 'load_labo_failure' },
          level: MessageLevel.ERROR,
        }, 'labo')])
      }
      this.laboId = id!;
      this.getLabo(id!);
    });

    this.labo$.pipe(takeUntil(this.destroy)).subscribe(labo => {
      if (labo) {
        this.formGroups = [];
        this.polygonLayers.clearLayers();
        labo.zonesPreleveurs?.forEach((zone, i) => {
          if (zone.zone) {
            const color = this.selectColor(i);

            const layer = new L.Polygon(
              (zone.zone as Polygon).coordinates[0].map(c => {
                return new L.LatLng(c[1], c[0])
              })
            );

            this.formGroups.push(
              this.fb.group({
                id: new FormControl(zone.id),
                name : new FormControl(zone.name, [Validators.required]),
                layer: new FormControl(layer),
                color: new FormControl(color),
                hide: new FormControl(true)
              })
            )
            layer.setStyle({color}).addTo(this.polygonLayers);
            layer.on('pm:remove', ({layer}) => {
              const dialogRef = this.dialog.open(DialogConfirmationComponent, {
                data: {
                  title: 'Confirmation de supression',
                  content: 'Attention : la suppression de cette zone entrainera la suppression des rendez-vous qui y sont attachés, confirmez vous cette suppression?',
                },
              });
              dialogRef.afterClosed().subscribe(result => {
                if (result === true) {
                  this.removeZone(layer);
                } else {
                  layer.addTo(this.map);
                }
              });
            });
            this.colorIdx++;
          }

        });
        var group:L.FeatureGroup = new L.FeatureGroup(this.formGroups.map(group => group.value.layer));
        group.addLayer(this.laboMarker);
        this.map.fitBounds(group.getBounds());
      }
    });
  }

  getLabo(id: string) {
    this.store.dispatch(new LoadOneLabo(id))
  }

  hide(checked: boolean, zone:FormGroup) {
    if (checked) {
      this.map.addLayer(zone.get('layer')!.value as L.Polygon);
    } else {
      this.map.removeLayer(zone.get('layer')!.value as L.Polygon);
    }
  }

  selectColor(number: number, alpha:number = 1) {
    const hue = number * 137.508;
    return `hsla(${hue}, 100%, 40%, ${alpha})`;
  }

  initMap(): void {
    this.map = L.map('mapZones', {center: [47, 2], zoom: 5});

    this.map.pm.addControls({
      position: 'topright',
      drawCircle: true,
      drawMarker: false,
      drawPolyline: false,
      drawRectangle: false,
      drawText: false,
      cutPolygon: false,
      drawCircleMarker: false
    })
    this.map.pm.setGlobalOptions({
      layerGroup: this.polygonLayers
    });

    new KantysTileLayer().addTo(this.map);

    this.map.on('pm:create', ({shape, layer}) => {

      if (layer instanceof L.Polygon || layer instanceof L.Circle) {

        const color = this.selectColor(this.colorIdx);

        this.formGroups.push(
          this.fb.group({
            id: new FormControl(),
            name : new FormControl('', [Validators.required]),
            layer: new FormControl(layer),
            color: new FormControl(color),
            hide: new FormControl(true)
          })
        );

        layer.setStyle({color: color});
        this.colorIdx++;
        layer.on('pm:remove', ({layer}) => {
          const dialogRef = this.dialog.open(DialogConfirmationComponent, {
            data: {
              title: 'Confirmation de supression',
              content: 'Attention : la suppression de cette zone entrainera la suppression des rendez-vous qui y sont attachés, confirmez vous cette suppression?',
            },
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
              this.removeZone(layer);
            } else {
              layer.addTo(this.map);
            }
          });
        });
      }
    });
    this.polygonLayers.addTo(this.map);
  }

  transformLayersToGeoJSON(): ZonePreleveur[] {
    return this.formGroups.filter(group => group.value.layer instanceof L.Circle || group.value.layer instanceof L.Polygon).map(group => {
      let poly: Feature;
      if (group.value.layer instanceof L.Circle) {
        poly = L.PM.Utils.circleToPolygon(group.value.layer).toGeoJSON();
      } else {
        poly = group.value.layer.toGeoJSON();
      }

      return {
        id: group.value.id,
        name : group.value.name,
        zone: poly.geometry,
        color: group.value.color
      }
    })
  }

  save(): void {
    if (this.formGroups.every(group => group.valid)) {
      this.store.dispatch(new SaveZonePreleveur(this.laboId, this.transformLayersToGeoJSON()));
    } else {
      this.formGroups.forEach(group => group.markAllAsTouched())
    }
  }

  removeZone(layer: L.Layer): void {
    this.formGroups.splice(this.formGroups.findIndex(group => group.value.layer === layer), 1);
  }

  markLabo(): void {
    this.labo$.pipe(takeUntil(this.destroy)).subscribe(labo => {
      if (labo && labo.position) {
        this.laboMarker = L.marker(new L.LatLng(labo.position.coordinates[1], labo.position.coordinates[0]), {icon: this.myIcon});
        this.laboMarker.addTo(this.map);
        this.map.flyTo(new L.LatLng(labo.position.coordinates[1], labo.position.coordinates[0]), 10, {duration:1});
      }
    })

  }

  togglePreleveursOnZone(zone: FormGroup): void {
    if (zone && zone.get('id')) {
      let idx;
      if ((idx = this.showedZoneIdPreleveurs.indexOf(zone.get('id')!.value)) >= 0) {
        this.showedZoneIdPreleveurs.splice(idx, 1);
      } else {
        this.showedZoneIdPreleveurs.push(zone.get('id')!.value);
        this.store.dispatch(new LoadPreleveursOnZone(zone.get('id')!.value))
      }
    }
  }

  isPreleveursOnZoneShown(zone: FormGroup): boolean {
    if (zone && zone.get('id')) {
      return this.showedZoneIdPreleveurs.includes(zone.get('id')!.value)
    }
    return false;
  }

  getPreleveursOnZone(preleveurs: PreleveursOnZone[], zone: FormGroup): PreleveursOnZone | undefined {
    if (zone && zone.get('id')) {
      return preleveurs.find(p => p.zoneId === zone.get('id')!.value)
    }
    return undefined;
  }

  goBack(): void {
    this.router.navigate(['labos', 'edit', this.laboId]);
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ClearPreleveursOnZone());
    this.map.off();
    this.map.remove();
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

}
