import {
  HTTP_INTERCEPTORS, HttpContext,
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParameterCodec,
  HttpRequest,
} from '@angular/common/http';
import {Injectable, Provider} from '@angular/core';
import {Router} from '@angular/router';
import {KeycloakService} from 'keycloak-angular';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {SnackbarService} from '../service/snackbar.service';

export const ENABLE_ERROR_SNACKBAR: boolean = true;
export const IS_ERROR_SNACKBAR_ENABLED: HttpContextToken<boolean> = new HttpContextToken<boolean>(() => ENABLE_ERROR_SNACKBAR);
export const NO_SNACKBAR_CONTEXT: HttpContext = new HttpContext().set(IS_ERROR_SNACKBAR_ENABLED, !ENABLE_ERROR_SNACKBAR);

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private _router: Router, private _keycloak: KeycloakService, private _snackbar: SnackbarService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!request.url.startsWith('/assets/')) {
      // À mettre si on a besoin du CustomEncoder (URLs ne seraient pas forcément bien encodé lors de l’envoi au serveur pur exemple)
      // if (!request.url.includes(environment.keycloak.url)) {
      //   const params: HttpParams = new HttpParams({encoder: new CustomEncoder(), fromString: request.params.toString()});
      //   request = request.clone({params});
      // }
      // this._keycloak.addTokenToHeader(request.headers);
    }
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (request.context.get(IS_ERROR_SNACKBAR_ENABLED)) {
          this._onError(err, request.url);
        }
        return throwError(() => err);
      })
    );
  }

  private _onError(err: HttpErrorResponse, url: string): void {
    let errorMsg: string;
    if (err.error instanceof ErrorEvent) {
      console.log('This is client side error');
      errorMsg = `Error: ${err.error.message}`;
    } else {
      console.log('This is server side error');
      errorMsg = `Error Code: ${err.status},  Message: ${err.message}`;
    }
    console.error(errorMsg, err);

    if (url.startsWith('/assets/')) {
      // Affiche l’erreur dans une snackbar
      this._snackbar.showError('Vérifier le fichier de traduction, il y a peut être une erreur', {
        config: {
          duration: 15000,
        },
      });
    } else {
      // Affiche l’erreur dans une snackbar
      this._snackbar.showError('error.' + err.status, {
        config: {
          duration: 15000,
        },
      });
    }

    if (err.status === 401 && !window.location.href.includes('/login')) {
      // auto logout if 401 response returned from api
      // logout
      // this._keycloak.logout();
      // window.location.reload();
    }

    // On error 403, go to 403
    if (err.status === 403) {
      // Logout
      // this._keycloak.logout();
      // window.location.reload();
    }

    // On error 0 (no internet connection)
    if (err.status === 0) {
      return;
    }

    // On error 500
    if (err.status === 500) {
      // return;
    }
  }
}

class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}

export const errorInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorInterceptor,
  multi: true,
};
