import {Component, OnInit, ChangeDetectionStrategy, OnDestroy} from '@angular/core';
import {AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors, Validator, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {NurseForm} from '@domain/nurse/Nurse';
import {Select, Store} from '@ngxs/store';
import {NurseService} from '@service/nurse.service';
import {DeleteOneNurse, LoadOneNewNurse, LoadOneNurse, SaveOneNurse, SearchAddress} from '@states/nurse/nurses.actions';
import {NursesStateSelector} from '@states/nurse/nurses.selectors';
import {DialogConfirmationComponent} from '@utils/confirmation-dialog/dialog-confirmation.component';
import {RegexPatternValidatorEnum} from '@utils/enum/regex.enum';
import {map, Observable, of, Subject, takeUntil} from 'rxjs';

@Component({
  selector: 'app-annuaire-edit',
  templateUrl: './annuaire-edit.component.html',
  styleUrls: ['./annuaire-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnuaireEditComponent implements OnInit, OnDestroy {
  nurseForm: FormGroup;
  telPattern: RegexPatternValidatorEnum = RegexPatternValidatorEnum.TEL;
  emailPattern: RegexPatternValidatorEnum = RegexPatternValidatorEnum.EMAIL;

  @Select(NursesStateSelector.currentNurse) currentNurse$: Observable<NurseForm>;
  private destroy: Subject<boolean> = new Subject<boolean>();
  constructor(
    private _route: ActivatedRoute,
    private store: Store,
    private router: Router,
    public dialog: MatDialog,
    private nurseService: NurseService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this._route.paramMap.subscribe(val => {
      const id: string | null = val.get('id');
      if (id) {
        this.store.dispatch(new LoadOneNurse(id));
      } else {
        this.store.dispatch(new LoadOneNewNurse());
      }
    });

    this.currentNurse$.pipe(takeUntil(this.destroy)).subscribe(nurse => {
      if (nurse) {
        this.nurseForm.get('id')?.setValue(nurse.id);
        this.nurseForm.get('informations')?.patchValue(nurse.informations);
        this.nurseForm.get('address')?.patchValue(nurse.address);
        this.nurseForm.get('profilePicture')?.setValue(nurse.profilePicture);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

  goBack(): void {
    this.store.dispatch(new LoadOneNewNurse());
    this.router.navigate(['/nurses']);
  }

  createForm(): void {
    this.nurseForm = new FormGroup(
      {
        id: new FormControl(''),
        informations: new FormGroup({
          name: new FormControl('', {validators: [Validators.required]}),
          phone: new FormControl(''),
          email: new FormControl(''),
        }),
        address: new FormGroup({
          address1: new FormControl('', [Validators.required]),
          address2: new FormControl(''),
          postalCode: new FormControl('', [Validators.required]),
          city: new FormControl('', [Validators.required]),
          country: new FormControl('', [Validators.required]),
        }),
        profilePicture: new FormControl(''),
      },
      {asyncValidators: [this.UniquenessValidator()]}
    );
  }

  getAdresseFormGroup(): FormGroup {
    return this.nurseForm.get('address') as FormGroup;
  }

  onSubmit(): void {
    const nurse: NurseForm = this.nurseForm.value;
    if (nurse.informations) {
      nurse.informations.phone = nurse.informations?.phone?.replace(/\s+/g, '');
    }
    this.store.dispatch(new SearchAddress({
      address1: nurse.address?.address1,
      address2: nurse.address?.address2,
      postalCode: nurse.address?.postalCode,
      city:nurse.address?.city,
      country:nurse.address?.country,
    })).subscribe(() => {this.store.dispatch(new SaveOneNurse(nurse))});
  }

  deleteNurse(id: string): void {
    const dialogRef = this.dialog.open(DialogConfirmationComponent, {
      data: {
        title: 'Confirmation de supression',
        content: 'Voulez-vous vraiment supprimer cet infirmier',
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.store.dispatch(new DeleteOneNurse(id));
      }
    });
  }

  changeProfilePicture(files: string[][]): void {
    if (files.length > 0) {
      this.nurseForm.get('profilePicture')?.setValue(files[0][0]);
    } else {
      this.nurseForm.get('profilePicture')?.setValue('');
    }
  }

  UniquenessValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      const name: string = control.get('informations')?.get('name')?.value;
      const address: string = control.get('address')?.get('address1')?.value;
      const postalCode: string = control.get('address')?.get('postalCode')?.value;
      const city: string = control.get('address')?.get('city')?.value;

      if (!control.get('id')?.value && name && address && postalCode && city) {
        return this.nurseService.checkUniqueness(name, address, postalCode, city).pipe(
          map(res => {
            // if res is true, nurse exists, return true
            return !res ? {alreadyExists: true} : null;
            // NB: Return null if there is no error
          })
        );
      } else {
        return of(null);
      }
    };
  }
}
