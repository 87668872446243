import {Location} from '@angular/common';
import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {PageEvent} from '@angular/material/paginator';
import {ActivatedRoute, Router} from '@angular/router';
import {DocumentTypeEnum, Sample, StatusSampleEnum} from '@domain/samples/sample';
import {Select, Store} from '@ngxs/store';
import {DownloadPrescription, UpdateStatus} from '@states/samples/samples.actions';
import {GetUserSamples, SuspendOneUser} from '@states/user/users.actions';
import {UsersStateSelector} from '@states/user/users.selectors';
import {DialogConfirmationComponent} from '@utils/confirmation-dialog/dialog-confirmation.component';
import {Pagination} from '@utils/pagination/Pagination';
import {PaginationOption, SortDirectionEnum} from '@utils/pagination/PaginationOption';
import {DateTime} from 'luxon';
import {Observable, take} from 'rxjs';

@Component({
  selector: 'app-user-sample-list',
  templateUrl: './user-sample-list.component.html',
  styleUrls: ['./user-sample-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserSampleListComponent implements OnInit {
  paginationOptions: PaginationOption;
  userId: string;
  statusEnum: typeof StatusSampleEnum = StatusSampleEnum;
  @Select(UsersStateSelector.userSamples) samples$: Observable<Pagination<Sample>>;

  constructor(private store: Store, private _route: ActivatedRoute, private _location: Location, private dialog: MatDialog) {
    this.paginationOptions = {
      length: 50,
      pageSize: 10,
      pageSizeOption: [10, 25, 50],
      pageIndex: 0,
      sortByColumnName: 'dateSample',
      sortDirection: SortDirectionEnum.DESC,
    }
  }

  ngOnInit(): void {
    this._route.params.subscribe(val => {
      this.userId = val['id'];
      if (this.userId) {
        this.store.dispatch(new GetUserSamples(this.userId, this.paginationOptions));
      }
    });
    this.samples$.subscribe(samples => this.paginationOptions.length = samples.totalElements);
  }

  goBack() {
    this._location.back();
  }

  onChangePage(pe: PageEvent) {
    this.paginationOptions.pageIndex = pe.pageIndex;
    this.paginationOptions.pageSize = pe.pageSize;
    this.store.dispatch(new GetUserSamples(this.userId, this.paginationOptions));
  }

  hasUploadedPresciption(sample: Sample): boolean {
    if (sample.documents) {
      return sample.documents.filter(doc => doc.documentType === DocumentTypeEnum.PRESCRIPTION).length >= 1;
    }
    return false;
  }

  downloadPrescription(sample: Sample): void {
    sample.documents
      ?.filter(doc => doc.documentType === DocumentTypeEnum.PRESCRIPTION)
      .forEach(doc => {
        if(doc.id) {
          this.store.dispatch(new DownloadPrescription(doc.id, doc.filename || ''));
        }
      });
  }

  getChecks(sample: Sample): string | undefined {
    return sample.checks?.map(check => check.libelle).join(' + ');
  }

  updateSort(sortByColumnName: string) {
    let sortDirection: SortDirectionEnum;
    if (this.paginationOptions.sortByColumnName === sortByColumnName && this.paginationOptions.sortDirection === SortDirectionEnum.ASC) {
      sortDirection = SortDirectionEnum.DESC;
    }
    else if (this.paginationOptions.sortByColumnName === sortByColumnName && this.paginationOptions.sortDirection === SortDirectionEnum.DESC) {
      sortDirection = SortDirectionEnum.ASC;
    }
    else {
      sortDirection = SortDirectionEnum.ASC;
    }
    this.paginationOptions.sortByColumnName = sortByColumnName;
    this.paginationOptions.sortDirection = sortDirection;
    this.store.dispatch(new GetUserSamples(this.userId, this.paginationOptions));
  }

  getSchedule(sample: Sample): string {
    let schedule_str: string = '';
    if (sample?.startTime && sample?.endTime) {
      schedule_str = DateTime.fromFormat(sample.startTime, 'hh:mm:ss').toFormat('hh:mm') + ' - ' + DateTime.fromFormat(sample.endTime, 'hh:mm:ss').toFormat('hh:mm');
    } else if (sample?.startTime) {
      schedule_str = DateTime.fromFormat(sample.startTime, 'hh:mm:ss').toFormat('hh:mm');
    } else if (sample?.desiredStartTime && sample?.desiredEndTime) {
      schedule_str = DateTime.fromFormat(sample.desiredStartTime, 'hh:mm:ss').toFormat('hh:mm') + ' - ' + DateTime.fromFormat(sample.desiredEndTime, 'hh:mm:ss').toFormat('hh:mm');
    } else if (sample?.desiredStartTime) {
      schedule_str = DateTime.fromFormat(sample.desiredStartTime, 'hh:mm:ss').toFormat('hh:mm');
    }
    return schedule_str;
  }

  cancelSample(id: string | undefined) {
    const dialogRef = this.dialog.open(DialogConfirmationComponent, {
      data: {
        title: "Confirmation d'annulation",
        content: 'Voulez-vous vraiment annuler ce rendez-vous ?',
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        if (id) {
          this.store.dispatch(new UpdateStatus(id, this.statusEnum.CANCEL));
        }
      }
    });
  }
}
