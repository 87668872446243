import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProfileEnum} from '@domain/user/user';
import {AuthenticatedGuard} from '@security/authenticated.guard';
import { LaboEditorComponent } from './labo-editor/labo-editor.component';
import { LaboListCheckComponent } from './labo-list-check/labo-list-check.component';
import { LaboPlanningJourneeComponent } from './labo-planning-journee/labo-planning-journee.component';
import { LaboPlanningPreleveurComponent } from './labo-planning-preleveur/labo-planning-preleveur.component';
import { LaboPlanningComponent } from './labo-planning/labo-planning.component';
import { LaboZonesPreleveursComponent } from './labo-zones-preleveurs/labo-zones-preleveurs.component';

const routes: Routes = [
    {path: '', canActivate: [AuthenticatedGuard], data:{roles:[ProfileEnum.ADMIN, ProfileEnum.PERSONNEL_LABO, ProfileEnum.TOURNEE_MANAGER]}, component: LaboPlanningComponent},
    {path: ':id/planning', canActivate: [AuthenticatedGuard], data:{roles:[ProfileEnum.ADMIN, ProfileEnum.TOURNEE_MANAGER]}, component: LaboPlanningJourneeComponent},
    {path: ':id/bilans', canActivate: [AuthenticatedGuard], data:{roles:[ProfileEnum.ADMIN, ProfileEnum.TOURNEE_MANAGER]}, component: LaboListCheckComponent},
    {path: ':laboId/planning/:preleveurId', canActivate: [AuthenticatedGuard], data:{roles:[ProfileEnum.ADMIN, ProfileEnum.TOURNEE_MANAGER]}, component: LaboPlanningPreleveurComponent},
    {path: 'edit', canActivate: [AuthenticatedGuard], data:{roles:[ProfileEnum.ADMIN, ProfileEnum.PERSONNEL_LABO, ProfileEnum.TOURNEE_MANAGER]}, component: LaboEditorComponent},
    {path: 'edit/:id', canActivate: [AuthenticatedGuard], data:{roles:[ProfileEnum.ADMIN, ProfileEnum.PERSONNEL_LABO, ProfileEnum.TOURNEE_MANAGER]}, component: LaboEditorComponent},
    {path: 'edit/:id/zonesPreleveurs', canActivate: [AuthenticatedGuard], data:{roles:[ProfileEnum.ADMIN, ProfileEnum.PERSONNEL_LABO]}, component: LaboZonesPreleveursComponent},
  ];

@NgModule({
  imports: [RouterModule.forChild(routes),],
  exports: [RouterModule],
})
export class LaboRoutingModule {}