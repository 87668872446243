export enum SamplesActions {
  LOAD_FILTERED_SAMPLES = 'Load filtered samples',
  LOAD_FILTERED_SAMPLES_SUCCESS = 'Load filtered samples success',
  LOAD_FILTERED_SAMPLES_FAILURE = 'Load filtered samples failure',

  CHANGE_LABO_DATE = 'Change Labo Date',
  CHANGE_LABO_DATE_SUCCESS = 'Change Labo Date Success',
  CHANGE_LABO_DATE_FAILURE = 'Change Labo Date Failure',

  LOAD_PRELEVEUR = 'Load preleveur',
  LOAD_PRELEVEUR_SUCCESS = 'Load preleveur Success',
  LOAD_PRELEVEUR_FAILURE = 'Load preleveur Failure',

  UPDATE_SAMPLE_PAGINATION = 'Update Sample pagination',
  UPDATE_SAMPLE_FILTERS = 'Update Sample filters',
  UPDATE_SAMPLE_SORT = 'Update Sample sort',

  UPDATE_STATUS = 'Update Status',
  UPDATE_STATUS_SUCCESS = 'Update Status Success',
  UPDATE_STATUS_FAILURE = 'Update Status Failure',

  AFFECT_HOME_SAMPLE = 'Affect Home Sample',
  AFFECT_HOME_SAMPLE_SUCCESS = 'Affect Home Sample Success',
  AFFECT_HOME_SAMPLE_FAILURE = 'Affect Home Sample Failure',

  VALIDATE_SAMPLES_FOR_DAY = 'Validate Sample For Day',
  VALIDATE_SAMPLES_FOR_DAY_SUCCESS = 'Validate Sample For Day Success',
  VALIDATE_SAMPLES_FOR_DAY_FAILURE = 'Validate Sample For Day Failure',

  LOCAL_PATCH_SAMPLE = 'Local patch a sample',

  RESET_ORDER_MODIFIED = 'Reset order modified',

  SAVE_PLANNING_FOR_DAY_AND_PRELEVEUR = 'Save Planning for day and preleveur',
  SAVE_PLANNING_FOR_DAY_AND_PRELEVEUR_SUCCESS = 'Save Planning for day and preleveur Success',
  SAVE_PLANNING_FOR_DAY_AND_PRELEVEUR_FAILURE = 'Save Planning for day and preleveur Failure',

  SET_DATE = 'Set Date',

  DOWNLOAD_PRESCRIPTION = 'Download prescription',
  DOWNLOAD_PRESCRIPTION_SUCCESS = 'Download prescription success',
  DOWNLOAD_PRESCRIPTION_FAILURE = 'Download prescription failure',

  GET_SAMPLES_BY_DAY = 'Get samples by day',
  GET_SAMPLES_BY_DAY_SUCCESS = 'Get Samples by day success',
  GET_SAMPLES_BY_DAY_FAILURE = 'Get Samples by day failure',

  UPDATE_PLANNING_FILTERS = 'Update planning filters',
  GET_CHECKS = 'Get checks',
  GET_CHECKS_SUCCESS = 'Get checks success',
  GET_CHECKS_FAILURE = 'Get checks failure',

  CREATE_NEW_SAMPLE = 'Create new Sample',
  SAMPLE_CREATION_PATCH = 'Sample creation patch',

  GET_NEAREST_LABO_FOR_SAMPLE = 'Get nearest labo for sample',
  GET_NEAREST_LABO_FOR_SAMPLE_SUCCESS = 'Get nearest labo for sample success',
  GET_NEAREST_LABO_FOR_SAMPLE_FAILURE = 'Get nearest labo for sample failure',

  GET_SAMPLE_TIME_SLOTS = 'Get sample time slots',
  GET_SAMPLE_TIME_SLOTS_SUCCESS = 'Get sample time slots success',
  GET_SAMPLE_TIME_SLOTS_FAILURE = 'Get sample time slots failure',

  UPDATE_TIME_SLOTS_SEARCH_MODE = 'Update time slots search mode',

  RESET_SAMPLE_TIME_SLOTS = 'Reset sample time slot',

  CREATE_SAMPLE = 'Create sample',
  CREATE_SAMPLE_SUCCESS = 'Create sample success',
  CREATE_SAMPLE_FAILURE = 'Create sample failure',

  VALIDATE_SAVED_STATUS = 'Validate saved status',
  VALIDATE_SAVED_STATUS_SUCCESS = 'Validate saved status success',
  VALIDATE_SAVED_STATUS_FAILURE = 'Validate saved status failure',

  GET_SAMPLE = 'Get sample',
  GET_SAMPLE_SUCCESS = 'Get sample success',
  GET_SAMPLE_FAILURE = 'Get sample failure',

  GET_SAMPLE_FIXED_SCHEDULE_OPTION = 'Get sample fixed schedule option',
  GET_SAMPLE_FIXED_SCHEDULE_OPTION_SUCCESS = 'Get sample fixed schedule option success',
  GET_SAMPLE_FIXED_SCHEDULE_OPTION_FAILURE = 'Get sample fixed schedule option failure',
}
