export interface PaginationOption {
  length?: number;
  pageSize?: number;
  pageSizeOption?: number[];
  pageIndex?: number;
  sortByColumnName?: string;
  sortDirection?: SortDirectionEnum;

  // constructor(length: number, pageSize: number, pageSizeOptions?: number[], sortByColumnName?: string, sortDirection?: SortDirectionEnum) {
  //   this.length = length;
  //   this.pageSize = pageSize;
  //
  //   if (pageSizeOptions) {
  //     this.pageSizeOption = pageSizeOptions;
  //   }
  //   else {
  //     this.pageSizeOption = [10, 20, 50];
  //   }
  //
  //   if (sortByColumnName){
  //     this.sortByColumnName = sortByColumnName;
  //   }
  //   else {
  //     this.sortByColumnName = '';
  //   }
  //
  //   if(sortDirection) {
  //     this.sortDirection = sortDirection;
  //   }
  //   else {
  //     this.sortDirection = SortDirectionEnum.ASC;
  //   }
  // }
}
export enum SortDirectionEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}
