import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {debounceTime, distinctUntilChanged, Observable, Subject} from 'rxjs';

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchbarComponent{
  @Input() search$: Observable<string>;
  @Input() placeholder: string;
  searchChanged: Subject<string> = new Subject<string>();

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
    this.searchChanged.pipe(
      debounceTime(500), // wait 300ms after the last event before emitting last event
      distinctUntilChanged()) // only emit if value is different from previous value
      .subscribe(search => this.valueChange.emit(search));
  }

  onChange(event: any) {
    this.searchChanged.next(event)
  }

  resetSearch() {
    this.valueChange.emit('')
  }
}
