import {HttpClient, HttpContext, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Cabinet} from '@domain/cabinet/cabinet';
import {environment} from '@environment/environment';
import {CabinetsActionsEnum} from '@states/cabinets/cabinets.actions.enum';
import {IS_ERROR_SNACKBAR_ENABLED} from '@utils/interceptor/error.interceptor';
import {Pagination} from '@utils/pagination/Pagination';
import {PaginationOption} from '@utils/pagination/PaginationOption';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CabinetService {
  constructor(public http: HttpClient) {}

  get(uuid: string): Observable<Cabinet> {
    return this.http.get(environment.api + '/cabinets/' + uuid, {context: new HttpContext().set(IS_ERROR_SNACKBAR_ENABLED, true)});
  }
  findByFiltersPaging(filter: string, paginationOption: PaginationOption): Observable<Pagination<Cabinet>> {
    let params_str: string[] = [];
      if(filter){
      params_str.push('search~' + filter);
    }
    let params: HttpParams = new HttpParams()
      .set('q', params_str.join(','))
      .set('page', paginationOption.pageIndex!)
      .set('size', paginationOption.pageSize!);
    return this.http.get<Pagination<Cabinet>>(environment.api + '/cabinets', {params: params, context: new HttpContext().set(IS_ERROR_SNACKBAR_ENABLED, true)});
  }

  create(cabinet: Cabinet): Observable<Cabinet> {
    return this.http.post(environment.api + '/cabinets', cabinet, {context: new HttpContext().set(IS_ERROR_SNACKBAR_ENABLED, true)});
  }

  update(uuid: string, cabinet: Cabinet): Observable<Cabinet> {
    return this.http.put(environment.api + '/cabinets/' + uuid, cabinet, {context: new HttpContext().set(IS_ERROR_SNACKBAR_ENABLED, true)});
  }

  delete(uuid: string): Observable<Object> {
    return this.http.delete(environment.api + '/cabinets/' + uuid, {context: new HttpContext().set(IS_ERROR_SNACKBAR_ENABLED, true)});
  }
}
