import {DayEnum, FixedSchedule} from '@domain/labo/Labo';
import {User} from '@domain/user/user';
import {LatLng} from 'leaflet';

export interface Sample {
  id?: string;
  dateSample?: string;
  status?: Status;
  user?: User;
  labo?: Labo;
  laboId?: string;
  laboName?: string;
  userId?: string;
  preleveur?: User;
  desiredStartTime?: string;
  desiredEndTime?: string;
  startTime?: string;
  endTime?: string;
  tolerance?: number;
  orderInCreneau?: number;
  hasRx?: boolean;
  homeRx?: boolean;
  renewableRx?: boolean;
  transmittedLaboRx?: boolean;
  documents?: Document[];
  checks?: Check[];
  inLabo?: boolean;
  adr1?: string;
  adr2?: string;
  cp?: string;
  ville?: string;
  pays?: string;
  commentaire?: string;
  latitude?: string,
  longitude?: string,
  resultsUrl?: string;
  zoneId?: string;
  noSejour?: string;
  saved?: boolean;
  savedAt?: Date;
  consignePatient?: string;
  consignePreleveur?: string;
  jeun?: boolean;
}

export interface Document {
  id?: string;
  filename?: string;
  documentType?: DocumentTypeEnum;
  documentMime?: string;
  sampleRef?: string;
  temporary?: boolean;
}

export enum DocumentTypeEnum {
  ID_CARD='ID_CARD',
  HEALTH_CARD='HEALTH_CARD',
  HEALTH_ATTESTATION='HEALTH_ATTESTATION',
  INSURANCE_CARD='INSURANCE_CARD',
  PRESCRIPTION='PRESCRIPTION'
}

export interface Labo {
  id: string;
  name: string;
  noHomeRxPrice: number;
  fixedSchedule: FixedSchedule[];
}
export interface Status {
  id?: string;
  status?: StatusSampleEnum;
  updateAt?: Date;
  updatedBy?: string;
}

export interface Check {
  id: string;
  libelle?: string;
  price?: number;
  description?: string;
  informationsPatient?: string;
  consignesPreleveur?: string;
  documentAidePrelevementUrl?: string;
  jeun?: boolean;
}

export interface CheckFilter {
  search?: string;
  laboId?: string;
}

export enum StatusSampleEnum {
  PENDING = 'PENDING',
  AFFECTED = 'AFFECTED',
  CONFIRM = 'CONFIRM',
  CANCEL = 'CANCEL',
  UNDERWAY = 'UNDERWAY',
  INPROGRESS = 'INPROGRESS',
  TOLABO= 'TOLABO',
  TOVALIDATE = 'TOVALIDATE',
  DONE = 'DONE',
  REFUSED = 'REFUSED',
}

export interface InLaboSampleCount {
  pending: number,
  confirmed: number
}

export interface SamplesFilter {
  patientName?: string;
  status?: string[];
  date?: string;
  laboId?: string;
  inLabo?: boolean;
  preleveurId?: string;
  search?: string;
}

export interface SampleInfos {
  id: string;
  userId: string;
  userName: string;
  patientIpp: string;
  numeroSecu: string;
  noSejour: string;
  prescription: Document[];
  checks: Check[];
  hasRx: boolean;
  status: StatusSampleEnum;
  saved: boolean;
  savedAt: Date;
}
export class Dateslot {
  date!: Date;
  timeslots!: Timeslot[];
}

export class Timeslot {
  day?: DayEnum;
  date!: Date;
  start!: string;
  end!: string;
  load?: 'LOW' | 'MEDIUM' | 'HIGH';
}
