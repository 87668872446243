import {Component, OnInit, ChangeDetectionStrategy, Inject, OnDestroy} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LaboForm} from '@domain/labo/Labo';
import {Check} from '@domain/samples/sample';
import {Actions, ofActionSuccessful, Select, Selector, Store} from '@ngxs/store';
import {CreateCheck, CreateCheckSuccess, UpdateCheck, UpdateCheckSuccess} from '@states/labos/labos.actions';
import {LabosStateSelector} from '@states/labos/labos.selectors';
import {first, Observable, Subject, takeUntil} from 'rxjs';

@Component({
  selector: 'app-labo-check-editor',
  templateUrl: './labo-check-editor.component.html',
  styleUrls: ['./labo-check-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaboCheckEditorComponent implements OnInit, OnDestroy {

  @Select(LabosStateSelector.currentLabo) labo$: Observable<LaboForm>

  checkForm: FormGroup = new FormGroup({
    id: new FormControl(''),
    libelle: new FormControl('',  Validators.required),
    price: new FormControl('', Validators.required),
    description: new FormControl(''),
    documentAidePrelevementUrl: new FormControl('', Validators.pattern('https:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,4}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)')),
    consignesPreleveur: new FormControl(''),
    informationsPatient: new FormControl(''),
    laboId: new FormControl(''),
    jeun: new FormControl(false, Validators.required)
  })

  private destroy: Subject<boolean> = new Subject<boolean>();

  constructor(public dialogRef: MatDialogRef<LaboCheckEditorComponent>,
              @Inject(MAT_DIALOG_DATA) public data: Check,
              private store: Store,
              private actions$: Actions) { }

  ngOnInit(): void {
    this.labo$.pipe(first(l => l != undefined)).subscribe(labo => {
      this.checkForm.get('laboId')?.patchValue(labo.id)
    })

    if(this.data) {
      this.checkForm.patchValue(this.data)
    }

    this.actions$.pipe(
      ofActionSuccessful(UpdateCheckSuccess, CreateCheckSuccess),
      takeUntil(this.destroy))
      .subscribe(() => this.dialogRef.close(true));
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  save() {
    if(this.data) {
      return this.store.dispatch(new UpdateCheck(this.checkForm.value))
    }
    return this.store.dispatch(new CreateCheck(this.checkForm.value))
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }
}
