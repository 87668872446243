import { Pipe, PipeTransform } from '@angular/core';
import {DateTime} from 'luxon';

@Pipe({
  name: 'toDateTime'
})
export class ToDateTimePipe implements PipeTransform {

  transform(value: string | undefined, format: string): string {
    if(!value) {
      return '';
    }
    return DateTime.fromISO(value).setLocale('fr').toFormat(format);
  }

}
