import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProfileEnum} from '@domain/user/user';
import {CabinetEditComponent} from '@features/cabinets/cabinet-edit/cabinet-edit.component';
import {CabinetListComponent} from '@features/cabinets/cabinet-list/cabinet-list.component';
import {AuthenticatedGuard} from '@security/authenticated.guard';

const routes: Routes = [
  {path: '', canActivate: [AuthenticatedGuard], data: {roles: [ProfileEnum.ADMIN, ProfileEnum.PERSONNEL_LABO, ProfileEnum.TOURNEE_MANAGER, ProfileEnum.SECRETAIRE]}, component: CabinetListComponent},
  {path: 'edit', canActivate: [AuthenticatedGuard], data: {roles: [ProfileEnum.ADMIN, ProfileEnum.PERSONNEL_LABO, ProfileEnum.TOURNEE_MANAGER, ProfileEnum.SECRETAIRE]}, component: CabinetEditComponent},
  {path: 'edit/:id', canActivate: [AuthenticatedGuard], data: {roles: [ProfileEnum.ADMIN, ProfileEnum.PERSONNEL_LABO, ProfileEnum.TOURNEE_MANAGER, ProfileEnum.SECRETAIRE]}, component: CabinetEditComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CabinetsRoutingModule {}
