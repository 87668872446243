<div class="checks" *ngIf="currentLabo$ | async as labo">
  <div class="header d-flex justify-content-between w-100">
    <div class="d-flex w-100 justify-content-end">
      <div class="me-auto d-flex">
        <div>
          <mat-icon class="p-3 back-arrow" role="button"  [routerLink]="['/labos', 'edit', labo.id]">arrow_backward</mat-icon>
        </div>
        <div class="p-3">
          {{ 'labos.checks.laboChecks' | transloco:{laboName: labo.name} }}
        </div>
      </div>
    </div>
  </div>

  <div class="p-3 d-flex justify-content-between">
    <h1 class="fw-bold fs-3" > {{ 'labos.checks.title' | transloco }}</h1>
    <div class="d-flex w-50 justify-content-end">
      <app-searchbar class="w-75"  [placeholder]="'labos.checks.searchCheck' | transloco"  (valueChange)="onSearchChanged($event)" [search$]="searchFilter$"></app-searchbar>
      <button class="ms-2 add-check" mat-flat-button (click)="addCheck()"> <mat-icon>add</mat-icon> {{ 'labos.checks.newCheck' | transloco}}</button>
    </div>
    </div>
    <div class="w-100 d-flex justify-content-center">
      <div class="w-100 p-3">
        <ng-container *ngIf="loaded$ | async else notLoaded">
          <ng-container *ngIf="checks$ | async as checks">
            <ng-container *ngIf="checks.content.length > 0; else noResults">
              <table mat-table  [dataSource]="checks.content" class="w-100" matSort (matSortChange)="sortChange($event)">
                <ng-container *ngFor="let column of columns; let first = first" [matColumnDef]="column">
                  <ng-container [ngSwitch]="column">
                    <ng-container *ngSwitchCase="'delete'">
                      <th mat-header-cell *matHeaderCellDef> </th>
                      <td mat-cell *matCellDef="let element"> <div  class="w-100 d-inline-flex justify-content-end"><mat-icon  role="button" (click)="$event.stopImmediatePropagation(); delete(element.id)">delete</mat-icon></div> </td>
                    </ng-container>
                    <ng-container *ngSwitchCase="'description'">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header [sortActionDescription]="'Sort by '+column"> <span [class.blue-title]="first"> {{ 'labos.checks.'+column | transloco }}</span>  </th>
                      <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
                    </ng-container>
                    <ng-container *ngSwitchCase="'price'">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header [sortActionDescription]="'Sort by '+column"> <span [class.blue-title]="first"> {{ 'labos.checks.'+column | transloco }}</span>  </th>
                      <td mat-cell *matCellDef="let element"> {{element[column]}} €</td>
                    </ng-container>
                    <ng-container *ngSwitchCase="'documentAidePrelevementUrl'">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header [sortActionDescription]="'Sort by '+column"> <span [class.blue-title]="first"> {{ 'labos.checks.documentAidePrelevementUrlShorten' | transloco }}</span>  </th>
                      <td mat-cell *matCellDef="let element" (click)="$event.stopImmediatePropagation()"> <a *ngIf="element[column]; else noDocument" [href]="element[column]" target="_blank"> {{ 'labos.checks.document' | transloco }}</a>  </td>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      <th mat-header-cell *matHeaderCellDef mat-sort-header [sortActionDescription]="'Sort by '+column"> <span [class.blue-title]="first"> {{ 'labos.checks.'+column | transloco }}</span>  </th>
                      <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr mat-row *matRowDef="let row; columns: columns;" class="pointer" (click)="edit(row)"></tr>
              </table>
              <mat-paginator *ngIf="pagination$ | async as pagination" [length]="checks.totalElements"
                             [pageIndex]="pagination.pageIndex"
                             (page)="onPaginateChange($event)"
                             [pageSize]="pagination.pageSize"
                             [pageSizeOptions]="pagination.pageSizeOption!">
              </mat-paginator>
            </ng-container>

          </ng-container>
        </ng-container>
      </div>

    </div>
</div>

<ng-template #notLoaded>
  <div class="w-100 mt-5 d-flex justify-content-center checks-loader">
    <div class="loader">
    </div>
  </div>
</ng-template>

<ng-template #noDocument>
  <span class="fst-italic"> {{ 'labos.checks.noDocuments' | transloco}}</span>
</ng-template>

<ng-template #noResults>
  <div>
    <span class="fs-6 fst-italic fw-bold"> {{ 'labos.checks.noChecksFound' | transloco }}</span>
  </div>
</ng-template>

