import {Injectable} from '@angular/core';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {TranslocoService} from '@ngneat/transloco';
@Injectable()
export class MatPaginatorIntlTransloco extends MatPaginatorIntl {

  constructor(private _translator: TranslocoService) {
    super();
    this._translator.langChanges$.subscribe(() => {
      this.translateLabels();
    });
    this.translateLabels();
  }

  translateLabels(): void {
    this._translator.selectTranslate('paginator.itemsPerPage').subscribe(result => this.itemsPerPageLabel = result);
    this._translator.selectTranslate('paginator.firstPage').subscribe(result => this.firstPageLabel = result);
    this._translator.selectTranslate('paginator.lastPage').subscribe(result => this.lastPageLabel = result);
    this._translator.selectTranslate('paginator.nextPage').subscribe(result => this.nextPageLabel = result);
    this._translator.selectTranslate('paginator.previousPage').subscribe(result => this.previousPageLabel = result);

		this.changes.next();
  }

  override getRangeLabel: (page: number, pageSize: number, length: number) => string =
    (page: number, pageSize: number, length: number) => {
      let pageNbr: number = page + 1;
      if (length === 0) {
        pageNbr = 1;
      }
      const amountPages: number = Math.ceil(length / pageSize);
      return this._translator.translate('paginator.range', {page: pageNbr, maxPage: amountPages});
    }
}
