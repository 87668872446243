<button mat-icon-button class="close-button" (click)="close()">
  <mat-icon color="warn">close</mat-icon>
</button>

<h1 mat-dialog-title> {{'users.samples.creation' | transloco}}</h1>
<mat-dialog-content>
  <mat-stepper orientation="vertical" linear #stepper class="white-label-icon">

    <mat-step completed="false" editable="false">
      <ng-template class="title" matStepLabel>{{'users.samples.location.title' | transloco}}</ng-template>
      <ng-template matStepContent>
        <form [formGroup]="adresse" class="pt-2">
          <mat-radio-group class="d-flex flex-column" formControlName="domicile" (change)="locationChange($event)">
            <mat-radio-button class="my-2 ms-2"
                              [value]="true">{{'users.samples.location.domicile' | transloco}}</mat-radio-button>
            <div class="pt-2 pb-2 px-3">
              <img class="icon" src="assets/img/home.svg">
              <span *ngIf="domicile$ | async as domicile"
                    class="px-3">{{ domicile.adr1 + ", " + domicile.cp + " " + domicile.ville}}</span>
            </div>
            <mat-radio-button class="my-2 ms-2"
                              [value]="false">{{'users.samples.location.other' | transloco}}</mat-radio-button>
            <div *ngIf="adresse.get('domicile')?.value === false" class="pt-2 pb-2">
              <app-adresse-form class="d-block w-75" [formGroup]="otherAddress" [displayNarrow]="true"
                                (formChanged)="adresse.setValue($event.value)"></app-adresse-form>
            </div>
          </mat-radio-group>
          <div>
            <button [disabled]="adresse.invalid" mat-button (click)="next()">{{'app.next' | transloco}}</button>
          </div>
        </form>
      </ng-template>
    </mat-step>

    <mat-step completed="false" editable="false">
      <ng-template matStepLabel>{{'users.samples.prescription.title' | transloco}}</ng-template>
      <ng-template matStepContent>
        <form [formGroup]="prescription" *ngIf="labo$ | async as labo" class="pt-2">
          <h2 class="title">{{'users.samples.prescription.is_prescription' | transloco}}</h2>
          <mat-radio-group class="d-flex flex-column pb-2" formControlName="hasPrescription">
            <mat-radio-button class="my-2 ms-2"
                              [value]="true">{{'users.samples.prescription.prescription_ok' | transloco}}</mat-radio-button>
            <mat-radio-button class="my-2 ms-2"
                              [value]="false">{{'users.samples.prescription.no_prescription' | transloco}}</mat-radio-button>
          </mat-radio-group>
          <div *ngIf="prescription.get('hasPrescription')?.value">
            <h2 class="title">{{'users.samples.prescription.is_domicile_prescription' | transloco}}</h2>
            <mat-radio-group class="d-flex flex-column" formControlName="hasHomePrescription">
              <mat-radio-button class="my-2 ms-2" [value]="true">{{'app.yes' | transloco}}</mat-radio-button>
              <mat-radio-button class="my-2 ms-2"
                                [value]="false">{{'users.samples.prescription.no_domicile_prescription'| transloco : {price: labo.noHomeRxPrice} }}</mat-radio-button>
            </mat-radio-group>
          </div>
          <div *ngIf="prescription.get('hasPrescription')?.value">
            <h2 class="title">{{'users.samples.prescription.is_renewable_prescription' | transloco}}</h2>
            <mat-radio-group class="d-flex flex-column" formControlName="hasRenewablePrescription">
              <mat-radio-button class="my-2 ms-2" [value]="true">{{'app.yes' | transloco}}</mat-radio-button>
              <mat-radio-button class="my-2 ms-2" [value]="false">{{'app.no' | transloco }}</mat-radio-button>
            </mat-radio-group>
          </div>
          <div
            *ngIf="prescription.get('hasPrescription')?.value && prescription.get('hasRenewablePrescription')?.value">
            <h2 class="title">{{'users.samples.prescription.is_rx_transmitted' | transloco}}</h2>
            <mat-radio-group class="d-flex flex-column" formControlName="laboHasRx">
              <mat-radio-button class="my-2 ms-2" [value]="true">{{'app.yes' | transloco}}</mat-radio-button>
              <mat-radio-button class="my-2 ms-2" [value]="false">{{'app.no' | transloco}}</mat-radio-button>
            </mat-radio-group>
          </div>
          <div>
            <button mat-button (click)="back()">{{'app.previous' | transloco}}</button>
            <button [disabled]="prescription.invalid" mat-button (click)="next()">{{'app.next' | transloco}}</button>
          </div>
        </form>
      </ng-template>
    </mat-step>

    <mat-step completed="false" editable="false">
      <ng-template matStepLabel>{{'users.samples.checks.title' | transloco}}</ng-template>
      <ng-template matStepContent>
        <form [formGroup]="checks" class="pt-2">
          <div *ngIf="sample$ | async as sample">
            <div *ngIf="sample.hasRx">
              <h2 class="title">{{'users.samples.checks.add_checks' | transloco}}</h2>
              <mat-radio-group class="d-flex flex-column" formControlName="addChecks">
                <mat-radio-button class="my-2 ms-2" [value]="true">{{'app.yes' | transloco}}</mat-radio-button>
                <mat-radio-button class="my-2 ms-2" [value]="false">{{'app.no' | transloco}}</mat-radio-button>
              </mat-radio-group>
            </div>
            <h2 class="title" *ngIf="!sample.hasRx">{{'users.samples.checks.choose_checks' | transloco}}</h2>
            <div *ngIf=" sample.hasRx && checks.get('addChecks')?.value === true || !sample.hasRx">
              <app-sample-checks *ngIf="sample && sample.laboId" [laboId]="sample.laboId"
                                 [selected]="checks.get('selectedChecks')?.value"
                                 (selectionChange)="getChecks($event)"></app-sample-checks>
            </div>
            <button mat-button (click)="back()">{{'app.previous' | transloco}}</button>
            <button [disabled]="checks.invalid" mat-button (click)="next()">{{'app.next' | transloco}}</button>
          </div>
        </form>
      </ng-template>
    </mat-step>

    <mat-step completed="false" editable="false">
      <ng-template matStepLabel>{{'users.samples.time.title' | transloco}}</ng-template>
      <ng-template matStepContent>
        <form [formGroup]="time" class="pt-2">
            <div class="d-flex justify-content-center">
              <div class = "d-flex flex-column" *ngIf="patient$ | async as patient">
                <div class="d-flex justify-content-start align-items-center">
                  <img class="icon" src="assets/img/users.svg" alt="picto user">
                  <div class="title">{{'users.samples.searchMode' | transloco}}</div>
                </div>
                <div class="d-flex justify-content-start">
                  <mat-radio-group (change)="updateSearchMode($event)" formControlName="searchMode">
                    <mat-radio-button [value]="associationModeEnum.STRICT"
                                      class="me-3">{{'users.samples.loadStrict' | transloco}}</mat-radio-button>
                    <mat-radio-button [value]="associationModeEnum.MIXED" class="me-3"
                                      [disabled]="patient?.associatedPreleveur?.associationMode === associationModeEnum.STRICT || !patient?.associatedPreleveur?.associationMode ">{{'users.samples.loadMixed' | transloco}}</mat-radio-button>
                    <mat-radio-button [value]="associationModeEnum.OPEN" class="me-3"
                                      [disabled]="patient?.associatedPreleveur?.associationMode !== associationModeEnum.OPEN">{{'users.samples.loadOpen' | transloco}}</mat-radio-button>
                  </mat-radio-group>
                </div>
            <div class="small-text d-flex justify-content-start align-items-center mt-3">
              <div class="grey col-6 d-flex flex-row py-2 px-1">
                <span class="title me-3">{{'users.samples.time.indicator' | transloco}}</span>
                <mat-icon class="icon-green" [inline]="true">circle</mat-icon>
                <span class="ms-2 me-4">{{'users.samples.time.indicator_low' | transloco}}</span>
                <mat-icon class="icon-orange" [inline]="true">circle</mat-icon>
                <span class="ms-2 me-4">{{'users.samples.time.indicator_med' | transloco}}</span>
                <mat-icon class="icon-red" [inline]="true">circle</mat-icon>
                <span class="ms-2 me-4">{{'users.samples.time.indicator_high' | transloco}}</span>
              </div>
            </div>
            <div class="mt-4 h-60 flex-grow-1 justify-content-start">
              <div *ngIf="dateslots$ | async as dateslots"
                   class="d-flex flex-column justify-content-center align-items-start">
                <div class="w-80">
                  <div *ngIf="dateslots.length ===0" class="item">{{'users.samples.time.no_slots' | transloco}}</div>
                  <div *ngFor="let dateslot of dateslots; let last = last">
                    <div class="item d-flex align-items-center w-100" *ngIf="labo$ | async as labo">
                      <mat-expansion-panel class="w-100">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <span class="title text-capitalize">{{dateslot.date | date:'EEEE dd MMMM'}}</span>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="content d-flex flex-wrap">
                          <button mat-button *ngFor="let timeslot of dateslot.timeslots"
                                  class="slot"
                                  (click)="selectSchedule(dateslot.date, timeslot)">
                  <span class="bdg-aff"
                        [ngClass]="timeslot.load! | lowercase">&#8288;</span>
                            <span>{{'users.samples.time.slot_time' | transloco:({
                              start: (timeslot.start | hour:true),
                              end: (timeslot.end | hour:true)
                            })
                              }}</span>
                          </button>
                          <button mat-button class="slot custom-slot" (click)="customRedirect(dateslot)"
                                  *ngIf="labo.fixedSchedule != undefined && labo.fixedSchedule.length>0 ">
                            <span>{{'users.samples.time.custom' | transloco}}</span>
                          </button>
                        </div>
                        <div *ngIf="time.get('customslot')?.value === true" class="mt-3">
                          <mat-form-field appearance="fill">
                            <mat-label>{{'users.samples.time.time_label' | transloco}}</mat-label>
                            <mat-icon matPrefix class="grey">watch_later</mat-icon>
                            <input matInput type="time" formControlName="desiredStartTime">
                            <mat-error *ngIf="time.get('desiredStartTime')?.hasError('minMax')">
                              {{'users.samples.time.time_error' | transloco}}} <br>
                            </mat-error>
                          </mat-form-field>
                          <p class="grey title mt-4">{{'users.samples.time.tolerance' | transloco}}</p>
                          <mat-radio-group class="d-flex flex-column" formControlName="customslotTolerance">
                            <mat-radio-button *ngFor="let option of labo.fixedSchedule" class="my-2 ms-2"
                                              [value]="option.delay">{{'users.samples.time.toleranceAndPrice' | transloco: {
                              tolerance: option.delay,
                              price: option.price
                            } }} </mat-radio-button>
                          </mat-radio-group>
                          <div class="mb-2">
                            <mat-checkbox class="list" formControlName="acceptedCustom">
                              <span class="grey small text-wrap">{{'users.samples.time.check_text' | transloco}}</span>
                            </mat-checkbox>
                          </div>
                          <div class="text-center">
                            <button mat-button (click)="back()">{{'app.previous' | transloco}}</button>
                            <button [disabled]="!time.valid" mat-button
                                    (click)="next()">{{'app.next' | transloco}}</button>
                          </div>
                        </div>
                      </mat-expansion-panel>
                    </div>
                  </div>
                </div>
                <div class="w-80 d-flex justify-content-center align-items-center">
                  <button mat-raised-button color="primary"
                          (click)="loadMore()">{{'users.samples.time.see_more' | transloco}}</button>
                </div>
              </div>
            </div>
              </div>
            </div>
        </form>
      </ng-template>
    </mat-step>

    <mat-step completed="false" editable="false">
      <ng-template matStepLabel>{{'users.samples.confirm.title' | transloco}}</ng-template>
      <ng-template matStepContent>
        <form [formGroup]="confirm" class="pt-2">
          <div class="text-center d-flex flex-column align-items-center justify-content-center"
               *ngIf="sample$ | async as sample">
            <div *transloco="let t; read: 'users.samples.confirm'">
              <h2 class="mt-4" *ngIf="sample.desiredEndTime">
                {{t('date_time', {
                date: sample.dateSample | date:'EEEE d MMMM', start: sample.desiredStartTime! | hour,
                end: sample.desiredEndTime! | hour
              })}}
              </h2>
              <h2 class="mt-4" *ngIf="!sample.desiredEndTime">
                {{t('date_at', {date: sample.dateSample | date:'EEEE d MMMM', start: sample.desiredStartTime! | hour})}}
                {{sample.tolerance ? t('timeTolerance', {tolerance: sample.tolerance}) : ''}}
              </h2>
              <p class="grey mb-0">{{t('to_address')}}</p>
              <h2>
                {{sample.adr1}}<span *ngIf="sample.adr2">, {{sample.adr2}}</span>
                <br>
                {{sample.cp}} {{sample.ville}}
              </h2>
              <div class="mb-2">
                <mat-checkbox class="list" formControlName="accepted">
                  <span class="grey small text-wrap">{{t('check_text')}}</span>
                </mat-checkbox>
              </div>
              <div>

                <button matStepperNext mat-raised-button color="primary" (click)="create()"
                        [disabled]="confirm.get('accepted')?.value !== true">
                  {{t('send')}}
                </button>
              </div>
            </div>
          </div>
        </form>
      </ng-template>
    </mat-step>
  </mat-stepper>
</mat-dialog-content>
