import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hour'
})
export class HourPipe implements PipeTransform {

  transform(value?: string, exact: boolean = false): string {
    if(value) {
      return value.replace(':', ' h ').substring(0, exact ? 7 : 5);
    }
    return ''
  }

}
