import {CdkDragDrop, CdkDragStart} from '@angular/cdk/drag-drop';
import {ChangeDetectionStrategy, Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {ActivatedRoute, Router} from '@angular/router';
import {DayEnum, LaboForm} from '@domain/labo/Labo';
import {InLaboSampleCount, Sample, StatusSampleEnum} from '@domain/samples/sample';
import {AggregatedUserSchedule, AggregatedUserScheduleException, User, UserScheduleException} from '@domain/user/user';
import {Navigate} from '@ngxs/router-plugin';
import {Actions, ofActionErrored, Select, Store} from '@ngxs/store';
import {ShowMessage} from '@states/global/global.actions';
import {MessageLevel} from '@states/global/global.state';
import {GetPreleveurs, LoadOneLabo} from '@states/labos/labos.actions';
import {LabosStateSelector} from '@states/labos/labos.selectors';
import {AffectHomeSample, UpdateSampleFilters, UpdateSamplePagination, ValidateSamplesForDay} from '@states/samples/samples.actions';
import {SamplesStateSelector} from '@states/samples/samples.selectors';
import {SortDirectionEnum} from '@utils/pagination/PaginationOption';
import {CreneauStatus, Disponibility} from '@utils/pipe/cut-hours.pipe';
import {DateTime} from 'luxon';
import {first, Observable, Subject, take, takeUntil} from 'rxjs';

@Component({
  selector: 'app-labo-planning-journee',
  templateUrl: './labo-planning-journee.component.html',
  styleUrls: ['./labo-planning-journee.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LaboPlanningJourneeComponent implements OnInit, OnDestroy {


  @Select(LabosStateSelector.preleveurs) preleveurs$: Observable<User[]>;
  @Select(LabosStateSelector.preleveursLoaded) preleveurLoaded$: Observable<boolean>
  @Select(LabosStateSelector.currentLabo) currentLabo$: Observable<LaboForm>;
  @Select(SamplesStateSelector.pendingHomeSamples) pendingSamples$: Observable<Sample[]>;
  @Select(SamplesStateSelector.affectedConfirmedHomeSamples) affectedConfirmedSamples$: Observable<Sample[]>;
  @Select(SamplesStateSelector.affectedHomeSamples) affectedSamples$: Observable<Sample[]>;
  @Select(SamplesStateSelector.inLaboSampleCount) inLaboSampleCount$: Observable<InLaboSampleCount>
  @Select(SamplesStateSelector.filteredSamplesLoaded) loaded$: Observable<boolean>

  day: DayEnum;

  date: DateTime;

  isPast: boolean;

  labo: LaboForm;

  status: typeof CreneauStatus = CreneauStatus

  private destroy: Subject<boolean> = new Subject<boolean>();

  currentSort: SortDirectionEnum = SortDirectionEnum.ASC

  sortEnum: typeof SortDirectionEnum = SortDirectionEnum;

  currentDragSample: Sample | null = null;

  constructor(private store: Store,
              private route: ActivatedRoute,
              private router: Router,
              private renderer: Renderer2,
              private actions$: Actions) {
  }

  ngOnInit(): void {

    this.route.paramMap.pipe(takeUntil(this.destroy)).subscribe(val => {
      const id: string | null = val.get('id');
      if (!id) {
        this.store.dispatch([ new Navigate(['labos']), new ShowMessage({
          content: { title: 'load_labo_failure' },
          level: MessageLevel.ERROR,
        }, 'labo')])
      }

      this.getPreleveurs(id!);
      this.getLabo(id!);

      this.currentLabo$.pipe(first(l => l != undefined)).subscribe(l => this.labo = l)

      this.route.queryParams.pipe(take(1)).subscribe(val => {
        if (!val['date']) {
          this.date = DateTime.now();
        } else {
          this.date = DateTime.fromISO(val['date']!);
        }

        this.isPast = this.date < DateTime.now();

        this.day = this.date.setLocale('en-GB').weekdayLong.toUpperCase() as DayEnum;
        this.getPendingAndConfirmSamples(id!);
      })
    });

    this.actions$.pipe(ofActionErrored(LoadOneLabo), takeUntil(this.destroy)).subscribe(s => {
      this.store.dispatch([ new Navigate(['labos']), new ShowMessage({
        content: { title: 'load_labo_failure' },
        level: MessageLevel.ERROR,
      }, 'labo')])
    })
  }



  getPreleveurs(laboId: string) {
    this.store.dispatch(new GetPreleveurs({laboId}))
  }



  getPendingAndConfirmSamples(laboId: string) {
    this.store.dispatch(new UpdateSampleFilters({
      inLabo: undefined,
      preleveurId: undefined,
      laboId: laboId,
      status: [
        StatusSampleEnum.PENDING,
        StatusSampleEnum.CONFIRM,
        StatusSampleEnum.AFFECTED,
        StatusSampleEnum.INPROGRESS,
        StatusSampleEnum.UNDERWAY,
        StatusSampleEnum.TOLABO,
        StatusSampleEnum.TOVALIDATE,
        StatusSampleEnum.DONE,
      ],
      date: this.date.toFormat('yyyy-MM-dd'),
    }, {
      pageSize: 0
    }))
  }

  toggleSortDirection() {
    if (this.currentSort === SortDirectionEnum.ASC) {
      this.currentSort = SortDirectionEnum.DESC;
      this.store.dispatch(new UpdateSamplePagination({
        sortByColumnName: 'patientName',
        sortDirection: SortDirectionEnum.DESC,
      }))
    } else {
      this.currentSort = SortDirectionEnum.ASC;
      this.store.dispatch(new UpdateSamplePagination({
        sortByColumnName: 'patientName',
        sortDirection: SortDirectionEnum.ASC,
      }))
    }
  }

  getLabo(laboId: string) {
    this.store.dispatch(new LoadOneLabo(laboId));
  }


  filterByDay = (us: AggregatedUserSchedule, day: DayEnum, labo: LaboForm): boolean => {
    return us.day === day && labo.zonesPreleveurs !== undefined && labo.zonesPreleveurs.some(zone => zone.id === us.zoneId)
  }

  filterByZone = (us: AggregatedUserSchedule | AggregatedUserScheduleException, zoneId: string): boolean => {
    return us.zoneId === zoneId
  }

  filterByDate = (use: UserScheduleException, date: DateTime): boolean => {
    return DateTime.fromISO(use.date).equals(date);
  }

  isHoliday = (use: UserScheduleException): boolean => {
    return use.zoneId == null && use.start === use.end
  }

  filterCreneauByZone(sample: Sample, zoneId: string) {
    return sample.zoneId === zoneId;
  }

  filterByPreleveur = (sample: Sample, preleveurId: string): boolean => {
    return sample.preleveur?.id === preleveurId
  }

  setSelected(element: HTMLElement, creneau: Disponibility) {
    if(creneau.status === CreneauStatus.FREE) {
      this.renderer.addClass(element, 'creneau-selected');
    }
  }

  removeSelected(element: HTMLElement, creneau: Disponibility) {
    if(creneau.status === CreneauStatus.FREE) {
      this.renderer.removeClass(element, 'creneau-selected')
    }
  }

  noReturnPredicate() {
    return false;
  }

  dropped(event: CdkDragDrop<any>, preleveurId: string, creneau: Disponibility, zoneId: string) {
    if (event.previousContainer === event.container || creneau.status === CreneauStatus.AWAY) {
      return;
    } else {
      this.store.dispatch(new AffectHomeSample({
        ...event.previousContainer.data[event.previousIndex],
        startTime: `${creneau.startHour}:00:00`,
        endTime: `${creneau.startHour+ creneau.size}:00:00`,
        zoneId,
        preleveurId
      }))
    }
  }


  validate() {
    this.store.dispatch(new ValidateSamplesForDay(this.labo.id!, this.date.toFormat('yyyy-MM-dd')))
  }

  changeDay(day: number) {
    this.date = this.date.plus({ day: day })
    this.changeDate()
  }

  dateChanged(date: MatDatepickerInputEvent<any>) {
    this.date = DateTime.fromJSDate(date.value);
    this.changeDate()
  }

  changeDate() {
    this.day = this.date.setLocale('en-GB').weekdayLong.toUpperCase() as DayEnum;
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { date: this.date.toFormat('yyyy-MM-dd') },
      });
    this.isPast = this.date < DateTime.now();
    this.store.dispatch(new UpdateSampleFilters({ date: this.date.toFormat('yyyy-MM-dd') }))
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

  selectCurrentSample(sample: Sample): void {
    this.currentDragSample = sample;
  }

  removeCurrentSample(): void {
    this.currentDragSample = null;
  }
  isAssociatedPreleveur(preleveur: User): boolean {
    if (this.currentDragSample) {
      return this.currentDragSample?.user?.associatedPreleveur?.id === preleveur.id;
    }
    return false;
  }

  isInCabinet(preleveur: User): boolean {
    if (this.currentDragSample?.user?.relativePreleveurs && preleveur.id) {
      return this.currentDragSample.user?.relativePreleveurs?.includes(preleveur?.id) && this.currentDragSample?.user?.associatedPreleveur?.id !== preleveur.id;
    }
    return false;
  }
}
