import {Location} from '@angular/common';
import {AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {Router} from '@angular/router';
import {ProfileEnum, User} from '@domain/user/user';
import {UserSampleEditComponent} from '@features/users/user-sample-edit/user-sample-edit.component';
import {UserFilter} from '@features/users/users-filter';
import {translate} from '@ngneat/transloco';
import {Select, Store} from '@ngxs/store';
import {UserInfo} from '@security/UserInfo';
import {UserService} from '@service/user.service';
import {LoadFilteredUsers, UpdateUserFilter, UpdateUserSort, UpdateUsersPagination} from '@states/user/users.actions';
import {UsersStateSelector} from '@states/user/users.selectors';
import {DialogConfirmationComponent} from '@utils/confirmation-dialog/dialog-confirmation.component';
import {PaginationOption} from '@utils/pagination/PaginationOption';
import {KeycloakService} from 'keycloak-angular';
import {debounceTime, Observable, Subject, takeUntil, take} from 'rxjs';

@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListUsersComponent extends UserInfo implements OnInit, OnDestroy {
  private destroy: Subject<boolean> = new Subject<boolean>();
  @Select(UsersStateSelector.filteredUsers) users$: Observable<User[]>;
  @Select(UsersStateSelector.paginationOption) paginationOption$: Observable<PaginationOption>;
  @Select(UsersStateSelector.filteredUsersLoaded) filteredUsersLoaded$: Observable<boolean>;
  profileEnum: string[] = Object.keys(ProfileEnum);
  ProfileEnum: typeof ProfileEnum = ProfileEnum;
  @Select(UsersStateSelector.userFilter) userFilter$: Observable<UserFilter>;
  @Select(UsersStateSelector.userFilterName) userFilterName$: Observable<string>
  filter: FormGroup;

  constructor(
    public override keycloakService: KeycloakService,
    private store: Store, private _userService: UserService, private _location: Location, private router: Router, public dialog: MatDialog,) {
    super(keycloakService);
  }

  ngOnInit(): void {
    this.filter = new FormGroup({
      profileFormControl: new FormControl(ProfileEnum),
      nameFormControl: new FormControl(),
    });

    this.userFilter$.pipe(take(1)).subscribe(f => {
      this.filter.get('profileFormControl')?.setValue(f.profile);
      this.filter.get('nameFormControl')?.setValue(f.name);
    })
    this.filter.valueChanges.pipe(debounceTime(500), takeUntil(this.destroy))
      .subscribe(_ => {
        this.store.dispatch(new UpdateUserFilter({
          name: this.filter.get('nameFormControl')?.value,
          profile: this.filter.get('profileFormControl')?.value }))
      });
    this.store.dispatch(new LoadFilteredUsers());
  }


  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

  onChangePage(pe: PageEvent): void {
    this.store.dispatch(
      new UpdateUsersPagination({
        pageIndex: pe.pageIndex,
        pageSize: pe.pageSize,
      })
    );
  }

  clearFilterField(): void {
    this.filter.get('nameFormControl')?.setValue('');
  }

  updateFilterName(event: string): void {
    this.filter.get('nameFormControl')?.setValue(event);
  }

  getLaboNames(user: User): any {
    return user.laboratory?.map(labo => labo.name);
  }

  searchNameChanged(search: string) {
    this.store.dispatch(new UpdateUserFilter({name: search}, {pageIndex: 0}))
  }

  getSearchFormControl(): FormControl {
    return this.filter.get('nameFormControl') as FormControl;
  }

  updateSort(columnName: string): void {
    this.store.dispatch(new UpdateUserSort(columnName));
    this.store.dispatch(new LoadFilteredUsers());
  }

  resetFilter(): void {
    this.filter.get('profileFormControl')?.setValue('');
  }

  createSample(id: string | undefined) {
    if (id) {
      const dialogRef = this.dialog.open(UserSampleEditComponent, {
        width:'1500px',
        data: {
          id: id,
        },
        disableClose: true,
      });
    }
  }
}
