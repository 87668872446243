import {Component} from '@angular/core';
import {environment} from '@environment/environment';
import {Store} from '@ngxs/store';
import {LoadUserProfile, Logout} from '@states/global/global.actions';
import {KeycloakService} from 'keycloak-angular';
import {UserInfo} from './security/UserInfo';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends UserInfo {
  title: string = 'kantysbio-app-web';

  enableLayout: boolean = true;
  prod: boolean = environment.production;
  version: string = environment.version;

  constructor(private _store: Store, public override keycloakService: KeycloakService) {
    super(keycloakService);
    _store.dispatch(new LoadUserProfile());
  }

  logout(): void {
    this._store.dispatch(new Logout())
  }
}
