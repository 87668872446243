
import {Nurse, NurseFilter, NurseForm} from '@domain/nurse/Nurse';
import {NurseActions} from '@states/nurse/nurses.actions.enum';
import {AddressNormalized, SearchAddressResult} from '@utils/coordinates/coordinates';
import {Pagination} from '@utils/pagination/Pagination';
import {PaginationOption} from '@utils/pagination/PaginationOption';

const PREFIX: string = '[Nurses] ';

export class LoadOneNurse {
  static readonly type: string = PREFIX + NurseActions.LOAD_ONE_NURSE;
  constructor(public id: string) {}
}
export class LoadOneNurseSuccess {
  static readonly type: string = PREFIX + NurseActions.LOAD_ONE_NURSE_SUCCESS;
  constructor(public nurse: Nurse) {}
}

export class LoadOneNurseFailure {
  static readonly type: string = PREFIX + NurseActions.LOAD_ONE_NURSE_FAIL;
  constructor(public error: String) {}
}

export class LoadOneNewNurse {
  static readonly type: string = PREFIX + NurseActions.LOAD_ONE_NEW_NURSE;
}

export class LoadFilteredNurses {
  static readonly type: string = PREFIX + NurseActions.LOAD_FILTERED_NURSES;
}

export class LoadFilteredNursesSuccess {
  static readonly type: string = PREFIX + NurseActions.LOAD_FILTERED_NURSES_SUCCESS;
  constructor(public nurses: Pagination<Nurse>) {}
}

export class LoadFilteredNursesFailure {
  static readonly type: string = PREFIX + NurseActions.LOAD_FILTERED_NURSES_FAIL;
  constructor(public error: string) {}
}

export class SaveOneNurse {
  static readonly type: string = PREFIX + NurseActions.SAVE_ONE_NURSE;
  constructor(public nurse: NurseForm) {}
}

export class SaveOneNurseSuccess {
  static readonly type: string = PREFIX + NurseActions.SAVE_ONE_NURSE_SUCCESS;
}

export class SaveOneNurseFailure {
  static readonly type: string = PREFIX + NurseActions.SAVE_ONE_NURSE_FAIL;
  constructor(public error: string) {}
}

export class DeleteOneNurse {
  static readonly type: string = PREFIX + NurseActions.DELETE_ONE_NURSE;
  constructor(public id: string) {}
}

export class DeleteOneNurseSuccess {
  static readonly type: string = PREFIX + NurseActions.DELETE_ONE_NURSE_SUCCESS;
}

export class DeleteOneNurseFailure {
  static readonly type: string = PREFIX + NurseActions.DELETE_ONE_NURSE_FAIL;
  constructor(public error: string) {}
}

export class UpdateNursePagination {
  static readonly type: string = PREFIX + NurseActions.UPDATE_NURSE_PAGINATION;
  constructor(public paginationOption: PaginationOption) {}
}

export class UpdateNurseSort {
  static readonly type: string = PREFIX + NurseActions.UPDATE_NURSE_SORT;
  constructor(public sortByColumnName: string) {}
}

export class UpdateNurseFilter {
  static readonly type: string = PREFIX + NurseActions.UPDATE_NURSE_FILTER;
  constructor(public filter: NurseFilter, public pagination?: PaginationOption) {}
}

export class SearchAddress {
  static readonly type: string = PREFIX + NurseActions.SEARCH_ADDRESS;
  constructor(public nurse: AddressNormalized) {}
}

export class SearchAddressSuccess {
  static readonly type: string = PREFIX + NurseActions.SEARCH_ADDRESS_SUCCESS;
  constructor(public searchResults: SearchAddressResult[]) {}
}

export class SearchAddressFailure {
  static readonly type: string = PREFIX + NurseActions.SEARCH_ADDRESS_FAILURE;
  constructor(public error: string) {}
}
