import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import { LaboForm } from '@domain/labo/Labo';
import {Sample} from '@domain/samples/sample';
import { User } from '@domain/user/user';
import {Select, Store} from '@ngxs/store';
import { LabosStateSelector } from '@states/labos/labos.selectors';
import {SamplesStateSelector} from '@states/samples/samples.selectors';
import * as L from 'leaflet';
import { Feature, Polygon } from 'geojson';
import {DivIcon, FeatureGroup, LatLng, LayerGroup} from 'leaflet';
import {distinctUntilKeyChanged, filter, Observable, Subject, takeUntil, tap} from 'rxjs';

@Component({
  selector: 'app-sample-map',
  templateUrl: './sample-map.component.html',
  styleUrls: ['./sample-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SampleMapComponent implements OnInit, OnDestroy {

  private map: L.Map;

  private markers: L.Marker[] = [];

  private trajet: L.Polyline = new L.Polyline([], {className: 'trajet'});

  private group: L.FeatureGroup = new FeatureGroup()

  private zones: L.FeatureGroup = new FeatureGroup()

  private legend = new L.Control({position: "bottomleft"});

  private isMapFitted: boolean = false

  private zoneIds: string[] = []

  private destroy: Subject<boolean> = new Subject<boolean>();
  @Select(SamplesStateSelector.sortedSamples) sortedSamples$: Observable<Sample[]>;
  @Select(SamplesStateSelector.currentPreleveur) preleveur$: Observable<User>
  @Select(LabosStateSelector.currentLabo) labo$: Observable<LaboForm>;

  private laboMarker: L.Marker;

  myIcon = L.icon({
    iconUrl: "assets/img/pin-K.svg",
    iconSize: [34, 40],
    iconAnchor: [18, 43],
    popupAnchor: [-2, -40],
  });

  constructor() {
  }

  ngOnInit(): void {
    this.initMap()
    this.markLabo()

    this.preleveur$.pipe(takeUntil(this.destroy)).subscribe(p => {
      this.zoneIds = p.disponibilities!.map(d => d.zoneId!)
    })

    this.labo$.pipe(takeUntil(this.destroy)).subscribe(labo => {

      if (labo) {
        this.zones.clearLayers();
        labo.zonesPreleveurs?.forEach((zone, i) => {
          if (zone.zone && this.zoneIds.includes(zone.id!)) {
            const color = this.selectColor(i);
            const layer = new L.Polygon(
              (zone.zone as Polygon).coordinates[0].map(c => {
                return new L.LatLng(c[1], c[0])
              })
            );
            layer.bindTooltip(new L.Tooltip({permanent: false, direction: 'center'}).setContent(zone.name!))

            layer.setStyle({color}).addTo(this.zones);

          }

        });
        this.zones.addLayer(this.laboMarker);
        this.map.fitBounds(this.zones.getBounds());
      }
    })

    this.sortedSamples$.pipe(filter(array => array.every(s => s.latitude != null && s.longitude != null)), takeUntil(this.destroy)).subscribe(samples => {

      this.group.clearLayers()
      this.markers = []
      let trajet: LatLng[]= [];
      samples.forEach((sample, index) => {
        this.markers.push(new L.Marker(new LatLng(Number(sample.latitude!), Number(sample.longitude!)), {
          icon: new DivIcon({
            html: `<div class="rdv-number"> ${index + 1} </div>
                      <img
                        class="red-pin" src="/assets/img/location.svg" alt="">
                   `,
            iconAnchor: [25, 50],
            popupAnchor: [-2, -40],
          }),
        }))
        trajet.push(new LatLng(Number(sample.latitude!), Number(sample.longitude!)))
      })
      this.markers.forEach(m => {
        m.addTo(this.group)
      })
      this.trajet.setLatLngs(trajet)
      if(!this.isMapFitted){
        this.map.flyToBounds(this.group.getBounds(), { duration: 1})
        this.isMapFitted = !this.isMapFitted
      }
    })
  }


  initMap(): void {
    this.map = L.map('map', {
      center: [47, 2],
      zoom: 5,
    });

    this.map.on('movestart', () => this.isMapFitted = false)

    // const tiles: any = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    //   maxZoom: 18,
    //   minZoom: 6,
    //   attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    // });


      const tiles: L.TileLayer = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&apistyle=s.t:2|s.e:l|p.v:off', {
      maxZoom: 18,
      minZoom: 3,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      attribution: '&copy; <a href="https://www.google.fr/maps/">Google Maps</a>',
    });

    tiles.addTo(this.map);
    this.trajet.addTo(this.map)
    this.group.addTo(this.map)
    this.zones.addTo(this.map)
  //   this.legend.onAdd = function(mymap){
  //     var div = L.DomUtil.create('div', 'legend');
  //     let labels = ['<strong>Categories</strong>'];
  //     let categories = ["Tobacco","Whiskey","Beer","Cigar","Other"];
  //     return div;
  // };
  //   this.legend.addTo(this.map);


  }

  markLabo(): void {
    this.labo$.pipe(takeUntil(this.destroy)).subscribe(labo => {
      if (labo && labo.position) {
        this.laboMarker = L.marker(new L.LatLng(labo.position.coordinates[1], labo.position.coordinates[0]), {icon: this.myIcon});
        this.laboMarker.addTo(this.map);
        this.map.flyTo(new L.LatLng(labo.position.coordinates[1], labo.position.coordinates[0]), 10, {duration:1});
      }
    })

  }

  selectColor(number: number, alpha:number = 1) {
    const hue = number * 137.508;
    return `hsla(${hue}, 100%, 40%, ${alpha})`;
  }

  public ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

}
