<input type="file" class="file-input d-none" (change)="onFileSelected($event)" [accept]="accept" [multiple]="multiple" #fileUpload>
<div class="file-upload">
  <button mat-mini-fab type="button" color="primary" class="upload-btn" (click)="fileUpload.click()">
    <mat-icon>add</mat-icon>
  </button>
  <div *ngIf=" showList && files.length > 0">
    <div *ngFor="let file of files">
      <label>{{file[1]}}</label>
      <button type="button" mat-icon-button>
        <mat-icon color="warn" (click)="delete(file)">close</mat-icon>
      </button>
    </div>

  </div>
</div>

