export enum CabinetsActionsEnum {
  LOAD_ONE_CABINET = 'Load one cabinet',
  LOAD_ONE_CABINET_SUCCESS = 'Load one cabinet success',
  LOAD_ONE_CABINET_FAILURE = 'Load one cabinet failure',

  LOAD_ONE_NEW_CABINET = 'Load one new cabinet',

  LOAD_FILTERED_CABINETS = 'Load filtered cabinets',
  LOAD_FILTERED_CABINETS_SUCCESS = 'Load filtered cabinets success',
  LOAD_FILTERED_CABINETS_FAILURE = 'Load filtered cabinets failure',

  UPDATE_CABINETS_FILTER = 'Update cabinets filter',
  UPDATE_CABINETS_PAGINATION = 'Update cabinets pagination',

  UPDATE_PRELEVEURS_FILTER = 'Update preleveurs filter',
  UPDATE_PRELEVEURS_PAGINATION = 'Update preleveurs pagination options',

  LOAD_FILTERED_PRELEVEURS = 'Load filtered preleveurs',
  LOAD_FILTERED_PRELEVEURS_SUCCESS = 'Load filtered preleveurs success',
  LOAD_FILTERED_PRELEVEURS_FAILURE = 'Load filtered preleveurs failure',

  ADD_PRELEVEUR_TO_CABINET = 'Add preleveur to cabinet',
  REMOVE_PRELEVEUR_FROM_CABINET = 'Remove preleveur from cabinet',

  SAVE_ONE_CABINET = 'Save one cabinet',
  SAVE_ONE_CABINET_SUCCESS = 'Save one cabinet success',
  SAVE_ONE_CABINET_FAILURE = 'Save one cabinet failure',

  DELETE_ONE_CABINET = 'Delete one cabinet',
  DELETE_ONE_CABINET_SUCCESS = 'Delete one cabinet success',
  DELETE_ONE_CABINET_FAILURE = 'Delete one cabinet failure'

}
