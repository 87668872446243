import {Injectable} from '@angular/core';
import {AdresseApi, Coord} from '@domain/geoapi/AdresseApi';
import {Labo} from '@domain/labo/Labo';
import {Check, Dateslot, Sample, SampleInfos, SamplesFilter, StatusSampleEnum, Timeslot} from '@domain/samples/sample';
import {AggregatedUserSchedule, AggregatedUserScheduleException, AssociationModeEnum, User} from '@domain/user/user';
import {Action, State, StateContext, Store} from '@ngxs/store';
import {patch, updateItem} from '@ngxs/store/operators';
import {GeoApiService} from '@service/geoapi.service';
import {LaboService} from '@service/labo.service';
import {MapService} from '@service/map.service';
import {SampleService} from '@service/sample.service';
import {ScheduleService} from '@service/schedule.service';
import {UserService} from '@service/user.service';
import {ShowMessage} from '@states/global/global.actions';
import {MessageLevel} from '@states/global/global.state';
import {LabosStateSelector} from '@states/labos/labos.selectors';

import {
  AffectHomeSample,
  AffectHomeSampleFailure,
  AffectHomeSampleSuccess,
  ChangeLaboDate,
  ChangeLaboDateFailure,
  ChangeLaboDateSuccess,
  CreateNewSample,
  CreateSample,
  CreateSampleFailure,
  CreateSampleSuccess,
  DownloadPrescription,
  DownloadPrescriptionFailure,
  DownloadPrescriptionSuccess,
  GetChecks,
  GetChecksFailure,
  GetChecksSuccess,
  GetNearestLaboForSample,
  GetNearestLaboForSampleFailure,
  GetNearestLaboForSampleSuccess,
  GetSample,
  GetSampleFailure,
  GetSampleFixedScheduleOption,
  GetSampleFixedScheduleOptionFailure,
  GetSampleFixedScheduleOptionSuccess,
  GetSamplesByDay,
  GetSamplesByDayFailure,
  GetSamplesByDaySuccess,
  GetSampleSuccess,
  GetSampleTimeSlots,
  GetSampleTimeSlotsFailure,
  GetSampleTimeSlotsSuccess,
  LoadFilteredSamples,
  LoadFilteredSamplesFailure,
  LoadFilteredSamplesSuccess,
  LoadPreleveur,
  LoadPreleveurFailure,
  LoadPreleveurSuccess,
  LocalPatchSample,
  ResetOrderModified,
  ResetSampleTimeSlot,
  SampleCreationPatch,
  SavePlanningForDayAndPreleveur,
  SavePlanningForDayAndPreleveurFailure,
  SavePlanningForDayAndPreleveurSuccess,
  SetDate,
  UpdatePlanningFilters,
  UpdateSampleFilters,
  UpdateSamplePagination,
  UpdateSampleSort,
  UpdateStatus,
  UpdateStatusFailure,
  UpdateStatusSuccess, UpdateTimeSlotsSearchMode,
  ValidateSamplesForDay,
  ValidateSamplesForDayFailure,
  ValidateSamplesForDaySuccess,
  ValidateSaveStatus,
  ValidateSaveStatusFailure,
  ValidateSaveStatusSuccess,
} from '@states/samples/samples.actions';
import {Pagination} from '@utils/pagination/Pagination';
import {PaginationOption, SortDirectionEnum} from '@utils/pagination/PaginationOption';
import {Dictionary, groupBy} from 'lodash';
import {DateTime} from 'luxon';
import {EMPTY, map} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';

export interface SamplesStatesModel {
  filteredSamples?: Pagination<Sample>;
  filteredSamplesLoaded: boolean;
  sampleOrderModified: boolean;
  labo?: Labo;
  preleveur?: User;
  samplesFilter: SamplesFilter;
  paginationOption: PaginationOption;
  date?: DateTime;
  checks?: Check[];
  sampleCreation?: Sample;
  sampleDateSlots?: Dateslot[];
  samplesByDay?: SampleInfos[];
  sampleInfo: Sample;
  planningFilter: SamplesFilter;
  planningByDayLoaded: boolean;
  timeslotsSearchMode: AssociationModeEnum;
}

@State<SamplesStatesModel>({
  name: 'samples',
  defaults: {
    filteredSamples: {
      content: [],
      totalElements: 0,
    },
    filteredSamplesLoaded: true,
    sampleOrderModified: false,
    labo: {},
    samplesFilter: {
      patientName: '',
      status: Object.keys(StatusSampleEnum),
    },
    paginationOption: {
      length: 100,
      pageSize: 25,
      pageIndex: 0,
      pageSizeOption: [10, 25, 50, 100],
    },
    sampleInfo: {},
    samplesByDay: [],
    planningFilter: {},
    checks: [],
    sampleCreation: undefined,
    sampleDateSlots: [],
    planningByDayLoaded: true,
    timeslotsSearchMode: AssociationModeEnum.STRICT,
  },
})
@Injectable()
export class SamplesState {
  constructor(private samplesService: SampleService, private laboService: LaboService, private mapService: MapService, private userService: UserService, private scheduleService: ScheduleService, private _geoApi: GeoApiService, private store: Store) {
  }

  @Action(LoadFilteredSamples)
  loadFilteredSamples(ctx: StateContext<SamplesStatesModel>){
    ctx.patchState({
      filteredSamplesLoaded: false
    });
    const state = ctx.getState();
    return this.samplesService.findByFilterPaging(state.samplesFilter, state.paginationOption).pipe(
      map(samples => ctx.dispatch(new LoadFilteredSamplesSuccess(samples))),
      catchError((error: any) => ctx.dispatch(new LoadFilteredSamplesFailure(error)))
    );
  }

  @Action(LoadFilteredSamplesSuccess)
  loadFilteredSamplesSuccess(ctx: StateContext<SamplesStatesModel>, {samples}: LoadFilteredSamplesSuccess) {
    const state = ctx.getState();
    if (samples) {
      ctx.setState(
        patch({
          filteredSamples: samples,
          filteredSamplesLoaded: true,
          paginationOption: patch({
            ...state.paginationOption,
            length: samples.totalElements,
          })
        })
      );
    }
  }

  @Action(LoadFilteredSamplesFailure)
  loadFilteredSamplesFailure(ctx: StateContext<SamplesStatesModel>, { error }: LoadFilteredSamplesFailure) {
    ctx.setState(
      patch({
        filteredSamplesLoaded: true,
      }),
    );
    return ctx.dispatch(new ShowMessage({
      content: { title: 'load_samples_failure' },
      level: MessageLevel.ERROR,
    }, 'samples'))
  }

  @Action(ChangeLaboDate)
  changeLaboDate(ctx: StateContext<SamplesStatesModel>, {laboId, date}: ChangeLaboDate) {
    if (date) {
      const state = ctx.getState();
      ctx.patchState({
        samplesFilter: {
          ...state.samplesFilter,
          date: DateTime.fromJSDate(date).toFormat('yyyy-MM-dd')
        }
      });
    }
    return this.laboService.get(laboId).pipe(
      map(labo => ctx.dispatch(new ChangeLaboDateSuccess(labo))),
      catchError((error: any) => ctx.dispatch(new ChangeLaboDateFailure(error)))
    );
  }

  @Action(ChangeLaboDateSuccess)
  changeLaboDateSuccess(ctx: StateContext<SamplesStatesModel>, {labo}: ChangeLaboDateSuccess) {
    if (labo) {
      const state = ctx.getState();
      ctx.patchState({
        labo: labo,
        samplesFilter: {
          ...state.samplesFilter,
          laboId: labo.id
        },
      });
      ctx.dispatch(new LoadFilteredSamples());
    }
  }

  @Action(ChangeLaboDateFailure)
  changeLaboFailure(ctx: StateContext<SamplesStatesModel>, {error}: ChangeLaboDateFailure){

  }

  @Action(UpdateSamplePagination)
  updatePagination(ctx: StateContext<SamplesStatesModel>, {pagination}: UpdateSamplePagination){
    const state = ctx.getState();
    ctx.patchState({
      paginationOption: {
        ...state.paginationOption,
        ...pagination,
      },
    });
    return ctx.dispatch(new LoadFilteredSamples());
  }

  @Action(UpdateSampleFilters)
  updateSampleFilters(ctx: StateContext<SamplesStatesModel>, {filter, pagination}: UpdateSampleFilters) {
    const state = ctx.getState();
    ctx.patchState({
      samplesFilter: {
        ...state.samplesFilter,
        ...filter,
      },
      paginationOption: {
        ...state.paginationOption,
        pageIndex: 0,
        ...pagination,
      },
    });
    return ctx.dispatch(new LoadFilteredSamples());
  }

  @Action(UpdateStatus)
  updateStatus(ctx: StateContext<SamplesStatesModel>, {sampleId, status}: UpdateStatus) {
    return this.samplesService.updateStatus(sampleId, status).pipe(
      map(sample => ctx.dispatch(new UpdateStatusSuccess())),
      catchError((error: string) => ctx.dispatch(new UpdateStatusFailure(error)))
    );
  }

  @Action(UpdateStatusSuccess)
  updateStatusSuccess(ctx: StateContext<SamplesStatesModel>) {
    ctx.dispatch(new LoadFilteredSamples());
    return ctx.dispatch(new ShowMessage({
      content: { title: 'samples_update_status' },
      level: MessageLevel.SUCCESS,
    }, 'samples'))
  }

  @Action(UpdateStatusFailure)
  updateStatusFailure(ctx: StateContext<SamplesStatesModel>, {error}: UpdateStatusFailure) {
    return ctx.dispatch(new ShowMessage({
      content: { title: 'update_status_failure' },
      level: MessageLevel.ERROR,
    }, 'samples'))
  }

  @Action(AffectHomeSample)
  affectHomeSample(ctx: StateContext<SamplesStatesModel>, { sample }: AffectHomeSample) {
    const toInt = (hour: string) => parseInt(hour!.substring(0, hour.indexOf(':')));
    const debut: number = toInt(sample.startTime!) % 2 ? toInt(sample.startTime!): toInt(sample.startTime!) - 1;
    const fin: number = toInt(sample.endTime!) % 2 ?  toInt(sample.endTime!): toInt(sample.endTime!) + 1 ;
    const sampleList: Sample[] = ctx.getState().filteredSamples ? ctx.getState().filteredSamples!.content : [];
    const orderInCreneau = sampleList.filter(s => s.startTime && s.endTime).filter(s => toInt(s.startTime!) == debut || toInt(s.endTime!) == fin).length + 1

    return this.samplesService.confirmSample({...sample, orderInCreneau}).pipe(
      map(sample => ctx.dispatch(new AffectHomeSampleSuccess(sample))),
      catchError((error: string) => ctx.dispatch(new AffectHomeSampleFailure(error)))
    );
  }

  @Action(AffectHomeSampleSuccess)
  affectHomeSampleSuccess(ctx: StateContext<SamplesStatesModel>, { sample }: AffectHomeSampleSuccess) {
    const laboId = this.store.selectSnapshot(LabosStateSelector.currentLabo)?.id;

    return ctx.dispatch(new UpdateSampleFilters({
      laboId: laboId,
      status: [
        StatusSampleEnum.PENDING,
        StatusSampleEnum.CONFIRM,
        StatusSampleEnum.AFFECTED,
        StatusSampleEnum.INPROGRESS,
        StatusSampleEnum.UNDERWAY,
        StatusSampleEnum.TOLABO,
        StatusSampleEnum.TOVALIDATE,
        StatusSampleEnum.DONE,
      ],
      date: sample.dateSample,
    }))
  }

  @Action(UpdateSampleSort)
  updateSort(ctx: StateContext<SamplesStatesModel>, {sortByColumnName}: UpdateSampleSort) {
    const state = ctx.getState();
    let sortDirection: SortDirectionEnum;
    if(state.paginationOption.sortByColumnName === sortByColumnName && state.paginationOption.sortDirection === SortDirectionEnum.ASC) {
      sortDirection = SortDirectionEnum.DESC;
    }
    else if (state.paginationOption.sortByColumnName === sortByColumnName && state.paginationOption.sortDirection === SortDirectionEnum.DESC) {
      sortDirection = SortDirectionEnum.ASC;
    }
    else {
      sortDirection = SortDirectionEnum.ASC;
    }
    ctx.patchState({
      paginationOption: {
        ...state.paginationOption,
        sortByColumnName: sortByColumnName,
        sortDirection: sortDirection,
      }
    })
  }

  @Action(ValidateSamplesForDay)
  validateSamplesForDay(ctx: StateContext<SamplesStatesModel>, { laboId, date }: ValidateSamplesForDay) {
    return this.samplesService.validateSamplesForDay(laboId, date).pipe(
      map(sample => ctx.dispatch(new ValidateSamplesForDaySuccess(date))),
      catchError((error: string) => ctx.dispatch(new ValidateSamplesForDayFailure(error)))
    );
  }

  @Action(ValidateSamplesForDaySuccess)
  validateSamplesForDaySuccess(ctx: StateContext<SamplesStatesModel>, { date }: ValidateSamplesForDaySuccess) {
    const laboId = this.store.selectSnapshot(LabosStateSelector.currentLabo)?.id;

    return ctx.dispatch([
      new UpdateSampleFilters({
          laboId: laboId,
          status: [
            StatusSampleEnum.PENDING,
            StatusSampleEnum.CONFIRM,
            StatusSampleEnum.AFFECTED,
            StatusSampleEnum.INPROGRESS,
            StatusSampleEnum.UNDERWAY,
            StatusSampleEnum.TOLABO,
            StatusSampleEnum.TOVALIDATE,
            StatusSampleEnum.DONE,
          ],
          date: date,
        },
      ), new ShowMessage({
        content: { title: 'day_validated' },
        level: MessageLevel.SUCCESS,
      }, 'samples'),
    ])
  }

  @Action(LoadPreleveur)
  loadPreleveur(ctx: StateContext<SamplesStatesModel>, { id }: LoadPreleveur) {
    return this.userService.get(id).pipe(
      map(user => ctx.dispatch(new LoadPreleveurSuccess(user))),
      catchError((error: any) => ctx.dispatch(new LoadPreleveurFailure(error))),
    );
  }

  @Action(LoadPreleveurSuccess)
  loadPreleveurSuccess(ctx: StateContext<SamplesStatesModel>, { preleveur }: LoadPreleveurSuccess) {

    const aggregatedDisponibilities: AggregatedUserSchedule[] | undefined = preleveur.disponibilities?.reduce<AggregatedUserSchedule[]>((acc, dispo) => {
      const dayDispo = acc.find(p => p.day === dispo.day && p.zoneId === dispo.zoneId);
      if (dayDispo) {
        const middle = dayDispo.schedule.find(d => !(d[0] > dispo.end! || d[1] < dispo.start!))
        if (middle) {
          middle[0] = dispo.start! < middle[0] ? dispo.start! : middle[0];
          middle[1] = dispo.end! > middle[1] ? dispo.end! : middle[1];
        } else {
          dayDispo.schedule.push([dispo.start!, dispo.end!]);
        }
      } else {
        acc.push({day: dispo.day, schedule: [[dispo.start!, dispo.end!]], zoneId: dispo.zoneId!});
      }
      return acc;
    }, []);

    const aggregatedExceptions: AggregatedUserScheduleException[] | undefined = preleveur.exceptions?.reduce<AggregatedUserScheduleException[]>((acc, exception) => {
      const dateDispo = acc.find(p => p.date === exception.date && p.zoneId === exception.zoneId);
      if (dateDispo) {
        const middle = dateDispo.schedule.find(d => !(d[0] >=exception.end! || d[1] <= exception.start!))
          if (middle) {
            middle[0] = exception.start! < middle[0] ? exception.start! : middle[0];
            middle[1] = exception.end! > middle[1] ? exception.end! : middle[1];
          } else {
            dateDispo.schedule.push([exception.start!, exception.end!]);
          }
      } else {
        acc.push({date: exception.date, schedule: [[exception.start!, exception.end!]], zoneId: exception.zoneId!});
      }
      return acc;
    }, []);

    preleveur.aggregatedDisponibilities = aggregatedDisponibilities || [];
    preleveur.aggregatedExceptions = aggregatedExceptions || [];
    return ctx.patchState({
      preleveur
    })
  }

  @Action(LocalPatchSample)
  localPatchSample(ctx: StateContext<SamplesStatesModel>, {
    id,
    sample,
  }: LocalPatchSample) {
    ctx.setState(
      patch<SamplesStatesModel>({
        filteredSamples: patch({
          content: updateItem<Sample>(sample => sample?.id === id, patch(sample)),
        }),
        sampleOrderModified: true,
      }),
    )
  }

  @Action(SavePlanningForDayAndPreleveur)
  savePlanningForDayAndPreleveur(ctx: StateContext<SamplesStatesModel>) {
    return this.samplesService.savePlanning(ctx.getState().filteredSamples!.content!).pipe(
      map(s => ctx.dispatch(new SavePlanningForDayAndPreleveurSuccess(s))),
      catchError(e => ctx.dispatch(new SavePlanningForDayAndPreleveurFailure(e))),
    )
  }

  @Action(SavePlanningForDayAndPreleveurSuccess)
  savePlanningForDayAndPreleveurSuccess(ctx: StateContext<SamplesStatesModel>, { samples }: SavePlanningForDayAndPreleveurSuccess) {
    ctx.setState(patch({
      sampleOrderModified: false,
    }))

    return ctx.dispatch([
      new LoadFilteredSamples(), new ShowMessage({
        content: { title: 'planning_saved' },
        level: MessageLevel.SUCCESS,
      }, 'samples'),
    ])
  }

  @Action(ResetOrderModified)
  resetOrderModified(ctx: StateContext<SamplesStatesModel>) {
    ctx.setState(patch({
      sampleOrderModified: false
    }))
  }

  @Action(SetDate)
  setDate(ctx: StateContext<SamplesStatesModel>, {date}: SetDate) {
    ctx.patchState({
      date
    })
  }

  @Action(DownloadPrescription)
  downloadPrescription(ctx: StateContext<SamplesStatesModel>, {id, filename}: DownloadPrescription) {
    return this.samplesService.downloadPrescription(id).pipe(
      map((doc) => ctx.dispatch(new DownloadPrescriptionSuccess(doc, filename))),
      catchError((error: string) => ctx.dispatch(new DownloadPrescriptionFailure(error)))
    );
  }

  @Action(DownloadPrescriptionSuccess)
  async downloadPrescriptionSuccess(ctx: StateContext<SamplesStatesModel>, {doc, filename}: DownloadPrescriptionSuccess) {
      const file: Blob = new Blob([doc.body!], {type: doc.headers.get('Content-Type') || 'image/png'});
      const url = window.URL.createObjectURL(file);
      window.open(url, '_blank');
  }

  @Action(DownloadPrescriptionFailure)
  downloadPrescriptionFailure(ctx: StateContext<SamplesStatesModel>, {error}: DownloadPrescriptionFailure) {
    return ctx.dispatch(new ShowMessage({
      content: { title: 'download_prescription_failure' },
      level: MessageLevel.ERROR,
    }, 'samples'))
  }

  @Action(GetChecks)
  getChecks(ctx: StateContext<SamplesStatesModel>, {laboId}: GetChecks) {
    return this.samplesService.getCheck(laboId).pipe(
      map((checks) => ctx.dispatch(new GetChecksSuccess(checks.content))),
      catchError((error: string) => ctx.dispatch(new GetChecksFailure(error)))
    );
  }

  @Action(GetChecksSuccess)
  getChecksSuccess(ctx: StateContext<SamplesStatesModel>, {checks} : GetChecksSuccess) {
    ctx.patchState({
      checks: checks,
    });
  }

  @Action(GetChecksFailure)
  getChecksFailure(ctx: StateContext<SamplesStatesModel>, {error}: GetChecksFailure) {

  }

  @Action(CreateNewSample)
  createNewSample(ctx: StateContext<SamplesStatesModel>) {
    ctx.patchState({
      sampleCreation: {
        id: undefined,
        dateSample: '',
        status: undefined,
        user: undefined,
        labo: undefined,
        laboId: undefined,
        userId: undefined,
        preleveur: undefined,
        desiredStartTime: undefined,
        desiredEndTime: undefined,
        startTime: undefined,
        endTime: undefined,
        tolerance: undefined,
        orderInCreneau: undefined,
        hasRx: undefined,
        homeRx: undefined,
        documents: undefined,
        checks: undefined,
        inLabo: false,
        adr1: '',
        adr2: '',
        cp: '',
        ville: '',
        latitude: '',
        longitude: '',
        resultsUrl: '',
        zoneId: '',
      },
      sampleDateSlots: [],
      timeslotsSearchMode: AssociationModeEnum.STRICT,
      checks: [],
      labo: {},
    });
  }

  @Action(GetNearestLaboForSample)
  getNearestLaboForSample(ctx: StateContext<SamplesStatesModel>, {userId, adresse}: GetNearestLaboForSample) {
    let coords: Coord = {latitude: 0, longitude: 0};
    return this._geoApi
      .getCoordOfAddress(adresse)
      .pipe(
        switchMap((adrApi: AdresseApi) => {
          if (adrApi) {
            coords = AdresseApi.getCoord(adrApi);
            return this.laboService.findNearest(coords.latitude, coords.longitude,25000);
          } else {
            return EMPTY;
          }
        }),
        catchError((error: string) => ctx.dispatch(new GetNearestLaboForSampleFailure(error)))
      )
      .subscribe(labos => ctx.dispatch(new GetNearestLaboForSampleSuccess(userId, labos ? labos : [], adresse, coords)));
  }

  @Action(GetNearestLaboForSampleSuccess)
  getNearestLaboForSampleSuccess(ctx: StateContext<SamplesStatesModel>, {userId, labos, adresse, coords}: GetNearestLaboForSampleSuccess) {
    const labo: Labo | undefined = labos.length ? labos[0] : undefined;
    if (labo?.id) {
      const sample: Sample = {
        userId: userId,
        dateSample: undefined,
        adr1: adresse.adr1,
        adr2: adresse.adr2,
        cp: adresse.cp,
        ville: adresse.ville,
        pays: adresse.pays,
        commentaire: adresse.commentaire,
        laboId: labo.id,
        laboName: labo.name,
        inLabo: false,
        latitude: coords?.latitude.toString(),
        longitude: coords?.longitude.toString(),
      };
      ctx.patchState({
        labo: labo,
      });
      ctx.dispatch(new GetSampleFixedScheduleOption(labo.id));
      ctx.dispatch(new SampleCreationPatch(sample));
    } else {
      ctx.dispatch(new GetNearestLaboForSampleFailure(''));
    }
  }

  @Action(GetNearestLaboForSampleFailure)
  getNearestLaboForSampleFailure(ctx: StateContext<SamplesStatesModel>, {error}: GetNearestLaboForSampleFailure) {
    ctx.patchState({
      labo: undefined,
    })
    ctx.dispatch(new ShowMessage({content: {title: 'nearest_labo_failure'}, level: MessageLevel.ERROR}, 'labos'));
  }

  @Action(SampleCreationPatch)
  sampleCreationPatch(ctx: StateContext<SamplesStatesModel>, {sample}: SampleCreationPatch) {
    ctx.patchState({
      sampleCreation: {
        ...ctx.getState().sampleCreation,
        ...sample,
      },
    });
  }

  @Action(GetSampleTimeSlots)
  async getSampleTimeSlots(ctx: StateContext<SamplesStatesModel>, {nbDays, last, searchMode}: GetSampleTimeSlots) {
    const sample: Sample | undefined = ctx.getState().sampleCreation;
    const dates = await this.scheduleService.getNextXDate(nbDays, sample?.cp, last);
    const datesIso: string[] = dates.map(slot => DateTime.fromJSDate(slot).toISODate());
    return this.samplesService.getSampleTimeslot(sample!.latitude!, sample!.longitude!, datesIso, sample!.userId!, searchMode).pipe(
      map((timeslots) => ctx.dispatch(new GetSampleTimeSlotsSuccess(timeslots))),
      catchError((error: string) => ctx.dispatch(new GetSampleTimeSlotsFailure(error)))
    );
  }

  @Action(GetSampleTimeSlotsSuccess)
  getSampleTimeSlotsSuccess(ctx: StateContext<SamplesStatesModel>, {timeslots}: GetSampleTimeSlotsSuccess) {
    const result: Dictionary<Timeslot[]> = groupBy(timeslots, 'date');

    const dateslots: Dateslot[] = [];
    for (const [key, value] of Object.entries(result)) {
      dateslots.push({
        date: new Date(key),
        timeslots: value,
      });
    }
    ctx.patchState({
      sampleDateSlots: ctx.getState()?.sampleDateSlots?.concat(dateslots),
    });
  }

  @Action(ResetSampleTimeSlot)
  resetSampleTimeSlots(ctx: StateContext<SamplesStatesModel>) {
    ctx.patchState({
      sampleDateSlots: [],
    });
  }

  @Action(UpdateTimeSlotsSearchMode)
  updateTimeSlotsSearchMode(ctx: StateContext<SamplesStatesModel>, {searchMode}: UpdateTimeSlotsSearchMode) {
    ctx.patchState({
      timeslotsSearchMode: searchMode,
      sampleDateSlots: [],
    });
  }

  @Action(CreateSample)
  createSample(ctx: StateContext<SamplesStatesModel>) {
    const sample: Sample | undefined = ctx.getState().sampleCreation;
    if (sample){
      return this.samplesService.create(sample).pipe(
        map((sample)=> ctx.dispatch(new CreateSampleSuccess())),
        catchError((error: string) => ctx.dispatch(new CreateSampleFailure(error)))
      );
    } else {
      return ctx.dispatch(new CreateSampleFailure('Sample undefined'));
    }
  }

  @Action(CreateSampleSuccess)
  createSampleSuccess(ctx: StateContext<SamplesStatesModel>) {
    ctx.dispatch(new ShowMessage({content: {title: 'sample_created'}, level: MessageLevel.SUCCESS}, 'samples'));
    ctx.dispatch(new CreateNewSample());
  }

  @Action(CreateSampleFailure)
  createSampleFailure(ctx: StateContext<SamplesStatesModel>, {error}: CreateSampleFailure) {
    ctx.dispatch(new ShowMessage({content: {title: 'sample_created_failure'}, level: MessageLevel.ERROR}, 'samples'));
  }

  @Action(GetSample)
  getSample(ctx: StateContext<SamplesStatesModel>, {id}: GetSample){
    return this.samplesService.get(id).pipe(
      map(sample => ctx.dispatch(new GetSampleSuccess(sample))),
      catchError((error: string) => ctx.dispatch(new GetSampleFailure(error)))
    );
  }

  @Action(GetSampleSuccess)
  getSampleSuccess(ctx: StateContext<SamplesStatesModel>, {sample}: GetSampleSuccess){
    if (sample){
      ctx.patchState({
        sampleInfo: sample
      })
    }
  }

  @Action(GetSamplesByDay)
  getSamplesByDay(ctx: StateContext<SamplesStatesModel>) {
    ctx.patchState({
      planningByDayLoaded: false,
    })
    const filter: SamplesFilter = ctx.getState().planningFilter;
    return this.samplesService.getSamplesByDay(filter).pipe(
      map(samples => ctx.dispatch(new GetSamplesByDaySuccess(samples))),
      catchError((error: string) => ctx.dispatch(new GetSamplesByDayFailure(error)))
    );
  }

  @Action(GetSamplesByDaySuccess)
  getSamplesByDaySuccess(ctx: StateContext<SamplesStatesModel>, {samples}: GetSamplesByDaySuccess) {
    ctx.patchState({
      planningByDayLoaded: false,
    });
    if (samples) {
      ctx.patchState({
        samplesByDay: samples,
        planningByDayLoaded: true,
      });
    }
  }

  @Action(ValidateSaveStatus)
  validateSaveStatus(ctx: StateContext<SamplesStatesModel>, {id, jeun, consignePatient, consignePreleveur}: ValidateSaveStatus) {
    return this.samplesService.changeSaveStatus(id, jeun, consignePatient, consignePreleveur).pipe(
      map(sample => ctx.dispatch(new ValidateSaveStatusSuccess(sample))),
      catchError((error: string) => ctx.dispatch(new ValidateSaveStatusFailure(error)))
    );
  }

  @Action(ValidateSaveStatusSuccess)
  validateSaveStatusSuccess(ctx: StateContext<SamplesStatesModel>, {sample}: ValidateSaveStatusSuccess) {
    const oldSample: SampleInfos | undefined = ctx.getState().samplesByDay?.find(s => s.id === sample.id);
    if(sample && oldSample) {
      const sampleUpdated: SampleInfos = {
        ...oldSample,
        saved: sample.saved,
        savedAt: sample.savedAt,
      };
      ctx.setState(
        patch({
          samplesByDay: updateItem<SampleInfos>(s => s?.id === sample?.id, sampleUpdated),
        })
      );
    }
  }

  @Action(UpdatePlanningFilters)
  updatePlanningFilters(ctx: StateContext<SamplesStatesModel>, {filters}: UpdatePlanningFilters) {
    ctx.patchState({
      planningFilter:{
        ...ctx.getState().planningFilter,
        ...filters,
      }
    });
    ctx.dispatch(new GetSamplesByDay());
  }

  @Action(GetSampleFixedScheduleOption)
  getSampleFixedScheduleOption(ctx: StateContext<SamplesStatesModel>, {laboId}: GetSampleFixedScheduleOption) {
    return this.laboService.getFixedSchedule(laboId).pipe(
      map(schedules => ctx.dispatch(new GetSampleFixedScheduleOptionSuccess(schedules))),
      catchError((error: string) => ctx.dispatch(new GetSampleFixedScheduleOptionFailure(error)))
    );
  }
  @Action(GetSampleFixedScheduleOptionSuccess)
  getSampleFixedScheduleOptionSuccess(ctx: StateContext<SamplesStatesModel>, {fixedSchedule}: GetSampleFixedScheduleOptionSuccess) {
    ctx.patchState({
      labo: {
        ...ctx.getState().labo,
        fixedSchedule: fixedSchedule,
      }
    })
  }
}

