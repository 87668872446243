import {ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {ActivatedRoute, Router} from '@angular/router';
import {Commune} from '@domain/geoapi/Commune';
import {AddTime, LaboForm, LaboFormSchedule} from '@domain/labo/Labo';
import {Select, Store} from '@ngxs/store';
import {UserInfo} from '@security/UserInfo';
import {GeoApiService} from '@service/geoapi.service';
import {ConfirmDeleteLabo, LoadOneLabo, LoadOneNewLabo, SaveOneLabo, SearchAddress} from '@states/labos/labos.actions';
import {LabosStateSelector} from '@states/labos/labos.selectors';
import {RegexPatternValidatorEnum} from '@utils/enum/regex.enum';
import {KeycloakService} from 'keycloak-angular';
import {BehaviorSubject, combineLatest, debounceTime, Observable, Subject, switchMap, takeUntil} from 'rxjs';
import {scheduleValidator} from '../schedule-validator.directive';

@Component({
  selector: 'app-labo-editor',
  templateUrl: './labo-editor.component.html',
  styleUrls: ['./labo-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LaboEditorComponent extends UserInfo implements OnInit, OnDestroy {
  private destroy: Subject<boolean> = new Subject<boolean>();
  @Select(LabosStateSelector.currentLabo) labo$: Observable<LaboForm>;

  week: string[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

  laboForm!: FormGroup;
  addTime: AddTime = {
    mondayAddTime: false,
    tuesdayAddTime: false,
    wednesdayAddTime: false,
    thursdayAddTime: false,
    fridayAddTime: false,
    saturdayAddTime: false,
    sundayAddTime: false,
  };
  cpInput$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  villeInput$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  sitePattern: RegexPatternValidatorEnum = RegexPatternValidatorEnum.SITE;
  autosuggestVille$!: Observable<any>;
  autosuggestCp$!: Observable<any>;

  @ViewChild('map') map: any;

  constructor(public override keycloakService: KeycloakService, private store: Store, private route: ActivatedRoute, private geoapi: GeoApiService, private router: Router) {
    super(keycloakService);
  }

  ngOnInit(): void {
    this.createForm();

    this.route.paramMap.subscribe(val => {
      const id: string | null = val.get('id');

      if (id) {
        this.store.dispatch(new LoadOneLabo(id));
      } else {
        this.store.dispatch(new LoadOneNewLabo());
      }

      this.labo$.pipe(takeUntil(this.destroy)).subscribe(labo => {
        if (labo) {
          this.laboForm.get('id')?.setValue(labo.id);
          this.laboForm.get('name')?.setValue(labo.name);
          this.laboForm.get('coord')?.setValue(labo.coord);
          this.laboForm.get('lowerLimitPerTimeSlot')?.setValue(labo.lowerLimitPerTimeSlot);
          this.laboForm.get('upperLimitPerTimeSlot')?.setValue(labo.upperLimitPerTimeSlot);
          this.laboForm.get('noHomeRxPrice')?.setValue(labo.noHomeRxPrice);
          this.laboForm.get('samplingManual')?.setValue(labo.samplingManual);
          this.fixedSchedule.clear();
          if (labo.fixedSchedule && labo.fixedSchedule.length > 0) {
            this.laboForm.get('autoriseFixedSchedule')?.setValue(true);
          } else {
            this.laboForm.get('autoriseFixedSchedule')?.setValue(false);
          }
          labo.fixedSchedule?.forEach(option => {
            this.fixedSchedule.push(
              new FormGroup({
                id: new FormControl(option.id),
                delay: new FormControl(option.delay, [Validators.required]),
                price: new FormControl(option.price, [Validators.required]),
              })
            );
          });
          this.formatTel();
          if (labo.schedule) {
            this.loadSchedule(labo.schedule);
          }
          if (!this.isAdmin && !this.isPersonnelLabo) {
            this.laboForm.disable();
          }
        }
      });
    });

    this.autosuggestVille$ = this.villeInput$.pipe(
      debounceTime(500),
      switchMap(text => this.geoapi.autocompleteCommunesBySearch(text))
    );

    this.autosuggestCp$ = this.cpInput$.pipe(
      debounceTime(500),
      switchMap(text => this.geoapi.autocompleteCommunesByCp(text))
    );
  }

  setButtonChange(day: string, enabled: boolean): void {
    this.addTime[(day + 'AddTime') as keyof AddTime] = enabled;
    if (!enabled) {
      this.laboForm.get('schedule')?.get(day)?.get('startTime2')?.setValue(null);
      this.laboForm.get('schedule')?.get(day)?.get('endTime2')?.setValue(null);
    }
  }

  getAddButtonStatus(day: string): boolean {
    return this.addTime[(day + 'AddTime') as keyof AddTime];
  }

  onSubmit(): void {
    const labo = this.laboForm.value;
    if (!this.laboForm.get('autoriseFixedSchedule')?.value) {
      labo.fixedSchedule = [];
    }
    labo.zones = [{typeZone: labo.typeZone}];
    this.store.dispatch(new SaveOneLabo(labo));
  }

  goBack(): void {
    this.router.navigate(['/labos']);
  }

  formatTel(): void {
    let tel: string | undefined = this.laboForm.get('coord')?.get('phone')?.value;
    tel = tel
      ?.replace(/[^0-9]/g, '') // keep only number
      .match(/.{1,2}/g) // match every 2 numbers
      ?.join(' '); // join with space

    this.laboForm
      .get('coord')
      ?.get('phone')
      ?.setValue(tel || null);
  }

  select(commune: Commune): void {
    if (commune?.codesPostaux?.length) {
      this.laboForm.get('coord')?.get('postalCode')?.setValue(commune?.codesPostaux[0]);
      this.laboForm.get('coord')?.get('postalCode')?.updateValueAndValidity();
    }
    if (commune.nom) {
      this.laboForm.get('coord')?.get('city')?.setValue(commune.nom);
      this.villeInput$.next(commune.nom);
    }
  }

  loadSchedule(schedules: LaboFormSchedule): void {
    this.laboForm.get('schedule')?.reset();

    type SchedulesKey = keyof typeof schedules;

    if (schedules) {
      const keys = Object.keys(schedules) as SchedulesKey[];

      keys.forEach(day => {
        if (schedules[day] && schedules[day]?.startTime) {
          this.laboForm.get('schedule')?.get(day)?.get('id')?.setValue(schedules[day]?.id);
          this.laboForm.get('schedule')?.get(day)?.get('checkbox')?.setValue(true);
          this.laboForm.get('schedule')?.get(day)?.get('startTime')?.setValue(schedules[day]?.startTime);
          this.laboForm.get('schedule')?.get(day)?.get('endTime')?.setValue(schedules[day]?.endTime);

          if (schedules[day]?.startTime2) {
            this.setButtonChange(day, true);
          }

          this.laboForm.get('schedule')?.get(day)?.get('id_2')?.setValue(schedules[day]?.id_2);
          this.laboForm.get('schedule')?.get(day)?.get('startTime2')?.setValue(schedules[day]?.startTime2);
          this.laboForm.get('schedule')?.get(day)?.get('endTime2')?.setValue(schedules[day]?.endTime2);
        }
      });
    }
  }

  deleteLabo(id: string): void {
    this.store.dispatch(new ConfirmDeleteLabo(id));
  }

  inputVilleSearch = (event: any): void => this.villeInput$.next(event.target.value);
  inputCpSearch = (event: any): void => this.cpInput$.next(event.target.value);

  resetSchedule(day: string): void {
    this.laboForm.get('schedule')?.get(day)?.get('id')?.setValue(null);
    this.laboForm.get('schedule')?.get(day)?.get('startTime')?.setValue(null);
    this.laboForm.get('schedule')?.get(day)?.get('endTime')?.setValue(null);
    this.laboForm.get('schedule')?.get(day)?.get('startTime2')?.setValue(null);
    this.laboForm.get('schedule')?.get(day)?.get('endTime2')?.setValue(null);
    this.addTime[(day + 'AddTime') as keyof AddTime] = false;
  }

  public createForm(): void {
    const schedulesForms: any = {};
    this.week.map(w => {
      schedulesForms[w] = new FormGroup(
        {
          checkbox: new FormControl('true'),
          id: new FormControl(''),
          id_2: new FormControl(''),
          startTime: new FormControl(''),
          endTime: new FormControl(''),
          startTime2: new FormControl(''),
          endTime2: new FormControl(''),
        },
        {validators: scheduleValidator}
      );
    });

    this.laboForm = new FormGroup({
      id: new FormControl(''),
      name: new FormControl(''),
      coord: new FormGroup({
        address1: new FormControl('', [Validators.required]),
        address2: new FormControl(''),
        postalCode: new FormControl('', [Validators.required]),
        city: new FormControl('', [Validators.required]),
        country: new FormControl('France', [Validators.required]),
        phone: new FormControl('', [Validators.required]),
      }),
      typeZone: new FormControl('5'),
      schedule: new FormGroup(schedulesForms),
      samplesPerTimeSlotForPreleveur: new FormControl(10, Validators.required),
      lowerLimitPerTimeSlot: new FormControl(10, Validators.required),
      upperLimitPerTimeSlot: new FormControl(20, Validators.required),
      noHomeRxPrice: new FormControl(10, Validators.required),
      samplingManual: new FormControl(''),
      autoriseFixedSchedule: new FormControl(false),
      fixedSchedule: new FormArray([]),
    });

    combineLatest({
      address1: this.laboForm.get('coord')!.get('address1')!.valueChanges,
      address2: this.laboForm.get('coord')!.get('address2')!.valueChanges,
      postalCode: this.laboForm.get('coord')!.get('postalCode')!.valueChanges,
      city: this.laboForm.get('coord')!.get('city')!.valueChanges,
      country: this.laboForm.get('coord')!.get('country')!.valueChanges,
    })
      .pipe(debounceTime(500), takeUntil(this.destroy))
      .subscribe(coord => {
        this.store.dispatch(new SearchAddress(coord));
      });

    if (!this.isAdmin) {
      this.laboForm.disable();
    }
  }

  public ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

  get fixedSchedule() {
    return this.laboForm.controls['fixedSchedule'] as FormArray;
  }

  addFixedScheduleOption(): void {
    this.fixedSchedule.push(
      new FormGroup({
        delay: new FormControl(null,[Validators.required]),
        price: new FormControl(null,[Validators.required]),
      })
    );
  }

  removeFixedScheduleOption(index: number): void {
    this.fixedSchedule.removeAt(index);
    if (this.fixedSchedule.length === 0) {
      this.laboForm.get('autoriseFixedSchedule')?.setValue(false);
    }
  }

  autoriseFixedScheduleChange(event: MatCheckboxChange) {
    if (event.checked && this.fixedSchedule.length === 0) {
      this.addFixedScheduleOption();
    }
    if (!event.checked){
      this.fixedSchedule.clear();
    }
  }
}
