import {Injectable} from '@angular/core';
import {Labo, LaboForm} from '@domain/labo/Labo';
import {Document, Sample} from '@domain/samples/sample';
import {Adress, AssociationModeEnum, LaboFormUser, ProfileEnum, ResultDiffusion, User, UserForm, UserZone} from '@domain/user/user';
import {UserFilter} from '@features/users/users-filter';
import {Navigate} from '@ngxs/router-plugin';
import {Action, State, StateContext} from '@ngxs/store';
import {insertItem, patch, removeItem, updateItem} from '@ngxs/store/operators';
import {GeoApiService} from '@service/geoapi.service';
import {LaboService} from '@service/labo.service';
import {SampleService} from '@service/sample.service';
import {ScheduleService} from '@service/schedule.service';
import {UserService} from '@service/user.service';
import {ShowMessage} from '@states/global/global.actions';
import {MessageLevel} from '@states/global/global.state';
import {
  AddAffectedZone,
  AddSelectedLabos,
  AssociatePreleveur,
  ChangePossibleLabo,
  ChangePossibleLaboFailure,
  ChangePossibleLaboSuccess,
  DeleteOneUser,
  DeleteOneUserFailure,
  DeleteOneUserSuccess,
  DissociatePreleveur,
  GetDomicileAddress,
  GetDomicileAddressFailure,
  GetFilteredPreleveurs,
  GetFilteredPreleveursFailure,
  GetFilteredPreleveursSuccess,
  GetUserDocuments,
  GetUserDocumentsFailure,
  GetUserDocumentsSuccess,
  GetUserResultDiffusion,
  GetUserResultDiffusionFailure,
  GetUserResultDiffusionSuccess,
  GetUserSamples,
  GetUserSamplesFailure,
  GetUserSamplesSuccess,
  LoadFilteredUsers,
  LoadFilteredUsersFailure,
  LoadFilteredUsersSuccess,
  LoadOneNewUser,
  LoadOneUser,
  LoadOneUserFailure,
  LoadOneUserSuccess,
  RemoveAffectedZone,
  RemoveSelectedLabos,
  SaveAffectedZone,
  SaveAffectedZoneFailure,
  SaveAffectedZoneSuccess,
  SaveOneUser,
  SaveOneUserFailure,
  SaveOneUserSuccess,
  SavePreleveurAssociation,
  SavePreleveurAssociationFailure,
  SavePreleveurAssociationSuccess,
  SetDomicileAddress,
  SuspendOneUser,
  SuspendOneUserFailure,
  SuspendOneUserSuccess,
  UpdatePreleveursFilter,
  UpdatePreleveursPaginationOption,
  UpdateUserFilter,
  UpdateUserSort,
  UpdateUsersPagination,
  UpdateZone,
} from '@states/user/users.actions';
import {Pagination} from '@utils/pagination/Pagination';
import {PaginationOption, SortDirectionEnum} from '@utils/pagination/PaginationOption';
import {KeycloakService} from 'keycloak-angular';
import {catchError, map, Observable} from 'rxjs';

export interface UsersStatesModel {
  currentUser?: UserForm;
  currentPreleveur?: User;
  filteredUser?: User[];
  userFilter: UserFilter;
  possibleLabos?: Labo[];
  chosenLabos?: Labo[];
  paginationOptions: PaginationOption;
  filteredUsersLoaded: boolean;
  allAddresses: Adress[];
  labos?: LaboForm[];
  affectedZones?: UserZone[];
  userSamples: Pagination<Sample>;
  domicileUserSamples?: Adress;
  userDocuments?: Document[];
  userResultDiffusion?: ResultDiffusion;
  preleveursList: Pagination<User>;
  preleveurPaginationOption: PaginationOption;
  preleveurFilter: string;
  currentAssociatedPreleveur: User | null;
}

@State<UsersStatesModel>({
  name: 'users',
  defaults: {
    filteredUser: [],
    userFilter: {
      profile: [],
      name: '',
    },
    chosenLabos: [],
    filteredUsersLoaded: true,
    allAddresses: [],
    paginationOptions: {
      length: 100,
      pageSize: 25,
      pageIndex: 0,
      pageSizeOption: [10, 25, 50, 100],
    },
    affectedZones: [],
    userSamples: {
      content: [],
      totalElements: 0,
    },
    userDocuments: [],
    preleveursList: {
      content: [],
      totalElements: 0,
    },
    preleveurPaginationOption: {
      length: 100,
      pageSize: 10,
      pageIndex: 0,
      pageSizeOption: [10, 25, 50],
    },
    preleveurFilter: '',
    currentAssociatedPreleveur: null,
  },
})
@Injectable()
export class UsersState {
  constructor(private userService: UserService, private laboService: LaboService, private _keycloak: KeycloakService, private _geoApi: GeoApiService, private scheduleService: ScheduleService, private sampleService: SampleService) {}

  @Action(LoadOneUser)
  loadOneUser(ctx: StateContext<UsersStatesModel>, {id}: LoadOneUser): Observable<void | Observable<void>> {
    return this.userService.get(id).pipe(
      map(user => ctx.dispatch(new LoadOneUserSuccess(user))),
      catchError((error: any) => ctx.dispatch(new LoadOneUserFailure(error)))
    );
  }

  @Action(LoadOneUserSuccess)
  loadOneUserSuccess(ctx: StateContext<UsersStatesModel>, {user}: LoadOneUserSuccess): void {
    if (user) {
      ctx.patchState({
        currentUser: {
          id: user.id,
          suspended: user.suspended,
          identity: {
            firstname: user.firstname,
            lastname: user.lastname,
            birthName: user.birthName,
            civility: user.civility,
            gender: user.gender,
            birthDate: user.birthDate,
            raisonSociale: user.raisonSociale,
            numeroSecu: user.numeroSecu,
          },
          adresses: user.adresses?.filter(address => address.domicile === true),
          informations: {
            email: user.email,
            phone: user.phone,
            profile: user.profile,
            laboratory: user.laboratory,
            ipp: user.ipp,
          },
          disponibilities: user.disponibilities,
          exceptions: user.exceptions,
          firstLogin: user.firstLogin,
          notificationsEnabled: user.notificationsEnabled,
          laboFavorite: user.laboFavorite,
          resultDiffusion: {
            id: user.resultDiffusion?.id,
            resultByInternetServer: user.resultDiffusion?.resultByInternetServer,
            resultByMail: user.resultDiffusion?.resultByMail,
            resultByPostal: user.resultDiffusion?.resultByPostal,
            resultDiffusionNurse: user.resultDiffusion?.resultDiffusionNurse,
          },
          associationMode: user.associationMode,
          associatedPreleveur: user.associatedPreleveur,
        },
        chosenLabos: user.laboratory,
        allAddresses: user.adresses,
        affectedZones: user.zones?.sort((a, b) => a.laboName?.localeCompare(b.laboName || '') || 1),
        currentAssociatedPreleveur: user.associatedPreleveur,
      });
    }
  }

  @Action(LoadOneUserFailure)
  loadOneUserFailure(ctx: StateContext<UsersStatesModel>, {error}: LoadOneUserFailure): void {
    ctx.dispatch(new ShowMessage({content: {title: 'load_user_failure'}, level: MessageLevel.ERROR}, 'users'));
    ctx.dispatch(new Navigate(['/users']));
  }

  @Action(LoadOneNewUser)
  loadOneNewUser(ctx: StateContext<UsersStatesModel>): void {
    ctx.patchState({
      currentUser: {
        id: undefined,
        identity: {
          firstname: '',
          lastname: '',
          birthName: '',
          civility: '',
          gender: '',
          birthDate: undefined,
          raisonSociale: '',
          numeroSecu: '',
        },
        adresses: undefined,
        informations: {
          email: '',
          phone: '',
          profile: ProfileEnum.PRELEVEUR,
          laboratory: [],
          ipp: '',
        },
        resultDiffusion: {
          id: undefined,
          resultByInternetServer: true,
          resultByMail: true,
          resultByPostal: true,
          resultDiffusionNurse: true,
        },
        disponibilities: [],
        exceptions: [],
        associatedPreleveur: undefined,
        associationMode: AssociationModeEnum.STRICT,
      },
      chosenLabos: [],
      allAddresses: [],
      affectedZones: [],
      currentAssociatedPreleveur: undefined,
    });
  }

  @Action(SaveOneUser)
  saveOneUser(ctx: StateContext<UsersStatesModel>, {user}: SaveOneUser): Observable<void | Observable<void>> {
    if (user?.adresses?.length && ctx.getState().allAddresses.length > 0) {
      ctx.setState(
        patch<UsersStatesModel>({
          allAddresses: updateItem<Adress>(address => address?.domicile === true, user.adresses[0]),
        })
      );
    } else if (user?.adresses?.length) {
      ctx.setState(
        patch<UsersStatesModel>({
          allAddresses: insertItem<Adress>(user.adresses[0]),
        })
      );
    } else if (ctx.getState().allAddresses.length > 0) {
      ctx.setState(
        patch<UsersStatesModel>({
          allAddresses: removeItem<Adress>(address => address?.domicile === true),
        })
      );
    }

    const userDto: User = {
      id: user.id,
      ...user.identity,
      ...user.informations,
      resultDiffusion: user.resultDiffusion,
      profile: ProfileEnum[user?.informations?.profile as keyof typeof ProfileEnum],
      adresses: ctx.getState().allAddresses,
      disponibilities: ctx.getState().currentUser?.disponibilities,
      exceptions: ctx.getState().currentUser?.exceptions,
      firstLogin: ctx.getState().currentUser?.firstLogin,
      notificationsEnabled: ctx.getState().currentUser?.notificationsEnabled,
      laboFavorite: ctx.getState().currentUser?.laboFavorite,
      associatedPreleveur: ctx.getState().currentAssociatedPreleveur,
      associationMode: user.associationMode,
    };
    userDto.laboratory = ctx.getState().chosenLabos;
    userDto.zones = ctx.getState().affectedZones;
    if (user.id) {
      return this.userService.update(user.id, userDto).pipe(
        map(retour => ctx.dispatch(new SaveOneUserSuccess())),
        catchError((error: any) => ctx.dispatch(new SaveOneUserFailure(error)))
      );
    } else {
      return this.userService.create(userDto).pipe(
        map(retour => ctx.dispatch(new SaveOneUserSuccess())),
        catchError((error: any) => ctx.dispatch(new SaveOneUserFailure(error)))
      );
    }
  }

  @Action(SaveOneUserSuccess)
  saveOneUserSuccess(ctx: StateContext<UsersStatesModel>): void {
    ctx.dispatch(new ShowMessage({content: {title: 'user_saved_success'}, level: MessageLevel.SUCCESS}, 'users'));
    if (!this._keycloak.isUserInRole(ProfileEnum.TOURNEE_MANAGER)) {
      ctx.dispatch(new LoadOneNewUser());
      ctx.dispatch(new Navigate(['/users']));
    }
  }

  @Action(SaveOneUserFailure)
  saveOneUserFailure(ctx: StateContext<UsersStatesModel>, {error}: SaveOneUserFailure): void {
    ctx.dispatch(new ShowMessage({content: {title: 'save_user_failure'}, level: MessageLevel.ERROR}, 'users'));
  }

  @Action(DeleteOneUser)
  deleteOneUser(ctx: StateContext<UsersStatesModel>, {id}: DeleteOneUser): Observable<void | Observable<void>> {
    return this.userService.delete(id).pipe(
      map(() => ctx.dispatch(new DeleteOneUserSuccess())),
      catchError((error: string) => ctx.dispatch(new DeleteOneUserFailure(error)))
    );
  }

  @Action(DeleteOneUserSuccess)
  deleteOneUserSuccess(ctx: StateContext<UsersStatesModel>): void {
    ctx.dispatch(new ShowMessage({content: {title: 'user_deleted_success'}, level: MessageLevel.SUCCESS}, 'users'));
    ctx.dispatch(new LoadOneNewUser());
    ctx.dispatch(new Navigate(['/users']));
  }

  @Action(DeleteOneUserFailure)
  deleteOneUserFailure(ctx: StateContext<UsersStatesModel>, {error}: DeleteOneUserFailure): void {
    ctx.dispatch(new ShowMessage({content: {title: 'delete_user_failure'}, level: MessageLevel.ERROR}, 'users'));
  }

  @Action(LoadFilteredUsers)
  loadFilteredUsers(ctx: StateContext<UsersStatesModel>): Observable<void | Observable<void>> {
    ctx.patchState({
      filteredUsersLoaded: false,
    });
    const state: UsersStatesModel = ctx.getState();
    return this.userService.findByFiltersPaging(state.userFilter, state.paginationOptions).pipe(
      map(users => ctx.dispatch(new LoadFilteredUsersSuccess(users))),
      catchError((error: any) => ctx.dispatch(new LoadFilteredUsersFailure(error)))
    );
  }

  @Action(LoadFilteredUsersSuccess)
  loadFilteredUsersSuccess(ctx: StateContext<UsersStatesModel>, {users}: LoadFilteredUsersSuccess): void {
    ctx.patchState({
      filteredUsersLoaded: false,
    });
    const state: UsersStatesModel = ctx.getState();
    if (users) {
      ctx.setState(
        patch({
          filteredUser: users.content,
          paginationOptions: patch({
            ...state.paginationOptions,
            length: users.totalElements,
          }),
          filteredUsersLoaded: true,
        })
      );
    }
  }

  @Action(LoadFilteredUsersFailure)
  loadFilteredUsersFailure(ctx: StateContext<UsersStatesModel>, {error}: LoadFilteredUsersFailure): void {
    ctx.setState(
      patch({
        filteredUsersLoaded: true,
      })
    );

    ctx.dispatch(new ShowMessage({content: {title: 'load_users_failure'}, level: MessageLevel.ERROR}, 'users'));
  }

  @Action(ChangePossibleLabo)
  changePossibleLabos(ctx: StateContext<UsersStatesModel>, {filter}: ChangePossibleLabo): Observable<void | Observable<void>> {
    const paginationOption: PaginationOption = {
      length: 10,
      pageSize: 10,
      pageIndex: 0,
    };
    return this.laboService.findByFiltersPaging(filter, paginationOption).pipe(
      map(labos => ctx.dispatch(new ChangePossibleLaboSuccess(labos))),
      catchError((error: any) => ctx.dispatch(new ChangePossibleLaboFailure(error)))
    );
  }

  @Action(ChangePossibleLaboSuccess)
  changePossibleLabosSuccess(ctx: StateContext<UsersStatesModel>, {labos}: ChangePossibleLaboSuccess): void {
    if (labos?.content) {
      ctx.setState(
        patch({
          possibleLabos: labos.content,
        })
      );
    }
  }

  @Action(SuspendOneUser)
  suspendOneUser(ctx: StateContext<UsersStatesModel>, {id}: SuspendOneUser): Observable<void | Observable<void>> {
    return this.userService.suspendUser(id).pipe(
      map(() => ctx.dispatch(new SuspendOneUserSuccess(id))),
      catchError((error: any) => ctx.dispatch(new SuspendOneUserFailure(error)))
    );
  }

  @Action(SuspendOneUserSuccess)
  suspendOneUserSuccess(ctx: StateContext<UsersStatesModel>, {id}: SuspendOneUserSuccess): void {
    ctx.dispatch(new ShowMessage({content: {title: 'user_suspended_success'}, level: MessageLevel.SUCCESS}, 'users'));
    ctx.dispatch(new LoadOneUser(id));
  }

  @Action(SuspendOneUserFailure)
  suspendOneUserFailure(ctx: StateContext<UsersStatesModel>, {error}: LoadFilteredUsersFailure): void {
    ctx.dispatch(new ShowMessage({content: {title: 'suspend_user_failure'}, level: MessageLevel.ERROR}, 'users'));
  }

  @Action(AddSelectedLabos)
  addSelectedLabos(ctx: StateContext<UsersStatesModel>, {labo}: AddSelectedLabos): void {
    const state: UsersStatesModel = ctx.getState();
    const listLabos: Labo[] = state.chosenLabos?.map(x => x) || [];
    listLabos.push(labo);
    ctx.patchState({
      chosenLabos: listLabos,
    });
  }

  @Action(RemoveSelectedLabos)
  removeSelectedLabos(ctx: StateContext<UsersStatesModel>, {id}: RemoveSelectedLabos): void {
    const state: UsersStatesModel = ctx.getState();
    let listLabos: LaboFormUser[] = state.chosenLabos?.map(x => x) || [];
    listLabos = listLabos.filter(laboratory => laboratory.id !== id);
    ctx.patchState({
      chosenLabos: listLabos,
      affectedZones: state.affectedZones?.filter(zone => zone.laboId !== id),
    });
  }

  @Action(UpdateUsersPagination)
  updatePagination(ctx: StateContext<UsersStatesModel>, {pagination}: UpdateUsersPagination): Observable<void> {
    const state: UsersStatesModel = ctx.getState();
    ctx.patchState({
      paginationOptions: {
        ...state.paginationOptions,
        ...pagination,
      },
    });
    return ctx.dispatch(new LoadFilteredUsers());
  }

  @Action(UpdateUserFilter)
  updateUserFilter(ctx: StateContext<UsersStatesModel>, {filter, pagination}: UpdateUserFilter): Observable<void> {
    const state: UsersStatesModel = ctx.getState();
    ctx.patchState({
      userFilter: {
        ...state.userFilter,
        ...filter,
      },
      paginationOptions: {
        ...state.paginationOptions,
        pageIndex: 0,
        ...pagination,
      },
    });
    return ctx.dispatch(new LoadFilteredUsers());
  }

  @Action(UpdateUserSort)
  updateSort(ctx: StateContext<UsersStatesModel>, {sortByColumnName}: UpdateUserSort): void {
    const state: UsersStatesModel = ctx.getState();
    let sortDirection: SortDirectionEnum;
    if (state.paginationOptions.sortByColumnName === sortByColumnName && state.paginationOptions.sortDirection === SortDirectionEnum.ASC) {
      sortDirection = SortDirectionEnum.DESC;
    } else if (state.paginationOptions.sortByColumnName === sortByColumnName && state.paginationOptions.sortDirection === SortDirectionEnum.DESC) {
      sortDirection = SortDirectionEnum.ASC;
    } else {
      sortDirection = SortDirectionEnum.ASC;
    }
    ctx.patchState({
      paginationOptions: {
        ...state.paginationOptions,
        sortByColumnName: sortByColumnName,
        sortDirection: sortDirection,
      },
    });
  }

  @Action(AddAffectedZone)
  addAffectedZone(ctx: StateContext<UsersStatesModel>, {zone}: AddAffectedZone): void {
    const state: UsersStatesModel = ctx.getState();
    const listZones: UserZone[] = state.affectedZones?.map(x => x) || [];
    listZones.push(zone);
    listZones.sort((a, b) => a.laboName?.localeCompare(b.laboName || '') || 1);
    ctx.patchState({
      affectedZones: listZones,
    });
  }

  @Action(RemoveAffectedZone)
  removeAffectedZone(ctx: StateContext<UsersStatesModel>, {id}: RemoveAffectedZone): void {
    const state: UsersStatesModel = ctx.getState();
    const listZones: UserZone[] = state.affectedZones?.map(x => x) || [];
    ctx.patchState({
      affectedZones: listZones.filter(zone => zone.zoneId !== id),
    });
  }

  @Action(SaveAffectedZone)
  saveAffectedZone(ctx: StateContext<UsersStatesModel>): Observable<void> | Observable<void | Observable<void>> {
    const id: string | undefined = ctx.getState().currentUser?.id;
    if (id) {
      const user: User = {
        id: id,
        zones: ctx.getState().affectedZones,
        laboratory: ctx.getState().chosenLabos,
      };
      return this.userService.patch(id, user).pipe(
        map(() => ctx.dispatch(new SaveAffectedZoneSuccess())),
        catchError((error: string) => ctx.dispatch(new SaveAffectedZoneFailure(error)))
      );
    }
    return ctx.dispatch(new SaveAffectedZoneFailure("Error: can't get user id"));
  }

  @Action(SaveAffectedZoneSuccess)
  saveAffectedZoneSuccess(ctx: StateContext<UsersStatesModel>): void {
    ctx.dispatch(new ShowMessage({content: {title: 'user_zone_saved_success'}, level: MessageLevel.SUCCESS}, 'users'));
  }

  @Action(SaveAffectedZoneFailure)
  saveAffectedZoneFailure(ctx: StateContext<UsersStatesModel>): void {
    ctx.dispatch(new ShowMessage({content: {title: 'save_zone_user_failure'}, level: MessageLevel.ERROR}, 'users'));
  }

  @Action(UpdateZone)
  updateZoneColor(ctx: StateContext<UsersStatesModel>, {id, zone}: UpdateZone): void {
    ctx.setState(
      patch<UsersStatesModel>({
        affectedZones: updateItem<UserZone>(zone => zone?.id === id, zone),
      })
    );
  }

  @Action(GetDomicileAddress)
  getDomicileAddress(ctx: StateContext<UsersStatesModel>, {id}: GetDomicileAddress): Observable<void | Observable<void>> {
    return this.userService.getDomicileAddress(id).pipe(
      map(address => ctx.dispatch(new SetDomicileAddress(address))),
      catchError((error: string) => ctx.dispatch(new GetDomicileAddressFailure(error)))
    );
  }

  @Action(SetDomicileAddress)
  setDomicileAddress(ctx: StateContext<UsersStatesModel>, {address}: SetDomicileAddress): void {
    ctx.patchState({
      domicileUserSamples: address,
    });
  }

  @Action(GetUserSamples)
  getUserSamples(ctx: StateContext<UsersStatesModel>, {userId, paginationOption}: GetUserSamples): Observable<void | Observable<void>> {
    return this.sampleService.findByPatientId(userId, paginationOption).pipe(
      map(samples => ctx.dispatch(new GetUserSamplesSuccess(samples))),
      catchError((error: string) => ctx.dispatch(new GetUserSamplesFailure(error)))
    );
  }

  @Action(GetUserSamplesSuccess)
  getUserSamplesSuccess(ctx: StateContext<UsersStatesModel>, {samples}: GetUserSamplesSuccess): void {
    ctx.patchState({
      userSamples: samples,
    });
  }

  @Action(GetUserSamplesFailure)
  getUserSamplesFailure(ctx: StateContext<UsersStatesModel>, {error}: GetUserSamplesFailure): void {}

  @Action(GetUserDocuments)
  getUserDocuments(ctx: StateContext<UsersStatesModel>, {id}: GetUserDocuments): Observable<void | Observable<void>> {
    return this.userService.getUserDocuments(id).pipe(
      map(documents => ctx.dispatch(new GetUserDocumentsSuccess(documents))),
      catchError((error: string) => ctx.dispatch(new GetUserDocumentsFailure(error)))
    );
  }

  @Action(GetUserDocumentsSuccess)
  getUserDocumentsSuccess(ctx: StateContext<UsersStatesModel>, {documents}: GetUserDocumentsSuccess): void {
    ctx.patchState({
      userDocuments: documents,
    });
  }

  @Action(GetUserResultDiffusion)
  getUserResultDiffusion(ctx: StateContext<UsersStatesModel>, {id}: GetUserResultDiffusion): Observable<void | Observable<void>> {
    return this.userService.getResultDiffusion(id).pipe(
      map(resultDiffusion => ctx.dispatch(new GetUserResultDiffusionSuccess(resultDiffusion))),
      catchError((error: string) => ctx.dispatch(new GetUserResultDiffusionFailure(error)))
    );
  }

  @Action(GetUserResultDiffusionSuccess)
  getUserResultDiffusionSuccess(ctx: StateContext<UsersStatesModel>, {resultDiffusion}: GetUserResultDiffusionSuccess): void {
    ctx.patchState({
      userResultDiffusion: resultDiffusion,
    });
  }

  @Action(GetFilteredPreleveurs)
  getFilteredPreleveurs(ctx: StateContext<UsersStatesModel>): Observable<void | Observable<void>> {
    const state: UsersStatesModel = ctx.getState();
    return this.userService.findPreleveurs(state.preleveurFilter, state.preleveurPaginationOption).pipe(
      map(preleveurs => ctx.dispatch(new GetFilteredPreleveursSuccess(preleveurs))),
      catchError(error => ctx.dispatch(new GetFilteredPreleveursFailure(error)))
    );
  }

  @Action(GetFilteredPreleveursSuccess)
  getFilteredPreleveursSuccess(ctx: StateContext<UsersStatesModel>, {preleveurs}: GetFilteredPreleveursSuccess): void {
    if (preleveurs) {
      ctx.patchState({
        preleveursList: preleveurs,
        preleveurPaginationOption: {
          ...ctx.getState().preleveurPaginationOption,
          length: preleveurs.totalElements,
        },
      });
    }
  }
  @Action(GetFilteredPreleveursFailure)
  getFilteredPreleveursFailure(ctx: StateContext<UsersStatesModel>, {error}: GetFilteredPreleveursFailure): void {
    ctx.dispatch(new ShowMessage({content: {title: 'load_preleveurs_failure'}, level: MessageLevel.ERROR}, 'users'));
  }

  @Action(UpdatePreleveursFilter)
  updatePreleveursFilter(ctx: StateContext<UsersStatesModel>, {filter}: UpdatePreleveursFilter): void {
    ctx.patchState({
      preleveurFilter: filter,
    });
    ctx.dispatch(new GetFilteredPreleveurs());
  }

  @Action(UpdatePreleveursPaginationOption)
  updatePreleveursPaginationOption(ctx: StateContext<UsersStatesModel>, {pagination}: UpdatePreleveursPaginationOption): void {
    const state: UsersStatesModel = ctx.getState();
    ctx.patchState({
      preleveurPaginationOption: {
        ...state.preleveurPaginationOption,
        ...pagination,
      },
    });
    ctx.dispatch(new GetFilteredPreleveurs());
  }

  @Action(AssociatePreleveur)
  associatePreleveur(ctx: StateContext<UsersStatesModel>, {preleveur}: AssociatePreleveur): void {
    ctx.patchState({
      currentAssociatedPreleveur: preleveur,
    });
  }

  @Action(DissociatePreleveur)
  dissociatePreleveur(ctx: StateContext<UsersStatesModel>): void {
    ctx.patchState({
      currentAssociatedPreleveur: undefined,
    });
  }

  @Action(SavePreleveurAssociation)
  savePreleveurAssociation(ctx: StateContext<UsersStatesModel>): Observable<void | Observable<void>> {
    const state: UsersStatesModel = ctx.getState();
    const user: User = {
      id: state.currentUser?.id,
      associatedPreleveur: state.currentAssociatedPreleveur ? state.currentAssociatedPreleveur : null,
    };
    if (user.id && user.associatedPreleveur) {
      return this.userService.patch(user.id, user).pipe(
        map(user => ctx.dispatch(new SavePreleveurAssociationSuccess(user))),
        catchError(error => ctx.dispatch(new SavePreleveurAssociationFailure(error)))
      );
    } else if (user.id) {
      return this.userService.dissociatePreleveur(user.id).pipe(
        map(user => ctx.dispatch(new SavePreleveurAssociationSuccess(user))),
        catchError(error => ctx.dispatch(new SavePreleveurAssociationFailure(error)))
      );
    }
    return ctx.dispatch(new SavePreleveurAssociationFailure(''));
  }

  @Action(SavePreleveurAssociationSuccess)
  savePreleveurAssociationSuccess(ctx: StateContext<UsersStatesModel>, {updatedUser}: SavePreleveurAssociationSuccess): void {
    if (updatedUser && updatedUser.id) {
      ctx.dispatch(new LoadOneUser(updatedUser?.id));
    }
    ctx.dispatch(new ShowMessage({content: {title: 'association_saved_success'}, level: MessageLevel.SUCCESS}, 'users'));
  }

  @Action(SavePreleveurAssociationFailure)
  savePreleveurAssociationFailure(ctx: StateContext<UsersStatesModel>, {error}: SavePreleveurAssociationFailure): void {
    ctx.patchState({
      currentAssociatedPreleveur: null,
    });
    ctx.dispatch(new ShowMessage({content: {title: 'save_association_failure'}, level: MessageLevel.ERROR}, 'users'));
  }
}
