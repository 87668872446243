import {DragDropModule} from '@angular/cdk/drag-drop';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSelectModule} from '@angular/material/select';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {LaboEditorComponent} from '@features/labo/labo-editor/labo-editor.component';
import {TranslocoModule} from '@ngneat/transloco';
import {NgxsModule} from '@ngxs/store';
import {LabosState} from '@states/labos/labos.state';
import {SamplesState} from '@states/samples/samples.state';
import {FilterPipe} from '@utils/pipe/filter.pipe';
import {SharedModule} from '@utils/shared.module';
import {LaboCheckEditorComponent} from './labo-check-editor/labo-check-editor.component';
import {LaboListCheckComponent} from './labo-list-check/labo-list-check.component';
import {LaboPlanningJourneeComponent} from './labo-planning-journee/labo-planning-journee.component';
import {LaboPlanningPreleveurComponent} from './labo-planning-preleveur/labo-planning-preleveur.component';
import {LaboPlanningComponent} from './labo-planning/labo-planning.component';
import {LaboRoutingModule} from './labo-routing.module';
import {LaboZonesPreleveursComponent} from './labo-zones-preleveurs/labo-zones-preleveurs.component';
import {MapComponent} from './map/map.component';
import {SampleMapComponent} from './sample-map/sample-map.component';
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
  declarations: [
    LaboPlanningComponent,
    LaboEditorComponent,
    MapComponent,
    LaboPlanningJourneeComponent,
    LaboPlanningPreleveurComponent,
    SampleMapComponent,
    LaboListCheckComponent,
    LaboCheckEditorComponent,
    LaboZonesPreleveursComponent
  ],
    imports: [
        CommonModule,
        LaboRoutingModule,
        NgxsModule.forFeature([LabosState, SamplesState]),
        ReactiveFormsModule,
        TranslocoModule,
        MatInputModule,
        MatDatepickerModule,
        MatButtonModule,
        MatIconModule,
        MatSelectModule,
        MatCheckboxModule,
        MatButtonToggleModule,
        MatAutocompleteModule,
        SharedModule,
        DragDropModule,
        MatTableModule,
        MatPaginatorModule,
        MatDialogModule,
        MatSortModule,
        MatTooltipModule,
    ],
  providers: [],
})
export class LaboModule {}
