import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {Check} from '@domain/samples/sample';
import {Select, Store} from '@ngxs/store';
import {GetChecks} from '@states/samples/samples.actions';
import {SamplesStateSelector} from '@states/samples/samples.selectors';
import {find, remove} from 'lodash';
import {BehaviorSubject, debounceTime, firstValueFrom, Observable, of, switchMap, take} from 'rxjs';

@Component({
  selector: 'app-sample-checks',
  templateUrl: './sample-checks.component.html',
  styleUrls: ['./sample-checks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SampleChecksComponent implements OnInit {
  @Input() selected: Check[] = [];
  @Input() laboId: any;
  filtered$: Observable<Check[]> = new Observable<Check[]>();

  filter: FormControl = new FormControl('');
  input$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  checks: Check[];
  @Output() selectionChange: EventEmitter<Check[]> = new EventEmitter<Check[]>();
  @Select(SamplesStateSelector.checks) checks$: Observable<Check[]>;
  constructor(private store: Store, private _cd: ChangeDetectorRef) {}

 ngOnInit(): void {
    this.loadChecks();
  }

  async loadChecks(): Promise<void> {
    await firstValueFrom(this.store.dispatch(new GetChecks(this.laboId)));
    this.checks = await firstValueFrom(this.checks$);

    this.filtered$ = this.input$.pipe(
      debounceTime(50),
      switchMap( (text: string): Observable<Check[]> => {
        if (text) {
          return of(
            this.checks.filter(check => {
              text = text.toLowerCase();
              const libelle: string = check.libelle?.toLowerCase() || '';
              let description: string = '';
              if (check.description) {
                description = check.description.toLowerCase();
              }
              return libelle.includes(text) || description.includes(text);
            })
          );
        } else {
          return of(this.checks);
        }
      })
    );
    this._cd.detectChanges();
  }

 onCheckChange(event: MatCheckboxChange): void {
    if (event.checked) {
      const check: Check | undefined = find(this.checks, ['id', event.source.value]);
      if (check) {
        this.selected = [...this.selected, check];
      }
    } else {
      const selected = [...this.selected];
      remove(selected, ['id', event.source.value]);
      this.selected = selected;
    }

    this.selectionChange.emit(this.selected);
  }

  isChecked(check: Check): boolean {
    return this.selected?.some((c: Check): boolean => c.id === check.id);
  }

  resetField(): void {
    this.filter.setValue('');
    this.input$.next('');
  }
}
