import { Labo, LaboForm, LaboRdvStatusCount, PreleveursOnZone, ZonePreleveur } from '@domain/labo/Labo';
import { Check } from '@domain/samples/sample';
import {User} from '@domain/user/user';
import {Selector} from '@ngxs/store';
import {Pagination} from '@utils/pagination/Pagination';
import {PaginationOption} from '@utils/pagination/PaginationOption';
import { FeatureCollection } from 'geojson';
import { LatLngExpression } from 'leaflet';
import { LabosState, LabosStateModel } from './labos.state';

export class LabosStateSelector {

  @Selector([LabosState])
  static labos(state: LabosStateModel): Labo[] {
    return state.labos;
  }

  @Selector([LabosState])
  static labosLoaded(state: LabosStateModel): boolean {
    return state.labosLoaded;
  }

  @Selector([LabosState])
  static currentLabo(state: LabosStateModel): LaboForm | undefined {
    return state.currentLabo;
  }

  @Selector([LabosState])
  static laboLoaded(state: LabosStateModel): boolean {
    return state.laboLoaded;
  }

  @Selector([LabosState])
  static currentLaboMapMarker(state: LabosStateModel): LatLngExpression | undefined {
    return state.currentLaboMap?.marker;
  }

  @Selector([LabosState])
  static currentLaboMapCoverType(state: LabosStateModel): string | undefined {
    return state.currentLaboMap?.coverType;
  }

  @Selector([LabosState])
  static currentLaboMapCoverPolygons(state: LabosStateModel): FeatureCollection | undefined {
    return state.currentLaboMap?.coverPolygons;
  }

  @Selector([LabosState])
  static labosRdvStatusCounts(state: LabosStateModel): LaboRdvStatusCount[] | undefined {
    return state.labosRdvStatusCounts;
  }

  @Selector([LabosState])
  static labosRdvStatusCountsLoaded(state: LabosStateModel): boolean {
    return state.labosRdvStatusCountsLoaded;
  }

  @Selector([LabosState])
  static preleveurs(state: LabosStateModel): User[] | undefined {
    return state.preleveurs
  }

  @Selector([LabosState])
  static preleveursLoaded(state: LabosStateModel): boolean {
    return state.preleveursLoaded
  }

  @Selector([LabosState])
  static checks(state: LabosStateModel): Pagination<Check> | undefined {
    return state.checks
  }

  @Selector([LabosState])
  static checksLoaded(state: LabosStateModel): boolean {
    return state.checksLoaded
  }

  @Selector([LabosState])
  static checksPagination(state: LabosStateModel): PaginationOption | undefined {
    return state.checkPagination
  }

  @Selector([LabosState])
  static checkFilterSearch(state: LabosStateModel): string | undefined {
    return state.checkFilter.search
  }

  @Selector([LabosState])
  static preleveursOnZones(state: LabosStateModel): PreleveursOnZone[] {
    return state.preleveursOnZones
  }
}
