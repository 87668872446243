<div class="planning h-100" *ngIf="currentPreleveur$ | async as preleveur">
  <div class="header d-flex justify-content-between w-100">
    <div class="d-flex w-100 justify-content-end">
      <div class="me-auto d-flex">
        <div>
          <mat-icon type="button" [routerLink]="['/labos', labo.id, 'planning']" [queryParams]="{date: date.toFormat('yyyy-MM-dd')}" class="p-3 back-arrow">arrow_backward</mat-icon>
        </div>
        <div class="position-relative d-flex align-items-center ms-3">
          <img class="d-block avatar" src="https://i.pinimg.com/280x280_RS/35/a1/e6/35a1e6d69cf6924b6076d864a5d39e95.jpg" />
          <img class="kantys-prel" src="assets/img/kantys-bio-small.png" />
        </div>
        <div class="p-3">{{ preleveur.firstname }} {{ preleveur.lastname }}</div>
      </div>

      <div class="py-2 mx-4" *ngIf="modified$ | async as modified">
        <button *ngIf="modified && !isPast" mat-flat-button class="height-75 col-auto ms-3 me-5 justify-content-end" (click)="save()">
          {{ 'samples.status.toSave' | transloco }}
        </button>
      </div>

      <div class="d-flex justify-content-between align-self-center h-75" *ngIf="samples$ | async as samples">
        <div class="px-3 ms-4 border-number">
          <span class="fs-4">{{ samples.length }} </span> <span class="fw-normal pe-2">{{ 'samples.sampleShort' | transloco }} </span>
        </div>
      </div>
    </div>
  </div>

  <div class="row page">
    <ng-container *ngIf="samples$ | async as samples">
      <div class="container col-8" cdkDropListGroup>
        <div class="day">
          {{ date.toJSDate() | date: 'EEEE dd LLLL' | titlecase }}
        </div>
        <div class="fst-italic">
          {{ 'samples.dragDropPreleveur' | transloco }}
        </div>
        <div class="mx-auto d-flex justify-content-between rdv-header">
          <div>{{ 'samples.table.patient' | transloco }}</div>
          <div>{{ 'samples.table.bilan' | transloco }}</div>
          <div>{{ 'samples.table.desiredTime' | transloco }}</div>
        </div>
        <ng-container *ngIf="loaded$ | async as load; else loading">
          <ng-container *ngIf="schedule$ | async as creneaux">
            <div *ngFor="let creneau of creneaux; let first = first" cdkDropList [cdkDropListDisabled]="isPast" [cdkDropListData]="creneau.samples" (cdkDropListDropped)="drop($event, creneau)">
              <ng-container *ngIf="creneau.status === creneauStatus.FREE">
                <div class="row mb-3 creneau-header" [class.mt-3]="!first">
                  <div class="col-1">{{ creneau.startHour }} - {{ creneau.startHour + creneau.size }}h</div>
                  <div class="creneau-border mt-2 col-10"></div>
                  <div class="col-1">{{ creneau?.samples?.length }} {{ 'samples.lowerSampleShort' | transloco }}</div>
                </div>
                <ng-container *ngIf="creneau?.samples?.length! <= 0">
                  <span class="ms-5 fw-bold fst-italic"> {{ 'samples.noSampleForCreneau' | transloco }}</span></ng-container
                >
                <div *ngFor="let sample of creneau.samples; let last = last" class="d-flex align-items-center appointment" [class.mb-2]="!last" cdkDrag>
                  <mat-icon cdkDragHandle class="d-block drag">drag_indicator</mat-icon>
                  <div class="d-flex details align-items-center justify-content-between">
                    <div class="pin d-flex">
                      <div class="position-relative">
                        <div class="rdv-number">{{ samples | findOrderInJournee: sample.id! }}</div>
                        <img class="neutral red-pin mx-3" src="assets/img/location.svg" alt="" />
                      </div>
                      <div>
                        <div class="patient-name fw-bold">{{ sample.user?.firstname }} {{ sample.user?.lastname }}</div>
                        <div class="fw-bold">{{sample.noSejour}}</div>
                        <div>
                          {{ sample.adr1 }}<span *ngIf="sample.adr2"> {{ sample.adr2 }}</span
                          >, {{ sample.cp }} {{ sample.ville }}
                        </div>
                      </div>
                    </div>

                    <div class="flex-grow-1 ordo-checks">
                      <ng-container>
                        <img *ngIf=" sample.hasRx && sample.documents?.length! <= 0" class="ordonnance" src="assets/img/files.svg" alt="ordonnance non uploadée">
                        <div *ngIf=" sample.hasRx &&sample.documents?.length! > 0">
                          <img class="ordonnance" src="assets/img/prescription-ok.svg" alt="ordonnance uploadée">
                          <button mat-icon-button (click)="downloadPrescription(sample)" class="me-3">
                            <mat-icon color="accent">download</mat-icon>
                          </button>
                        </div>
                        <img *ngIf="!sample.hasRx" class="ordonnance" src="assets/img/prescription-missing.svg" alt = "absence d'ordonnance ">
                      </ng-container>
                      <span *ngFor="let check of sample.checks; let last = last"> {{ check.libelle }} <span *ngIf="!last"> + </span></span>
                    </div>
                    <div *ngIf="sample.jeun" class="jeun-red me-5">{{'samples.jeun' | transloco}}</div>
                    <div class="desired-time d-flex align-items-center justify-content-around">
                      <mat-icon *ngIf="sample.desiredStartTime! > sample.endTime! || sample?.desiredEndTime! < sample.startTime!" class="not-desired d-block me-1 align-middle">error</mat-icon>
                      <div>
                        <div [class.exact-time]="!sample.desiredEndTime">
                          {{ sample.desiredStartTime | hour: !sample.desiredEndTime }}<span *ngIf="sample.desiredEndTime">- {{ sample.desiredEndTime | hour: false }}</span>
                        </div>
                        <div class="tolerance" *ngIf="!sample.desiredEndTime">(+/-{{ sample.tolerance }}{{ 'app.minuteShort' | transloco }})</div>
                      </div>
                    </div>
                  </div>
                  <ng-container *ngIf="!isPast">
                    <mat-icon *ngIf="isCancellable(sample); else notCancellable" type="button" class="d-block ms-2 cancel" (click)="disaffect(sample.id!)">close</mat-icon>
                    <ng-template #notCancellable>
                      <mat-icon class="d-block ms-2 cancel" [matTooltip]="getNotCancellableReason(sample)">info</mat-icon>
                    </ng-template>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="unaffected$ | async as unaffected">
          <ng-container *ngIf="unaffected.length">
            <div class="row mb-3 disaffected-header mt-5">
              <div class="col-2">
                {{ 'samples.notAffected' | transloco }}
              </div>
              <div class="creneau-border mt-2 col-9 me-2"></div>
            </div>
            <div cdkDropList [cdkDropListData]="unaffected" [cdkDropListEnterPredicate]="noReturnPredicate">
              <div *ngFor="let sample of unaffected; let last = last" class="d-flex align-items-center appointment me-3" [class.mb-2]="!last" cdkDrag>
                <mat-icon cdkDragHandle class="d-block drag">drag_indicator</mat-icon>
                <div class="d-flex details align-items-center justify-content-between">
                  <div class="pin disaffected d-flex">
                    <div class="position-relative">
                      <div class="rdv-number">0</div>
                      <img class="neutral grey-pin mx-3" src="assets/img/location.svg" alt="" />
                    </div>
                    <div>
                      <div class="patient-name fw-bold">{{ sample.user?.firstname }} {{ sample.user?.lastname }}</div>
                      <div class="fw-bold">{{sample.noSejour}}</div>
                      <div>
                        {{ sample.adr1 }}<span *ngIf="sample.adr2"> {{ sample.adr2 }}</span
                        >, {{ sample.cp }} {{ sample.ville }}
                      </div>
                    </div>
                  </div>

                  <div class="flex-grow-1 ordo-checks">
                    <ng-container *ngIf="sample.hasRx">
                      <img *ngIf="sample.documents?.length! > 0" class="ordonnance" src="assets/img/prescription-ok.svg" alt="" />
                      <img *ngIf="sample.documents?.length! <= 0" class="ordonnance" src="assets/img/prescription-missing.svg" alt="" />
                    </ng-container>
                    <span *ngFor="let check of sample.checks; let last = last"> {{ check.libelle }} <span *ngIf="!last"> + </span></span>
                  </div>
                  <div *ngIf="sample.jeun" class="jeun-black me-5">{{'samples.jeun' | transloco}}</div>
                  <div class="desired-time d-flex align-items-center">
                    <div>
                      <div [class.exact-time]="!sample.desiredEndTime">
                        {{ sample.desiredStartTime | hour: !sample.desiredEndTime }}<span *ngIf="sample.desiredEndTime">- {{ sample.desiredEndTime | hour: false }}</span>
                      </div>
                      <div class="tolerance" *ngIf="!sample.desiredEndTime">(+/-{{ sample.tolerance }}{{ 'app.minuteShort' | transloco }})</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <app-sample-map class="col-4 map"></app-sample-map>
    </ng-container>
  </div>
</div>

<ng-template #loading>
  <div class="w-100 mt-5 d-flex justify-content-center sample-loader">
    <div class="loader"></div>
  </div>
</ng-template>
