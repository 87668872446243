import {Cabinet, PreleveurCabinet} from '@domain/cabinet/cabinet';
import {CabinetsActionsEnum} from '@states/cabinets/cabinets.actions.enum';
import {Pagination} from '@utils/pagination/Pagination';
import {PaginationOption} from '@utils/pagination/PaginationOption';

const PREFIX: string = '[Cabinets] ';
export class LoadOneCabinet {
  static readonly type: string = PREFIX + CabinetsActionsEnum.LOAD_ONE_CABINET;
  constructor(public id: string) {}
}

export class LoadOneCabinetSuccess {
  static readonly type: string = PREFIX + CabinetsActionsEnum.LOAD_ONE_CABINET_SUCCESS;
  constructor(public cabinet: Cabinet) {}
}

export class LoadOneCabinetFailure {
  static readonly type: string = PREFIX + CabinetsActionsEnum.LOAD_ONE_CABINET_FAILURE;
  constructor(public error: string) {}
}

export class LoadOneNewCabinet {
  static readonly type: string = PREFIX + CabinetsActionsEnum.LOAD_ONE_NEW_CABINET;
}

export class LoadFilteredCabinets {
  static readonly type: string = PREFIX + CabinetsActionsEnum.LOAD_FILTERED_CABINETS;
}

export class LoadFilteredCabinetsSuccess {
  static readonly type: string = PREFIX + CabinetsActionsEnum.LOAD_FILTERED_CABINETS_SUCCESS;
  constructor(public cabinets: Pagination<Cabinet>) {}
}

export class LoadFilteredCabinetsFailure {
  static readonly type: string = PREFIX + CabinetsActionsEnum.LOAD_FILTERED_CABINETS_FAILURE;
  constructor(public error: string) {}
}

export class UpdateCabinetsFilter {
  static readonly type: string = PREFIX + CabinetsActionsEnum.UPDATE_CABINETS_FILTER;
  constructor(public filter: string) {}
}

export class UpdateCabinetsPaginationOption {
  static readonly type: string = PREFIX + CabinetsActionsEnum.UPDATE_CABINETS_PAGINATION;
  constructor(public paginationOption: PaginationOption) {}
}

export class UpdatePreleveursFilter {
  static readonly type: string = PREFIX + CabinetsActionsEnum.UPDATE_PRELEVEURS_FILTER;
  constructor(public filter: string) {}
}

export class UpdatePreleveursPaginationOption {
  static readonly type: string = PREFIX + CabinetsActionsEnum.UPDATE_PRELEVEURS_PAGINATION;
  constructor(public pagination: PaginationOption) {}
}
export class LoadFilteredPreleveurs {
  static readonly type: string = PREFIX + CabinetsActionsEnum.LOAD_FILTERED_PRELEVEURS;
}

export class LoadFilteredPreleveursSuccess {
  static readonly type: string = PREFIX + CabinetsActionsEnum.LOAD_FILTERED_PRELEVEURS_SUCCESS;
  constructor(public preleveurs: Pagination<PreleveurCabinet>) {}
}

export class LoadFilteredPreleveursFailure {
  static readonly type: string = PREFIX + CabinetsActionsEnum.LOAD_FILTERED_PRELEVEURS_FAILURE;
  constructor(public error: string) {}
}

export class AddPreleveurToCabinet {
  static readonly type: string = PREFIX + CabinetsActionsEnum.ADD_PRELEVEUR_TO_CABINET;
  constructor(public preleveur: PreleveurCabinet){}
}

export class RemovePreleveurFromCabinet {
  static readonly type: string = PREFIX + CabinetsActionsEnum.REMOVE_PRELEVEUR_FROM_CABINET;
  constructor(public id: string) {}
}

export class SaveOneCabinet {
  static readonly type: string = PREFIX + CabinetsActionsEnum.SAVE_ONE_CABINET;
  constructor(public cabinet: Cabinet) {}
}

export class SaveOneCabinetSuccess {
  static readonly type: string = PREFIX + CabinetsActionsEnum.SAVE_ONE_CABINET_SUCCESS;
}

export class SaveOneCabinetFailure {
  static readonly type: string = PREFIX + CabinetsActionsEnum.SAVE_ONE_CABINET_FAILURE;
  constructor(public error: string) {}
}

export class DeleteOneCabinet {
  static readonly type: string = PREFIX + CabinetsActionsEnum.DELETE_ONE_CABINET;
  constructor(public id: string) {}
}

export class DeleteOneCabinetSuccess {
  static readonly type: string = PREFIX + CabinetsActionsEnum.DELETE_ONE_CABINET_SUCCESS;
}

export class DeleteOneCabinetFailure {
  static readonly type: string = PREFIX + CabinetsActionsEnum.DELETE_ONE_CABINET_FAILURE;
  constructor(public error: string) {}
}
