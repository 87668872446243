import {Selector} from '@ngxs/store';
import {GlobalState, GlobalStateModel} from '@states/global/global.state';
import {KeycloakProfile} from 'keycloak-js';

export class GlobalStateSelector {
  @Selector([GlobalState])
  static currentUser(state: GlobalStateModel): KeycloakProfile | undefined {
    return state.currentUser;
  }

  @Selector([GlobalState])
  static isInitializing(state: GlobalStateModel): boolean {
    return state.isInitializing;
  }
}
