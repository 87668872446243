import {Injectable} from '@angular/core';
import {DayEnum, Labo, LaboForm, LaboMapForm, LaboRdvStatusCount, PreleveursOnZone} from '@domain/labo/Labo';
import {Check, CheckFilter} from '@domain/samples/sample';
import {AggregatedUserSchedule, AggregatedUserScheduleException, User} from '@domain/user/user';
import {Navigate} from '@ngxs/router-plugin';
import {Action, State, StateContext} from '@ngxs/store';
import {insertItem, patch, removeItem, updateItem} from '@ngxs/store/operators';
import {LaboService} from '@service/labo.service';
import {MapService} from '@service/map.service';
import {SampleService} from '@service/sample.service';
import { UserService } from '@service/user.service';
import {ShowMessage} from '@states/global/global.actions';
import {MessageLevel} from '@states/global/global.state';
import {ModalConfirmMessageEnum} from '@utils/modal/modal-confirm/modal-confirm-message-enum';
import {Pagination} from '@utils/pagination/Pagination';
import {PaginationOption} from '@utils/pagination/PaginationOption';
import {ModalService} from '@utils/service/modal.service';
import firebase from 'firebase/compat';
import {Feature, FeatureCollection, Geometry, MultiPolygon, Point, Polygon} from 'geojson';
import * as L from 'leaflet'
import {catchError, EMPTY, map, of} from 'rxjs';
import {
  ChangeCouvertureLabo,
  ChangeCouvertureTypeLabo,
  ClearPreleveursOnZone,
  ConfirmDeleteCheck,
  ConfirmDeleteLabo,
  CreateCheck,
  CreateCheckFailure,
  CreateCheckSuccess,
  DeleteCheck,
  DeleteCheckFailure,
  DeleteCheckSuccess,
  DeleteOneLabo,
  DeleteOneLaboFailure,
  DeleteOneLaboSuccess,
  GetPreleveurs,
  GetPreleveursFailure,
  GetPreleveursSuccess,
  LoadChecks,
  LoadChecksFailure,
  LoadCheckSuccess,
  LoadLaboRdvStatusCounts,
  LoadLaboRdvStatusCountsFailure,
  LoadLaboRdvStatusCountsSuccess,
  LoadOneLabo,
  LoadOneLaboFailure,
  LoadOneLaboSuccess,
  LoadOneNewLabo,
  LoadPreleveursOnZone,
  LoadPreleveursOnZoneFailure,
  LoadPreleveursOnZoneSuccess,
  SaveOneLabo,
  SaveOneLaboFailure,
  SaveOneLaboSuccess,
  SaveZonePreleveur,
  SaveZonePreleveurFailure,
  SaveZonePreleveurSuccess,
  SearchAddress,
  SearchAddressFailure,
  SearchAddressSuccess,
  SearchLabos,
  SearchLabosFailure,
  SearchLabosSuccess,
  UpdateCheck,
  UpdateCheckFailure,
  UpdateChecksFilter,
  UpdateChecksPagination,
  UpdateCheckSuccess,
} from './labos.actions';

export interface LabosStateModel {
  labos: Labo[],
  labosLoaded: boolean,
  labosRdvStatusCounts: LaboRdvStatusCount[],
  labosRdvStatusCountsLoaded: boolean,
  labosPaging: {
    size: number,
    page: number
  }
  currentLabo?: LaboForm,
  laboLoaded: boolean,
  currentLaboMap?: LaboMapForm,
  zonesPreleveursLoaded: boolean,
  preleveurs?: User[],
  preleveursLoaded: boolean;
  checks?: Pagination<Check>,
  checkFilter: CheckFilter,
  checkPagination: PaginationOption,
  checksLoaded: boolean,
  preleveursOnZones: PreleveursOnZone[]
}

@State<LabosStateModel>({
  name: 'labos',
  defaults: {
    labos: [],
    labosLoaded: true,
    laboLoaded: true,
    labosRdvStatusCounts: [],
    labosRdvStatusCountsLoaded: true,
    labosPaging: {
      size: 10,
      page: 0
    },
    preleveursLoaded: true,
    checkFilter: {},
    checkPagination: {
      pageSize: 15,
      pageIndex: 0,
      pageSizeOption: [10, 15, 20]
    },
    checksLoaded: true,
    zonesPreleveursLoaded: true,
    preleveursOnZones: []
  },
})

@Injectable()
export class LabosState {
  constructor(private laboService: LaboService, private sampleService: SampleService, private userService: UserService, private modalService: ModalService, private mapService: MapService) {}

  @Action(SearchLabos)
  searchLabos(ctx: StateContext<LabosStateModel>) {
    ctx.patchState({
      labosLoaded: false
    });
    return this.laboService.search().pipe(
      map(labos => ctx.dispatch(new SearchLabosSuccess(labos))),
      catchError((error: any) => ctx.dispatch(new SearchLabosFailure(error)))
    )
  }

  @Action(SearchLabosSuccess)
  searchLabosSuccess(ctx: StateContext<LabosStateModel>, {labos}: SearchLabosSuccess) {
    ctx.patchState(
      {
        labos: labos.content,
        labosLoaded: true,
        labosPaging: {
          page: labos.number,
          size: labos.size
        }
      }
    );
  }

  @Action(SearchLabosFailure)
  searchLabosFailure(ctx: StateContext<LabosStateModel>, {error}: SearchLabosFailure) {
    ctx.patchState({
      labosLoaded: true
    });
  }

  @Action(LoadLaboRdvStatusCounts)
  loadLaboRdvStatusCounts(ctx: StateContext<LabosStateModel>, {fromDate, toDate}: LoadLaboRdvStatusCounts) {
    ctx.patchState({
      labosRdvStatusCountsLoaded: false
    })
    return this.sampleService.getLaboRdvStatusCount(fromDate, toDate).pipe(
      map(counts => ctx.dispatch(new LoadLaboRdvStatusCountsSuccess(counts))),
      catchError((error: any) => ctx.dispatch(new LoadLaboRdvStatusCountsFailure(error)))
    )
  }

  @Action(LoadLaboRdvStatusCountsSuccess)
  loadLaboRdvStatusCountsSuccess(ctx: StateContext<LabosStateModel>, {counts}: LoadLaboRdvStatusCountsSuccess) {
    ctx.patchState({
      labosRdvStatusCounts: counts,
      labosRdvStatusCountsLoaded: true
    })
  }

  @Action(LoadLaboRdvStatusCountsFailure)
  loadLaboRdvStatusCountsFailure(ctx: StateContext<LabosStateModel>, {error}: LoadLaboRdvStatusCountsFailure) {
    ctx.patchState({
      labosRdvStatusCountsLoaded: true
    })
  }

  @Action(LoadOneLabo)
  loadOneLabo(ctx: StateContext<LabosStateModel>, {id}: LoadOneLabo) {
    ctx.patchState({
      laboLoaded: false
    })
    return this.laboService.get(id).pipe(
      map(labo => ctx.dispatch(new LoadOneLaboSuccess(labo))),
      catchError((error: any) => ctx.dispatch(new LoadOneLaboFailure(error)))
    )
  }

  @Action(LoadOneLaboSuccess)
  loadOneLaboSuccess(ctx: StateContext<LabosStateModel>, {labo}: LoadOneLaboSuccess) {
    if (labo) {
      let cover: FeatureCollection<Polygon> | undefined;
      if (labo.zones && labo.zones.length > 0 && labo.zones[0].zone) {
        cover = {
          type:'FeatureCollection',
          features: []
        };

        (labo.zones[0].zone as MultiPolygon).coordinates.forEach(coord => {
          var polygon:Polygon = {
            type: "Polygon",
            coordinates: coord
          }
          cover!.features.push({
            type: 'Feature',
            geometry: polygon,
            properties:{}
          });
        })
      }

      ctx.patchState(
        {
          laboLoaded: true,
          currentLabo: {
            id: labo.id,
            name: labo.name,
            coord: {
              address1: labo.address1,
              address2: labo.address2,
              postalCode: labo.postalCode,
              city: labo.city,
              country: labo.country,
              phone: labo.phone,
            },
            schedule: {
              monday: labo.schedule?.find(el => el.day == 'monday'),
              tuesday: labo.schedule?.find(el => el.day == 'tuesday'),
              wednesday: labo.schedule?.find(el => el.day == 'wednesday'),
              thursday: labo.schedule?.find(el => el.day == 'thursday'),
              friday: labo.schedule?.find(el => el.day == 'friday'),
              saturday: labo.schedule?.find(el => el.day == 'saturday'),
              sunday: labo.schedule?.find(el => el.day == 'sunday')
            },
            zones: labo.zones ? labo.zones[0] : undefined,
            zonesPreleveurs: labo.zonesPreleveurs,
            position: labo.position,
            lowerLimitPerTimeSlot: labo.lowerLimitPerTimeSlot,
            upperLimitPerTimeSlot: labo.upperLimitPerTimeSlot,
            samplesPerTimeSlotForPreleveur: labo.samplesPerTimeSlotForPreleveur,
            noHomeRxPrice: labo.noHomeRxPrice,
            samplingManual: labo.samplingManual,
            fixedSchedule: labo.fixedSchedule,
          },
          currentLaboMap:{
            marker: new L.LatLng(labo.position!.coordinates[1], labo.position!.coordinates[0]),
            coverType: labo.zones ? labo.zones[0].typeZone : '5',
            coverPolygons: cover
          }
        }
      )
    }
  }

  @Action(LoadOneLaboFailure)
  loadOneLaboFailure(ctx: StateContext<LabosStateModel>, {error}: LoadOneLaboFailure) {
    ctx.patchState({
      laboLoaded: true
    })
  }

  @Action(LoadOneNewLabo)
  loadOneNewLabo(ctx: StateContext<LabosStateModel>) {
    ctx.patchState(
      {
        currentLabo: {
          id: undefined,
          name: "",
          coord: {
            address1: "",
            address2: "",
            city: "",
            country: "FR",
            phone: "",
            postalCode: ""
          },
          schedule: {
            monday: undefined,
            tuesday: undefined,
            wednesday: undefined,
            thursday: undefined,
            friday: undefined,
            saturday: undefined,
            sunday: undefined
          },
          zones:{
            typeZone: "5"
          },
          zonesPreleveurs: [],
          noHomeRxPrice: 10,
          samplingManual: '',
          fixedSchedule: [],
        },
        currentLaboMap: {},

      }
    )
  }

  @Action(SearchAddress)
  searchAddress(ctx: StateContext<LabosStateModel>, {labo}: SearchAddress) {
    return this.mapService.searchByAddress(labo).pipe(
      map(results => ctx.dispatch(new SearchAddressSuccess(results))),
      catchError((error: any) => ctx.dispatch(new SearchAddressFailure(error)))
    );
  }

  @Action(SearchAddressSuccess)
  searchAddressSuccess(ctx: StateContext<LabosStateModel>, {searchResults}: SearchAddressSuccess) {
    if (searchResults && searchResults.length > 0) {
      searchResults.sort((a,b) => {
        return a.importance - b.importance;
      })
      ctx.setState(
        patch({
          currentLaboMap: patch({
            marker: L.latLng(searchResults[0].lat, searchResults[0].lon),
          })
        })
      );
    }
  }

  @Action(SearchAddressFailure)
  searchAddressFailure(ctx: StateContext<LabosStateModel>, {error}: SearchAddressFailure) {
    // do something ?
  }

  @Action(ChangeCouvertureTypeLabo)
  changeCouvertureTypeLabo(ctx: StateContext<LabosStateModel>, {coverType}: ChangeCouvertureTypeLabo) {
    ctx.setState(
      patch({
        currentLaboMap: patch({
          coverType: coverType
        })
      })
    );
  }

  @Action(ChangeCouvertureLabo)
  changeCouvertureLabo(ctx: StateContext<LabosStateModel>, {featureCollection}: ChangeCouvertureLabo) {
    ctx.setState(
      patch({
        currentLaboMap: patch({
          coverPolygons: featureCollection
        })
      })
    );
  }

  @Action(SaveOneLabo)
  saveOneLabo(ctx: StateContext<LabosStateModel>, { labo }: SaveOneLabo) {

    const week: string[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

    const typeCouverture = ctx.getState().currentLaboMap?.coverType;
    let multiPolygon: MultiPolygon | undefined = undefined;

    multiPolygon = {
      type: "MultiPolygon",
      coordinates: []
    }
    ctx.getState().currentLaboMap?.coverPolygons?.features.forEach((f: Feature<Geometry>) => {
      const polygon: Polygon = f.geometry as Polygon;
      multiPolygon!.coordinates.push(polygon.coordinates);
    })

    const marker = ctx.getState().currentLaboMap?.marker;
    let point: Point = {
      type: 'Point',
      coordinates: [marker!.lng, marker!.lat]
    }

    const laboDto: Labo = {
      id :labo.id,
      name: labo.name,
      ...labo.coord,
      position: point,
      schedule: [],
      lowerLimitPerTimeSlot: labo.lowerLimitPerTimeSlot,
      upperLimitPerTimeSlot: labo.upperLimitPerTimeSlot,
      samplesPerTimeSlotForPreleveur: labo.samplesPerTimeSlotForPreleveur,
      noHomeRxPrice: labo.noHomeRxPrice,
      samplingManual: labo.samplingManual,
      fixedSchedule: labo.fixedSchedule,
      zones: [{
        typeZone: '5',
        zone: multiPolygon
      }],
      zonesPreleveurs: ctx.getState().currentLabo?.zonesPreleveurs
    };

    week.forEach(day => {
      const scheduleTmp:any = labo.schedule;
      if (scheduleTmp[day] && scheduleTmp[day].startTime) {
        let schedule:any = {
          day: day as DayEnum,
          startTime: scheduleTmp[day].startTime,
          endTime: scheduleTmp[day].endTime,
          id: scheduleTmp[day]?.id,
        };
        if (scheduleTmp[day].startTime2) {
          schedule = {
            ...schedule,
            startTime2: scheduleTmp[day].startTime2,
            endTime2: scheduleTmp[day].endTime2,
            id_2: scheduleTmp[day]?.id_2,
          }
        }
        laboDto.schedule?.push(schedule)
      }
    })

    if (labo.id) {
      return this.laboService.update(labo.id, laboDto).pipe(
        map(retour => {
          ctx.dispatch(new LoadOneLaboSuccess(retour));
          ctx.dispatch(new SaveOneLaboSuccess());
        }),
        catchError((error: any) => ctx.dispatch(new SaveOneLaboFailure(error)))
      )
    } else {
      return this.laboService.create(laboDto).pipe(
        map(retour => {
          ctx.dispatch(new LoadOneLaboSuccess(retour));
          ctx.dispatch(new SaveOneLaboSuccess());
        }),
        catchError((error: any) => ctx.dispatch(new SaveOneLaboFailure(error)))
      )
    }
  }

  @Action(SaveOneLaboSuccess)
  saveOneLaboSuccess(ctx: StateContext<LabosStateModel>) {
    ctx.dispatch(new ShowMessage({content: {title: 'labo_saved_success'}, level: MessageLevel.SUCCESS}, 'labos'));
  }

  @Action(SaveOneLaboFailure)
  saveOneLaboFailure(ctx: StateContext<LabosStateModel>, {error}: SaveOneLaboFailure) {
    // do something ?
  }

  @Action(ConfirmDeleteLabo)
  confirmDeleteLabo(ctx: StateContext<LabosStateModel>, { id }: ConfirmDeleteLabo) {
    return this.modalService.confirm(ModalConfirmMessageEnum.DELETE_LABO, ModalConfirmMessageEnum.DELETE_LABO).pipe(
      map(response => {
        if (response) {
          return ctx.dispatch(new DeleteOneLabo(id))
        }
        return EMPTY;
      }),
      catchError(() => EMPTY)
    )
  }

  @Action(DeleteOneLabo)
  deleteOneLabo(ctx: StateContext<LabosStateModel>, {id}: DeleteOneLabo) {
    return this.laboService.delete(id).pipe(
      map(_ => ctx.dispatch(new DeleteOneLaboSuccess())),
      catchError((error: any) => ctx.dispatch(new DeleteOneLaboFailure(error)))
    )
  }

  @Action(DeleteOneLaboSuccess)
  deleteOneLaboSuccess(ctx: StateContext<LabosStateModel>) {
    ctx.dispatch(new ShowMessage({content: {title: 'labo_deleted_success'}, level: MessageLevel.SUCCESS}, 'labos'));
    ctx.dispatch(new Navigate(['/labos']));
  }

  @Action(DeleteOneLaboFailure)
  deleteOneLaboFailure(ctx: StateContext<LabosStateModel>, {error}: DeleteOneLaboFailure) {
    // do something ?
  }

  @Action(GetPreleveurs)
  getPreleveursForLabo(ctx: StateContext<LabosStateModel>, { filter }: GetPreleveurs) {
    ctx.patchState({
      preleveursLoaded: false
    });
    return this.laboService.getPreleveurs(filter).pipe(
      map(preleveurs => ctx.dispatch(new GetPreleveursSuccess(preleveurs.content))),
      catchError((error: any) => ctx.dispatch(new GetPreleveursFailure(error)))
    )
  }

  @Action(GetPreleveursSuccess)
  getPreleveursForLaboSuccess(ctx: StateContext<LabosStateModel>, { preleveurs }: GetPreleveursSuccess) {
    const getZoneName = (zoneId: string) => {
      return ctx.getState().currentLabo?.zonesPreleveurs?.find(z => z.id === zoneId)?.name!
    }

    preleveurs?.forEach(preleveur => {
      const aggregatedDisponibilities: AggregatedUserSchedule[] | undefined = preleveur.disponibilities?.reduce<AggregatedUserSchedule[]>((acc, dispo) => {
        const dayDispo = acc.find(p => p.day === dispo.day && p.zoneId === dispo.zoneId);
        if (dayDispo) {
          const middle = dayDispo.schedule.find(d => !(d[0] >=dispo.end! || d[1] <= dispo.start!))
          if (middle) {
            middle[0] = dispo.start! < middle[0] ? dispo.start! : middle[0];
            middle[1] = dispo.end! > middle[1] ? dispo.end! : middle[1];
          } else {
            dayDispo.schedule.push([dispo.start!, dispo.end!]);
          }
        } else {
          acc.push({
            day: dispo.day,
            schedule: [[dispo.start!, dispo.end!]],
            zoneId: dispo.zoneId!,
          });
        }
        return acc;
      }, []);
      preleveur.aggregatedDisponibilities = aggregatedDisponibilities ? aggregatedDisponibilities.sort((a,b) => {
        if (getZoneName(a.zoneId) < getZoneName(b.zoneId)) return -1;
        if (getZoneName(a.zoneId) > getZoneName(b.zoneId)) return 1;
        return 0;
      } ) : [];


      const aggregatedExceptions: AggregatedUserScheduleException[] | undefined = preleveur.exceptions?.reduce<AggregatedUserScheduleException[]>((acc, exception) => {
        const dateDispo = acc.find(p => p.date === exception.date && p.zoneId === exception.zoneId);
        if (dateDispo) {
          const middle = dateDispo.schedule.find(d => !(d[0] >=exception.end! || d[1] <= exception.start!))
          if (middle) {
            middle[0] = exception.start! < middle[0] ? exception.start! : middle[0];
            middle[1] = exception.end! > middle[1] ? exception.end! : middle[1];
          } else {
            dateDispo.schedule.push([exception.start!, exception.end!]);
          }
        } else {
          acc.push({
            date: exception.date,
            schedule: [[exception.start!, exception.end!]],
            zoneId: exception.zoneId!,
          });
        }
        return acc;
      }, []);
      preleveur.aggregatedExceptions = aggregatedExceptions? aggregatedExceptions.sort((a,b) => {
        if (getZoneName(a.zoneId) < getZoneName(b.zoneId)) return -1;
        if (getZoneName(a.zoneId) > getZoneName(b.zoneId)) return 1;
        return 0;
      } ) : [];
    })

    return ctx.patchState({
      preleveurs: preleveurs,
      preleveursLoaded: true
    });
  }

  @Action(GetPreleveursFailure)
  getPreleveursForLaboFailure(ctx: StateContext<LabosStateModel>, { error }: GetPreleveursFailure) {
    ctx.patchState({
      preleveursLoaded: true
    });
  }


  @Action(LoadChecks)
  loadChecks(ctx: StateContext<LabosStateModel>, {withReload}: LoadChecks) {
    const filter: CheckFilter = ctx.getState().checkFilter
    const pagination: PaginationOption = ctx.getState().checkPagination
    if(withReload) {
      ctx.patchState({
        checksLoaded: false
      })
    }
    return this.laboService.loadChecks(filter, pagination).pipe(
      map(checks => ctx.dispatch(new LoadCheckSuccess(checks))),
      catchError(e => ctx.dispatch(new LoadChecksFailure(e)))
    )
  }

  @Action(LoadCheckSuccess)
  loadCheckSuccess(ctx: StateContext<LabosStateModel>, {checks}: LoadCheckSuccess) {
    return ctx.setState(patch({
      checksLoaded: true,
      checks: checks
    }))
  }

  @Action(UpdateChecksFilter)
  updateChecksFilter(ctx: StateContext<LabosStateModel>, { filter, pagination }: UpdateChecksFilter) {
    ctx.patchState({
      checkFilter: filter,
      checkPagination: {
        ...ctx.getState().checkPagination,
        pageIndex: 0,
        ...pagination
      }
    });
    return ctx.dispatch(new LoadChecks())
  }

  @Action(UpdateChecksPagination)
  updateChecksPagination(ctx: StateContext<LabosStateModel>, { paginationOption }: UpdateChecksPagination) {
    ctx.setState(patch({
      checkPagination: patch(paginationOption)
    }))
    return ctx.dispatch(new LoadChecks(false))
  }

  @Action(CreateCheck)
  createCheck(ctx: StateContext<LabosStateModel>, { check }: CreateCheck) {
    return this.laboService.createCheck(check).pipe(
      map(c => ctx.dispatch(new CreateCheckSuccess())),
      catchError(e => ctx.dispatch(new CreateCheckFailure(e)))
    )
  }

  @Action(CreateCheckSuccess)
  createCheckSuccess(ctx: StateContext<LabosStateModel>) {
    return ctx.dispatch(new LoadChecks())
  }

  @Action(ConfirmDeleteCheck)
  confirmDeleteCheck(ctx: StateContext<LabosStateModel>, { id }: ConfirmDeleteCheck) {
    return this.modalService.confirm(ModalConfirmMessageEnum.DELETE_CHECK, ModalConfirmMessageEnum.DELETE_CHECK).pipe(
      map(response => {
        if (response) {
          return ctx.dispatch(new DeleteCheck(id))
        }
        return EMPTY;
      }),
      catchError(() => EMPTY)
    )
  }

  @Action(DeleteCheck)
  deleteCheck(ctx: StateContext<LabosStateModel>, { id }: DeleteCheck) {
    return this.laboService.deleteCheck(id).pipe(
      map(() => ctx.dispatch(new DeleteCheckSuccess())),
      catchError(e => ctx.dispatch(new DeleteCheckFailure(e)))
    )
  }

  @Action(DeleteCheckSuccess)
  deleteCheckSuccess(ctx: StateContext<LabosStateModel>) {
    return ctx.dispatch(new LoadChecks())
  }

  @Action(UpdateCheck)
  updateCheck(ctx: StateContext<LabosStateModel>, {check}: UpdateCheck) {
    return this.laboService.updateCheck(check.id!, check).pipe(
      map(c => ctx.dispatch(new UpdateCheckSuccess())),
      catchError(e => ctx.dispatch(new UpdateCheckFailure(e)))
    )
  }

  @Action(UpdateCheckSuccess)
  updateCheckSuccess(ctx: StateContext<LabosStateModel>) {
    return ctx.dispatch(new LoadChecks())
  }

  @Action(SaveZonePreleveur)
  saveZonePreleveur(ctx: StateContext<LabosStateModel>, {laboId, zones}: SaveZonePreleveur) {
    ctx.patchState({
      zonesPreleveursLoaded: false
    })
    return this.laboService.saveZonesPreleveurs(laboId, zones).pipe(
      map(c => ctx.dispatch(new SaveZonePreleveurSuccess())),
      catchError(e => ctx.dispatch(new SaveZonePreleveurFailure(e)))
    )
  }

  @Action(SaveZonePreleveurSuccess)
  saveZonePreleveurSuccess(ctx: StateContext<LabosStateModel>) {
    ctx.patchState({
      zonesPreleveursLoaded: true
    })
    ctx.dispatch(new ShowMessage({content: {title: 'zones_preleveurs_saved_success'}, level: MessageLevel.SUCCESS}, 'labos'));
  }

  @Action(SaveZonePreleveurFailure)
  saveZonePreleveurFailure(ctx: StateContext<LabosStateModel>, {error}: SaveZonePreleveurFailure) {
    ctx.patchState({
      zonesPreleveursLoaded: true
    })
  }

  @Action(LoadPreleveursOnZone)
  loadPreleveursOnZone(ctx: StateContext<LabosStateModel>, {zoneId}: LoadPreleveursOnZone) {
    const alreadyLoaded = ctx.getState().preleveursOnZones.find(zone => zone.zoneId === zoneId);
    if (alreadyLoaded) {
      return of(alreadyLoaded)
    }
    ctx.setState(
      patch({
        preleveursOnZones: insertItem<PreleveursOnZone>({zoneId, preleveurs:[], loaded: false})
      })
    )
    return this.userService.findPreleveursOnZone(zoneId).pipe(
      map(users => ctx.dispatch(new LoadPreleveursOnZoneSuccess(zoneId, users.content))),
      catchError(e => ctx.dispatch(new LoadPreleveursOnZoneFailure(e, zoneId)))
    )
  }

  @Action(LoadPreleveursOnZoneSuccess)
  loadPreleveursOnZoneSuccess(ctx: StateContext<LabosStateModel>, {zoneId, preleveurs}: LoadPreleveursOnZoneSuccess) {
    ctx.setState(
      patch({
        preleveursOnZones: updateItem<PreleveursOnZone>(z => z?.zoneId === zoneId, {zoneId, preleveurs, loaded: true})
      })
    )
  }

  @Action(LoadPreleveursOnZoneFailure)
  loadPreleveursOnZoneFailure(ctx: StateContext<LabosStateModel>, {error, zoneId}: LoadPreleveursOnZoneFailure) {
    ctx.dispatch(new ShowMessage({content: {title: 'load_preleveurs_on_zone_failure'}, level: MessageLevel.ERROR}, 'labos'));
    ctx.setState(
      patch({
        preleveursOnZones: removeItem<PreleveursOnZone>(z => z?.zoneId === zoneId)
      })
    )
  }

  @Action(ClearPreleveursOnZone)
  clearPreleveursOnZone(ctx: StateContext<LabosStateModel>) {
    ctx.patchState({
        preleveursOnZones: []
    })
  }
}
