
<div class="header row align-items-center">
  <button type="button" class="col-auto px-3" mat-button (click)="goBack()">
    <mat-icon aria-hidden="false" aria-label="go back icon">arrow_back</mat-icon>
  </button>
  <div class="col-auto me-auto">{{'labos.zonesPreleveurs.updateHeader' | transloco}}
    <ng-container *ngIf="laboLoaded$ | async">
      <ng-container *ngIf="labo$ | async as labo">
        <label *ngIf="labo?.id"> {{labo.name}}</label>
      </ng-container>
    </ng-container>
  </div>
  <ng-container *ngIf="laboLoaded$ | async">
    <ng-container *ngIf="isAdmin || isPersonnelLabo">
      <button mat-flat-button class="height-75 col-auto ms-3 me-5 justify-content-end helveticaBold13" (click)="save()" >{{'app.save' | transloco}}</button>
    </ng-container>
  </ng-container>
</div>

<div class="row panel">
  <div id="mapZones">
  </div>
  <div class="col-4 scrollable">
    <ng-container *ngIf="laboLoaded$ | async ; else loadingLaboBlock">
      <ng-container *ngIf="formGroups.length > 0; else noZoneBlock">
        <div class="title d-flex justify-content-center pt-1">{{ 'labos.zonesPreleveurs.definedZones' | transloco}}</div>
          <ng-container *ngFor="let zone of formGroups; let i = index" [formGroup]="zone">
          <div class="zoneNames">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="title">{{ 'labos.zonesPreleveurs.zone' | transloco}} {{i + 1}}</div>
                <div class="colorView" [ngStyle]="{'background-color': zone.value.color}"></div>
              </div>
              <mat-checkbox class="hideCheck" formControlName="hide" (change)="hide($event.checked, zone)" labelPosition="before" title="{{ 'labos.zonesPreleveurs.hideLabel' | transloco}}">{{ 'labos.zonesPreleveurs.hide' | transloco}}</mat-checkbox>            
            </div>
            <div class="d-flex align-items-center">
              <mat-form-field appearance="fill">
                <mat-label>{{ 'labos.zonesPreleveurs.zoneName' | transloco}}</mat-label>
                <input matInput placeholder="" formControlName="name">
              </mat-form-field>
            </div>
            <div class="d-flex preleveurList toggler" (click)="togglePreleveursOnZone(zone)">
              <div>{{'labos.zonesPreleveurs.prelveursEnActivites' | transloco}}</div>
              <mat-icon *ngIf="isPreleveursOnZoneShown(zone); else preleveursNotShown" aria-hidden="false" aria-label="show preleveurs icon">expand_more</mat-icon>
              <ng-template #preleveursNotShown>
                <mat-icon aria-hidden="false" aria-label="show preleveurs icon">chevron_right</mat-icon>
              </ng-template>
            </div>
            <div *ngIf="isPreleveursOnZoneShown(zone)">
              <div *ngIf="preleveursOnZones$ | async as preleveursOnZones">
                <div *ngIf="getPreleveursOnZone(preleveursOnZones, zone) as preleveursOnZone">
                  <div *ngIf="preleveursOnZone.loaded; else preleveursLoading">  
                    <div *ngIf="preleveursOnZone.preleveurs.length > 0; else noPreleveur">
                      <ng-container *ngFor="let user of preleveursOnZone.preleveurs">
                        <div class="preleveurList">{{user.firstname}} {{user.lastname}}</div>
                      </ng-container>
                    </div>
                    <ng-template #noPreleveur>
                      <div class="preleveurList">{{'labos.zonesPreleveurs.aucunPrelveursEnActivites' | transloco}}</div>
                    </ng-template>
                  </div>
                  <ng-template #preleveursLoading>
                    <div class="w-100 d-flex flex-column justify-content-center align-items-center preleveursLoader">
                      <div class="loader">
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #noZoneBlock>
        <div class="legend d-flex flex-column justify-content-center align-items-center">
          <div>{{ 'labos.zonesPreleveurs.defineZones' | transloco}}</div>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #loadingLaboBlock>
      <div class="w-100 d-flex flex-column justify-content-center align-items-center laboLoader">
        <div class="loader">
        </div>
      </div>
    </ng-template>
  </div>
</div>

