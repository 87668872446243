import {ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Document, DocumentTypeEnum, Sample, SampleInfos} from '@domain/samples/sample';
import {ResultDiffusion} from '@domain/user/user';
import {Select, Store} from '@ngxs/store';
import {DownloadPrescription, GetSample, ValidateSaveStatus} from '@states/samples/samples.actions';
import {SamplesStateSelector} from '@states/samples/samples.selectors';
import {GetUserDocuments, GetUserResultDiffusion} from '@states/user/users.actions';
import {UsersStateSelector} from '@states/user/users.selectors';
import {firstValueFrom, Observable, Subject, take, takeUntil} from 'rxjs';

@Component({
  selector: 'app-dialog-sample-infos',
  templateUrl: './dialog-sample-infos.component.html',
  styleUrls: ['./dialog-sample-infos.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogSampleInfosComponent implements OnInit, OnDestroy {

  constructor(public dialogRef: MatDialogRef<DialogSampleInfosComponent>, @Inject(MAT_DIALOG_DATA) public data: {sample: SampleInfos}, private store: Store) { }
  @Select(UsersStateSelector.documents) documents$: Observable<Document[]>;
  @Select(UsersStateSelector.hasIdCard) hasIdCard$: Observable<boolean>;
  @Select(UsersStateSelector.hasHealthCard) hasHealthCard$: Observable<boolean>;
  @Select(UsersStateSelector.hasHealthAttestation) hasHealthAttestation$: Observable<boolean>;
  @Select(UsersStateSelector.hasInsuranceCard) hasInsuranceCard$: Observable<boolean>;
  @Select(SamplesStateSelector.sampleInfos) sample$: Observable<Sample>;
  @Select(UsersStateSelector.resultDiffusion) resultDiffusion$: Observable<ResultDiffusion>;
  documentTypeEnum: typeof DocumentTypeEnum = DocumentTypeEnum;
  otherConsignes!: FormGroup;
  private destroy: Subject<boolean> = new Subject<boolean>();
  ngOnInit(): void {
    if (this?.data?.sample?.userId){
      this.store.dispatch(new GetUserDocuments(this.data.sample.userId));
      this.store.dispatch(new GetUserResultDiffusion(this.data.sample.userId));
    }
    this.store.dispatch(new GetSample(this.data.sample.id));
    this.otherConsignes = new FormGroup({
      jeun: new FormControl(false),
      patientConsigne: new FormControl(''),
      preleveurConsigne: new FormControl('')
    });

    this.sample$.pipe(takeUntil(this.destroy)).subscribe(sample => {
      if (sample) {
        this.otherConsignes.get('patientConsigne')?.setValue(sample.consignePatient);
        this.otherConsignes.get('preleveurConsigne')?.setValue(sample.consignePreleveur);
        this.otherConsignes.get('jeun')?.setValue(sample.jeun);
      }
    })
  }

  async validate(): Promise<void> {
    this.store.dispatch(new ValidateSaveStatus(this.data.sample.id, this.otherConsignes.value.jeun, this.otherConsignes.value.patientConsigne, this.otherConsignes.value.preleveurConsigne));
    this.dialogRef.close(true);
  }

  hasUploadedPresciption(sample: SampleInfos): boolean {
    if (sample.prescription) {
      return sample.prescription.filter(doc => doc.documentType === DocumentTypeEnum.PRESCRIPTION).length >= 1;
    }
    return false;
  }

  async downloadDocument(documentType: DocumentTypeEnum): Promise<void> {
    const documents: Document[] = await firstValueFrom(this.documents$);
    documents
      .filter(doc => doc.documentType === documentType)
      .forEach(doc => {
        if (doc.id) {
          this.store.dispatch(new DownloadPrescription(doc.id, doc.filename || ''));
        }
    });
  }

  async downloadPrescription(sample: Sample): Promise<void> {
    const documents: Document[] = await firstValueFrom(this.documents$);
    documents
      .filter(doc => doc.documentType === DocumentTypeEnum.PRESCRIPTION && doc.sampleRef === sample.noSejour)
      .forEach(doc => {
        if (doc.id) {
          this.store.dispatch(new DownloadPrescription(doc.id, doc.filename || ''));
        }
      });
  }

  close() {
    this.dialogRef.close(false);
  }

  getFixPrice(sample: Sample): number | undefined {
    if (sample.labo?.fixedSchedule) {
      return sample.labo?.fixedSchedule?.filter(sched => Number.parseInt(sched.delay) == sample.tolerance)[0].price;
    }
    return 0;
  }
  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }
}
