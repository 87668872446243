import {Component, OnInit, ChangeDetectionStrategy, OnDestroy} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { LabosStateSelector } from '@states/labos/labos.selectors';
import * as L from 'leaflet'
import '@geoman-io/leaflet-geoman-free';
import { Observable, Subject, takeUntil } from 'rxjs';
import { FeatureCollection } from 'geojson';
import { ChangeCouvertureLabo } from '@states/labos/labos.actions';
import { UserInfo } from '@security/UserInfo';
import { KeycloakService } from 'keycloak-angular';
import { KantysTileLayer } from 'src/app/shared/maps/TileLayer';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapComponent extends UserInfo implements OnInit, OnDestroy {
  
  private map: L.Map;
  private polygonLayers = L.featureGroup();

  private destroy: Subject<boolean> = new Subject<boolean>();
  @Select(LabosStateSelector.currentLaboMapMarker) laboMarker$: Observable<L.LatLngExpression>;
  @Select(LabosStateSelector.currentLaboMapCoverType) laboCoverType$: Observable<string>;
  @Select(LabosStateSelector.currentLaboMapCoverPolygons) laboCoverPolygons$: Observable<FeatureCollection>;

  myIcon = L.icon({
    iconUrl: "assets/img/pin-K.svg",
    iconSize: [34, 40],
    iconAnchor: [18, 43],
    popupAnchor: [-2, -40],
  });

  private currentMarker: L.Marker;

  constructor(public override keycloakService: KeycloakService, private store: Store) {
    super(keycloakService);
  }

  ngOnInit(): void {
    this.initMap();

    // when labo address change
    this.laboMarker$.pipe(takeUntil(this.destroy)).subscribe(marker => {
      if (marker) {
        if (this.currentMarker) {
            this.currentMarker.setLatLng(marker);
            this.map.flyTo(marker, 10, {duration:1});
        } else {
          this.currentMarker = L.marker(marker, {icon: this.myIcon});
          this.currentMarker.addTo(this.map);
          this.map.flyTo(marker, 10, {duration:1});
        }
      }
    })

    // when coverage polygon change
    this.laboCoverPolygons$.pipe(takeUntil(this.destroy)).subscribe(polys => {
      if (polys) {
        this.polygonLayers.clearLayers();
        polys.features.forEach(poly => {
          var layer = L.geoJSON(poly, {pmIgnore: false, interactive: true});
          layer.on('pm:edit', (e) => {
            this.updatePolygon();
          });
          layer.on('pm:remove', (e) => {
            // without the removeLayer below, the polygon is visually removed but the sourceTarget (which is different) is not
            // automatically removed
            // @ts-ignore (for sourceTarget)
            this.polygonLayers.removeLayer(e.sourceTarget);
            this.updatePolygon();
          });
          this.polygonLayers.addLayer(layer);
        })
      }
    })

    // when coverage type change
    this.laboCoverType$.pipe(takeUntil(this.destroy)).subscribe(type => {
      if (type === 'custom') {  // custom coverage
        if (this.isAdmin || this.isPersonnelLabo) {
          // display controls
          this.map.pm.addControls({
            position: 'topright',  
            drawCircle: false,
            drawMarker: false,
            drawPolyline: false,
            drawRectangle: false,
            drawText: false,
            cutPolygon: false,
            drawCircleMarker: false
          })
          this.map.pm.setGlobalOptions({
            layerGroup: this.polygonLayers
          });
        }
      } else {
        // clear polygons
        this.polygonLayers.clearLayers()
        // remove controls
        this.map.pm.removeControls();
        // draw circle
        if (this.currentMarker) {
          this.polygonLayers.addLayer(L.PM.Utils.circleToPolygon(L.circle(this.currentMarker.getLatLng(), {radius:parseInt(type) * 1000}), 60));
          this.updatePolygon();
        }
      }
    })
  }

  updatePolygon() {
    this.store.dispatch(new ChangeCouvertureLabo(this.polygonLayers.toGeoJSON() as FeatureCollection));
  }

  initMap(): void {
    this.map = L.map('map', {center: [47, 2], zoom: 5});
    this.polygonLayers.addTo(this.map);

    new KantysTileLayer().addTo(this.map);

    this.map.on('pm:create', ({layer}) => {
      this.updatePolygon();
      layer.on('pm:edit', (e) => {
        this.updatePolygon();
      });
      layer.on('pm:remove', (e) => {
        this.updatePolygon();
      });
    });

  }

  public ngOnDestroy(): void {
    this.map.off();
    this.map.remove();
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }
}
