<div class="header row align-items-center">
  <div class="ms-3"> {{'samples.planning.title' | transloco}}</div>
</div>
<div class="row mt-3">
  <span class="day col-2">{{date.value | date:'EEEE d MMMM yyyy'}}</span>
  <div class="dayPaginator col-2 me-auto">
    <img class="icon" src="assets/img/left.svg" class="dayPaginatorLeft" (click)="prevDay()">
    <mat-form-field class="visually-hidden">
      <input matInput [matDatepicker]="picker" [formControl]="date" (dateChange)="goToDate($event)">
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <button mat-icon-button (click)="picker.open()" >
      <img src="assets/img/avaibility.svg" class="icon">
    </button>
    <img class="icon" src="assets/img/right.svg" class="dayPaginatorRight" (click)="nextDay()">
  </div>

  <app-searchbar class="col-4" (valueChange)="searchChanged($event)" [search$]="filterSearch$" [placeholder]="'samples.search' | transloco"></app-searchbar>
  <mat-form-field class="col-2 me-3" *ngIf="planningFilter$ | async as filter">
    <mat-label>{{'samples.planning.location' | transloco}}</mat-label>
    <mat-select (valueChange)="updateLocationFilter($event)" [placeholder]="'samples.planning.location' | transloco">
      <mat-option [value]="undefined" *ngIf="filter.inLabo !== undefined"></mat-option>
      <mat-option [value]="true">{{'samples.planning.inLabo' | transloco}}</mat-option>
      <mat-option [value] = "false">{{'samples.planning.atHome' | transloco}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div class="row m-3">
  <div class="table-header col-auto">{{'samples.planning.table.savedStatus' | transloco}}</div>
  <div class="table-header col-2">{{'samples.planning.table.patient' | transloco}}</div>
  <div class="table-header col-1">{{'samples.planning.table.ipp' | transloco}}</div>
  <div class="table-header col-2 d-flex justify-content-center">{{'samples.planning.table.secu' | transloco}}</div>
  <div class="table-header col-2"> {{'samples.planning.table.noSejour' | transloco}}</div>
  <div class="table-header col-2"> {{'samples.planning.table.prescription' | transloco}}</div>
  <div class="table-header col-2 d-flex justify-content-center">{{'samples.planning.table.status' | transloco}}</div>
</div>

<ng-container *ngIf="userSamplesLoaded$ | async else loading">
<ng-container *ngIf="dayPlanning$ | async as samples">
  <div *ngIf="samples.length>0; else noSamples">
    <div *ngFor="let sample of samples; let index = index"  class="row row-table ms-3 me-3 mt-1 mb-1 align-items-center">
      <div class="col-auto">
        <mat-icon *ngIf="sample.saved" color="primary">check</mat-icon>
        <mat-icon *ngIf="!sample.saved" color="warn" role="button" (click)="updateSaveStatus(sample.id)">close</mat-icon>
      </div>
      <div class="col-2">{{sample.userName}}</div>
      <div class="col-1">{{sample.patientIpp}}</div>
      <div class="col-2 d-flex justify-content-center">{{sample.numeroSecu}}</div>
      <div class="col-2">{{sample.noSejour}}</div>
      <div class="col-2 d-flex justify-content-start align-items-center">
        <img *ngIf="sample.hasRx && !hasUploadedPresciption(sample)" class="icon_check me-1" src="assets/img/files.svg" alt="ordonnance non uploadée">
        <div *ngIf="sample.hasRx && hasUploadedPresciption(sample)">
          <img class="icon_check me-1" src="assets/img/prescription-ok.svg" alt="ordonnance uploadée">
          <button mat-icon-button (click)="downloadPrescription(sample)" class="me-3">
            <mat-icon color="accent">download</mat-icon>
          </button>
        </div>
        <img *ngIf="!sample.hasRx" class="icon_check me-1" src="assets/img/prescription-missing.svg" alt = "absence d'ordonnance ">
        <label> {{getChecks(sample)}} </label>
      </div>
      <div class="col-2 d-flex justify-content-center" [ngSwitch]="sample?.status">
        <div *ngSwitchCase="statusEnum.PENDING" class="status_to_save">
          {{'samples.status.PENDING' | transloco}}
        </div>
        <div *ngSwitchCase="statusEnum.DONE" class="status_done">
          {{'samples.status.done' | transloco}}
        </div>
        <div *ngSwitchCase="statusEnum.CANCEL" class="status_cancel">
          {{'samples.status.cancel' | transloco}}
        </div>
        <div *ngSwitchDefault class="status_saved">
          {{'samples.status.' + sample?.status | transloco}}
        </div>
      </div>
      <div class="col-auto ms-auto">
        <button mat-icon-button (click)="openDetails(sample, index)">
          <mat-icon>arrow_forward</mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-container>

  <ng-template #noSamples>
    <div class="w-100 d-flex justify-content-center noresult mt-3">  {{'samples.planning.noSamples' | transloco}}</div>
  </ng-template>

</ng-container>

<ng-template #loading>
  <div class="w-100 d-flex justify-content-center samplesLoader">
    <div class="loader">
    </div>
  </div>
</ng-template>

