<h1 mat-dialog-title> {{ data?.libelle || 'labos.checks.newCheck' | transloco}}</h1>
<div mat-dialog-content>
  <form [formGroup]="checkForm" class="row">
    <mat-form-field appearance="fill" class="col-7">
      <mat-label> {{'labos.checks.libelle' | transloco }}</mat-label>
      <input matInput formControlName="libelle" type="text">
    </mat-form-field>

    <mat-form-field appearance="fill" class="col-5">
      <mat-label> {{'labos.checks.price' | transloco }}</mat-label>
      <input matInput formControlName="price" type="number">
      <span matSuffix> €</span>
    </mat-form-field>

    <mat-form-field appearance="fill" class="col-12">
      <mat-label> {{'labos.checks.documentAidePrelevementUrl' | transloco }}</mat-label>
      <input matInput formControlName="documentAidePrelevementUrl" type="text">
      <mat-error *ngIf="checkForm.get('documentAidePrelevementUrl')?.hasError('pattern')"> {{ 'labos.checks.urlNotValid' | transloco }}</mat-error>
    </mat-form-field>


    <mat-form-field appearance="fill" class="col-12">
      <mat-label> {{'labos.checks.description' | transloco }}</mat-label>
      <textarea matInput formControlName="description" type="text"></textarea>
    </mat-form-field>

    <mat-form-field appearance="fill" class="col-12">
      <mat-label> {{'labos.checks.consignesPreleveur' | transloco }}</mat-label>
      <textarea matInput formControlName="consignesPreleveur" type="text"></textarea>
    </mat-form-field>

    <mat-form-field appearance="fill" class="col-12">
      <mat-label> {{'labos.checks.informationsPatient' | transloco }}</mat-label>
      <textarea matInput formControlName="informationsPatient" type="text"></textarea>
    </mat-form-field>

    <mat-checkbox formControlName="jeun"> {{'labos.checks.jeun' | transloco}}</mat-checkbox>

  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()"> {{ 'app.cancel' | transloco}}</button>
  <button mat-button (click)="save()" [disabled]="checkForm.invalid"> {{ 'app.save' | transloco}}</button>
</div>
