<form [formGroup]="formGroup">
  <mat-form-field appearance="fill">
    <mat-label>{{'adresse.street' | transloco}}</mat-label>
    <input matInput required [placeholder]="'adresse.street' | transloco" formControlName="address1">
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>{{'adresse.compl' | transloco}}</mat-label>
    <input matInput [placeholder]="'adresse.compl'| transloco" formControlName="address2">
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>{{'adresse.cp' | transloco}}</mat-label>
    <input matInput maxlength="5" (input)="inputCpSearch($event)"
           [placeholder]="'adresse.cp' | transloco" formControlName="postalCode" [matAutocomplete]="autoCp" required>
    <mat-autocomplete #autoCp="matAutocomplete">
      <mat-option *ngFor="let suggest of autosuggestCp$ | async" (onSelectionChange)="select(suggest)"
                  [value]="suggest.codesPostaux[0]">
        {{suggest.nom}} - {{suggest.codesPostaux.length ? suggest.codesPostaux[0] : ''}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>{{'adresse.city' | transloco}}</mat-label>
    <input matInput required [placeholder]="'adresse.city' | transloco" formControlName="city" (input)="inputVilleSearch($event)"
           [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let suggest of autosuggestVille$ | async" (onSelectionChange)="select(suggest)"
                  [value]="suggest.nom">
        {{suggest.nom}} - {{suggest.codesPostaux.length ? suggest.codesPostaux[0] : ''}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>{{'adresse.country' | transloco}}</mat-label>
    <input matInput required [placeholder]="'adresse.country' | transloco" formControlName="country">
  </mat-form-field>
</form>
