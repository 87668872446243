import {NgModule} from '@angular/core';
import {SharedModule} from '@utils/shared.module';
import {UnauthorizedComponent} from './unauthorized/unauthorized.component';
import {UnauthorizedRoutingModule} from './unauthorized-routing.module';

@NgModule({
  declarations: [UnauthorizedComponent],
  imports: [
    SharedModule,
    UnauthorizedRoutingModule,
  ],
  providers: [],
})
export class UnauthorizedModule {}
