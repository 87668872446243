import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {PageEvent} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import {ActivatedRoute} from '@angular/router';
import {LaboForm} from '@domain/labo/Labo';
import {Check} from '@domain/samples/sample';
import {LaboCheckEditorComponent} from '@features/labo/labo-check-editor/labo-check-editor.component';
import {Navigate} from '@ngxs/router-plugin';
import {Select, Store} from '@ngxs/store';
import {ShowMessage} from '@states/global/global.actions';
import {MessageLevel} from '@states/global/global.state';
import {ConfirmDeleteCheck, LoadOneLabo, UpdateChecksFilter, UpdateChecksPagination} from '@states/labos/labos.actions';
import {LabosStateSelector} from '@states/labos/labos.selectors';
import {Pagination} from '@utils/pagination/Pagination';
import {PaginationOption, SortDirectionEnum} from '@utils/pagination/PaginationOption';
import {Observable, Subject, takeUntil} from 'rxjs';

@Component({
  selector: 'app-labo-list-check',
  templateUrl: './labo-list-check.component.html',
  styleUrls: ['./labo-list-check.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaboListCheckComponent implements OnInit, OnDestroy {

  @Select(LabosStateSelector.currentLabo) currentLabo$: Observable<LaboForm>
  @Select(LabosStateSelector.checks) checks$: Observable<Pagination<Check>>
  @Select(LabosStateSelector.checksLoaded) loaded$: Observable<boolean>
  @Select(LabosStateSelector.checksPagination) pagination$: Observable<PaginationOption>
  @Select(LabosStateSelector.checkFilterSearch) searchFilter$: Observable<string>

  columns: string[] = ['libelle', 'price', 'description', 'documentAidePrelevementUrl', 'delete']

  private laboId: string;

  private destroy: Subject<boolean> = new Subject<boolean>();

  constructor(private store: Store,
              private route: ActivatedRoute,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.route.paramMap.pipe(takeUntil(this.destroy)).subscribe(val => {
      const id: string | null = val.get('id');
      if (!id) {
        this.store.dispatch([ new Navigate(['labos']), new ShowMessage({
          content: { title: 'load_labo_failure' },
          level: MessageLevel.ERROR,
        }, 'labo')])
      }
      this.laboId = id!;
      this.getLabo(id!);
      this.getChecks(id!)

    });

  }

  getLabo(id: string) {
    this.store.dispatch(new LoadOneLabo(id))
  }

  getChecks(laboId: string) {
    this.store.dispatch(new UpdateChecksFilter({ laboId }))
  }

  onSearchChanged(search: string) {
    this.store.dispatch(new UpdateChecksFilter({ laboId: this.laboId, search: search}, { pageIndex: 0}))
  }

  onPaginateChange(event: PageEvent) {
    this.store.dispatch(new UpdateChecksPagination({
      pageIndex: event.pageIndex,
      pageSize: event.pageSize,
    }))
  }

  addCheck() {
    this.dialog.open(LaboCheckEditorComponent, {disableClose: true});
  }

  delete(id: string) {
    this.store.dispatch(new ConfirmDeleteCheck(id))
  }

  edit(check: Check) {
    this.dialog.open(LaboCheckEditorComponent, {
      data: check,
      disableClose: true
    });
  }

  sortChange(sortState: Sort) {
    this.store.dispatch(new UpdateChecksPagination({sortByColumnName: sortState.active, sortDirection: SortDirectionEnum[sortState.direction.toUpperCase() as keyof typeof SortDirectionEnum]}))
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }
}
