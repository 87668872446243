 <form [formGroup]="formGroup">
   <div [class.flex]="displayNarrow">
      <mat-form-field appearance="fill" [class.col-5]="displayNarrow">
        <mat-label>{{'adresse.street' | transloco}}</mat-label>
        <input matInput [placeholder]="'adresse.street' | transloco" formControlName="adr1" (input)="formChanged.emit(formGroup)">
      </mat-form-field>

    <mat-form-field appearance="fill" [class.col-5]="displayNarrow">
      <mat-label>{{'adresse.compl' | transloco}}</mat-label>
      <input matInput [placeholder]="'adresse.compl'| transloco" formControlName="adr2" (input)="formChanged.emit(formGroup)">
    </mat-form-field>
   </div>

   <div [class.flex]="displayNarrow">
    <mat-form-field appearance="fill" [class.col-2]="displayNarrow">
      <mat-label>{{'adresse.cp' | transloco}}</mat-label>
      <input matInput maxlength="5" (input)="inputCpSearch($event)"
             [placeholder]="'adresse.cp' | transloco" formControlName="cp" [matAutocomplete]="autoCp" (input)="formChanged.emit(formGroup)">
      <mat-autocomplete #autoCp="matAutocomplete">
        <mat-option *ngFor="let suggest of autosuggestCp$ | async" (onSelectionChange)="select(suggest)"
                    [value]="suggest.codesPostaux[0]">
          {{suggest.nom}} - {{suggest.codesPostaux.length ? suggest.codesPostaux[0] : ''}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field appearance="fill" *ngIf="!displayNarrow">
      <mat-label>{{'adresse.comm' | transloco}}</mat-label>
      <input matInput [placeholder]="'adresse.comm' | transloco" formControlName="commentaire" (input)="formChanged.emit(formGroup)">
    </mat-form-field>
    <mat-form-field appearance="fill" [class.col-5]="displayNarrow">
      <mat-label>{{'adresse.city' | transloco}}</mat-label>
      <input matInput [placeholder]="'adresse.city' | transloco" formControlName="ville" (input)="inputVilleSearch($event)"
             [matAutocomplete]="auto" (change)="formChanged.emit(formGroup)">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let suggest of autosuggestVille$ | async" (onSelectionChange)="select(suggest)"
                    [value]="suggest.nom">
          {{suggest.nom}} - {{suggest.codesPostaux.length ? suggest.codesPostaux[0] : ''}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field appearance="fill" [class.col-2]="displayNarrow">
      <mat-label>{{'adresse.country' | transloco}}</mat-label>
      <input matInput [placeholder]="'adresse.country' | transloco" formControlName="pays" (input)="formChanged.emit(formGroup)">
    </mat-form-field>
   </div>
   <div class="flex" *ngIf="displayNarrow">
     <mat-form-field appearance="fill" class="col-11">
       <mat-label>{{'adresse.comm' | transloco}}</mat-label>
       <input matInput [placeholder]="'adresse.comm' | transloco" formControlName="commentaire" (input)="formChanged.emit(formGroup)">
     </mat-form-field>
   </div>
  </form>

  <button *ngIf="canDelete" (click)="onDelete.emit()" mat-button color="warn"
          class="w-100 red">{{'app.delete' | transloco}}</button>
