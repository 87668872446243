<ng-container *ngIf="nurses$| async as nurses">
  <ng-container *ngIf="paginationOptions$ | async as paginationOption">
    <div class="header row align-items-center">
      <div class="ms-3 col-auto me-auto">{{'nurses.title'| transloco}}
      </div>
      <div class="col-auto" *ngIf="isAdmin || isPersonnelLabo">
        <button mat-raised-button class="h-75 col-auto ms-3 me-5 justify-content-end helveticaBold13 " routerLink="edit/">
          {{'nurses.addNurse' | transloco}}
        </button>
      </div>
    </div>
    <div class=" ms-auto row mt-3 mb-3 me-3">
      <div class="title col-auto align-self-baseline justify-content-start ms-3 me-auto"> {{'nurses.title' | transloco}}</div>
      <app-searchbar class="col-4" [search$]="filterName$" (valueChange)="searchChanged($event)" [placeholder]="'nurses.search' | transloco"></app-searchbar>
    </div>
    <div class="row m-3">
      <div class="col-1 table-header"></div>
      <div class="col-3 table-header justify-content-center" (click)="updateSort('name')" [ngClass]="{'sortBy': paginationOption.sortByColumnName === 'name'}">
        <span>{{'nurses.table.nurse' | transloco }}</span>
        <mat-icon *ngIf="paginationOption.sortByColumnName=== 'name' && paginationOption.sortDirection === 'DESC'; else asc" class="arrow">arrow_downward</mat-icon>
        <ng-template #asc>
          <mat-icon class="arrow">arrow_upward</mat-icon>
        </ng-template>
      </div>
      <div class="col-2 table-header justify-content-center">
        <span> {{'nurses.table.email' | transloco }} </span>
      </div>
      <div class="col-2 table-header justify-content-center">
        <span>{{'nurses.table.phone' | transloco }}</span>
      </div>
      <div class="col-3 table-header justify-content-center" (click)="updateSort('address')" [ngClass]="{'sortBy': paginationOption.sortByColumnName === 'address'}">
        <span>{{'nurses.table.address' | transloco }}</span>
        <mat-icon *ngIf="paginationOption.sortByColumnName=== 'address' && paginationOption.sortDirection === 'DESC'; else asc" class="arrow">arrow_downward</mat-icon>
      </div>
      <div class="col-auto ms-auto table-header">{{'nurses.table.edit' | transloco }}</div>
    </div>

    <ng-container *ngIf="filteredNursesLoaded$ | async else loadingNursesBlock">
      <ng-container *ngIf="nurses.content.length > 0 else noNurses">

      <div *ngFor="let nurse of nurses.content" class="row row-table ms-3 me-3 mt-1 mb-1 align-items-center">
      <div class="col-1 mt-1 mb-1">
        <div>
          <img *ngIf="nurse.profilePicture; else noImage" [src]="nurse.profilePicture | safizer" alt="profile-picture" class="profile-picture">
          <ng-template #noImage>
            <div class="profile-picture d-flex flex-column justify-content-center align-items-center">
              <mat-icon>person</mat-icon>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="col-3 fw-bold">{{nurse.name}}</div>
      <div class="col-2 justify-content-center">{{nurse.email}}</div>
      <div class="col-2 justify-content-center">{{nurse.phone}}</div>
      <div class="col-3 justify-content-center">{{nurse.address1 + ', ' + nurse.postalCode + ', ' + nurse.city }}</div>
      <div class="col-auto ms-auto">
        <button mat-icon-button [routerLink]="'edit/'+ nurse.id" class="align-self-center align-items-center">
          <mat-icon class="align-self-center">edit</mat-icon>
        </button>
      </div>
    </div>
    <mat-paginator [length]="paginationOption.length" [pageSize]="paginationOption.pageSize"
                   [pageIndex]="paginationOption.pageIndex"  [pageSizeOptions]="paginationOption.pageSizeOption || [10, 20, 50]"
    (page)="onChangePage($event)"></mat-paginator>
  </ng-container>
  <ng-template #noNurses>
    <div class="w-100 d-flex justify-content-center noresult">
      {{'nurses.noresult' | transloco}}
    </div>
  </ng-template>
    </ng-container>
  <ng-template #loadingNursesBlock>
    <div class="w-100 d-flex justify-content-center nursesLoader">
      <div class="loader">
      </div>
    </div>
  </ng-template>
</ng-container>

