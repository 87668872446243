import {Component, OnInit, ChangeDetectionStrategy, OnDestroy, ViewChild, AfterViewInit} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {Nurse, NurseFilter} from '@domain/nurse/Nurse';
import {Select, Store} from '@ngxs/store';
import { UserInfo } from '@security/UserInfo';
import {LoadFilteredNurses, UpdateNurseFilter, UpdateNursePagination, UpdateNurseSort} from '@states/nurse/nurses.actions';
import {NursesStateSelector} from '@states/nurse/nurses.selectors';
import {Pagination} from '@utils/pagination/Pagination';
import {PaginationOption} from '@utils/pagination/PaginationOption';
import {SearchbarComponent} from '@utils/searchbar/searchbar.component';
import { KeycloakService } from 'keycloak-angular';
import {Observable, Subject} from 'rxjs';

@Component({
  selector: 'app-annuaire-list',
  templateUrl: './annuaire-list.component.html',
  styleUrls: ['./annuaire-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnuaireListComponent extends UserInfo implements OnInit, OnDestroy {

  private destroy: Subject<boolean> = new Subject<boolean>();
  @Select(NursesStateSelector.filteredNurses) nurses$: Observable<Pagination<Nurse>>;
  @Select(NursesStateSelector.paginationOptions) paginationOptions$: Observable<PaginationOption>;
  @Select(NursesStateSelector.filteredNursesLoaded) filteredNursesLoaded$: Observable<boolean>;
  @Select(NursesStateSelector.filter) filter$: Observable<NurseFilter>;
  @Select(NursesStateSelector.filterName) filterName$: Observable<string>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(SearchbarComponent) search: SearchbarComponent;

  constructor(public override keycloakService: KeycloakService, private store: Store) {
    super(keycloakService);
  }

  ngOnInit(): void {

    this.store.dispatch(new LoadFilteredNurses());
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

  onChangePage(pe: PageEvent): void {
    this.store.dispatch(
      new UpdateNursePagination({
        pageIndex: pe.pageIndex,
        pageSize: pe.pageSize,
      })
    );
  }

  searchChanged(search: string) {
    this.store.dispatch(new UpdateNurseFilter({search}, {pageIndex: 0}))
  }

  goBack():void {

  }

  updateSort(columnName: string): void {
    this.store.dispatch(new UpdateNurseSort(columnName));
  }
}
