import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AbstractControl, AsyncValidatorFn, ValidationErrors} from '@angular/forms';
import {PreleveurCabinet} from '@domain/cabinet/cabinet';
import {Adress, ProfileEnum, ResultDiffusion, User, UserScheduleException} from '@domain/user/user';
import {Document} from '@domain/samples/sample';
import {environment} from '@environment/environment';
import {UserFilter} from '@features/users/users-filter';
import {NO_SNACKBAR_CONTEXT} from '@utils/interceptor/error.interceptor';
import {Pagination} from '@utils/pagination/Pagination';
import {PaginationOption, SortDirectionEnum} from '@utils/pagination/PaginationOption';
import {KeycloakService} from 'keycloak-angular';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(public http: HttpClient, public keycloakService: KeycloakService) {}

  get(uuid: string, showErrorMessage: boolean = true): Observable<User> {
    return this.http.get<User>(environment.api + '/users/' + uuid, {context: NO_SNACKBAR_CONTEXT});
  }

  findAll(showErrorMessage: boolean = true): Observable<User[]> {
    return this.http.get<User[]>(environment.api + '/users', {context: NO_SNACKBAR_CONTEXT});
  }

  findByFiltersPaging(filter: UserFilter, paginationOption: PaginationOption): Observable<Pagination<User>> {

    let params_str: string[] = [];
    if(filter.name){
       params_str.push('search~' + filter.name);
    }
    if (this.keycloakService.getKeycloakInstance().hasRealmRole(ProfileEnum.TOURNEE_MANAGER) || this.keycloakService.getKeycloakInstance().hasRealmRole(ProfileEnum.SECRETAIRE)) {
      params_str.push('profile:[' + ProfileEnum.PATIENT + ']');
    } else if (filter.profile && filter.profile?.length > 0) {
      params_str.push('profile:[' + filter.profile + ']');
    }
    let params: HttpParams = new HttpParams()
      .set('page', paginationOption.pageIndex!)
      .set('size', paginationOption.pageSize!);

   if (params_str.length>0) {
     params = params.set('q', params_str.join(','));
   }
    if(paginationOption.sortByColumnName) {
     params = params.set('sort', paginationOption.sortDirection === SortDirectionEnum.ASC ? paginationOption.sortByColumnName : '-' + paginationOption.sortByColumnName);
    }
    return this.http.get<Pagination<User>>(environment.api + '/users?format=LIGHT', { params, context: NO_SNACKBAR_CONTEXT, });
  }

  findPreleveurs(filter: string, paginationOption: PaginationOption): Observable<Pagination<PreleveurCabinet>> {
    let param_str: string[] = [];
    if (filter) {
      param_str.push('search~' + filter);
    }
    param_str.push('profile:[' + ProfileEnum.PRELEVEUR + ']');

    let params: HttpParams = new HttpParams().set('q', param_str.join(',')).set('page', paginationOption.pageIndex!).set('size', paginationOption.pageSize!);
    return this.http.get<Pagination<PreleveurCabinet>>(environment.api + '/users?format=LIGHT',{params, context: NO_SNACKBAR_CONTEXT});
  }
  findPreleveursOnZone(zoneId: string): Observable<Pagination<User>> {
    let params: HttpParams = new HttpParams()
      .set('q', 'affectedOnZones:[' + zoneId + ']');
    return this.http.get<Pagination<User>>(environment.api + '/users?format=LIGHT', { params, context: NO_SNACKBAR_CONTEXT, });
  }

  create(user: User, showErrorMessage: boolean = true): Observable<User> {
    return this.http.post(environment.api + '/users', user, {context: NO_SNACKBAR_CONTEXT});
  }

  update(id: string, user: User, showErrorMessage: boolean = true): Observable<User> {
    return this.http.put(environment.api + '/users/' + id, user, {context: NO_SNACKBAR_CONTEXT});
  }

  delete(id: string, showErrorMessage: boolean = true): Observable<Object> {
    return this.http.delete(environment.api + '/users/' + id, {context: NO_SNACKBAR_CONTEXT});
  }

  suspendUser(id: string): Observable<Object> {
    return this.http.delete(environment.api + '/users/suspend/' + id, {context: NO_SNACKBAR_CONTEXT});
  }

  checkPhone(phone: string): Observable<boolean> {
    if (phone) {
      phone = phone.replace(/\s+/g, ''); // remove space before sending it
    }
    const params: HttpParams = new HttpParams().set('phone', phone);
    return this.http.get<boolean>(environment.api + '/users/check-phone', {context: NO_SNACKBAR_CONTEXT, params});
  }

  checkEmail(email: string): Observable<boolean> {
    const params: HttpParams = new HttpParams().set('email', email);
    return this.http.get<boolean>(environment.api + '/users/check-email', {context: NO_SNACKBAR_CONTEXT, params});
  }

  patch(id: string, user: User): Observable<User> {
    return this.http.patch(environment.api + '/users/' + id, user, {context: NO_SNACKBAR_CONTEXT});
  }

  createException(preleveurId: string, exception: UserScheduleException) {
    return this.http.post<UserScheduleException>(environment.api + `/users/${preleveurId}/user-schedule-exception`, exception, {context: NO_SNACKBAR_CONTEXT})
  }

  getUserDocuments(id: string): Observable<Document[]> {
    const params: HttpParams = new HttpParams().set('userId', id);
    return this.http.get<Document[]>(environment.api + '/documents', {params: params, context: NO_SNACKBAR_CONTEXT});
  }
  getDomicileAddress(id: string): Observable<Adress> {
    return this.http.get(environment.api + '/users/' + id + '/domicile', {context: NO_SNACKBAR_CONTEXT});
  }

  getResultDiffusion(id: string): Observable<ResultDiffusion> {
    return this.http.get(environment.api + '/users/' + id + '/resultDiffusion', {context: NO_SNACKBAR_CONTEXT});
  }

  dissociatePreleveur(patientId: string): Observable<User> {
    return this.http.delete(environment.api + '/users/dissociate-preleveur/' + patientId, {context: NO_SNACKBAR_CONTEXT});
  }
}
