<ng-container *transloco="let t">
<div mat-dialog-title>

    <span>{{ 'modal.confirm.title.' + data.title | transloco }}</span>


<!--	<button class="btn-close" (click)="closeModal(false)">-->
<!--		<mat-icon>close</mat-icon>-->
<!--	</button>-->
</div>
<div mat-dialog-content>
	<div>{{ 'modal.confirm.body.' + data.body | transloco:data.params}} </div>
</div>
<div mat-dialog-actions>
	<button mat-raised-button color="accent"  (click)="closeModal(false)">{{ 'app.cancel' | transloco}}</button>
	<button mat-raised-button color="warn" (click)="closeModal(true)">{{ 'app.yes' | transloco}}</button>
</div>
</ng-container>
