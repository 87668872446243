<div class="padding-3 flex-container">
  {{ "messages.info.global.var" | transloco: {variable: "Hello world"} }} - {{ "messages.info.global.loggedIn" | transloco }}
</div>
<ng-container *transloco="let t; read: 'messages.info.global'">
  {{t('home')}}
</ng-container>

<span [innerHTML]="test | safizer: 'html'"></span>

<form [formGroup]="group">
<mat-form-field appearance="fill">
  <mat-label>Fill form field</mat-label>
  <input matInput placeholder="Placeholder" type="text" formControlName="name">
</mat-form-field>

<mat-form-field appearance="fill">
  <mat-label>Fill form field</mat-label>
  <input matInput placeholder="Placeholder" type="text" formControlName="tel">
  <mat-hint>Hint</mat-hint>
</mat-form-field>

<mat-form-field appearance="fill">
  <mat-label>Fill form field</mat-label>
  <input matInput placeholder="Placeholder" type="text" formControlName="email">
</mat-form-field>

  <button (click)="validate()" type="submit">submit</button>
</form>

<div class="welcome">
  <ng-container *ngIf="currentUser$ | async as currentUser">
    Welcome <span class="welcome-mail">{{currentUser.email}}</span>
  </ng-container>
</div>
<div>
  <button (click)="logout()">Logout</button>
</div>

<a class="padding-vertical-1 display-block" href="kantysbio://localhost?t=123-5432">Ouvrir l’app mobile (accessible par
  un clic dessus via le périphérique qui à l’application installée)</a>

<div><strong>Kecyloak user :</strong> {{user | json}}</div>
<div><strong>API user :</strong> {{userResponse | json}}</div>

<ng-container *ngIf="latestNotifications$ | async as latestNotifications">
  Dernières notifications :
  
  <div *ngFor="let latestNotification of latestNotifications">
    {{ latestNotification.notification?.title }} | {{ latestNotification.notification?.message }} | {{ latestNotification.sentAt }}
  </div>
</ng-container>

<h1>Exemple design</h1>
<h1>
  HOME H1
</h1>
<h2>
  HOME H2
</h2>
<h3>
  HOME H3
</h3>
<h4>
  HOME H4
</h4>
<h5>
  HOME H5
</h5>
<h6>
  HOME H6
</h6>
<div>
  <span>XY Grid</span>
  <div class="grid-x">
    <div class="cell">full width cell</div>
    <div class="cell">full width cell</div>
  </div>
  <div class="grid-x">
    <div class="cell auto">Auto size cell</div>
    <div class="cell auto">Auto size cell</div>
  </div>
  <div class="grid-x">
    <div class="cell medium-6 large-4">12/6/4 cells</div>
    <div class="cell medium-6 large-8">12/6/8 cells</div>
  </div>
</div>

<mat-form-field appearance="fill">
  <mat-label>Fill form field</mat-label>
  <input matInput placeholder="Placeholder">
  <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
  <mat-hint>Hint</mat-hint>
</mat-form-field>

<mat-form-field appearance="fill">
  <mat-label>N° téléphone</mat-label>
  <mat-icon matPrefix>phone</mat-icon>
  <input matInput>
</mat-form-field>

<mat-form-field appearance="fill">
  <mat-label>Email</mat-label>
  <mat-icon matPrefix>alternate_email</mat-icon>
  <input matInput placeholder="Placeholder">
</mat-form-field>

<mat-radio-group aria-label="Select an option">
  <mat-radio-button value="1">Option 1</mat-radio-button>
  <mat-radio-button value="2">Option 2</mat-radio-button>
</mat-radio-group>

<mat-form-field appearance="fill">
  <mat-label>Choose a date</mat-label>
  <input [matDatepicker]="picker" matInput>
  <mat-hint>DD/MM/YYYY</mat-hint>
  <mat-datepicker-toggle [for]="picker" matPrefix></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>

<section class="example-section">
  <mat-checkbox class="example-margin">Check me!</mat-checkbox>
  <mat-checkbox [disabled]="true" class="example-margin">Disabled</mat-checkbox>
</section>

<mat-form-field appearance="fill">
  <mat-label>Cars</mat-label>
  <mat-select required>
    <mat-option value="volvo">Volvo</mat-option>
    <mat-option value="saab">Saab</mat-option>
    <mat-option value="mercedes">Mercedes</mat-option>
    <mat-option value="audi">Audi</mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field appearance="fill">
  <mat-label>Cars</mat-label>
  <select matNativeControl required>
    <option value="volvo">Volvo</option>
    <option value="saab">Saab</option>
    <option value="mercedes">Mercedes</option>
    <option value="audi">Audi</option>
  </select>
</mat-form-field>

<section>
  <div class="example-label">Basic</div>
  <div class="example-button-row">
    <button mat-button>Basic</button>
    <button color="primary" mat-button>Primary</button>
    <button color="accent" mat-button>Accent</button>
    <button color="warn" mat-button>Warn</button>
    <button disabled mat-button>Disabled</button>
    <a href="https://www.google.com/" mat-button target="_blank">Link</a>
  </div>
</section>
<mat-divider></mat-divider>
<section>
  <div class="example-label">Raised</div>
  <div class="example-button-row">
    <button mat-raised-button>Basic</button>
    <button color="primary" mat-raised-button>Primary</button>
    <button color="accent" mat-raised-button>Accent</button>
    <button color="warn" mat-raised-button>Warn</button>
    <button disabled mat-raised-button>Disabled</button>
    <a href="https://www.google.com/" mat-raised-button target="_blank">Link</a>
  </div>
</section>
<mat-divider></mat-divider>
<section>
  <div class="example-label">Stroked</div>
  <div class="example-button-row">
    <button mat-stroked-button>Basic</button>
    <button color="primary" mat-stroked-button>Primary</button>
    <button color="accent" mat-stroked-button>Accent</button>
    <button color="warn" mat-stroked-button>Warn</button>
    <button disabled mat-stroked-button>Disabled</button>
    <a href="https://www.google.com/" mat-stroked-button target="_blank">Link</a>
  </div>
</section>
<mat-divider></mat-divider>
<section>
  <div class="example-label">Flat</div>
  <div class="example-button-row">
    <button mat-flat-button>Basic</button>
    <button color="primary" mat-flat-button>Primary</button>
    <button color="accent" mat-flat-button>Accent</button>
    <button color="warn" mat-flat-button>Warn</button>
    <button disabled mat-flat-button>Disabled</button>
    <a href="https://www.google.com/" mat-flat-button target="_blank">Link</a>
  </div>
</section>
<mat-divider></mat-divider>
<section>
  <div class="example-label">Icon</div>
  <div class="example-button-row">
    <div class="example-flex-container">
      <button aria-label="Example icon button with a vertical three dot icon" mat-icon-button>
        <mat-icon>more_vert</mat-icon>
      </button>
      <button aria-label="Example icon button with a home icon" color="primary" mat-icon-button>
        <mat-icon>home</mat-icon>
      </button>
      <button aria-label="Example icon button with a menu icon" color="accent" mat-icon-button>
        <mat-icon>menu</mat-icon>
      </button>
      <button aria-label="Example icon button with a heart icon" color="warn" mat-icon-button>
        <mat-icon>favorite</mat-icon>
      </button>
      <button aria-label="Example icon button with a open in new tab icon" disabled mat-icon-button>
        <mat-icon>open_in_new</mat-icon>
      </button>
    </div>
  </div>
</section>
<mat-divider></mat-divider>
<section>
  <div class="example-label">FAB</div>
  <div class="example-button-row">
    <div class="example-flex-container">
      <div class="example-button-container">
        <button aria-label="Example icon button with a delete icon" color="primary" mat-fab>
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <div class="example-button-container">
        <button aria-label="Example icon button with a bookmark icon" color="accent" mat-fab>
          <mat-icon>bookmark</mat-icon>
        </button>
      </div>
      <div class="example-button-container">
        <button aria-label="Example icon button with a home icon" color="warn" mat-fab>
          <mat-icon>home</mat-icon>
        </button>
      </div>
      <div class="example-button-container">
        <button aria-label="Example icon button with a heart icon" disabled mat-fab>
          <mat-icon>favorite</mat-icon>
        </button>
      </div>
    </div>
  </div>
</section>
<mat-divider></mat-divider>
<section>
  <div class="example-label">Mini FAB</div>
  <div class="example-button-row">
    <div class="example-flex-container">
      <div class="example-button-container">
        <button aria-label="Example icon button with a menu icon" color="primary" mat-mini-fab>
          <mat-icon>menu</mat-icon>
        </button>
      </div>
      <div class="example-button-container">
        <button aria-label="Example icon button with a plus one icon" color="accent" mat-mini-fab>
          <mat-icon>plus_one</mat-icon>
        </button>
      </div>
      <div class="example-button-container">
        <button aria-label="Example icon button with a filter list icon" color="warn" mat-mini-fab>
          <mat-icon>filter_list</mat-icon>
        </button>
      </div>
      <div class="example-button-container">
        <button aria-label="Example icon button with a home icon" disabled mat-mini-fab>
          <mat-icon>home</mat-icon>
        </button>
      </div>
    </div>
  </div>
</section>

<h1>{{'patient.test' | transloco}}</h1>
<h2>Documents</h2>

<mat-list class="full-size" role="list">
  <mat-list-item role="listitem">Item 1</mat-list-item>
  <mat-list-item role="listitem">Item 2</mat-list-item>
  <mat-list-item role="listitem">Item 3</mat-list-item>
</mat-list>

<button [matMenuTriggerFor]="menu" mat-button>Menu</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item>Item 1</button>
  <button mat-menu-item>Item 2</button>
</mat-menu>
