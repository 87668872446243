import {Component, OnInit, ChangeDetectionStrategy, OnDestroy} from '@angular/core';
import {PageEvent} from '@angular/material/paginator';
import {Cabinet} from '@domain/cabinet/cabinet';
import {Select, Store} from '@ngxs/store';
import {
  LoadFilteredCabinets,
  UpdateCabinetsFilter,
  UpdateCabinetsPaginationOption,
  UpdatePreleveursPaginationOption,
} from '@states/cabinets/cabinets.actions';
import {CabinetsStateSelectors} from '@states/cabinets/cabinets.selectors';
import {Pagination} from '@utils/pagination/Pagination';
import {PaginationOption} from '@utils/pagination/PaginationOption';
import {Observable, Subject, takeUntil} from 'rxjs';

@Component({
  selector: 'app-cabinet-list',
  templateUrl: './cabinet-list.component.html',
  styleUrls: ['./cabinet-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CabinetListComponent implements OnInit, OnDestroy {
  private destroy: Subject<boolean> = new Subject<boolean>();
  @Select(CabinetsStateSelectors.filteredCabinets) filteredCabinets$: Observable<Pagination<Cabinet>>;
  @Select(CabinetsStateSelectors.cabinetsPaginationOption) paginationOption$: Observable<PaginationOption>;
  @Select(CabinetsStateSelectors.cabinetsFilter) filter$: Observable<string>;
  constructor(private store: Store) { }

  ngOnInit(): void {
    this.filteredCabinets$.pipe(takeUntil(this.destroy)).subscribe()
    this.store.dispatch(new LoadFilteredCabinets());
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

  updateFilter(event: string): void {
    this.store.dispatch(new UpdateCabinetsFilter(event));
  }

  onChangePage(pe: PageEvent): void {
    this.store.dispatch(
      new UpdateCabinetsPaginationOption({
        pageIndex: pe.pageIndex,
        pageSize: pe.pageSize,
      })
    );
  }
  splitInColumn = (arr: any[], size: number): any[] => (arr.length > size ? [arr.slice(0, size), ...this.splitInColumn(arr.slice(size), size)] : [arr]);
}
