<button mat-icon-button class="close-button" (click)="close()">
  <mat-icon color="warn">close</mat-icon>
</button>
<div mat-dialog-title class="title">{{'users.association.title' | transloco}}</div>
<div mat-dialog-content>
  <div class="subtitle">{{'users.association.associatedPreleveur' | transloco}}</div>
  <ng-container *ngIf="currentAssociatedPreleveur$ | async as currentAssociatedPreleveur else noPreleveur">
    <div class="d-flex flex-row align-items-center">
      <div *ngIf="currentAssociatedPreleveur.id != null else noPreleveur">{{currentAssociatedPreleveur?.firstname + " " + currentAssociatedPreleveur?.lastname}}</div>
      <button class="ms-3" *ngIf="currentAssociatedPreleveur.id" type="button" mat-icon-button (click)="delete()">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </ng-container>
  <ng-template #noPreleveur>{{'users.association.noAssociatedPreleveur' | transloco}}</ng-template>
  <div class="mt-5">
    <p class="subtitle">{{'users.association.associatePreleveur' | transloco}}</p>
    <app-searchbar [placeholder]="'cabinets.searchPreleveur' | transloco " [search$]="filter$"
                   (valueChange)="updateFilter($event)"></app-searchbar>
    <ng-container *ngIf="preleveurs$ | async as filteredPreleveurs">
      <ng-container *ngIf="paginationOption$ | async as paginationOption">
        <mat-button-toggle-group vertical="true" class="d-flex" [formControl]="associatedPreleveur">
          <mat-button-toggle [value]="preleveur.id" *ngFor="let preleveur of filteredPreleveurs.content" (change)="select(preleveur)">
            {{preleveur.firstname + " " + preleveur.lastname}}
          </mat-button-toggle>
        </mat-button-toggle-group>
        <div *ngIf="filteredPreleveurs.content.length == 0">{{'cabinets.noPreleveursFound' | transloco}}</div>
        <mat-paginator [length]="paginationOption?.length" [pageSize]="paginationOption.pageSize"
                       [pageIndex]="paginationOption.pageIndex"  [pageSizeOptions]="paginationOption?.pageSizeOption || [10,20,30]"
                       (page)="onChangePage($event)"></mat-paginator>
      </ng-container>
    </ng-container>
  </div>
</div>
<div mat-dialog-actions class="d-flex justify-content-center mt-3">
  <button mat-raised-button color="primary" (click)="save()">{{'app.save' | transloco}}</button>
</div>
