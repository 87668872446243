import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AdresseApi, SearchType} from '@domain/geoapi/AdresseApi';
import {Commune} from '@domain/geoapi/Commune';
import {Adress} from '@domain/user/user';
import {environment} from '@environment/environment';
import {EMPTY, firstValueFrom, Observable, of, take} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GeoApiService {
  private _baseUrl: string = 'https://geo.api.gouv.fr/';
  private _communes!: Commune[];

  constructor(private _http: HttpClient) {}

  /**
   * Autocomplete Commune by Postal code
   * @param cp
   * @returns only for a valid Postal code
   */
  autocompleteCommunesByCp(cp: string): Observable<Commune[]> {
    const params: HttpParams = new HttpParams().set('codePostal', cp).set('fields', 'codesPostaux,nom');
    return this._http.get<Commune[]>(this._baseUrl + 'communes', {params}).pipe(
      map(communes => {
        // keep only the postal code given in parameters
        communes.forEach(c => (c.codesPostaux = [cp]));
        return communes;
      })
    );
  }

  /**
   * @param input
   * @returns
   */
  /**
   * Search city by Name
   * If City as multiple Postal code then it will return a entry for each
   * @param input city name
   * @param limit - default 5 - number of unique city returned
   * @param boost - default true - order city by population
   * @returns
   */
  autocompleteCommunesBySearch(input: string, limit: number = 5, boost: boolean = true): Observable<Commune[]> {
    let params: HttpParams = new HttpParams().set('nom', input).set('fields', 'codesPostaux,nom').set('limit', limit);

    if (boost) {
      params = params.set('boost', 'population');
    }

    return this._http.get<Commune[]>(this._baseUrl + 'communes', {params}).pipe(
      map((communes: Commune[]) => {
        const newCommunes: Commune[] = [];
        communes.map(commune => {
          commune.codesPostaux?.forEach(cp => {
            newCommunes.push({nom: commune.nom, codesPostaux: [cp]});
          });
        });
        return newCommunes;
      })
    );
  }

  async getAllCommunes(): Promise<Commune[]> {
    if (this._communes) {
      return this._communes;
    } else {
      const communes: Commune[] = await firstValueFrom(this._http.get<Commune[]>(this._baseUrl + 'communes'));
      this._communes = communes;
      return this._communes;
    }
  }

  getCoordOfAddress(address: Adress): Observable<AdresseApi> {
    let adr: string[] = [];
    if (address.adr1 && address.cp && address.ville) {
      adr = [address.adr1, address.cp, address.ville];
    }
    return this.search(adr.join(','), SearchType.HOUSENUMBER).pipe(
      take(1),
      switchMap((res: AdresseApi[]) => {
        return res.length ? of(res[0]) : EMPTY;
      })
    );
  }

  search(input: string, type: SearchType, limit: number = 5, autocomplete: number = 1): Observable<AdresseApi[]> {
    if (!input) {
      return of([]);
    }
    const params: HttpParams = new HttpParams().set('q', input).set('type', type).set('limit', limit).set('autocomplete', autocomplete);
    return this._http.get<AdresseApi[]>(environment.api + '/external/search', {params}).pipe(
      catchError(() => {
        return EMPTY;
      })
    );
  }
}
