/**
 * Confirm message enum
 */
export enum ModalConfirmMessageEnum {
	CONFIRM,
	CANCEL,
	WARNING,
	DELETE_CHECK,
  DELETE_LABO
}
