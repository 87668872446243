import {Nurse, NurseFilter} from '@domain/nurse/Nurse';
import {Selector} from '@ngxs/store';
import {NursesState, NursesStateModel} from '@states/nurse/nurses.state';
import {Pagination} from '@utils/pagination/Pagination';
import {PaginationOption} from '@utils/pagination/PaginationOption';

export class NursesStateSelector {
  @Selector([NursesState])
  static currentNurse(state: NursesStateModel): Nurse | undefined {
    return state.currentNurse;
  }

  @Selector([NursesState])
  static filteredNurses(state: NursesStateModel): Pagination<Nurse> | undefined {
    return state.filteredNurses;
  }

  @Selector([NursesState])
  static paginationOptions(state: NursesStateModel): PaginationOption {
    return state.paginationOptions;
  }

  @Selector([NursesState])
  static filteredNursesLoaded(state: NursesStateModel): boolean | undefined {
    return state.filteredNursesLoaded;
  }

  @Selector([NursesState])
  static filter(state: NursesStateModel): NurseFilter | undefined {
    return state.nurseFilter;
  }

  @Selector([NursesState])
  static filterName(state: NursesStateModel): string | undefined {
    return state.nurseFilter.search;
  }
}

