import {ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Labo} from '@domain/labo/Labo';
import {LaboFormUser} from '@domain/user/user';
import {Select, Store} from '@ngxs/store';
import {AddSelectedLabos, ChangePossibleLabo, RemoveSelectedLabos} from '@states/user/users.actions';
import {UsersStateSelector} from '@states/user/users.selectors';
import {debounceTime, distinctUntilChanged, Observable, Subject, takeUntil} from 'rxjs';

@Component({
  selector: 'app-laboratory-select',
  templateUrl: './laboratory-select.component.html',
  styleUrls: ['./laboratory-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LaboratorySelectComponent implements OnInit, OnDestroy {

  @Input() listLabo!: LaboFormUser[] | undefined;
  @Select(UsersStateSelector.possibleLabos) labos$: Observable<Labo[]>;

  @ViewChild('search') search: ElementRef;
  searchControl: FormControl = new FormControl('');
  laboInput$: Subject<string> = new Subject<string>();

  private destroy: Subject<boolean> = new Subject<boolean>();

  constructor(private store: Store) {
  }

  ngOnInit(): void {
    if (!this.listLabo) {
      this.listLabo = [];
    }
    this.store.dispatch(new ChangePossibleLabo(''));

    this.laboInput$.pipe(
      takeUntil(this.destroy),
      debounceTime(500), // wait 300ms after the last event before emitting last event
      distinctUntilChanged()) // only emit if value is different from previous value
      .subscribe(search => this.store.dispatch(new ChangePossibleLabo(search)));
  }

  inputLaboSearch(event: any): void {
    this.laboInput$.next(event.target.value);
  }

  select(laboratory: Labo): void {
    const labo: LaboFormUser = {
      id: laboratory.id,
      name: laboratory.name,

    }
    this.store.dispatch(new AddSelectedLabos(laboratory));
    this.searchControl.setValue('');
    this.search.nativeElement.blur();
  }

  deleteLabo(id: string | undefined): void {
    if (id) {
      this.store.dispatch(new RemoveSelectedLabos(id));
    }
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }
}
