<div *transloco="let t; read: 'samples.checks'">
  <div class="d-flex flex-column h-100 " #container>
    <div class="grey w-50">
      <mat-form-field appearance="fill" class="reduce w-75" (click)="container.scrollIntoView({behavior: 'smooth'});">
        <mat-icon matPrefix class="grey">search</mat-icon>
        <mat-label>{{t('search')}}</mat-label>
        <input
          type="text"
          matInput
          (input)="input$.next(filter.value)"
          [formControl]="filter"
          autocomplete="off">
        <button *ngIf="filter.value" class="grey" matSuffix mat-icon-button aria-label="Clear"
                (click)="resetField()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="list-checks">
      <div class="w-75 row item" *ngFor="let check of filtered$ | async; let last = last">
        <mat-checkbox class="col-10" [class.last]="last" [value]="check.id" [checked]="isChecked(check)"
                      (change)="onCheckChange($event)">
          <div class="d-flex flex-row justify-content-between align-items-center w-100">
            <span>{{check.libelle}}</span>
          </div>
          <div class="description">
          <span class="small">
            {{check.description}}
          </span>
          </div>
        </mat-checkbox>
        <div class="col-1">{{ t('price',{price: check.price})}}</div>
      </div>
    </div>
  </div>
</div>

