import {Location} from '@angular/common';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSelectChange} from '@angular/material/select';
import {ActivatedRoute, Router} from '@angular/router';
import {Adress, AssociationModeEnum, LaboFormUser, ProfileEnum, User, UserForm} from '@domain/user/user';
import {AssociatePreleveurDialogComponent} from '@features/users/associate-preleveur-dialog/associate-preleveur-dialog.component';
import {UserSampleEditComponent} from '@features/users/user-sample-edit/user-sample-edit.component';
import {Select, Store} from '@ngxs/store';
import {UserInfo} from '@security/UserInfo';
import {LaboService} from '@service/labo.service';
import {UserService} from '@service/user.service';
import {
  AssociatePreleveur,
  ChangePossibleLabo,
  DeleteOneUser,
  LoadOneNewUser,
  LoadOneUser,
  SaveOneUser, SavePreleveurAssociation,
  SuspendOneUser,
} from '@states/user/users.actions';
import {UsersStateSelector} from '@states/user/users.selectors';
import {DialogConfirmationComponent} from '@utils/confirmation-dialog/dialog-confirmation.component';
import {RegexPatternValidatorEnum} from '@utils/enum/regex.enum';
import {PaginationOption} from '@utils/pagination/PaginationOption';
import {KeycloakService} from 'keycloak-angular';
import {map, Observable, of, Subject, takeUntil} from 'rxjs';

@Component({
  selector: 'app-edit-users',
  templateUrl: './edit-users.component.html',
  styleUrls: ['./edit-users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditUsersComponent extends UserInfo implements OnInit, OnDestroy {
  @Select(UsersStateSelector.currentUser) user$: Observable<UserForm>;
  @Select(UsersStateSelector.chosenLabos) chosenLabos$: Observable<LaboFormUser[]>;
  telPattern: RegexPatternValidatorEnum = RegexPatternValidatorEnum.TEL;
  emailPattern: RegexPatternValidatorEnum = RegexPatternValidatorEnum.EMAIL;
  secuPattern: RegexPatternValidatorEnum = RegexPatternValidatorEnum.SECU;
  autosuggestLabo$: Observable<any>;
  paginationOption: PaginationOption;
  userForm!: FormGroup;
  profiles: ProfileEnum[] = Object.values(ProfileEnum).filter(value => value !== ProfileEnum.PATIENT);
  profileEnum: typeof ProfileEnum = ProfileEnum;
  associationModeEnum: typeof AssociationModeEnum = AssociationModeEnum;
  profilesWithoutAdmin: ProfileEnum[] = this.profiles.filter(value => value !== ProfileEnum.ADMIN && value !== ProfileEnum.SECRETAIRE);
  readonly!: boolean;
  private destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    public override keycloakService: KeycloakService,
    private _route: ActivatedRoute,
    private _userService: UserService,
    private _laboService: LaboService,
    private _location: Location,
    private store: Store,
    public dialog: MatDialog,
    private router: Router
  ) {
    super(keycloakService);
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

  ngOnInit(): void {
    this.createForm();
    this._route.paramMap.pipe(takeUntil(this.destroy)).subscribe(val => {
      const id: string | null = val.get('id');
      if (id) {
        this.store.dispatch(new LoadOneUser(id));
      } else {
        this.store.dispatch(new LoadOneNewUser());
      }
    });

    this.readonly = true;
    this._route.data.pipe(takeUntil(this.destroy)).subscribe(data => {
      this.readonly = data['readonly'];
    });

    this.user$.pipe(takeUntil(this.destroy)).subscribe(user => {
      if (user) {
        this.userForm.get('id')?.setValue(user.id);
        this.userForm.get('suspended')?.setValue(user.suspended);
        this.userForm.get('identity')?.patchValue(user.identity);
        this.userForm.get('resultDiffusion')?.patchValue(user.resultDiffusion);
        this.userForm.get('associationMode')?.patchValue(user.associationMode);
        if (user?.adresses?.length && user?.adresses?.length > 0) {
          this.userForm.get('adresses')?.setValue(user.adresses[0]);
        }
        this.userForm.get('informations')?.patchValue(user.informations);

        if ((this.isManager || this.isSecretaire) && !this.userForm.get('id')?.value) {
          this.userForm.get('informations')?.get('profile')?.setValue(ProfileEnum.PATIENT);
        }
        if ([ProfileEnum.PERSONNEL_LABO, ProfileEnum.TOURNEE_MANAGER, ProfileEnum.ADMIN, ProfileEnum.SECRETAIRE].includes(this.userForm.get('informations')?.get('profile')?.value)) {
          this.userForm.get('informations')?.get('email')?.addValidators(Validators.required);
          this.userForm.get('informations')?.get('phone')?.removeValidators(Validators.required);
        } else if (this.userForm.get('informations')?.get('profile')?.value === ProfileEnum.PRELEVEUR) {
          this.userForm.get('associationMode')?.addValidators(Validators.required);
          this.userForm.get('informations')?.get('phone')?.removeValidators(Validators.required);
          this.userForm.get('informations')?.get('email')?.removeValidators(Validators.required);
        } else {
          this.userForm.get('informations')?.get('phone')?.removeValidators(Validators.required);
          this.userForm.get('informations')?.get('email')?.removeValidators(Validators.required);
        }
        this.userForm.get('informations')?.get('phone')?.updateValueAndValidity();
        this.userForm.get('informations')?.get('email')?.updateValueAndValidity();

        this.updateAddressRequired();

        if (this.readonly) {
          this.userForm.disable();
        } else {
          this.userForm.enable();
        }
        if (!this.isAdmin && this.userForm.get('id')?.value) {
          this.userForm.get('informations')?.get('profile')?.disable();
        } else {
          this.userForm.get('informations')?.get('profile')?.enable();
        }
      }
    });

    this.store.dispatch(new ChangePossibleLabo(''));
  }

  onSubmit(): void {
    const user: UserForm = this.userForm.value;
    if (user.informations) {
      user.informations.profile = this.userForm.get('informations')?.get('profile')?.value;
    }
    const adresse: any = [];
    if (this.userForm.get('adresses')?.get('adr1')?.value !== '') {
      adresse.push(this.userForm.get('adresses')?.value);
    }
    user.adresses = adresse;
    if (user.informations?.profile === ProfileEnum.TOURNEE_MANAGER) {
      user.informations.phone = '';
    }
    if (user.informations) {
      user.informations.phone = user.informations?.phone?.replace(/\s+/g, '');
    }
    if (user.informations?.profile === ProfileEnum.PATIENT) {
      user.informations.laboratory = undefined;
    } else {
      user.resultDiffusion = undefined;
    }
    this.store.dispatch(new SaveOneUser(user));
  }

  deleteUser(id: string): void {
    const dialogRef = this.dialog.open(DialogConfirmationComponent, {
      data: {
        title: 'Confirmation de supression',
        content: 'Voulez-vous vraiment supprimer cet utilisateur ?',
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        if (this.user?.id) {
          this.store.dispatch(new DeleteOneUser(id));
        }
      }
    });
  }

  goBack(): void {
    this.store.dispatch(new LoadOneNewUser());
    if (this.readonly) {
      this._location.back();
    } else {
      this.router.navigate(['/users']);
    }
  }

  createForm(): void {
    this.userForm = new FormGroup({
      id: new FormControl(''),
      suspended: new FormControl(''),
      identity: new FormGroup({
        firstname: new FormControl('', [Validators.required]),
        lastname: new FormControl('', [Validators.required]),
        birthName: new FormControl(''),
        birthDate: new FormControl('', [Validators.required]),
        civility: new FormControl(''),
        gender: new FormControl(''),
        raisonSociale: new FormControl(''),
        numeroSecu: new FormControl(''),
      }),
      adresses: new FormGroup({
        id: new FormControl(),
        adr1: new FormControl('', Validators.required),
        adr2: new FormControl(''),
        cp: new FormControl('', [Validators.required]),
        commentaire: new FormControl(''),
        ville: new FormControl('', [Validators.required]),
        pays: new FormControl('FR', [Validators.required]),
        domicile: new FormControl(true),
      }),
      informations: new FormGroup(
        {
          profile: new FormControl(ProfileEnum.PRELEVEUR, [Validators.required]),
          email: new FormControl('', {validators: [Validators.email], asyncValidators: [this.emailValidator()]}),
          phone: new FormControl('', {asyncValidators: [this.phoneValidator()]}),
          ipp: new FormControl(''),
        },
        [this.identifierRequired]
      ),
      resultDiffusion: new FormGroup({
        id: new FormControl(),
        resultByInternetServer: new FormControl(true),
        resultByPostal: new FormControl(true),
        resultByMail: new FormControl(true),
        resultDiffusionNurse: new FormControl(true),
      }),
      associationMode: new FormControl(this.associationModeEnum.STRICT),
    });
  }

  identifierRequired(control: AbstractControl): ValidationErrors | null {
    const tel: string = control.get('phone')?.value;
    const email: string = control.get('email')?.value;

    if (!tel && !email) {
      return {identifierRequired: true};
    }
    return null;
  }

  suspend(id: string): void {
    const user: UserForm = this.userForm.value;
    const dialogRef = this.dialog.open(DialogConfirmationComponent, {
      data: {
        title: user.suspended === false ? 'Confirmation de suspension' : 'Confirmation de réactivation',
        content: user.suspended === false ? 'Voulez-vous vraiment suspendre cet utilisateur' : 'Voulez-vous vraiment réactiver cet utilisateur ',
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        if (this.user?.id) {
          this.store.dispatch(new SuspendOneUser(id));
        }
      }
    });
  }

  changeProfil(event: MatSelectChange): void {
    if ([ProfileEnum.PERSONNEL_LABO, ProfileEnum.TOURNEE_MANAGER, ProfileEnum.ADMIN, ProfileEnum.SECRETAIRE].includes(event.value)) {
      this.userForm.get('informations')?.get('email')?.addValidators(Validators.required);
      this.userForm.get('informations')!.get('email')?.setValue(this.userForm.get('informations')!.get('email')?.value);
      this.userForm.get('informations')?.get('phone')?.removeValidators(Validators.required);
    } else if (event.value === ProfileEnum.PATIENT) {
      this.userForm.get('informations')?.get('phone')?.removeValidators(Validators.required);
      this.userForm.get('informations')?.get('email')?.removeValidators(Validators.required);
      const resultByInternetServer: boolean | undefined = this.userForm.get('resultDiffusion')?.get('resultByInternetServer')?.value;
      const resultByMail: boolean | undefined = this.userForm.get('resultDiffusion')?.get('resultByMail')?.value;
      const resultByPostal: boolean | undefined = this.userForm.get('resultDiffusion')?.get('resultByPostal')?.value;
      const resultDiffusionNurse: boolean | undefined = this.userForm.get('resultDiffusion')?.get('resultDiffusionNurse')?.value;
      this.userForm.get('resultDiffusion')?.get('resultByInternetServer')?.setValue(resultByInternetServer !== undefined ? resultByInternetServer : true);
      this.userForm.get('resultDiffusion')?.get('resultByMail')?.setValue(resultByMail !== undefined ? resultByMail : true);
      this.userForm.get('resultDiffusion')?.get('resultByPostal')?.setValue(resultByPostal !== undefined ? resultByPostal : true);
      this.userForm.get('resultDiffusion')?.get('resultDiffusionNurse')?.setValue(resultDiffusionNurse !== undefined ? resultDiffusionNurse : true);
      this.userForm.get('associationMode')?.removeValidators(Validators.required);
    } else if (event.value === ProfileEnum.PRELEVEUR) {
      this.userForm.get('associationMode')?.addValidators(Validators.required);
      this.userForm.get('informations')?.get('phone')?.removeValidators(Validators.required);
      this.userForm.get('informations')?.get('email')?.removeValidators(Validators.required);
    } else {
      this.userForm.get('informations')?.get('phone')?.removeValidators(Validators.required);
      this.userForm.get('informations')?.get('email')?.removeValidators(Validators.required);
    }
    this.userForm.get('informations')?.get('phone')?.updateValueAndValidity();
    this.userForm.get('informations')?.get('email')?.updateValueAndValidity();
    this.updateAddressRequired();
  }

  phoneValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (control.value && !this.userForm.get('id')?.value) {
        return this._userService.checkPhone(control.value).pipe(
          map(res => {
            // if res is true, username exists, return true
            return !res ? {phoneExists: true} : null;
            // NB: Return null if there is no error
          })
        );
      } else {
        return of(null);
      }
    };
  }

  emailValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (control.value && !this.userForm.get('id')?.value) {
        return this._userService.checkEmail(control.value).pipe(
          map(res => {
            // if res is true, username exists, return true
            return !res ? {emailExists: true} : null;
            // NB: Return null if there is no error
          })
        );
      } else {
        return of(null);
      }
    };
  }

  updateAddressRequired(): void {
    const adr1 = this.userForm.get('adresses')?.get('adr1');
    const adr2 = this.userForm.get('adresses')?.get('adr2');
    const cp = this.userForm.get('adresses')?.get('cp');
    const ville = this.userForm.get('adresses')?.get('ville');
    const commentaire = this.userForm.get('adresses')?.get('commentaire');
    const pays = this.userForm.get('adresses')?.get('pays');

    if (
      adr1?.value === '' &&
      adr2?.value === '' &&
      cp?.value === '' &&
      ville?.value === '' &&
      commentaire?.value === '' &&
      [ProfileEnum.PERSONNEL_LABO, ProfileEnum.TOURNEE_MANAGER, ProfileEnum.SECRETAIRE].includes(this.userForm.get('informations')?.get('profile')?.value)
    ) {
      this.userForm.get('adresses')?.get('adr1')?.removeValidators(Validators.required);
      this.userForm.get('adresses')?.get('cp')?.removeValidators(Validators.required);
      this.userForm.get('adresses')?.get('ville')?.removeValidators(Validators.required);
      this.userForm.get('adresses')?.get('pays')?.removeValidators(Validators.required);
    } else {
      this.userForm.get('adresses')?.get('adr1')?.addValidators(Validators.required);
      this.userForm.get('adresses')?.get('cp')?.addValidators(Validators.required);
      this.userForm.get('adresses')?.get('ville')?.addValidators(Validators.required);
      this.userForm.get('adresses')?.get('pays')?.addValidators(Validators.required);
    }

    this.userForm.get('adresses')?.get('adr1')?.updateValueAndValidity();
    this.userForm.get('adresses')?.get('cp')?.updateValueAndValidity();
    this.userForm.get('adresses')?.get('ville')?.updateValueAndValidity();
    this.userForm.get('adresses')?.get('pays')?.updateValueAndValidity();
  }

  createSample(id: string | undefined, adresses: Adress[] | undefined, associatedPreleveur: User | undefined | null) {
    let adresse: Adress | undefined = undefined;
    if (adresses && adresses?.length > 0) {
      adresse = adresses[0];
    }
    if (id) {
      const dialogRef = this.dialog.open(UserSampleEditComponent, {
        width: '1500px',
        data: {
          id: id,
          adresse: adresse,
          associatedPreleveur: associatedPreleveur,
        },
        disableClose: true,
      });
    }
  }

  associatePreleveur(associatedPreleveur: User | null | undefined): void {
    const dialogRef: MatDialogRef<AssociatePreleveurDialogComponent> = this.dialog.open(AssociatePreleveurDialogComponent, {
      width: '1000px',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.store.dispatch(new SavePreleveurAssociation());
      } else {
        this.store.dispatch(new AssociatePreleveur(associatedPreleveur));
      }
    })
  }
}
