import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Commune} from '@domain/geoapi/Commune';
import {GeoApiService} from '@service/geoapi.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {debounceTime, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-nurse-adresse-form',
  templateUrl: './nurse-adresse-form.component.html',
  styleUrls: ['./nurse-adresse-form.component.scss'],
})
export class NurseAdresseFormComponent implements OnInit {
  @Input() formArray!: FormArray;
  /**
   * alternate background color
   */
  @Input() alternate: boolean = false;
  /**
   * If the title should be translate or not
   *
   * default : true
   */
  @Input() translate: boolean = true;
  /**
   * Title of the section
   */
  @Input() title!: string;
  /**
   * If we can remove the adresse
   */
  @Input() canDelete: boolean = false;

  @Output() onDelete: EventEmitter<void> = new EventEmitter<void>();

  @Output() formChanged: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  @Input() formGroup!: FormGroup;

  cpInput$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  villeInput$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  autosuggestVille$!: Observable<any>;
  autosuggestCp$!: Observable<any>;

  constructor(private _fb: FormBuilder, private _geoapi: GeoApiService) {}

  ngOnInit(): void {
    if (this.formArray) {
      this.formArray.push(this.formGroup);
    }

    this.autosuggestVille$ = this.villeInput$.pipe(
      debounceTime(500),
      switchMap(text => this._geoapi.autocompleteCommunesBySearch(text))
    );

    this.autosuggestCp$ = this.cpInput$.pipe(
      debounceTime(500),
      switchMap(text => this._geoapi.autocompleteCommunesByCp(text))
    );
  }

  select(commune: Commune): void {
    if (commune?.codesPostaux?.length) {
      this.formGroup.get('postalCode')?.setValue(commune?.codesPostaux[0]);
      this.formGroup.get('postalCode')?.updateValueAndValidity();
    }
    if (commune.nom) {
      this.formGroup.get('city')?.setValue(commune.nom);
      this.villeInput$.next(commune.nom);
    }
  }

  inputVilleSearch = (event: any): void => this.villeInput$.next(event.target.value);
  inputCpSearch = (event: any): void => this.cpInput$.next(event.target.value);
}
