import { Notification } from '@domain/notification/notification';
import {Selector} from '@ngxs/store';
import { NotificationsState, NotificationsStateModel } from './notifications.state';

export class NotificationsStateSelector {
  @Selector([NotificationsState])
  static latestNotifications(state: NotificationsStateModel): Notification[] {
    return state.latestNotifications;
  }

}
