import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatTableModule} from '@angular/material/table';
import {LaboWorklistComponent} from '@features/samples/labo-worklist/labo-worklist.component';
import {TranslocoModule} from '@ngneat/transloco';
import {NgxsModule} from '@ngxs/store';
import {SamplesState} from '@states/samples/samples.state';
import {SharedModule} from '@utils/shared.module';
import { SamplesRoutingModule } from './samples-routing.module';
import { PlanningSecretaireComponent } from './planning-secretaire/planning-secretaire.component';
import { DialogSampleInfosComponent } from './dialog-sample-infos/dialog-sample-infos.component';
import {MatDividerModule} from '@angular/material/divider';
import { SampleChecksComponent } from './sample-checks/sample-checks.component';
import {MatCheckboxModule} from '@angular/material/checkbox';

@NgModule({
  declarations: [LaboWorklistComponent, SampleChecksComponent, PlanningSecretaireComponent, DialogSampleInfosComponent],
  imports: [
    CommonModule,
    SamplesRoutingModule,
    NgxsModule.forFeature([SamplesState]),
    ReactiveFormsModule,
    TranslocoModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    FormsModule,
    SharedModule,
    MatRadioModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatDatepickerModule,
    MatDividerModule,
    MatCheckboxModule,
  ],
  providers: [],
  exports: [
    SampleChecksComponent,
  ],
})
export class SamplesModule {}

