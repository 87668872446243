import { Injectable } from '@angular/core';
import { ModalConfirmMessageEnum } from '../modal/modal-confirm/modal-confirm-message-enum';
import { Observable } from 'rxjs';
import { ModalConfirmComponent } from '../modal/modal-confirm/modal-confirm.component';
import { ModalErrorComponent } from '../modal/modal-error/modal-error.component';
import { ModalErrorMessageEnum } from '../modal/modal-error/modal-error-message-enum';
import { ModalInfoMessageEnum } from '../modal/modal-info/modal-info-message-enum';
import { ModalInfoComponent } from '../modal/modal-info/modal-info.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Injectable()
export class ModalService {
	constructor(private _dialog: MatDialog) {

	}

	confirm(title: ModalConfirmMessageEnum, body: ModalConfirmMessageEnum, params?: any): Observable<boolean> {
		return this._dialog.open(ModalConfirmComponent, <MatDialogConfig>{
			data: { title: ModalConfirmMessageEnum[title], body: ModalConfirmMessageEnum[body], params: params },
		}).afterClosed();
	}

	error(title: ModalErrorMessageEnum, body: ModalErrorMessageEnum): Observable<void> {
		return this._dialog.open(ModalErrorComponent, <MatDialogConfig>{
			data: { title: ModalErrorMessageEnum[title], body: ModalErrorMessageEnum[body] },
		}).afterClosed();
	}

	info(title: ModalInfoMessageEnum, body: ModalInfoMessageEnum): Observable<void> {
		return this._dialog.open(ModalInfoComponent, <MatDialogConfig>{
			data: { title: ModalInfoMessageEnum[title], body: ModalInfoMessageEnum[body] },
		}).afterClosed();
	}

}
