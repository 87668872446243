export class AdresseApi {
  type!: string;
  geometry!: {
    type: string;
    coordinates: number[];
  };
  properties!: {
    city: string;
    street: string;
    district: string;
    citycode: string;
    context: string;
    id: string;
    importance: number;
    label: string;
    name: string;
    population: number;
    postcode: string;
    score: number;
    type: SearchType;
    x: number;
    y: number;
  };

  static getCoord(adr: AdresseApi): Coord {
    return {
      latitude: adr.geometry.coordinates[1],
      longitude: adr.geometry.coordinates[0],
    };
  }
}

export class Coord {
  latitude!: number;
  longitude!: number;
}

/**
 * Search enum type for geo api service
 */
export enum SearchType {
  /**
   * Numéro "à la plaque"
   */
  HOUSENUMBER = 'housenumber',
  /**
   * Position "à la voie", placé approximativement au centre de celle-ci
   */
  STREET = 'street',
  /**
   * lieu-dit
   */
  LOCALITY = 'locality',
  /**
   * numéro "à la commune"
   */
  MUNICIPALITY = 'municipality',
}
