import {HttpResponse} from '@angular/common/http';
import {Coord} from '@domain/geoapi/AdresseApi';
import {FixedSchedule, Labo} from '@domain/labo/Labo';
import {Sample, SamplesFilter, StatusSampleEnum, Check, Timeslot, SampleInfos} from '@domain/samples/sample';
import {Adress, AssociationModeEnum, User} from '@domain/user/user';
import {SamplesActions} from '@states/samples/samples.actions.enum';
import {Pagination} from '@utils/pagination/Pagination';
import {PaginationOption} from '@utils/pagination/PaginationOption';
import {DateTime} from 'luxon';

const PREFIX: string = '[Samples] ';

export class LoadFilteredSamples {
  static readonly type: string = PREFIX + SamplesActions.LOAD_FILTERED_SAMPLES;

  constructor() {}
}

export class LoadFilteredSamplesSuccess {
  static readonly type: string = PREFIX + SamplesActions.LOAD_FILTERED_SAMPLES_SUCCESS;
  constructor(public samples: Pagination<Sample>) {}
}

export class LoadFilteredSamplesFailure {
  static readonly type: string = PREFIX + SamplesActions.LOAD_FILTERED_SAMPLES_FAILURE;
  constructor(public error: string) {}
}

export class ChangeLaboDate {
  static readonly type: string = PREFIX + SamplesActions.CHANGE_LABO_DATE;
  constructor(public laboId: string, public date: Date) {}
}

export class ChangeLaboDateSuccess {
  static readonly type: string = PREFIX + SamplesActions.CHANGE_LABO_DATE_SUCCESS;
  constructor(public labo: Labo) {}
}

export class ChangeLaboDateFailure {
  static readonly type: string = PREFIX + SamplesActions.CHANGE_LABO_DATE_FAILURE;
  constructor(public error: string) {}
}

export class UpdateSamplePagination {
  static readonly type: string = PREFIX + SamplesActions.UPDATE_SAMPLE_PAGINATION;
  constructor(public pagination: PaginationOption) {}
}

export class UpdateSampleFilters {
  static readonly type: string = PREFIX + SamplesActions.UPDATE_SAMPLE_FILTERS;

  constructor(public filter: SamplesFilter, public pagination?: PaginationOption) {}
}

export class UpdateStatus {
  static readonly type: string = PREFIX + SamplesActions.UPDATE_STATUS;
  constructor(public sampleId: string, public status: StatusSampleEnum) {}
}

export class UpdateStatusSuccess {
  static readonly type: string = PREFIX + SamplesActions.UPDATE_STATUS_SUCCESS;
}

export class UpdateStatusFailure {
  static readonly type: string = PREFIX + SamplesActions.UPDATE_STATUS_FAILURE;
  constructor(public error: string) {}
}

export class AffectHomeSample {
  static readonly type: string = PREFIX + SamplesActions.AFFECT_HOME_SAMPLE;
  constructor(public sample: Sample) {}
}

export class AffectHomeSampleSuccess {
  static readonly type: string = PREFIX + SamplesActions.AFFECT_HOME_SAMPLE_SUCCESS;
  constructor(public sample: Sample) {}
}

export class AffectHomeSampleFailure {
  static readonly type: string = PREFIX + SamplesActions.AFFECT_HOME_SAMPLE_FAILURE;
  constructor(public error: string) {}
}

export class ValidateSamplesForDay {
  static readonly type: string = PREFIX + SamplesActions.VALIDATE_SAMPLES_FOR_DAY;
  constructor(public laboId: string, public date: string) {}
}

export class ValidateSamplesForDaySuccess {
  static readonly type: string = PREFIX + SamplesActions.VALIDATE_SAMPLES_FOR_DAY_SUCCESS;
  constructor(public date: string) {}
}

export class ValidateSamplesForDayFailure {
  static readonly type: string = PREFIX + SamplesActions.VALIDATE_SAMPLES_FOR_DAY_FAILURE;
  constructor(public error: string) {}
}

export class LoadPreleveur {
  static readonly type: string = PREFIX + SamplesActions.LOAD_PRELEVEUR;
  constructor(public id: string) {}
}
export class LoadPreleveurSuccess {
  static readonly type: string = PREFIX + SamplesActions.LOAD_PRELEVEUR_SUCCESS;
  constructor(public preleveur: User) {}
}

export class LoadPreleveurFailure {
  static readonly type: string = PREFIX + SamplesActions.LOAD_PRELEVEUR_FAILURE;
  constructor(public error: String) {}
}

export class LocalPatchSample {
  static readonly type: string = PREFIX + SamplesActions.LOCAL_PATCH_SAMPLE;
  constructor(public id: string, public sample: Sample) {}
}

export class ResetOrderModified {
  static readonly type: string = PREFIX + SamplesActions.RESET_ORDER_MODIFIED;
}

export class SavePlanningForDayAndPreleveur {
  static readonly type: string = PREFIX + SamplesActions.SAVE_PLANNING_FOR_DAY_AND_PRELEVEUR;
}

export class SavePlanningForDayAndPreleveurSuccess {
  static readonly type: string = PREFIX + SamplesActions.SAVE_PLANNING_FOR_DAY_AND_PRELEVEUR_SUCCESS;
  constructor(public samples: Sample[]) {}
}

export class SavePlanningForDayAndPreleveurFailure {
  static readonly type: string = PREFIX + SamplesActions.SAVE_PLANNING_FOR_DAY_AND_PRELEVEUR_FAILURE;
  constructor(public error: any) {}
}

export class UpdateSampleSort {
  static readonly type: string = PREFIX + SamplesActions.UPDATE_SAMPLE_SORT;
  constructor(public sortByColumnName: string) {}
}

export class SetDate {
  static readonly type :string =  PREFIX + SamplesActions.SET_DATE
  constructor(public date: DateTime) {}
}

export class DownloadPrescription {
  static readonly type: string = PREFIX + SamplesActions.DOWNLOAD_PRESCRIPTION;
  constructor(public id: string, public filename: string) {}
}

export class DownloadPrescriptionSuccess {
  static readonly type: string = PREFIX + SamplesActions.DOWNLOAD_PRESCRIPTION_SUCCESS;
  constructor(public doc: HttpResponse<Blob>, public filename: string) {}
}

export class DownloadPrescriptionFailure {
  static readonly type: string = PREFIX + SamplesActions.DOWNLOAD_PRESCRIPTION_FAILURE;
  constructor(public error: string) {}
}

export class GetChecks {
  static readonly type: string = PREFIX + SamplesActions.GET_CHECKS;
  constructor(public laboId: string) {}
}

export class GetChecksSuccess {
  static readonly type: string = PREFIX + SamplesActions.GET_CHECKS_SUCCESS;
  constructor(public checks: Check[]) {}
}

export class GetChecksFailure {
  static readonly type: string = PREFIX + SamplesActions.GET_CHECKS_FAILURE;
  constructor(public error: string) {}
}

export class CreateNewSample {
  static readonly type: string = PREFIX + SamplesActions.CREATE_NEW_SAMPLE;
}

export class SampleCreationPatch {
  static readonly type: string = PREFIX + SamplesActions.SAMPLE_CREATION_PATCH;
  constructor(public sample: Sample) {}
}

export class GetNearestLaboForSample {
  static readonly type: string = PREFIX + SamplesActions.GET_NEAREST_LABO_FOR_SAMPLE;
  constructor (public userId: string, public adresse: Adress) {}
}

export class GetNearestLaboForSampleSuccess {
  static readonly type: string = PREFIX + SamplesActions.GET_NEAREST_LABO_FOR_SAMPLE_SUCCESS;
  constructor(public userId: string, public labos: Labo[], public adresse: Adress, public coords: Coord) {}
}

export class GetNearestLaboForSampleFailure {
  static readonly type: string = PREFIX + SamplesActions.GET_NEAREST_LABO_FOR_SAMPLE_FAILURE;
  constructor(public error: string) {}
}

export class GetSampleTimeSlots {
  static readonly type: string = PREFIX + SamplesActions.GET_SAMPLE_TIME_SLOTS;
  constructor(public searchMode: AssociationModeEnum, public nbDays: number, public last?: Date) {}
}

export class GetSampleTimeSlotsSuccess {
  static readonly type: string = PREFIX + SamplesActions.GET_SAMPLE_TIME_SLOTS_SUCCESS;
  constructor(public timeslots: Timeslot[]) {}
}

export class GetSampleTimeSlotsFailure {
  static readonly type: string = PREFIX + SamplesActions.GET_SAMPLE_TIME_SLOTS_FAILURE;
  constructor(public error: string) {}
}

export class UpdateTimeSlotsSearchMode {
  static readonly type: string = PREFIX + SamplesActions.UPDATE_TIME_SLOTS_SEARCH_MODE;
  constructor(public searchMode: AssociationModeEnum) {}
}

export class ResetSampleTimeSlot {
  static readonly type: string = PREFIX + SamplesActions.RESET_SAMPLE_TIME_SLOTS;
}
export class CreateSample {
  static readonly type: string = PREFIX + SamplesActions.CREATE_SAMPLE;
}

export class CreateSampleSuccess {
  static readonly type: string = PREFIX + SamplesActions.CREATE_SAMPLE_SUCCESS;
}

export class CreateSampleFailure {
  static readonly type: string = PREFIX + SamplesActions.CREATE_SAMPLE_FAILURE;
  constructor(public error: string) {}
}

export class GetSamplesByDay {
  static readonly type: string = PREFIX + SamplesActions.GET_SAMPLES_BY_DAY;
}

export class GetSamplesByDaySuccess {
  static readonly type: string = PREFIX + SamplesActions.GET_SAMPLES_BY_DAY_SUCCESS;
  constructor(public samples: SampleInfos[]) {}
}

export class GetSamplesByDayFailure {
  static readonly type: string = PREFIX + SamplesActions.GET_SAMPLES_BY_DAY_FAILURE;
  constructor(public error: string) {}
}

export class UpdatePlanningFilters {
  static readonly type: string = PREFIX + SamplesActions.UPDATE_PLANNING_FILTERS;
  constructor(public filters: SamplesFilter) {}
}

export class ValidateSaveStatus {
  static readonly type: string = PREFIX + SamplesActions.VALIDATE_SAVED_STATUS;
  constructor(public id: string, public jeun?: boolean, public consignePatient?: string, public consignePreleveur?: string) {}
}

export class ValidateSaveStatusSuccess {
  static readonly type: string = PREFIX + SamplesActions.VALIDATE_SAVED_STATUS_SUCCESS;
  constructor(public sample: SampleInfos) {}
}
export class ValidateSaveStatusFailure {
  static readonly type: string = PREFIX + SamplesActions.VALIDATE_SAVED_STATUS_FAILURE;
  constructor(public error: string) {}
}

export class GetSample {
  static readonly type: string = PREFIX + SamplesActions.GET_SAMPLE;
  constructor(public id: string) {}
}

export class GetSampleSuccess {
  static readonly type: string = PREFIX + SamplesActions.GET_SAMPLE_SUCCESS;
  constructor(public sample: Sample) {
  }
}

export class GetSampleFailure {
  static readonly type: string = PREFIX + SamplesActions.GET_SAMPLE_FAILURE;
  constructor(public error: string) {}
}

export class GetSampleFixedScheduleOption {
  static readonly type: string = PREFIX + SamplesActions.GET_SAMPLE_FIXED_SCHEDULE_OPTION;
  constructor(public laboId: string){}
}

export class GetSampleFixedScheduleOptionSuccess {
  static readonly type: string = PREFIX + SamplesActions.GET_SAMPLE_FIXED_SCHEDULE_OPTION_SUCCESS;
  constructor(public fixedSchedule: FixedSchedule[]) {}
}

export class GetSampleFixedScheduleOptionFailure {
  static readonly type: string = PREFIX + SamplesActions.GET_SAMPLE_FIXED_SCHEDULE_OPTION_FAILURE;
  constructor(public error: string) {}
}
