import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {User} from '@domain/user/user';
import {TranslocoService} from '@ngneat/transloco';
import {Select, Store} from '@ngxs/store';
import {Logout} from '@states/global/global.actions';
import {GlobalStateSelector} from '@states/global/global.selectors';
import {KeycloakService} from 'keycloak-angular';
import {first, Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {UserService} from '@service/user.service';
import { NotificationsStateSelector } from '@states/notifications/notifications.selectors';
import { LoadLatestNotifcations } from '@states/notifications/notifications.actions';
import { Notification } from '@domain/notification/notification';
import { UserInfo } from '@security/UserInfo';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent extends UserInfo implements OnInit {
  @Select(GlobalStateSelector.currentUser) currentUser$: Observable<User> | undefined;
  @Select(NotificationsStateSelector.latestNotifications) latestNotifications$: Observable<Notification[]>;
  userResponse?: User;

  group: FormGroup;

  test: string = '<span>mon texte html</span>';

  constructor(
    public override keycloakService: KeycloakService,
    private store: Store,
    private userService: UserService,
    private change: ChangeDetectorRef,
    private translator: TranslocoService,
    private fb: FormBuilder
  ) {
    super(keycloakService);
    this.group = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      tel: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required, Validators.email]),
    });
  }

  ngOnInit(): void {
    this.store.dispatch(new LoadLatestNotifcations());

    this.userService
      .get('fa72133e-8bb7-45f2-8fe2-30ec8fbcff54')
      .pipe(
        first(),
        catchError(err => {
          console.log(err);
          return throwError(() => err);
        })
      )
      .subscribe(res => {
        this.userResponse = res;
        this.change.detectChanges();
      });
  }

  validate(): void {
    if (this.group.invalid) {
      return;
    }
  }

  logout(): void {
    this.store.dispatch(new Logout());
  }
}
