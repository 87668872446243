<ng-container *ngIf="labos$ | async as labos">
  <form>
    <mat-form-field appearance="fill" class=" me-2" >
      <input #search matInput [placeholder]="'users.laboratory' | transloco" [matAutocomplete]="autoLabo" (input)="inputLaboSearch($event)" [formControl]="searchControl">
      <mat-autocomplete #autoLabo="matAutocomplete" >
        <mat-option *ngFor="let laboratory of labos" (click)="select(laboratory)" [value]='laboratory.name'>
          {{laboratory.name}}
        </mat-option>
      </mat-autocomplete>
      <div>
        <div *ngFor="let labo of listLabo" class="d-inline-flex flex-row justify-content-between align-items-center labo-card mt-2 me-2">
          <span class="ms-2">{{labo.name}}</span>
          <button mat-icon-button>
            <mat-icon color="warn" (click)="deleteLabo(labo?.id)">close</mat-icon>
          </button>
        </div>
      </div>
    </mat-form-field>
  </form>
</ng-container>
