import {Injectable} from '@angular/core';
import {Cabinet, PreleveurCabinet} from '@domain/cabinet/cabinet';
import {Navigate} from '@ngxs/router-plugin';
import {Action, State, StateContext} from '@ngxs/store';
import {append, patch, removeItem} from '@ngxs/store/operators';
import {CabinetService} from '@service/cabinet.service';
import {UserService} from '@service/user.service';
import {
  AddPreleveurToCabinet,
  DeleteOneCabinet,
  DeleteOneCabinetFailure,
  DeleteOneCabinetSuccess,
  LoadFilteredCabinets,
  LoadFilteredCabinetsFailure,
  LoadFilteredCabinetsSuccess,
  LoadFilteredPreleveurs,
  LoadFilteredPreleveursFailure,
  LoadFilteredPreleveursSuccess,
  LoadOneCabinet,
  LoadOneCabinetFailure,
  LoadOneCabinetSuccess,
  LoadOneNewCabinet,
  RemovePreleveurFromCabinet,
  SaveOneCabinet,
  SaveOneCabinetFailure,
  SaveOneCabinetSuccess,
  UpdateCabinetsFilter,
  UpdateCabinetsPaginationOption,
  UpdatePreleveursFilter,
  UpdatePreleveursPaginationOption,
} from '@states/cabinets/cabinets.actions';
import {ShowMessage} from '@states/global/global.actions';
import {MessageLevel} from '@states/global/global.state';
import {Pagination} from '@utils/pagination/Pagination';
import {PaginationOption} from '@utils/pagination/PaginationOption';
import {map, Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';

export interface CabinetsStateModel {
  filteredCabinets: Pagination<Cabinet>;
  cabinetsFilter: string;
  cabinetsPaginationOption: PaginationOption;
  currentCabinet?: Cabinet;
  currentCabinetPreleveurs: PreleveurCabinet[];
  filteredPreleveurs: Pagination<PreleveurCabinet>;
  preleveursFilter: string;
  preleveursPaginationOption: PaginationOption;
}

@State<CabinetsStateModel>({
  name: 'cabinets',
  defaults: {
    filteredCabinets: {
      content: [],
      totalElements: 0,
    },
    cabinetsFilter: '',
    cabinetsPaginationOption: {
      length: 100,
      pageSize: 25,
      pageIndex: 0,
      pageSizeOption: [10, 25, 50, 100],
    },
    currentCabinet: {},
    currentCabinetPreleveurs: [],
    preleveursFilter: '',
    preleveursPaginationOption: {
      length: 100,
      pageSize: 10,
      pageIndex: 0,
      pageSizeOption: [10, 20, 30],
    },
    filteredPreleveurs: {
      content: [],
      totalElements: 0,
    },
  },
})
@Injectable()
export class CabinetsState {
  constructor(private cabinetService: CabinetService, private userService: UserService) {}

  @Action(LoadOneCabinet)
  loadOneCabinet(ctx: StateContext<CabinetsStateModel>, {id}: LoadOneCabinet): Observable<void | Observable<void>> {
    return this.cabinetService.get(id).pipe(
      map(cabinet => ctx.dispatch(new LoadOneCabinetSuccess(cabinet))),
      catchError((error: any) => ctx.dispatch(new LoadOneCabinetFailure(error)))
    );
  }

  @Action(LoadOneCabinetSuccess)
  loadOneCabinetSuccess(ctx: StateContext<CabinetsStateModel>, {cabinet}: LoadOneCabinetSuccess): void {
    ctx.patchState({
      currentCabinet: cabinet,
      currentCabinetPreleveurs: cabinet.preleveurs,
    });
  }

  @Action(LoadOneCabinetFailure)
  loadOneCabinetFailure(ctx: StateContext<CabinetsStateModel>, {error}: LoadOneCabinetFailure): void {
    ctx.dispatch(new ShowMessage({content: {title: 'load_cabinet_failure'}, level: MessageLevel.ERROR}, 'cabinets'));
    ctx.dispatch(new Navigate(['/cabinets']));
  }

  @Action(LoadOneNewCabinet)
  loadOneNewCabinet(ctx: StateContext<CabinetsStateModel>): void {
    ctx.patchState({
      currentCabinet: {
        name: '',
        preleveurs: [],
      },
      currentCabinetPreleveurs: [],
    });
  }

  @Action(LoadFilteredCabinets)
  loadFilteredCabinets(ctx: StateContext<CabinetsStateModel>): Observable<void | Observable<void>> {
    const state: CabinetsStateModel = ctx.getState();
    return this.cabinetService.findByFiltersPaging(state.cabinetsFilter, state.cabinetsPaginationOption).pipe(
      map(cabinets => ctx.dispatch(new LoadFilteredCabinetsSuccess(cabinets))),
      catchError(error => ctx.dispatch(new LoadFilteredCabinetsFailure(error)))
    );
  }

  @Action(LoadFilteredCabinetsSuccess)
  loadFilteredCabinetsSuccess(ctx: StateContext<CabinetsStateModel>, {cabinets}: LoadFilteredCabinetsSuccess): void {
    const state: CabinetsStateModel = ctx.getState();
    if (cabinets) {
      ctx.patchState({
        filteredCabinets: cabinets,
        cabinetsPaginationOption: {
          ...state.cabinetsPaginationOption,
          length: cabinets.totalElements,
        },
      });
    }
  }
  @Action(LoadFilteredCabinetsFailure)
  loadFilteredCabinetsFailure(ctx: StateContext<CabinetsStateModel>, {error}: LoadFilteredCabinetsFailure): void {
    ctx.dispatch(new ShowMessage({content: {title: 'load_cabinets_failure'}, level: MessageLevel.ERROR}, 'cabinets'));
  }

  @Action(UpdateCabinetsFilter)
  updateCabinetsFilter(ctx: StateContext<CabinetsStateModel>, {filter}: UpdateCabinetsFilter): void {
    ctx.patchState({
      cabinetsFilter: filter,
    });
    ctx.dispatch(new LoadFilteredCabinets());
  }

  @Action(UpdateCabinetsPaginationOption)
  updateCabinetsPaginationOption(ctx: StateContext<CabinetsStateModel>, {paginationOption}: UpdateCabinetsPaginationOption): void {
    const state: CabinetsStateModel = ctx.getState();
    ctx.patchState({
      cabinetsPaginationOption: {
        ...state.cabinetsPaginationOption,
        ...paginationOption,
      },
    });
    ctx.dispatch(new LoadFilteredCabinets());
  }
  @Action(UpdatePreleveursFilter)
  updatePreleveursFilter(ctx: StateContext<CabinetsStateModel>, {filter}: UpdatePreleveursFilter): void {
    ctx.patchState({
      preleveursFilter: filter,
    });
    ctx.dispatch(new LoadFilteredPreleveurs());
  }

  @Action(UpdatePreleveursPaginationOption)
  updatePreleveursPaginationOptions(ctx: StateContext<CabinetsStateModel>, {pagination}: UpdatePreleveursPaginationOption): void {
    const state: CabinetsStateModel = ctx.getState();
    ctx.patchState({
      preleveursPaginationOption: {
        ...state.preleveursPaginationOption,
        ...pagination,
      },
    });

    ctx.dispatch(new LoadFilteredPreleveurs());
  }

  @Action(LoadFilteredPreleveurs)
  loadFilteredPreleveurs(ctx: StateContext<CabinetsStateModel>): Observable<void | Observable<void>> {
    const state: CabinetsStateModel = ctx.getState();
    return this.userService.findPreleveurs(state.preleveursFilter, state.preleveursPaginationOption).pipe(
      map(preleveurs => ctx.dispatch(new LoadFilteredPreleveursSuccess(preleveurs))),
      catchError(err => ctx.dispatch(new LoadFilteredPreleveursFailure(err)))
    );
  }
  @Action(LoadFilteredPreleveursSuccess)
  loadFilteredPreleveursSuccess(ctx: StateContext<CabinetsStateModel>, {preleveurs}: LoadFilteredPreleveursSuccess): void {
    const state: CabinetsStateModel = ctx.getState();
    if (preleveurs) {
      ctx.setState(
        patch({
          filteredPreleveurs: preleveurs,
          preleveursPaginationOption: patch({
            ...state.preleveursPaginationOption,
            length: preleveurs.totalElements,
          }),
        })
      );
    }
  }

  @Action(LoadFilteredPreleveursFailure)
  loadFilteredPreleveursFailure(ctx: StateContext<CabinetsStateModel>, {error}: LoadFilteredPreleveursFailure): void {
    ctx.dispatch(new ShowMessage({content: {title: 'load_preleveurs_failure'}, level: MessageLevel.ERROR}, 'cabinets'));
  }

  @Action(AddPreleveurToCabinet)
  addPreleveurToCabinet(ctx: StateContext<CabinetsStateModel>, {preleveur}: AddPreleveurToCabinet): void {
    ctx.setState(
      patch({
        currentCabinetPreleveurs: append<PreleveurCabinet>([preleveur]),
      })
    );
  }

  @Action(RemovePreleveurFromCabinet)
  removePreleveurFromCabinet(ctx: StateContext<CabinetsStateModel>, {id}: RemovePreleveurFromCabinet): void {
    ctx.setState(
      patch({
        currentCabinetPreleveurs: removeItem<PreleveurCabinet>(preleveur => preleveur?.id === id),
      })
    );
  }

  @Action(SaveOneCabinet)
  saveOneCabinet(ctx: StateContext<CabinetsStateModel>, {cabinet}: SaveOneCabinet): Observable<void | Observable<void>> {
    const state: CabinetsStateModel = ctx.getState();
    const cabinetToSave: Cabinet = {
      id: state.currentCabinet?.id,
      name: cabinet?.name,
      preleveurs: state.currentCabinetPreleveurs,
    };
    if (cabinet.id) {
      return this.cabinetService.update(cabinet.id, cabinetToSave).pipe(
        map(_ => ctx.dispatch(new SaveOneCabinetSuccess())),
        catchError(error => ctx.dispatch(new SaveOneCabinetFailure(error)))
      );
    } else {
      return this.cabinetService.create(cabinetToSave).pipe(
        map(_ => ctx.dispatch(new SaveOneCabinetSuccess())),
        catchError(error => ctx.dispatch(new SaveOneCabinetFailure(error)))
      );
    }
  }

  @Action(SaveOneCabinetSuccess)
  saveOneCabinetSuccess(ctx: StateContext<CabinetsStateModel>): void {
    ctx.dispatch(new ShowMessage({content: {title: 'cabinet_saved_success'}, level: MessageLevel.SUCCESS}, 'cabinets'));
    ctx.dispatch(new LoadOneNewCabinet());
    ctx.dispatch(new Navigate(['/cabinets']));
  }

  @Action(SaveOneCabinetFailure)
  saveOneCabinetFailure(ctx: StateContext<CabinetsStateModel>, {error}: SaveOneCabinetFailure): void {
    ctx.dispatch(new ShowMessage({content: {title: 'save_cabinet_failure'}, level: MessageLevel.ERROR}, 'cabinets'));
  }

  @Action(DeleteOneCabinet)
  deleteOneCabinet(ctx: StateContext<CabinetsStateModel>, {id}: DeleteOneCabinet): Observable<void | Observable<void>> {
    return this.cabinetService.delete(id).pipe(
      map(_ => ctx.dispatch(new DeleteOneCabinetSuccess())),
      catchError(error => ctx.dispatch(new DeleteOneCabinetFailure(error)))
    );
  }

  @Action(DeleteOneCabinetSuccess)
  deleteOneCabinetSuccess(ctx: StateContext<CabinetsStateModel>): void {
    ctx.dispatch(new ShowMessage({content: {title: 'cabinet_deleted_success'}, level: MessageLevel.SUCCESS}, 'cabinets'));
    ctx.dispatch(new LoadOneNewCabinet());
    ctx.dispatch(new Navigate(['/cabinets']));
  }
  @Action(DeleteOneCabinetFailure)
  deleteOneCabinetFailure(ctx: StateContext<CabinetsStateModel>, {error}: DeleteOneCabinetFailure): void {
    ctx.dispatch(new ShowMessage({content: {title: 'delete_cabinet_failure'}, level: MessageLevel.ERROR}, 'cabinets'));
  }
}
