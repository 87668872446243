import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export const scheduleValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const startTime: any = control.get('startTime')?.value;
  const endTime: any = control.get('endTime')?.value;
  const startTime2: any = control.get('startTime2')?.value;
  const endTime2: any = control.get('endTime2')?.value;
  if (
    (startTime && !endTime) ||
    (startTime && endTime && startTime > endTime) ||
    (startTime2 && !endTime2) ||
    (endTime && startTime2 && startTime2 < endTime) ||
    (startTime2 && endTime2 && startTime2 > endTime2)
  ) {
    return {wrongSchedule: true};
  }
  return null;
};
