import {Pipe, PipeTransform} from '@angular/core';
import {DayEnum, LaboForm, LaboFormSchedule, Schedule} from '@domain/labo/Labo';

@Pipe({
  name: 'getLaboSchedule',
})
export class GetLaboSchedule implements PipeTransform {
  transform(labo: LaboForm, day: DayEnum): number[] {
    const sched: Schedule | undefined = labo?.schedule?.[day.toLowerCase() as keyof LaboFormSchedule];
    let start: number = 5;
    let end: number = 5;
    if (sched && sched.startTime && sched.endTime) {
      start = parseInt(sched.startTime.substring(0, sched.startTime.indexOf(':')));
      end = sched.endTime2?.length > 0 ? parseInt(sched.endTime2.substring(0, sched.endTime2.indexOf(':'))) : parseInt(sched.endTime.substring(0, sched.endTime.indexOf(':')));
    }
    return [start, end];
  }
}
