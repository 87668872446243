<header>
  <div class="d-flex align-items-center me-3">
    <img class="logo col-auto ms-4 me-auto" src="assets/img/kantys-bio.png" alt="logo Kantysbio">
    <div>
      <mat-icon class="col-auto align-self-center me-1">notifications</mat-icon>
      <mat-icon class="col-auto align-self-center" role="button" [routerLink]="['/users','edit', user?.id]">person</mat-icon>
      <mat-icon class="col-auto align-self-center" role="button" (click)="logout()">power_settings_new</mat-icon>
    </div>
  </div>
</header>

<mat-sidenav-container>
    <mat-sidenav [opened]="true" mode="side" class="side-menu">
      <mat-button-toggle-group [vertical]="true" class="mt-3">
        <mat-button-toggle *ngIf="isAdmin || isPersonnelLabo || isManager || isSecretaire" routerLink="/users" routerLinkActive="mat-button-toggle-checked">
          <img class="icon" src="assets/img/users.svg" alt="{{ 'menu.users' | transloco }}" title="{{ 'menu.users' | transloco }}">
        </mat-button-toggle>
        <mat-button-toggle *ngIf="isAdmin || isPersonnelLabo || isManager" routerLink="/labos" routerLinkActive="mat-button-toggle-checked">
          <img class="icon" src="assets/img/laboratory.svg" alt="{{ 'menu.labos' | transloco }}" title="{{ 'menu.labos' | transloco }}">
        </mat-button-toggle>
        <mat-button-toggle *ngIf="isAdmin" routerLink="/nurses" routerLinkActive="mat-button-toggle-checked">
          <img class="icon" src="assets/img/tubes.svg">
        </mat-button-toggle>
        <mat-button-toggle *ngIf="isSecretaire || isAdmin" routerLink="/samples/planning" routerLinkActive="mat-button-toggle-checked">
          <img class="icon" src="assets/img/avaibility.svg">
        </mat-button-toggle>
        <mat-button-toggle *ngIf="isAdmin || isPersonnelLabo || isManager || isSecretaire" routerLink="/cabinets" routerLinkActive="mat-button-toggle-checked">
          <img class="icon" src="assets/img/nurse.png" alt="{{ 'menu.users' | transloco }}" title="{{ 'menu.users' | transloco }}">
        </mat-button-toggle>
      </mat-button-toggle-group>
    </mat-sidenav>
    <mat-sidenav-content class="content">
      <router-outlet> </router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
