import { NgModule } from '@angular/core';
import {MatSnackBarModule} from '@angular/material/snack-bar'
import { SharedModule } from '@utils/shared.module';
import { NotificationComponent } from './notification.component';

@NgModule({
    declarations: [NotificationComponent],
    imports: [
      SharedModule,
      MatSnackBarModule,
    ],
    providers: [],
  })
  export class NotificationModule {}