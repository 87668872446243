export enum GlobalActions {
  SHOW_MESSAGE = 'Show message',

  LOAD_USER_PROFILE = 'Load User Profile',
  LOAD_USER_PROFILE_SUCCESS = 'Load User Profile Success',
  LOAD_USER_PROFILE_FAILURE = 'Load User Profile Failure',
  LOGOUT = 'Logout',
  END_INITIALIZATION = 'End Initialization',

  REGISTER_FOR_NOTIFICATION = 'Register For Notification',
  REGISTER_FOR_NOTIFICATION_FAILURE = 'Register For Notification Failure',

  SUBSCRIBE_TO_TOPIC = 'Subscribe To Topic',

  SHOW_NOTIFICATION = 'Show Notification',

}
