import {Location} from '@angular/common';
import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {PageEvent} from '@angular/material/paginator';
import {ActivatedRoute, Router} from '@angular/router';
import {Labo} from '@domain/labo/Labo';
import {DocumentTypeEnum, Sample, SamplesFilter, StatusSampleEnum} from '@domain/samples/sample';
import {translate} from '@ngneat/transloco';
import {Select, Store} from '@ngxs/store';
import {SampleService} from '@service/sample.service';
import {
  DownloadPrescription,
  LoadFilteredSamples,
  UpdateSampleFilters,
  UpdateSamplePagination,
  UpdateSampleSort,
  UpdateStatus,
} from '@states/samples/samples.actions';
import {SamplesStateSelector} from '@states/samples/samples.selectors';
import {Pagination} from '@utils/pagination/Pagination';
import {PaginationOption} from '@utils/pagination/PaginationOption';
import {debounceTime, Observable, Subject, take, takeUntil} from 'rxjs';

@Component({
  selector: 'app-labo-worklist',
  templateUrl: './labo-worklist.component.html',
  styleUrls: ['./labo-worklist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaboWorklistComponent implements OnInit, OnDestroy {
  filterGroup: FormGroup;
  private destroy: Subject<boolean> = new Subject<boolean>();
  @Select(SamplesStateSelector.filteredSamples) samples$: Observable<Pagination<Sample>>;
  @Select(SamplesStateSelector.filteredSamplesLoaded) samplesLoaded$: Observable<boolean>;
  @Select(SamplesStateSelector.labo) labo$: Observable<Labo>;
  @Select(SamplesStateSelector.paginationOption) paginationOption$: Observable<PaginationOption>;
  @Select(SamplesStateSelector.filter) filter$: Observable<SamplesFilter>;
  @Select(SamplesStateSelector.filterPatientName) filterPatientName$: Observable<string>
  statusEnumArray: string[] = Object.keys(StatusSampleEnum);
  statusEnum: typeof StatusSampleEnum = StatusSampleEnum;

  constructor(private store: Store, private router: Router, private _route: ActivatedRoute, private _location: Location, private _sampleService: SampleService) {
  }

  ngOnInit(): void {
    this.createForm();
    this._route.paramMap.subscribe(val => {
      const laboId: string | null = val.get('id');
      const date: string | null = val.get('date');
      if (laboId && date) {
        this.store.dispatch(new UpdateSampleFilters({
          laboId: laboId,
          inLabo: true,
          date: date,
        }, {pageIndex: 0, pageSize: 10}));
      }
    });

    this.filter$.pipe(take(1)).subscribe(f => {
      this.filterGroup.get('patientName')?.setValue(f.patientName);
      this.filterGroup.get('status')?.setValue('');
    })

    this.filterGroup.valueChanges.pipe(debounceTime(500), takeUntil(this.destroy))
    .subscribe(_ => {
      this.store.dispatch(new UpdateSampleFilters({
        patientName: this.filterGroup.get('patientName')?.value,
        status: this.filterGroup.get('status')?.value }))
    });
  }
  onChangePage(pe: PageEvent): void {
    this.store.dispatch(
      new UpdateSamplePagination({
        pageIndex: pe.pageIndex,
        pageSize: pe.pageSize,
      })
    );
  }

  goBack(): void {
    this._location.back();
  }

  getSearchFormControl(): FormControl {
    return this.filterGroup.get('patientName') as FormControl;
  }

  createForm(): void {
    this.filterGroup = new FormGroup({
      patientName: new FormControl(''),
      status: new FormControl(Object.keys(StatusSampleEnum)),
    });
  }

  changeFilter(search: string) {
    this.store.dispatch(new UpdateSampleFilters({patientName: search}, {pageIndex: 0}))
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

  getStatus(status: string[] | undefined): string {
    let status_translated: string[] = [];
    if (status) {
      status.forEach(value => (status_translated.push( translate('samples.status.' + value))));
    }
    return status_translated.join(', ');
  }
  getChecks(sample: Sample): string | undefined {
    return sample.checks?.map(check => check.libelle).join(' + ');
  }

  updateStatus(sample: Sample): void {
    if (sample.id != null) {
      this.store.dispatch(new UpdateStatus(sample.id, StatusSampleEnum.CONFIRM));
    }
  }

  updateSort(columnName: string): void {
    this.store.dispatch(new UpdateSampleSort(columnName));
    this.store.dispatch(new LoadFilteredSamples());
  }

  goToUserDetails(id: string | undefined): void {
    if (id) {
      this.router.navigateByUrl('/users/view/' + id);
    }

  }

  resetFilter(): void {
    this.filterGroup.get('status')?.setValue('');
  }

  getFirstStatus(status: string[] | undefined): string {
    if(status?.length){
      return translate('samples.status.' + status[0])
    }
    return '';
  }

  getNumberStatus(status: string[] | undefined): string {
    if (status?.length && status.length > 1) {
      return ' +' + (status.length -1).toString();
    }
    return '';
  }

  hasUploadedPresciption(sample: Sample): boolean {
    if (sample.documents) {
      return sample.documents.filter(doc => doc.documentType === DocumentTypeEnum.PRESCRIPTION).length >= 1;
    }
    return false;
  }

  downloadPrescription(sample: Sample): void {
    sample.documents
      ?.filter(doc => doc.documentType === DocumentTypeEnum.PRESCRIPTION)
      .forEach(doc => {
        if(doc.id) {
          this.store.dispatch(new DownloadPrescription(doc.id, doc.filename || ''));
        }
      });
  }
}
