export enum UserActions {
  LOAD_ONE_USER = 'Load one user',
  LOAD_ONE_USER_SUCCESS = 'Load one user success',
  LOAD_ONE_USER_FAIL = 'Load one user failure',

  LOAD_FILTERED_USERS = 'Load a page of filtered users',
  LOAD_FILTERED_USERS_SUCCESS = 'Load a page of filtered users success',
  LOAD_FILTERED_USERS_FAIL = 'Load a page of filtered users failure',

  LOAD_ONE_NEW_USER = 'Load one new user',

  SAVE_ONE_USER = 'Save one user',
  SAVE_ONE_USER_SUCCESS = 'Save one user success',
  SAVE_ONE_USER_FAIL = 'Save one user failure',

  DELETE_ONE_USER = 'Delete one user',
  DELETE_ONE_USER_SUCCESS = 'Delete one user success',
  DELETE_ONE_USER_FAIL = 'Delete one user fail',

  CHANGE_POSSIBLE_LABO = 'Change possible labo',
  CHANGE_POSSIBLE_LABO_SUCCESS = 'Change possible labo success',
  CHANGE_POSSIBLE_LABO_FAILURE = 'Change possible labo failure',

  SUSPEND_ONE_USER = 'Suspend one user',
  SUSPEND_ONE_USER_SUCCESS = 'Suspend one user success',
  SUSPEND_ONE_USER_FAILURE = 'Suspend one user failure',

  ADD_SELECTED_LABOS = 'Add labo to selected labos',
  REMOVE_SELECTED_LABOS = 'Remove labo to selected labos',

  UPDATE_USERS_PAGINATION = 'Update User Pagination',

  UPDATE_USER_FILTER = 'Update User Filter',

  UPDATE_USER_SORT = 'Update sort',

  ADD_AFFECTED_ZONE = 'Add affected zone',
  REMOVE_AFFECTED_ZONE = 'Remove affected zone',

  SAVE_AFFECTED_ZONE = 'Save affected zone',
  SAVE_AFFECTED_ZONE_SUCCESS = 'Save affected zone success',
  SAVE_AFFECTED_ZONE_FAILURE = 'Save affected zone failure',

  UPDATE_ZONE = 'Update zone',

  GET_DOMICILE_ADDRESS = 'Get domicile address',
  GET_DOMICILE_ADDRESS_FAILURE = 'Get domicile address failure',
  SET_DOMICILE_ADDRESS = 'Set domicile address',

  GET_USER_SAMPLES = 'Get user samples',
  GET_USER_SAMPLES_SUCCESS = 'Get user samples success',
  GET_USER_SAMPLES_FAILURE = 'Get user samples failure',

  GET_USER_DOCUMENTS = 'Get user documents',
  GET_USER_DOCUMENTS_SUCCESS = 'Get user documents success',
  GET_USER_DOCUMENTS_FAILURE = 'Get user documents failure',

  GET_USER_RESULT_DIFFUSION = 'Get user result diffusion',
  GET_USER_RESULT_DIFFUSION_SUCCESS = 'Get user result diffusion success',
  GET_USER_RESULT_DIFFUSION_FAILURE = 'Get user result diffusion failure',

  GET_FILTERED_PRELEVEURS = 'Get filtered preleveurs',
  GET_FILTERED_PRELEVEURS_SUCCESS = 'Get filtered preleveurs success',
  GET_FILTERED_PRELEVEURS_FAILURE = 'Get filtered preleveurs failure',

  UPDATE_PRELEVEURS_PAGINATION = 'Update preleveurs pagination',
  UPDATE_PRELEVEURS_FILTER = 'Update preleveurs filter',

  ASSOCIATE_PRELEVEUR = 'Associate preleveur',
  DISSOCIATE_PRELEVEUR = 'Dissociate preleveur',

  SAVE_PRELEVEUR_ASSOCIATION = 'Save preleveur association',
  SAVE_PRELEVEUR_ASSOCIATION_SUCCESS = 'Save preleveur association success',
  SAVE_PRELEVEUR_ASSOCIATION_FAILURE = 'Save preleveur association failure',
}
