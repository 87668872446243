import {Component, OnInit, ChangeDetectionStrategy, ViewChild, OnDestroy} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Labo, LaboRdvStatusCount } from '@domain/labo/Labo';
import { Select, Store } from '@ngxs/store';
import { UserInfo } from '@security/UserInfo';
import { LoadLaboRdvStatusCounts, SearchLabos } from '@states/labos/labos.actions';
import { LabosStateSelector } from '@states/labos/labos.selectors';
import { KeycloakService } from 'keycloak-angular';
import { DateTime } from 'luxon';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-labo-planning',
  templateUrl: './labo-planning.component.html',
  styleUrls: ['./labo-planning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LaboPlanningComponent extends UserInfo implements OnInit, OnDestroy {

  @Select(LabosStateSelector.labos) labos$: Observable<Labo[]>;
  @Select(LabosStateSelector.labosLoaded) labosLoaded$: Observable<boolean>;
  @Select(LabosStateSelector.labosRdvStatusCounts) labosRdvStatusCounts$: Observable<LaboRdvStatusCount[]>;
  @Select(LabosStateSelector.labosRdvStatusCountsLoaded) labosRdvStatusCountsLoaded$: Observable<boolean>;

  private destroy: Subject<boolean> = new Subject<boolean>();

  constructor(public override keycloakService: KeycloakService, private store: Store) {
    super(keycloakService);
  }

  range = new FormGroup({
    start: new FormControl(DateTime.now().startOf('week').toJSDate()),
    end: new FormControl(DateTime.now().endOf('week').toJSDate())
  });

  ngOnInit(): void {
    this.store.dispatch(new SearchLabos())
      .pipe(takeUntil(this.destroy))
      .subscribe(_ => this.loadLaboRdvStatusCounts());
  }

  startDateSelection(): void {
    let start = new Date(this.range.controls["start"].value);
    start.setDate(start.getDate() - start.getDay() + 1);
    this.range.controls["start"].setValue(start);
  }
  endDateSelection(): void {
    let start = new Date(this.range.controls["start"].value);
    let end = new Date(start);
    end.setDate(start.getDate() + 6);
    this.range.controls["end"].setValue(end);
    this.loadLaboRdvStatusCounts();
  }

  prevWeek() {
    let start = new Date(this.range.controls["start"].value);
    let end = new Date(this.range.controls["end"].value);
    start.setDate(start.getDate() - 7);
    end.setDate(end.getDate() - 7);
    this.range.controls["start"].setValue(start);
    this.range.controls["end"].setValue(end);
    this.loadLaboRdvStatusCounts();
  }

  nextWeek() {
    let start = new Date(this.range.controls["start"].value);
    let end = new Date(this.range.controls["end"].value);
    start.setDate(start.getDate() + 7);
    end.setDate(end.getDate() + 7);
    this.range.controls["start"].setValue(start);
    this.range.controls["end"].setValue(end);
    this.loadLaboRdvStatusCounts();
  }

  weekRange() {
    return Array.from({length: 7}, (_, i) => {
      return DateTime.fromJSDate(this.range.controls["start"].value).plus({days:i}).toJSDate()
    })
  }

  loadLaboRdvStatusCounts() {
    this.store.dispatch(new LoadLaboRdvStatusCounts(this.range.controls["start"].value, this.range.controls["end"].value))
  }

  getPendingStatus(labosRdvStatusCounts:LaboRdvStatusCount[], labo: Labo, day: Date) {
    return labosRdvStatusCounts
      .filter(counts => counts.laboId === labo.id && counts.date === DateTime.fromJSDate(day).toFormat("yyyy-MM-dd") && counts.status === 'PENDING')
      .reduce((previousValue, currentValue) => previousValue + currentValue.count! , 0)
  }

  getPlanifiedStatus(labosRdvStatusCounts:LaboRdvStatusCount[], labo: Labo, day: Date) {
    return labosRdvStatusCounts
      .filter(counts => counts.laboId === labo.id && counts.date === DateTime.fromJSDate(day).toFormat("yyyy-MM-dd") && counts.status !== 'PENDING' && counts.status !== 'CANCEL' && counts.status !=  'REFUSED')
      .reduce((previousValue, currentValue) => previousValue + currentValue.count! , 0)
  }

  getRDVATraiter(labosRdvStatusCounts:LaboRdvStatusCount[]) {
    return labosRdvStatusCounts
    .filter(counts => counts.status === 'PENDING')
    .reduce((previousValue, currentValue) => previousValue + currentValue.count! , 0)
  }

  public ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }
}
