import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DayEnum, LaboForm} from '@domain/labo/Labo';
import {DocumentTypeEnum, Sample, StatusSampleEnum} from '@domain/samples/sample';
import {AggregatedUserSchedule, NoZoneSchedule, User, UserSchedule, UserScheduleException} from '@domain/user/user';
import { TranslocoService } from '@ngneat/transloco';
import {Navigate} from '@ngxs/router-plugin';
import {Actions, ofActionErrored, Select, Store} from '@ngxs/store';
import {ShowMessage} from '@states/global/global.actions';
import {MessageLevel} from '@states/global/global.state';
import { LoadOneLabo } from '@states/labos/labos.actions';
import { LabosStateSelector } from '@states/labos/labos.selectors';
import {
  DownloadPrescription,
  LoadPreleveur,
  LocalPatchSample, ResetOrderModified,
  SavePlanningForDayAndPreleveur,
  SetDate,
  UpdateSampleFilters,
} from '@states/samples/samples.actions';
import {SamplesStateSelector} from '@states/samples/samples.selectors';
import {LoadOneUser} from '@states/user/users.actions';
import {CreneauStatus, Disponibility} from '@utils/pipe/cut-hours.pipe';
import {DateTime} from 'luxon';
import {first, Observable, Subject, take, takeUntil} from 'rxjs';

@Component({
  selector: 'app-labo-planning-preleveur',
  templateUrl: './labo-planning-preleveur.component.html',
  styleUrls: ['./labo-planning-preleveur.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LaboPlanningPreleveurComponent implements OnInit, OnDestroy {

  @Select(SamplesStateSelector.currentPreleveur) currentPreleveur$: Observable<User>
  @Select(SamplesStateSelector.sortedSamples) samples$: Observable<Sample[]>
  @Select(SamplesStateSelector.filteredSamplesLoaded) loaded$: Observable<boolean>
  @Select(SamplesStateSelector.unaffectedSamples) unaffected$: Observable<Sample[]>
  @Select(SamplesStateSelector.sampleOrderModified) modified$: Observable<boolean>
  @Select(SamplesStateSelector.preleveurSchedule) schedule$: Observable<Disponibility[]>;
  @Select(LabosStateSelector.currentLabo) currentLabo$: Observable<LaboForm>;

  day: DayEnum;

  date: DateTime;

  isPast: boolean;

  creneauStatus: typeof CreneauStatus = CreneauStatus;

  labo: LaboForm;

  preleveur: User;

  // schedule: string[][];

  private destroy: Subject<boolean> = new Subject<boolean>();

  constructor(private store: Store, private route: ActivatedRoute, private router: Router, private actions$: Actions, private transloco: TranslocoService) {
  }

  ngOnInit(): void {
    this.store.dispatch(new ResetOrderModified())
    this.route.paramMap.pipe(takeUntil(this.destroy)).subscribe(val => {
      const laboId: string | null = val.get('laboId');
      const preleveurId: string | null = val.get('preleveurId');
      if (!laboId || !preleveurId) {
        return this.store.dispatch([
          new Navigate(['labos']), new ShowMessage({
            content: { title: 'load_user_failure' },
            level: MessageLevel.ERROR,
          }, 'users'),
        ])
      }

      this.getLabo(laboId)
      this.getPreleveur(preleveurId);
      this.currentPreleveur$.pipe(first(u => u != undefined)).subscribe(p => {

        if(p.suspended) {
          this.store.dispatch([
            new Navigate(['labos']), new ShowMessage({
              content: { title: 'load_user_failure' },
              level: MessageLevel.ERROR,
            }, 'users'),
          ])
        }
        this.preleveur = p
      })

      this.currentLabo$.pipe(first(u => u != undefined)).subscribe(l=> {
        this.labo = l
      })

      // this.schedule$.pipe(takeUntil(this.destroy)).subscribe(zoneSchedule => {
      //   this.schedule = zoneSchedule.map(s => s.schedule)
      // })


      return this.route.queryParams.pipe(take(1)).subscribe(val => {
        if (!val['date']) {
          this.date = DateTime.now();
        } else {
          this.date = DateTime.fromISO(val['date']!);
        }

        this.isPast = this.date < DateTime.now();
        this.day = this.date.setLocale('en-GB').weekdayLong.toUpperCase() as DayEnum;
        this.store.dispatch(new SetDate(this.date))

        this.schedule$.pipe(first(p => p != undefined)).subscribe(s => {
         if(s.length <= 0){
            return this.store.dispatch([new Navigate(['labos', laboId, 'planning'], { date: this.date.toFormat('yyyy-MM-dd') })]);
          }
          return;
        })

        this.getSamplesForPreleveur(preleveurId, laboId)
      })
    });

    this.actions$.pipe(ofActionErrored(LoadOneUser), takeUntil(this.destroy)).subscribe(s => {
      this.store.dispatch([
        new Navigate(['labos']), new ShowMessage({
          content: { title: 'load_labo_failure' },
          level: MessageLevel.ERROR,
        }, 'labo'),
      ])
    })
  }

  getPreleveur(id: string) {
    this.store.dispatch(new LoadPreleveur(id))
  }

  getLabo(id: string) {
    this.store.dispatch(new LoadOneLabo(id))
  }


  getSamplesForPreleveur(preleveurId: string, laboId: string) {
    this.store.dispatch(new UpdateSampleFilters({
      laboId: laboId,
      status: [
        StatusSampleEnum.PENDING,
        StatusSampleEnum.CONFIRM,
        StatusSampleEnum.AFFECTED,
        StatusSampleEnum.INPROGRESS,
        StatusSampleEnum.UNDERWAY,
        StatusSampleEnum.TOLABO,
        StatusSampleEnum.TOVALIDATE,
        StatusSampleEnum.DONE,
      ],
      preleveurId: preleveurId,
      inLabo: false,
      date: this.date.toFormat('yyyy-MM-dd'),
    }))
  }

  drop(event: CdkDragDrop<any>, creneau: Disponibility) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      event.container.data.forEach((e: Sample, i: number) => {
          this.store.dispatch(new LocalPatchSample(e.id!, {...e, orderInCreneau: i+1}))
        },
      )
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );

      event.previousContainer.data.forEach((p: Sample, i: number) => {
        this.store.dispatch(new LocalPatchSample(p.id!, {...p, orderInCreneau: i+1}))
        },
      )
      event.container.data.forEach((e: Sample, i: number) => {
          this.store.dispatch(new LocalPatchSample(e.id!, {...e, orderInCreneau: i+1}))
        },
      )
      // pour détecter qu'un rdv est dans un créneau il faut que son heure soit sous la forme '08:00:00' au lieu de '8:00:00' pour appartenir à un créneau
      const endHour = creneau.startHour + creneau.size;
      this.store.dispatch(new LocalPatchSample(event.container.data[event.currentIndex].id, {
        ...event.container.data[event.currentIndex],
        startTime: `${creneau.startHour >= 10 ? creneau.startHour : '0'+creneau.startHour}:00:00`,
        endTime: `${ endHour >= 10 ? endHour : '0'+endHour }:00:00`,
        preleveur: this.preleveur
      }))
    }
  }

  isCancellable(sample: Sample): boolean {
    if ([StatusSampleEnum.PENDING, StatusSampleEnum.AFFECTED, StatusSampleEnum.CONFIRM].includes(sample.status?.status!)) {
      return true;
    }
    return false;
  }

  getNotCancellableReason(sample: Sample): string {
    return this.transloco.translate("samples.notCancellableReason", {status: this.transloco.translate("samples.status." + sample.status?.status!)});
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

  filterByDay = (us: UserSchedule): boolean => {
    return us.day === this.day
  }

  filterByDate = (use: UserScheduleException): boolean => {
    return DateTime.fromISO(use.date).equals(this.date);
  }

  save() {
    this.store.dispatch(new SavePlanningForDayAndPreleveur())
  }

  disaffect(id: string) {
    this.store.dispatch(new LocalPatchSample(id, {
      preleveur: undefined,
      startTime: undefined,
      endTime: undefined,
      orderInCreneau: undefined,
      zoneId: undefined
    }))
  }

  noReturnPredicate() {
    return false;
  }

  downloadPrescription(sample: Sample) {
    sample.documents
      ?.filter(doc => doc.documentType === DocumentTypeEnum.PRESCRIPTION)
      .forEach(doc => {
        if(doc.id) {
          this.store.dispatch(new DownloadPrescription(doc.id, doc.filename || ''));
        }
      });
  }
}
