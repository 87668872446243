import {DragDropModule} from '@angular/cdk/drag-drop';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatTableModule} from '@angular/material/table';
import {AdresseFormComponent} from '@features/users/adresse-form/adresse-form.component';
import {EditUsersComponent} from '@features/users/edit-users/edit-users.component';
import {ListUsersComponent} from '@features/users/list-users/list-users.component';
import {TranslocoModule} from '@ngneat/transloco';
import {NgxsModule} from '@ngxs/store';
import {UsersState} from '@states/user/users.states';
import {SharedModule} from '@utils/shared.module';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {LaboratorySelectComponent} from '@features/users/laboratory-select/laboratory-select.component';
import { UsersRoutingModule } from './users-routing.module';
import { PreleveurAffectationZoneComponent } from './preleveur-affectation-zone/preleveur-affectation-zone.component';
import { UserSampleListComponent } from './user-sample-list/user-sample-list.component';
import { UserSampleEditComponent } from './user-sample-edit/user-sample-edit.component';
import {MatStepperModule} from '@angular/material/stepper';
import {SamplesModule} from '@features/samples/samples.module';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { AssociatePreleveurDialogComponent } from './associate-preleveur-dialog/associate-preleveur-dialog.component';

@NgModule({
  declarations: [ListUsersComponent, EditUsersComponent, AdresseFormComponent, LaboratorySelectComponent, PreleveurAffectationZoneComponent, UserSampleListComponent, UserSampleEditComponent, AssociatePreleveurDialogComponent],
  imports: [
    CommonModule,
    UsersRoutingModule,
    NgxsModule.forFeature([UsersState]),
    ReactiveFormsModule,
    TranslocoModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    FormsModule,
    SharedModule,
    MatRadioModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatStepperModule,
    SamplesModule,
    MatExpansionModule,
    MatCheckboxModule,
    DragDropModule,
  ],
  providers: [],
})
export class UserModule {}
