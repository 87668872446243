import { User } from '@domain/user/user';
import {AddressNormalized} from '@utils/coordinates/coordinates';
import { Feature, FeatureCollection, Geometry, Point } from 'geojson';
import {GeoJSON} from 'geojson';

export enum DayEnum {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
}
export const days: string[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

export interface LaboPagination {
  content: Labo[],
  size: number,
  number: number,
  first: boolean,
  last: boolean,
  totalElements: number,
  totalPages: number,
  numberOfElements: number
}

export interface Labo {
  id?: string;
  name?: string;
  address1?: string;
  address2?: string;
  postalCode?: string;
  city?: string;
  country?: string;
  phone?: string;
  position?: Point;
  zones?: LaboCouverture[];
  schedule?: Schedule[];
  zonesPreleveurs?: ZonePreleveur[];
  samplesPerTimeSlotForPreleveur?: number;
  lowerLimitPerTimeSlot?: number;
  upperLimitPerTimeSlot?: number;
  noHomeRxPrice?: number;
  samplingManual?: string;
  fixedSchedule?: FixedSchedule[];
}

export interface Schedule {
  day: DayEnum;
  startTime: string;
  endTime: string;
  startTime2: string;
  endTime2: string;
  id: string;
  id_2: string;
}

export interface FixedSchedule {
  id: string;
  delay: string;
  price: number;
}

export interface LaboRdvStatusCount {
  laboId?: string;
  date?: string;
  status?: string;
  count?: number;
}

export interface AddTime {
  mondayAddTime: boolean;
  tuesdayAddTime: boolean;
  wednesdayAddTime: boolean;
  thursdayAddTime: boolean;
  fridayAddTime: boolean;
  saturdayAddTime: boolean;
  sundayAddTime: boolean;
}

export interface LaboCouverture {
  zone?: GeoJSON;
  typeZone?: string;
  id?: string;
}

export interface LaboForm {
  id?: string;
  name?: string;
  coord?: AddressNormalized;
  zones?: LaboCouverture;
  schedule?: LaboFormSchedule;
  zonesPreleveurs?: ZonePreleveur[];
  samplesPerTimeSlotForPreleveur?: number;
  lowerLimitPerTimeSlot?: number;
  upperLimitPerTimeSlot?: number;
  position?: Point;
  noHomeRxPrice?: number;
  samplingManual?: string;
  fixedSchedule?: FixedSchedule[];
}

export interface LaboMapForm {
  marker?: L.LatLng;
  coverType?: string;
  coverPolygons?: FeatureCollection
}

export interface LaboFormSchedule {
  monday?: Schedule,
  tuesday?: Schedule,
  wednesday?: Schedule,
  thursday?: Schedule,
  friday?: Schedule,
  saturday?: Schedule,
  sunday?: Schedule
}

export interface ZonePreleveur {
  id?: string;
  name?: string;
  zone?: Geometry;
  color?: string;
}

export interface PreleveursOnZone {
  zoneId: string;
  loaded: boolean;
  preleveurs: User[];
}
