import { ProfileEnum } from '@domain/user/user';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';


export class UserInfo {
  roles: string[] = [];

  isAdmin: boolean = false;
  isManager: boolean = false;
  isPersonnelLabo: boolean = false;
  isSecretaire: boolean = false;
  isUser: boolean = false;

  user?: KeycloakProfile;

  userId?: string;

  token?: string;

  constructor(public keycloakService: KeycloakService) {
    this.initData();
  }

  initData(): void {
    if (this.keycloakService?.getKeycloakInstance()) {
      this.userId = this.keycloakService.getKeycloakInstance().subject;
      this.user = this.keycloakService.getKeycloakInstance().profile as KeycloakProfile;
      this.roles = this.keycloakService.getUserRoles(true);
      this.token = this.keycloakService.getKeycloakInstance().token;
      this.isAdmin = this.keycloakService.getKeycloakInstance().hasRealmRole(ProfileEnum.ADMIN);
      this.isManager = this.keycloakService.getKeycloakInstance().hasRealmRole(ProfileEnum.TOURNEE_MANAGER);
      this.isPersonnelLabo = this.keycloakService.getKeycloakInstance().hasRealmRole(ProfileEnum.PERSONNEL_LABO);
      this.isSecretaire = this.keycloakService.getKeycloakInstance().hasRealmRole(ProfileEnum.SECRETAIRE);
    }
  }
}
