import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDividerModule} from '@angular/material/divider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {SharedModule} from '@utils/shared.module';
import {HomeComponent} from './home/home.component';
import {HomeRoutingModule} from './home-routing.module';
import { NgxsModule } from '@ngxs/store';
import { NotificationsState } from '@states/notifications/notifications.state';

@NgModule({
  declarations: [HomeComponent],
  imports: [
    SharedModule,
    NgxsModule.forFeature([NotificationsState]),
    HomeRoutingModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatSelectModule,
    MatButtonModule,
    MatDividerModule,
    MatListModule,
    MatMenuModule,
  ],
  providers: [],
})
export class HomeModule {}
