import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {LaboForm} from '@domain/labo/Labo';
import {UserForm, UserZone} from '@domain/user/user';
import {Navigate} from '@ngxs/router-plugin';
import {Select, Store} from '@ngxs/store';
import {UserInfo} from '@security/UserInfo';
import {ShowMessage} from '@states/global/global.actions';
import {MessageLevel} from '@states/global/global.state';
import {LabosStateSelector} from '@states/labos/labos.selectors';
import {AddAffectedZone, LoadOneUser, RemoveAffectedZone, SaveAffectedZone, SaveOneUser, UpdateZone} from '@states/user/users.actions';
import {UsersStateSelector} from '@states/user/users.selectors';
import {Polygon} from 'geojson';
import {KeycloakService} from 'keycloak-angular';
import * as L from 'leaflet';
import {Observable, Subject, take, takeUntil} from 'rxjs';
import {KantysTileLayer} from '../../../shared/maps/TileLayer';

@Component({
  selector: 'app-preleveur-affectation-zone',
  templateUrl: './preleveur-affectation-zone.component.html',
  styleUrls: ['./preleveur-affectation-zone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreleveurAffectationZoneComponent extends UserInfo implements OnInit, OnDestroy {

  @Select(UsersStateSelector.chosenLabos) labos$: Observable<LaboForm[]>;
  @Select(UsersStateSelector.affectedZones) affectedZones$: Observable<UserZone[]>;
  @Select(UsersStateSelector.currentUser) currentUser$: Observable<UserForm>;
  public formGroups: FormGroup[] = [];
  myIcon = L.icon({
    iconUrl: 'assets/img/pin-K.svg',
    iconSize: [34, 40],
    iconAnchor: [18, 43],
    popupAnchor: [-2, -40],
  });
  colorIdx = 0;
  private user_id: string;
  private map: L.Map;
  private destroy: Subject<boolean> = new Subject<boolean>();
  private polygonLayers = L.featureGroup();
  private laboMarkers: L.Marker[] = [];

  constructor(public override keycloakService: KeycloakService, private store: Store, private route: ActivatedRoute, private router: Router, private fb: FormBuilder, private _cd: ChangeDetectorRef) {
    super(keycloakService);
  }

  ngOnInit(): void {

    this.initMap();
    this.markLabo();

    this.route.paramMap.pipe(takeUntil(this.destroy)).subscribe(val => {
      const id: string | null = val.get('id');
      if (!id) {
        this.store.dispatch([
          new Navigate(['users']), new ShowMessage({
            content: { title: 'load_labo_failure' },
            level: MessageLevel.ERROR,
          }, 'labo'),
        ])
      }
      this.user_id = id!;
    });

    this.labos$.pipe(takeUntil(this.destroy)).subscribe(labos => {
      if (labos) {
        this.formGroups = [];
        this.polygonLayers.clearLayers();
        labos.forEach((labo, i) => {
          labo.zonesPreleveurs?.forEach((zone, j) => {
            if (zone.zone) {
              const color = this.selectColor((i+j)*(i+j+1)/2 + j);

              const layer = new L.Polygon(
                (zone.zone as Polygon).coordinates[0].map(c => {
                  return new L.LatLng(c[1], c[0])
                }),
              );
              this.formGroups.push(
                this.fb.group({
                  id: new FormControl(zone.id, [Validators.required]),
                  name: new FormControl(zone.name, [Validators.required]),
                  laboName: new FormControl(labo.name, [Validators.required]),
                  layer: new FormControl(layer),
                  color: new FormControl(color),
                }),
              );
              layer.addEventListener('click', (event) => {
                const userZone: UserZone = {
                  name: zone.name,
                  laboName: labo.name,
                  zoneId: zone.id,
                  color: color,
                };
                this.affectedZones$.pipe(take(1)).subscribe(zones => {
                  if (!zones.some(zone => zone.zoneId === userZone.zoneId)) {
                    this.store.dispatch(new AddAffectedZone(userZone));
                  }
                });

              });
              layer.addEventListener('mouseover', (event) => {
                var tooltip = L.tooltip().setLatLng(event.latlng).setContent(labo.name + " - " + zone.name).addTo(this.map);
                layer.addEventListener('mouseout', (event)=> {
                  this.map.closeTooltip(tooltip);
                });
              });

              //Ajout couleur zone
              this.affectedZones$.pipe(take(1)).subscribe(zones => {
                zones.forEach(zone => {
                  let formGroup: FormGroup = this.formGroups.filter(formGroup => formGroup.value.id === zone.zoneId)[0];
                  if (zone.id && formGroup.value.color) {
                    const updatedZone: UserZone = {
                      id: zone.id,
                      name: zone.name,
                      laboName: zone.laboName,
                      zoneId: zone.zoneId,
                      color: formGroup.value.color
                    }
                    this.store.dispatch(new UpdateZone(zone.id, updatedZone));
                  }
                });
              });
              layer.setStyle({ color }).addTo(this.polygonLayers);

              this.colorIdx++;
            }
          });
        });
        this._cd.detectChanges();
        var group: L.FeatureGroup = new L.FeatureGroup(this.formGroups.map(group => group.value.layer));
        this.laboMarkers.forEach(marker => group.addLayer(marker));
        this.map.fitBounds(group.getBounds());
      }
    })
  }

  selectColor(number: number, alpha: number = 1) {
    const hue = number * 137.508;
    return `hsla(${hue}, 100%, 40%, ${alpha})`;
  }

  initMap(): void {
    this.map = L.map('mapZones', {
      center: [47, 2],
      zoom: 5,
    });
    new KantysTileLayer().addTo(this.map);


    this.polygonLayers.addTo(this.map);
  }

  save(): void {
   this.store.dispatch(new SaveAffectedZone());
  }

  removeZone(zone: UserZone): void {
    if (zone.zoneId) {
      this.store.dispatch(new RemoveAffectedZone(zone.zoneId));
    }
  }

  markLabo(): void {
    this.labos$.pipe(takeUntil(this.destroy)).subscribe(labos => {
      labos.forEach(labo => {
        if (labo && labo.position) {
          let laboMarker = L.marker(new L.LatLng(labo.position.coordinates[1], labo.position.coordinates[0]), { icon: this.myIcon });
          this.laboMarkers.push(laboMarker);
          laboMarker.addTo(this.map);
          this.map.flyTo(new L.LatLng(labo.position.coordinates[1], labo.position.coordinates[0]), 10, { duration: 1 });
        }
      });
    });

  }

  goBack(): void {
    this.router.navigate(['users', 'edit', this.user_id]);
  }

  ngOnDestroy(): void {
    this.map.off();
    this.map.remove();
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

}
