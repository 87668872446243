import {Injectable} from '@angular/core';
import {Nurse, NurseFilter, NurseForm} from '@domain/nurse/Nurse';
import {Navigate} from '@ngxs/router-plugin';
import {Action, State, StateContext} from '@ngxs/store';
import {patch} from '@ngxs/store/operators';
import {MapService} from '@service/map.service';
import {NurseService} from '@service/nurse.service';
import {ShowMessage} from '@states/global/global.actions';
import {MessageLevel} from '@states/global/global.state';
import {
  DeleteOneNurse,
  DeleteOneNurseFailure,
  DeleteOneNurseSuccess,
  LoadFilteredNurses,
  LoadFilteredNursesFailure,
  LoadFilteredNursesSuccess,
  LoadOneNewNurse,
  LoadOneNurse,
  LoadOneNurseFailure,
  LoadOneNurseSuccess,
  SaveOneNurse,
  SaveOneNurseFailure,
  SaveOneNurseSuccess,
  SearchAddress,
  SearchAddressFailure,
  SearchAddressSuccess,
  UpdateNurseFilter,
  UpdateNursePagination,
  UpdateNurseSort,
} from '@states/nurse/nurses.actions';
import {SaveOneUserFailure} from '@states/user/users.actions';
import {Pagination} from '@utils/pagination/Pagination';
import {PaginationOption, SortDirectionEnum} from '@utils/pagination/PaginationOption';
import {Point} from 'geojson';
import {map} from 'rxjs';
import {catchError} from 'rxjs/operators';

export interface NursesStateModel {
  currentNurse?: NurseForm;
  filteredNurses?: Pagination<Nurse>;
  filteredNursesLoaded: boolean;
  paginationOptions: PaginationOption;
  nurseFilter: NurseFilter;
  nursePosition?: Point;
}

@State<NursesStateModel>({
  name: 'nurses',
  defaults: {
    filteredNurses: {
      content: [],
      totalElements: 0,
    },
    filteredNursesLoaded: true,
    nurseFilter: {
      search: '',
    },
    paginationOptions: {
      length: 100,
      pageSize: 25,
      pageIndex: 0,
      pageSizeOption: [10, 25, 50, 100],
    },
    nursePosition: undefined,
  },
})
@Injectable()
export class NursesState {
  constructor(private nurseService: NurseService, private mapService: MapService) {}

  @Action(LoadOneNurse)
  loadOneNurse(ctx: StateContext<NursesStateModel>, {id}: LoadOneNurse) {
    return this.nurseService.get(id).pipe(
      map(nurse => ctx.dispatch(new LoadOneNurseSuccess(nurse))),
      catchError((error: string) => ctx.dispatch(new LoadOneNurseFailure(error)))
    );
  }

  @Action(LoadOneNurseSuccess)
  loadOneNurseSuccess(ctx: StateContext<NursesStateModel>, {nurse}: LoadOneNurseSuccess) {
    if (nurse) {
      ctx.patchState({
        currentNurse: {
          id: nurse.id,
          informations: {
            name: nurse.name,
            phone: nurse.phone,
            email: nurse.email,
          },
          address: {
            address1: nurse.address1,
            address2: nurse.address2,
            postalCode: nurse.postalCode,
            city: nurse.city,
            country: nurse.country,
          },
          profilePicture: nurse.profilePicture,
        },
      });
    }
  }

  @Action(LoadOneNurseFailure)
  loadOneNurseFailure(ctx: StateContext<NursesStateModel>, {error}: LoadOneNurseFailure) {
    ctx.dispatch(new ShowMessage({content: {title: 'load_nurse_failure'}, level: MessageLevel.ERROR}, 'nurses'));
  }

  @Action(LoadOneNewNurse)
  loadOneNewNurse(ctx: StateContext<NursesStateModel>) {
    ctx.patchState({
      currentNurse: {
        id: undefined,
        informations: {
          name: '',
          phone: '',
          email: '',
        },
        address: {
          address1: '',
          address2: '',
          postalCode: '',
          city: '',
          country: 'FR',
        },
        profilePicture: '',
      },
    });
  }

  @Action(SaveOneNurse)
  saveOneNurse(ctx: StateContext<NursesStateModel>, {nurse}: SaveOneNurse) {
    const state = ctx.getState();
    const nurseDto: Nurse = {
      id: nurse.id,
      ...nurse.informations,
      ...nurse.address,
      profilePicture: nurse.profilePicture,
      position: state.nursePosition,
    };
    if (nurse.id) {
      return this.nurseService.update(nurse.id, nurseDto).pipe(
        map(nurse => ctx.dispatch(new SaveOneNurseSuccess())),
        catchError((error: string) => ctx.dispatch(new SaveOneUserFailure(error)))
      );
    } else {
      return this.nurseService.create(nurseDto).pipe(
        map(nurse => ctx.dispatch(new SaveOneNurseSuccess())),
        catchError((error: string) => ctx.dispatch(new SaveOneNurseFailure(error)))
      );
    }
  }

  @Action(SaveOneNurseSuccess)
  saveOneNurseSucess(ctx: StateContext<NursesStateModel>) {
    ctx.dispatch(new ShowMessage({content: {title: 'nurse_saved_success'}, level: MessageLevel.SUCCESS}, 'nurses'));
    ctx.dispatch(new Navigate(['/nurses']));
  }

  @Action(SaveOneNurseFailure)
  saveOneNurseFailure(ctx: StateContext<NursesStateModel>, {error}: SaveOneNurseFailure) {
    ctx.dispatch(new ShowMessage({content: {title: 'save_nurse_failure'}, level: MessageLevel.ERROR}, 'nurses'));
  }

  @Action(DeleteOneNurse)
  deleteOneNurse(ctx: StateContext<NursesStateModel>, {id}: DeleteOneNurse) {
    return this.nurseService.delete(id).pipe(
      map(() => ctx.dispatch(new DeleteOneNurseSuccess())),
      catchError((error: string) => ctx.dispatch(new DeleteOneNurseFailure(error)))
    );
  }

  @Action(DeleteOneNurseSuccess)
  deleteOneNurseSuccess(ctx: StateContext<NursesStateModel>) {
    ctx.dispatch(new ShowMessage({content: {title: 'nurse_deleted_success'}, level: MessageLevel.SUCCESS}, 'nurses'));
    ctx.dispatch(new Navigate(['/nurses']));
  }

  @Action(DeleteOneNurseFailure)
  deleteOneNurseFailure(ctx: StateContext<NursesStateModel>, {error}: DeleteOneNurseFailure) {
    ctx.dispatch(new ShowMessage({content: {title: 'delete_nurse_failure'}, level: MessageLevel.ERROR}, 'nurses'));
  }

  @Action(LoadFilteredNurses)
  loadFilteredNurses(ctx: StateContext<NursesStateModel>) {
    ctx.patchState({
      filteredNursesLoaded: false,
    });
    const state = ctx.getState();
    return this.nurseService.findByFiltersPaging(state.nurseFilter, state.paginationOptions).pipe(
      map(nurses => ctx.dispatch(new LoadFilteredNursesSuccess(nurses))),
      catchError((error: string) => ctx.dispatch(new LoadFilteredNursesFailure(error)))
    );
  }

  @Action(LoadFilteredNursesSuccess)
  loadFilteredNursesSuccess(ctx: StateContext<NursesStateModel>, {nurses}: LoadFilteredNursesSuccess) {
    ctx.patchState({
      filteredNursesLoaded: false,
    });
    const state = ctx.getState();
    if (nurses) {
      ctx.patchState({
        filteredNurses: nurses,
        paginationOptions: {
          ...state.paginationOptions,
          length: nurses.totalElements,
        },
        filteredNursesLoaded: true,
      });
    }
  }

  @Action(LoadFilteredNursesFailure)
  loadFilteredNursesFailure(ctx: StateContext<NursesStateModel>, {error}: LoadFilteredNursesFailure) {
    ctx.setState(
      patch({
        filteredNursesLoaded: true,
      })
    );
    ctx.dispatch(new ShowMessage({content: {title: 'load_nurses_failure'}, level: MessageLevel.ERROR}, 'nurses'));
  }

  @Action(UpdateNursePagination)
  updatePagination(ctx: StateContext<NursesStateModel>, {paginationOption}: UpdateNursePagination) {
    const state = ctx.getState();
    ctx.patchState({
      paginationOptions: {
        ...state.paginationOptions,
        ...paginationOption,
      },
    });
    return ctx.dispatch(new LoadFilteredNurses());
  }

  @Action(UpdateNurseSort)
  updateSort(ctx: StateContext<NursesStateModel>, {sortByColumnName}: UpdateNurseSort) {
    const state = ctx.getState();
    let sortDirection: SortDirectionEnum;
    if (state.paginationOptions.sortByColumnName === sortByColumnName && state.paginationOptions.sortDirection === SortDirectionEnum.ASC) {
      sortDirection = SortDirectionEnum.DESC;
    } else if (
      state.paginationOptions.sortByColumnName === sortByColumnName &&
      state.paginationOptions.sortDirection === SortDirectionEnum.DESC
    ) {
      sortDirection = SortDirectionEnum.ASC;
    } else {
      sortDirection = SortDirectionEnum.ASC;
    }
    ctx.patchState({
      paginationOptions: {
        ...state.paginationOptions,
        sortByColumnName: sortByColumnName,
        sortDirection: sortDirection,
      },
    });
    return ctx.dispatch(new LoadFilteredNurses());
  }

  @Action(UpdateNurseFilter)
  updateFilter(ctx: StateContext<NursesStateModel>, {filter, pagination}: UpdateNurseFilter) {
    const state = ctx.getState();
    ctx.patchState({
      nurseFilter: {
        ...state.nurseFilter,
        ...filter,
      },
      paginationOptions: {
        ...state.paginationOptions,
        pageIndex: 0,
        ...pagination,
      },
    });
    return ctx.dispatch(new LoadFilteredNurses());
  }

  @Action(SearchAddress)
  searchAddress(ctx: StateContext<NursesStateModel>, {nurse}: SearchAddress) {
    return this.mapService.searchByAddress(nurse).pipe(
      map(result => ctx.dispatch(new SearchAddressSuccess(result))),
      catchError((error: string) => ctx.dispatch(new SearchAddressFailure(error)))
    );
  }
  @Action(SearchAddressSuccess)
  searchAddressSuccess(ctx: StateContext<NursesStateModel>, {searchResults}: SearchAddressSuccess) {
    if (searchResults && searchResults.length > 0) {
      searchResults.sort((a, b) => {
        return a.importance - b.importance;
      });
      const point: Point = {
        type: 'Point',
        coordinates: [searchResults[0].lon, searchResults[0].lat],
      };
      ctx.patchState({
        nursePosition: point,
      });
    }
  }

  @Action(SearchAddressFailure)
  searchAddressFailure(ctx: StateContext<NursesStateModel>, {error}: SearchAddressFailure) {

  }
}
