import { Pipe, PipeTransform } from '@angular/core';
import { LaboForm } from '@domain/labo/Labo';

@Pipe({
  name: 'getLaboName'
})
export class GetLaboName implements PipeTransform {

  transform(zoneId: string, labo: LaboForm): string {
    return labo.zonesPreleveurs?.find(z => z.id === zoneId)?.name!
  }

}
