import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {days, Schedule} from '@domain/labo/Labo';
import {environment} from '@environment/environment';
import {intersection} from 'lodash';
import {DateTime} from 'luxon';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScheduleService {
  metropole: DateTime[] = [];
  alsaceMoselle: DateTime[] = [];

  constructor(private http: HttpClient) {}

  async initHolidays(): Promise<void> {
    this.metropole = Object.keys(await firstValueFrom(this.http.get(`${environment.api}/external/jours-feries/metropole.json`))).map(d => DateTime.fromISO(d));
    this.alsaceMoselle = Object.keys(await firstValueFrom(this.http.get(`${environment.api}/external/jours-feries/alsace-moselle.json`))).map(d => DateTime.fromISO(d));
  }

  /**
   * generate N dates based on schedule
   * @param scheduleDays
   * @param nb
   * @param cp
   * @param startDate
   * @returns
   */
  async generateNextXDate(scheduleDays: Schedule[], nb: number, cp?: string, startDate?: Date): Promise<Date[]> {
    if (!this.metropole.length || !this.alsaceMoselle.length) {
      await this.initHolidays();
    }

    const holidays: DateTime[] = cp?.startsWith('67') || cp?.startsWith('68') || cp?.startsWith('57') ? this.alsaceMoselle : this.metropole;

    const result: Date[] = [];
    let current: DateTime = startDate ? DateTime.fromJSDate(startDate).plus({days: 1}) : DateTime.now();
    // if no schedule days provide Or none of them correspond to days list
    if (
      !scheduleDays.length ||
      !intersection(
        scheduleDays.map(sch => sch.day),
        days
      ).length
    ) {
      return result;
    }

    // to remove today if endTime is passed
    const first: Schedule | undefined = scheduleDays.find((sch: Schedule) => sch.day === days[current.weekday - 1]);
    if ((first?.endTime2 && DateTime.fromISO(first.endTime2) < current) || (!first?.endTime2 && first?.endTime && DateTime.fromISO(first.endTime) < current)) {
      current = current.plus({days: 1});
    }

    do {
      const scheduleDay: Schedule | undefined = scheduleDays.find((sch: Schedule) => sch.day === days[current.weekday - 1]);
      if (scheduleDay && !holidays.some(holiday => current.startOf('day').equals(holiday))) {
        result.push(current.toJSDate());
      }
      current = current.plus({days: 1});
    } while (result.length < nb);
    return result;
  }

  /**
   * generate N dates
   * @param scheduleDays
   * @param nb
   * @param cp
   * @param startDate
   * @returns
   */
  async getNextXDate(nb: number, cp?: string, startDate?: Date): Promise<Date[]> {
    if (!this.metropole.length || !this.alsaceMoselle.length) {
      await this.initHolidays();
    }

    const holidays: DateTime[] = cp?.startsWith('67') || cp?.startsWith('68') || cp?.startsWith('57') ? this.alsaceMoselle : this.metropole;

    const result: Date[] = [];
    let current: DateTime = startDate ? DateTime.fromJSDate(startDate).plus({days: 1}) : DateTime.now().plus({days: 1});
    // if no schedule days provide Or none of them correspond to days list

    do {
      if (!holidays.some(holiday => current.startOf('day').equals(holiday))) {
        result.push(current.toJSDate());
      }
      current = current.plus({days: 1});
    } while (result.length < nb);
    return result;
  }
}
