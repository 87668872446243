import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {RouterModule} from '@angular/router';
import {TranslocoModule} from '@ngneat/transloco';
import {DialogConfirmationComponent} from '@utils/confirmation-dialog/dialog-confirmation.component';
import {FileUploadComponent} from '@utils/file-upload/file-upload/file-upload.component';
import {ModalConfirmComponent} from '@utils/modal/modal-confirm/modal-confirm.component';
import {ModalErrorComponent} from '@utils/modal/modal-error/modal-error.component';
import {ModalInfoComponent} from '@utils/modal/modal-info/modal-info.component';
import {FindOrderInJourneePipe} from '@utils/pipe/find-order-in-journee.pipe';
import {GetLaboSchedule} from '@utils/pipe/get-labo-schedule.pipe';
import {SafizerPipe} from '@utils/pipe/safizer.pipe';
import {ModalService} from '@utils/service/modal.service';
import { FilterPipe } from './pipe/filter.pipe';
import { CutHoursPipe } from './pipe/cut-hours.pipe';
import { ToFormControlPipe } from './pipe/to-form-control.pipe';
import { HourPipe } from './pipe/hour.pipe';
import { ToDateTimePipe } from './pipe/to-date-time.pipe';
import { SampleCountPipe } from './pipe/sample-count.pipe';
import {SearchbarComponent} from '@utils/searchbar/searchbar.component';
import {MatProgressBarModule} from "@angular/material/progress-bar";
import { GetLaboName } from './pipe/get-labo-name.pipe';
import {ToFormGroupPipe} from "@utils/pipe/to-form-group.pipe";

@NgModule({
    declarations: [SafizerPipe, GetLaboName, FilterPipe, CutHoursPipe, ToFormControlPipe, HourPipe, ToDateTimePipe, SampleCountPipe, SearchbarComponent, DialogConfirmationComponent, FileUploadComponent, FindOrderInJourneePipe, ModalConfirmComponent, ModalInfoComponent, ModalErrorComponent, ToFormGroupPipe, GetLaboSchedule],
  imports: [CommonModule, MatFormFieldModule, FormsModule, TranslocoModule, MatIconModule, MatDialogModule, ReactiveFormsModule, MatInputModule, MatButtonModule, MatProgressBarModule],
    exports: [
        CommonModule, RouterModule, FormsModule, ReactiveFormsModule, HttpClientModule, SafizerPipe, TranslocoModule, FilterPipe, CutHoursPipe, ToFormControlPipe, HourPipe, ToDateTimePipe, SampleCountPipe, SearchbarComponent,
        DialogConfirmationComponent, FileUploadComponent, FindOrderInJourneePipe, GetLaboName, ToFormGroupPipe, GetLaboSchedule],
  providers: [ModalService]
})
export class SharedModule {}
