import {TranslocoService} from '@ngneat/transloco';
import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {
  EndInitialization,
  LoadUserProfile,
  LoadUserProfileFailure,
  LoadUserProfileSuccess,
  LoadUserRoleFailure,
  Logout,
  RegisterForNotification,
  RegisterForNotificationFailure,
  ShowMessage,
  ShowNotification,
  SubscribeToTopic,
} from './global.actions';
import {catchError, from, map, Observable, tap} from 'rxjs';
import {KeycloakService} from 'keycloak-angular';
import { NotificationService } from '@service/notification.service';
import { Registration } from '@domain/notification/registration';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationComponent } from 'src/app/shared/notifications/notification.component';

export interface GlobalStateModel {
  currentUser?: Keycloak.KeycloakProfile;
  isInitializing: boolean;
  isAuthenticated: boolean;
}

export interface Message {
  content: {
    title: string;
    details?: string;
  };
  level?: MessageLevel;
}

export enum MessageLevel {
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
}

@State<GlobalStateModel>({
  name: 'global',
  defaults: {
    isInitializing: true,
    isAuthenticated: false,
    currentUser: undefined,
  },
})
@Injectable()
export class GlobalState {

  constructor(private _translator: TranslocoService, private _keycloak: KeycloakService, private notificationService: NotificationService,
    private snackBar: MatSnackBar) {}

  @Action(ShowMessage)
  showMessage(_ctx: StateContext<GlobalStateModel>, {message, scope, data}: ShowMessage): void {
    if (!message.content || !message.content.title) {
      return;
    }
    let title: string = '';
    let details: string | undefined;
    if (scope) {
      const subScop: string = message.level ? message.level : MessageLevel.ERROR;
      title = 'messages.' + subScop.toLowerCase() + '.' + scope + '.' + message.content.title;
      if (message.content.details) {
        details = 'messages.' + subScop.toLowerCase() + '.' + scope + '.' + message.content.details;
      }
    }
    const translatedTitle: string = this._translator.translate(title, data);
    const translatedDetails: string = details ? this._translator.translate(details) : '';

    this.snackBar.open(translatedTitle,
      "",
      {
        duration: 3000,
        panelClass: 'snack-style-' + [message.level || MessageLevel.ERROR]
      }
    )

  }

  @Action(Logout)
  logout(ctx: StateContext<GlobalStateModel>): Promise<void> {
    ctx.patchState({
      currentUser: undefined,
    });
    return this._keycloak.logout().then(() => this._keycloak.clearToken());
  }

  @Action(EndInitialization)
  endInitialization(ctx: StateContext<GlobalStateModel>): void {
    ctx.patchState({
      isInitializing: false,
    });
  }

  @Action(LoadUserProfile)
  loadUserProfile(ctx: StateContext<GlobalStateModel>): Observable<void | Observable<void>> {
    return from(this._keycloak.loadUserProfile()).pipe(
      map(profile => ctx.dispatch(new LoadUserProfileSuccess(profile))),
      catchError((error: any) => ctx.dispatch(new LoadUserProfileFailure(error)))
    );
  }

  @Action(LoadUserProfileSuccess)
  loadUserProfileSuccess(ctx: StateContext<GlobalStateModel>, {profile}: LoadUserProfileSuccess): void {
    ctx.patchState({
      currentUser: profile,
      isInitializing: false,
      isAuthenticated: true,
    });
  }

  @Action(LoadUserRoleFailure)
  loadUserRoleFailure(ctx: StateContext<GlobalStateModel>, {error}: LoadUserRoleFailure): void {
    ctx.dispatch(new ShowMessage({content: {title: 'load_user_role_failure', details: error}, level: MessageLevel.ERROR}, 'global'));
  }

  @Action(RegisterForNotification)
  registerForNotification(ctx: StateContext<GlobalStateModel>, {registration}: RegisterForNotification): Observable<void> {
    return this.notificationService.register(registration).pipe(
      catchError((error: any) => ctx.dispatch(new RegisterForNotificationFailure(error)))
    )
  }

  @Action(RegisterForNotificationFailure)
  registerForNotificationFailure(ctx: StateContext<GlobalStateModel>, {error}: RegisterForNotificationFailure): void {
    ctx.dispatch(new ShowMessage({content: {title: 'unable_to_register', details: error}, level: MessageLevel.ERROR}, 'global'));
  }

  @Action(ShowNotification)
  showNotification(ctx: StateContext<GlobalStateModel>, {notification}: ShowNotification): void {
    this.snackBar.openFromComponent(NotificationComponent, {data: notification, horizontalPosition:'right', verticalPosition: 'top', duration:3000});
  }

  @Action(SubscribeToTopic)
  subscribeToTopic(ctx: StateContext<GlobalStateModel>, {token, topic, key}: SubscribeToTopic): Observable<void> {
    return this.notificationService.subscribeToTopic(token, topic, key);
  }
}
