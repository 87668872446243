<button mat-icon-button class="close-button" (click)="close()">
  <mat-icon color="warn">close</mat-icon>
</button>
<div mat-dialog-title class="d-flex flex-row justify-content-between mb-4">
  <div class="title">
    <img class="icon" src="assets/img/users.svg">
    <span class="ms-3">{{'samples.planning.details.title' | transloco}}</span>
  </div>
  <div [ngClass]="data.sample.saved ? 'saved' : 'not-saved'">
    {{ 'samples.planning.details.' + (data.sample.saved ? 'statusSaved' : 'statusNotSaved') | transloco}}
    <span *ngIf="data.sample.saved" class="saved">le {{data.sample.savedAt | date: 'dd-MM-yyyy à HH:mm'}}</span>
  </div>
</div>
<ng-container *ngIf="sample$ | async as sample">
  <div mat-dialog-content>
    <div class="d-flex justify-content-start">
      <div class="w-50">
        <div class="mb-2"> <span class="bold-cabin">{{'samples.planning.details.patient' | transloco}}</span>{{data.sample.patientIpp}} </div>
        <div>{{data.sample.userName}}</div>
        <div class="pt-2"><span>{{'samples.planning.details.secu' | transloco}}</span>
          <span *ngIf="data.sample.numeroSecu">{{data.sample.numeroSecu}}</span>
          <span *ngIf="!data.sample.numeroSecu">{{'samples.planning.details.unknown' | transloco}}</span>
        </div>
        <div class="mt-4">
          <span class="bold-cabin">{{'samples.planning.details.documents.title' | transloco}}</span>
          <div *ngIf="documents$ | async as documents">
            <div class="row w-75">
              <span class="col-8">{{'samples.planning.details.documents.idCard' | transloco}}</span>
              <mat-icon class="col-auto ms-auto" *ngIf="hasIdCard$ | async; else no" color="accent" role="button" (click)="downloadDocument(documentTypeEnum.ID_CARD)">download</mat-icon>
            </div>
            <div class="row w-75">
              <span class="col-8">{{'samples.planning.details.documents.healthCard' | transloco}}</span>
              <mat-icon class="col-auto ms-auto" *ngIf="hasHealthCard$ | async; else no" color="accent" role="button" (click)="downloadDocument(documentTypeEnum.HEALTH_CARD)">download</mat-icon>
            </div>
            <div class="row w-75">
              <span class="col-8">{{'samples.planning.details.documents.healthAttestation' | transloco}}</span>
              <mat-icon class="col-auto ms-auto" *ngIf="hasHealthAttestation$ | async; else no" color="accent" role="button" (click)="downloadDocument(documentTypeEnum.HEALTH_ATTESTATION)">download</mat-icon>
            </div>
            <div class="row w-75">
              <span class="col-8">{{'samples.planning.details.documents.insuranceCard' | transloco}}</span>
              <mat-icon class="col-auto ms-auto" *ngIf="hasInsuranceCard$ | async; else no" color="accent" role="button" (click)="downloadDocument(documentTypeEnum.INSURANCE_CARD)">download</mat-icon>
            </div>
          </div>
        </div>
        <div class="mt-3" *ngIf="resultDiffusion$ | async as resultDiffusion">
          <span class="bold-cabin">{{'samples.planning.details.results.title' | transloco}}</span>
          <div class="row w-75">
            <span class="col-10">{{'samples.planning.details.results.internetServer' | transloco}}</span>
            <mat-icon class="col-auto ms-auto" color="primary" *ngIf="resultDiffusion.resultByInternetServer; else no">check</mat-icon>
          </div>
          <div class="row w-75">
            <span class="col-10">{{'samples.planning.details.results.mail' | transloco}}</span>
            <mat-icon class="col-auto ms-auto" color="primary" *ngIf=" resultDiffusion.resultByMail; else no">check</mat-icon>
          </div>
          <div class="row w-75">
            <span class="col-10">{{'samples.planning.details.results.postal' | transloco}}</span>
            <mat-icon class="col-auto ms-auto" color="primary" *ngIf=" resultDiffusion.resultByPostal; else no">check</mat-icon>
          </div>
          <div class="row w-75">
            <span class="col-10">{{'samples.planning.details.results.nurseDiffusion' | transloco}}</span>
            <mat-icon class="col-auto ms-auto" color="primary" *ngIf=" resultDiffusion.resultDiffusionNurse; else no">check</mat-icon>
          </div>
        </div>
      </div>
      <mat-divider [vertical]="true"></mat-divider>
      <div class="w-50 ms-3">
        <div class="mb-3"> <span class="bold-cabin">{{'samples.planning.details.sample.title' | transloco}}</span>{{data.sample.noSejour}}</div>
        <div class="mb-3"><span class="bold-cabin"> {{'samples.planning.details.sample.'+ (sample.inLabo? 'labo': 'attachedLabo') | transloco}}</span> {{sample.labo?.name}}</div>
        <div class="mb-3">
          <span class="bold-cabin">{{'samples.planning.details.sample.prescription' | transloco}}</span>
          <div class="ms-2">
            <div *ngIf="data.sample.hasRx && !hasUploadedPresciption(data.sample)">
              <img class="icon_check me-1" src="assets/img/files.svg" alt="ordonnance non uploadée">
              <span class="legend">{{'samples.planning.details.sample.noUpload' | transloco}}</span>
            </div>
            <div *ngIf=" data.sample.hasRx && hasUploadedPresciption(data.sample)">
              <img class="icon_check me-1" src="assets/img/prescription-ok.svg" alt="ordonnance uploadée">
              <button mat-icon-button (click)="downloadPrescription(sample)">
                <mat-icon color="accent">download</mat-icon>
              </button>
            </div>
            <div *ngIf="!data.sample.hasRx">
              <img class="icon_check me-1" src="assets/img/prescription-missing.svg" alt = "absence d'ordonnance ">
              <span class="legend">{{'samples.planning.details.sample.noRx' | transloco}}</span>
            </div>
            <div *ngIf="data.sample.hasRx">
              <div class="row w-90">
                <span class="col-11">{{'samples.planning.details.sample.renewableRx' | transloco}}</span>
                <mat-icon class="col-auto ms-auto" color="primary" *ngIf="sample.renewableRx; else no">check</mat-icon>
              </div>
            </div>
            <div *ngIf="data.sample.hasRx">
              <div class="row w-90">
                <span class="col-11">{{'samples.planning.details.sample.transmittedRx' | transloco}}</span>
                <mat-icon class="col-auto ms-auto" color="primary" *ngIf="sample.transmittedLaboRx; else no">check</mat-icon>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="data.sample.checks.length > 0" class="mb-3">
          <span class="bold-cabin">{{'samples.planning.details.sample.checks' | transloco}}</span>
          <div *ngFor="let check of data.sample.checks" class="mt-2">
            <span class="fw-bold ms-2">{{check.libelle}}</span> ({{check.price}}€)
            <div *ngIf="check.consignesPreleveur" class="ms-3">
              <img src="assets/img/information.svg" alt="information icon" class="information-icon">
              <span class="legend me-1">{{'samples.planning.details.sample.preleveurConsigne' | transloco}}</span>
              <span class="consigne"> {{check.consignesPreleveur}}</span>
            </div>
            <div *ngIf="check.informationsPatient" class="ms-3">
              <img src="assets/img/information.svg" alt="information icon" class="information-icon">
              <span class="legend me-1">{{'samples.planning.details.sample.patientConsigne' | transloco}}</span>
              <span class="consigne"> {{check.informationsPatient}} </span>
            </div>
            <div *ngIf="check.jeun" class="ms-3 mb-1 legend">
              <img src="assets/img/information.svg" alt="information icon" class="information-icon">
              {{'samples.planning.details.sample.jeun' | transloco}}
            </div>
          </div>
        </div>
        <div>
          <div *ngIf=" !sample.inLabo && sample.homeRx == false" class="mb-3">
            <span class="bold-cabin"> {{'samples.planning.details.sample.surfacturation' | transloco}}</span> {{sample.labo?.noHomeRxPrice}}€
          </div>
          <div class="mb-3" *ngIf="!sample.inLabo">
            <span class="bold-cabin">{{'samples.planning.details.sample.horaire' | transloco}}</span>
            <span *ngIf="sample.tolerance">{{'samples.planning.details.sample.tolerance' | transloco:{start: sample.desiredStartTime | toDateTime: "HH'h'mm", tolerance: sample.tolerance, price: getFixPrice(sample)} }}</span>
            <span *ngIf="!sample.tolerance && sample.startTime">{{sample.startTime | toDateTime: "HH'h'mm"}} - {{sample.endTime | toDateTime: "HH'h'mm"}}</span>
            <span *ngIf="!sample.tolerance && !sample.startTime">{{sample.desiredStartTime | toDateTime: "HH'h'mm"}} - {{sample.desiredEndTime| toDateTime: "HH'h'mm"}}</span>
          </div>
        </div>
        <div>

        </div>
      </div>
    </div>

    <div [formGroup]="otherConsignes" class="mt-5">
      <mat-checkbox formControlName="jeun" class="py-3">{{'samples.planning.details.sample.jeun' | transloco}}</mat-checkbox>

      <mat-form-field appearance="fill" class="col-12">
        <mat-label> {{'samples.planning.details.otherConsignePreleveur' | transloco }}</mat-label>
        <textarea matInput formControlName="preleveurConsigne" type="text"></textarea>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-12">
        <mat-label> {{'samples.planning.details.otherConsignePatient' | transloco }}</mat-label>
        <textarea matInput formControlName="patientConsigne" type="text"></textarea>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions class="d-flex justify-content-center mt-3">
    <button mat-raised-button color="primary" (click)="validate()">{{'samples.planning.details.validateAndNext' | transloco}}</button>
  </div>

  <ng-template #no>
    <mat-icon class="col-auto ms-auto" color="warn">close</mat-icon>
  </ng-template>
</ng-container>

