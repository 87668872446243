import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProfileEnum} from '@domain/user/user';
import {PlanningSecretaireComponent} from '@features/samples/planning-secretaire/planning-secretaire.component';
import {AuthenticatedGuard} from '@security/authenticated.guard';
import {LaboWorklistComponent} from './labo-worklist/labo-worklist.component';

const routes: Routes = [
    {path: 'planning', canActivate: [AuthenticatedGuard], data: {roles: [ProfileEnum.ADMIN, ProfileEnum.SECRETAIRE]}, component: PlanningSecretaireComponent},
    {path: 'worklist', canActivate: [AuthenticatedGuard], data:{roles:[ProfileEnum.ADMIN, ProfileEnum.TOURNEE_MANAGER]}, component: LaboWorklistComponent},
    {path: 'worklist/:id/:date', canActivate: [AuthenticatedGuard], data:{roles:[ProfileEnum.ADMIN, ProfileEnum.TOURNEE_MANAGER]}, component: LaboWorklistComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes),],
  exports: [RouterModule],
})
export class SamplesRoutingModule {}
