export enum NurseActions {
  LOAD_ONE_NURSE = 'Load one nurse',
  LOAD_ONE_NURSE_SUCCESS = 'Load one nurse success',
  LOAD_ONE_NURSE_FAIL = 'Load one nurse failure',

  LOAD_FILTERED_NURSES = 'Load a page of filtered nurses',
  LOAD_FILTERED_NURSES_SUCCESS = 'Load a page of filtered nurses success',
  LOAD_FILTERED_NURSES_FAIL = 'Load a page of filtered nurses failure',

  LOAD_ONE_NEW_NURSE = 'Load one new nurse',

  SAVE_ONE_NURSE = 'Save one nurse',
  SAVE_ONE_NURSE_SUCCESS = 'Save one nurse success',
  SAVE_ONE_NURSE_FAIL = 'Save one nurse failure',

  DELETE_ONE_NURSE = 'Delete one nurse',
  DELETE_ONE_NURSE_SUCCESS = 'Delete one nurse success',
  DELETE_ONE_NURSE_FAIL = 'Delete one nurse fail',

  UPDATE_NURSE_PAGINATION = 'Update nurse pagination',
  UPDATE_NURSE_SORT = 'Update nurse sort',
  UPDATE_NURSE_FILTER = 'Update nurse filter',

  SEARCH_ADDRESS = 'Search Address',
  SEARCH_ADDRESS_SUCCESS = 'Search Address Success',
  SEARCH_ADDRESS_FAILURE = 'Search Address Failure',

}
