import { Pipe, PipeTransform } from '@angular/core';
import {Sample} from '@domain/samples/sample';

export interface Disponibility {
  size: number,
  startHour: number;
  status: CreneauStatus
  samples?: Sample[],
  forced?: boolean
}

export enum CreneauStatus {
  AWAY='AWAY',
  FREE='FREE'
}

@Pipe({
  name: 'cutHours',
})
export class CutHoursPipe implements PipeTransform {

  minHour: number = 5;
  maxHour: number = 21;

  sortHour = (a: Disponibility, b: Disponibility) => {
    if(a.startHour < b.startHour) return -1;
    if(a.startHour >  b.startHour) return 1;
    return 0
  }

  transform(schedule: string[][] | undefined, confirmed: Sample[]): Disponibility[] {

    if(!schedule) {
      return [{startHour: 5, size:14, status: CreneauStatus.AWAY, samples: []}]
    }

    let response : Disponibility[] = [];
    let dispo: string[][] = [];

    schedule.forEach(plage => {
      let start : number = parseInt(plage[0].substring(0, plage[0].indexOf(':')))
      let end : number = parseInt(plage[1].substring(0, plage[1].indexOf(':')))

      if(start % 2 == 0) {
        dispo.push([`${start < 10 ? 0 : ''}${start}:00`, `${start + 1}:00`])
        start++;
      }

      if(end % 2 == 0) {
        dispo.push([`${end -1 < 10 ? 0 : ''}${end - 1}:00`, `${end}:00`])
        end--;
      }

      const plageNumber : number = (end - start) / 2;

      let tempStart: number = start;
      for(let i = 0; i < plageNumber; i++) {
        dispo.push([`${tempStart < 10 ? 0 : ''}${tempStart}:00`, `${tempStart + 2}:00`])
        tempStart += 2;
      }
    })

    const toInt = (hour: string) => parseInt(hour!.substring(0, hour.indexOf(':')));
    const isSampleInCreneau = (sample: Sample, debut: number, fin: number) => {
      if(sample.startTime && sample.endTime) {
        if(dispo.some(d => toInt(d[0]) === toInt(sample.startTime!)) && dispo.some(d => toInt(d[1]) === toInt(sample.endTime!))) {
          return toInt(sample.startTime!) === debut && toInt(sample.startTime!) <= fin
        }
        return fin === toInt(sample.endTime!) || debut === toInt(sample.startTime!);
      }
      return false;
    }

    dispo.sort((a, b) => {
      if(parseInt(a[0]) < parseInt(b[0])) return -1;
      if(parseInt(a[0]) >  parseInt(b[0])) return 1;
      return 0
    }).forEach(d => {
      const debut : number = toInt(d[0]);
      const fin: number = toInt(d[1])
      response.push({startHour: debut, size: fin - debut, status: CreneauStatus.FREE, samples: confirmed.filter(c => isSampleInCreneau(c, debut, fin)).sort(this.sortByOrderInCreneau)})
    })

    const noCreneauForSample: Sample[] = confirmed.filter(c => !dispo.some(d => isSampleInCreneau(c, toInt(d[0]), toInt(d[1]))))

    noCreneauForSample.forEach(noCreneau => {
      if(noCreneau.endTime && noCreneau.startTime) {
        const fin: number = toInt(noCreneau.endTime!)
        const debut: number = toInt(noCreneau.startTime!)
        if(!response.some(r => r.startHour === toInt(noCreneau.startTime!))){
          response.push({startHour: toInt(noCreneau.startTime!), size: fin - debut, status: CreneauStatus.FREE, samples: noCreneauForSample.filter(c => isSampleInCreneau(c, debut, fin)).sort(this.sortByOrderInCreneau), forced: true})
        }
      }
    })



    response = response.sort(this.sortHour)

    let temp: Disponibility[] = []

    const premierCreneau = response[0];
    const dernierCreneau = response[response.length - 1];

    if (premierCreneau.startHour > this.minHour && temp.filter(t => t.startHour === this.minHour).length === 0) {
      temp.push({startHour: 5, size: response[0].startHour - this.minHour, status: CreneauStatus.AWAY, samples: []})
    }

    if(dernierCreneau.startHour + dernierCreneau.size < this.maxHour && temp.filter(t => t.startHour === dernierCreneau.startHour + dernierCreneau.size).length === 0) {
      temp.push({startHour: dernierCreneau.startHour + dernierCreneau.size, size: this.maxHour - (dernierCreneau.startHour + dernierCreneau.size), status: CreneauStatus.AWAY, samples: []})
    }

    response.forEach((r, index) => {
      if ((r.startHour + r.size) < response[index + 1]?.startHour && temp.filter(t => t.startHour === (r.startHour + r.size)).length === 0) {
        temp.push({startHour: (r.startHour + r.size), size: (response[index + 1].startHour - (r.startHour + r.size)), status: CreneauStatus.AWAY, samples: []})
      }
    })

    response.push(...temp)

    response.sort(this.sortHour)

    return response;
  }

  sortByOrderInCreneau = (a: Sample, b: Sample) => {
    if(a.orderInCreneau! < b.orderInCreneau!) return -1
    if(a.orderInCreneau! > b.orderInCreneau!) return 1
    return 0;
  }

}
