import {Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploadComponent implements OnInit {

  fileReader: FileReader;
  @Input() accept: string;
  @Input() multiple: boolean = true;
  @Input() showList: boolean = true;
  @Input() files: string[][] = [];
  @Output() filesChanged: EventEmitter<string[][]> = new EventEmitter<string[][]>();
  @ViewChild('fileUpload') upload: ElementRef;
  constructor() {}

  ngOnInit(): void {
    this.fileReader = new FileReader();
  }

  onFileSelected(event: any): void {
    const files: File[] = event.target.files;
    var filename: string = event.target.files[0].name;
    this.fileReader.onload = (event) => {
      if (this.multiple === false){
        this.files = [];
      }
      if(files[0].type.startsWith('image') )
      {
        this.resizeImage(this.fileReader.result as string, files[0].type).then(data => {
          this.files.push([data, filename]);
          this.filesChanged.emit(this.files);
        });
      } else {
        this.files.push([this.fileReader.result as string, filename]);
        this.filesChanged.emit(this.files);
      }
    }
    for(let file of files) {
      this.fileReader.readAsDataURL(file);
    }

  }

  reset(): void {
    this.files = [];
    this.filesChanged.emit(this.files);
  }

  delete(file: string[]): void {
    this.files = this.files.filter(el => el[0] !== file[0] && el[1] !== file[1]);
    this.filesChanged.emit(this.files);
    this.upload.nativeElement.value = '';
  }

  async resizeImage(base64Str: string, type: string): Promise<string> {
    return new Promise(resolve => {
      const img = new Image();
      img.src = base64Str;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const MAX_WIDTH: number = 150;
        const MAX_HEIGHT: number = 150;
        let width: number = img.width;
        let height: number = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL(type,0.8));
      }
  });
  }
}
