import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthenticatedGuard} from '@security/authenticated.guard';
import {HomeComponent} from './home/home.component';

const routes: Routes = [{path: '', canActivate: [AuthenticatedGuard], component: HomeComponent}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
