export enum LabosActions {

  SEARCH_LABOS = 'Search Labos',
  SEARCH_LABOS_SUCCESS = 'Search Labos Success',
  SEARCH_LABOS_FAILURE = 'Search Labos Failure',

  LOAD_LABO_RDV_STATUS_COUNTS = 'Load Labo Rdv Status Counts',
  LOAD_LABO_RDV_STATUS_COUNTS_SUCCESS = 'Load Labo Rdv Status Counts Success',
  LOAD_LABO_RDV_STATUS_COUNTS_FAILURE = 'Load Labo Rdv Status Counts Failure',

  LOAD_ONE_LABO = 'Load One Labo',
  LOAD_ONE_LABO_SUCCESS = 'Load One Labo Success',
  LOAD_ONE_LABO_FAILURE = 'Load One Labo Failure',

  LOAD_ONE_NEW_LABO = 'Load One New Labo',

  SEARCH_ADDRESS = 'Search Address',
  SEARCH_ADDRESS_SUCCESS = 'Search Address Success',
  SEARCH_ADDRESS_FAILURE = 'Search Address Failure',

  CHANGE_COUVERTURE_TYPE_LABO = 'Change Couverture Type Labo',
  CHANGE_COUVERTURE_LABO = 'Change Couverture Labo',

  SAVE_ONE_LABO = 'Save One Labo',
  SAVE_ONE_LABO_SUCCESS = 'Save One Labo Success',
  SAVE_ONE_LABO_FAILURE = 'Save One Labo Failure',

  CONFIRM_DELETE_LABO = 'Confirm Delete Labo',
  DELETE_ONE_LABO = 'Delete One Labo',
  DELETE_ONE_LABO_SUCCESS = 'Delete One Labo Success',
  DELETE_ONE_LABO_FAILURE = 'Delete One Labo Failure',

  GET_PRELEVEURS = 'Get Preleveur',
  GET_PRELEVEURS_SUCCESS = 'Get Preleveur Success',
  GET_PRELEVEURS_FAILURE = 'Get Preleveur Failure',

  LOAD_CHECKS = 'Load Checks',
  LOAD_CHECKS_SUCCESS = 'Load Checks Success',
  LOAD_CHECKS_FAILURE = 'Load Checks Failure',

  UPDATE_CHECKS_FILTER = 'Update checks filter',
  UPDATE_CHECKS_PAGINATION = 'Update checks pagination',

  CREATE_CHECK = 'Create Check',
  CREATE_CHECK_SUCCESS = 'Create Check Success',
  CREATE_CHECK_FAILURE = 'Create Check Failure',

  UPDATE_CHECK = 'Update Check',
  UPDATE_CHECK_SUCCESS = 'Update Check Success',
  UPDATE_CHECK_FAILURE = 'Update Check Failure',

  CONFIRM_DELETE_CHECK = 'Confirm Delete Check',
  DELETE_CHECK = 'Delete Check',
  DELETE_CHECK_SUCCESS = 'Delete Check Success',
  DELETE_CHECK_FAILURE = 'Delete Check Failure',

  SAVE_ZONE_PRELEVEUR = 'Save Zone Preleveur',
  SAVE_ZONE_PRELEVEUR_SUCCESS = 'Save Zone Preleveur Success',
  SAVE_ZONE_PRELEVEUR_FAILURE = 'Save Zone Preleveur Failure',

  LOAD_PRELEVEURS_ON_ZONE = 'Load Preleveurs On Zone',
  LOAD_PRELEVEURS_ON_ZONE_SUCCESS = 'Load Preleveurs On Zone Success',
  LOAD_PRELEVEURS_ON_ZONE_FAILURE = 'Load Preleveurs On Zone Failure',
  CLEAR_PRELEVERS_ON_ZONE = 'Clear Preleveurs On Zone',
}
