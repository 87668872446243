<div class="header row align-items-center">
  <div class="col-auto me-auto ms-3">{{'cabinets.title'| transloco}}
  </div>
  <button mat-raised-button class="h-75 col-auto ms-3 me-5 justify-content-end helveticaBold13 " routerLink="edit/">
    {{'cabinets.addCabinet' | transloco}}
  </button>
</div>
<div class="ms-auto row mt-3 mb-3 me-3">
  <div class="title col-auto align-self-baseline justify-content-start ms-3 me-auto">
    {{'cabinets.list' | transloco}}
  </div>
  <app-searchbar class="col-4" (valueChange)="updateFilter($event)" [search$]="filter$" [placeholder]="'cabinets.search' | transloco" ></app-searchbar>
  <ng-container *ngIf="filteredCabinets$ | async as cabinets">
    <ng-container *ngIf = "paginationOption$ | async as paginationOption">
        <div class="row">
          <div class="col-4 d-flex flex-row align-items-center">
            <div class="col-7 table-header">{{'cabinets.table.name' | transloco}}</div>
            <div class="col-3 table-header ps-2">{{'cabinets.table.count' | transloco}}</div>
          </div>
          <div class="col-4 d-flex flex-row align-items-center">
            <div class="col-7 table-header ps-2">{{'cabinets.table.name' | transloco}}</div>
            <div class="col-3 table-header ps-2">{{'cabinets.table.count' | transloco}}</div>
          </div>
          <div class="col-4 d-flex flex-row align-items-center">
            <div class="col-7 table-header ps-2">{{'cabinets.table.name' | transloco}}</div>
            <div class=" col-3 table-header ps-2">{{'cabinets.table.count' | transloco}}</div>
          </div>
        </div>
          <div *ngFor = "let row of splitInColumn(cabinets.content, 3)" class="row my-2">
            <div *ngFor="let cabinet of row" class="col-4">
              <div class="row-table d-flex flex-row align-items-center">
                <div class="col-7 ps-2">{{cabinet.name }}</div>
                <div class="col-3 ps-2">{{cabinet.preleveurs?.length}}</div>
                <div class="col-auto ms-auto">
                  <button mat-icon-button [routerLink]="'edit/'+ cabinet.id">
                    <mat-icon>arrow_forward</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>

      <mat-paginator [length]="paginationOption?.length" [pageSize]="paginationOption.pageSize"
                     [pageIndex]="paginationOption.pageIndex"  [pageSizeOptions]="paginationOption?.pageSizeOption || [10,20,30]"
                     (page)="onChangePage($event)"></mat-paginator>
    </ng-container>
  </ng-container>
</div>
