import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProfileEnum} from '@domain/user/user';
import {PreleveurAffectationZoneComponent} from '@features/users/preleveur-affectation-zone/preleveur-affectation-zone.component';
import {UserSampleEditComponent} from '@features/users/user-sample-edit/user-sample-edit.component';
import {UserSampleListComponent} from '@features/users/user-sample-list/user-sample-list.component';
import {AuthenticatedGuard} from '@security/authenticated.guard';
import {EditUsersComponent} from './edit-users/edit-users.component';
import {ListUsersComponent} from './list-users/list-users.component';

const routes: Routes = [
  {path: '', canActivate: [AuthenticatedGuard], data: {roles: [ProfileEnum.ADMIN, ProfileEnum.PERSONNEL_LABO, ProfileEnum.TOURNEE_MANAGER, ProfileEnum.SECRETAIRE]}, component: ListUsersComponent},
  {path: 'edit', canActivate: [AuthenticatedGuard], data: {roles: [ProfileEnum.ADMIN, ProfileEnum.PERSONNEL_LABO, ProfileEnum.TOURNEE_MANAGER, ProfileEnum.SECRETAIRE]}, component: EditUsersComponent},
  {path: 'edit/:id', canActivate: [AuthenticatedGuard], data: {roles: [ProfileEnum.ADMIN, ProfileEnum.PERSONNEL_LABO, ProfileEnum.TOURNEE_MANAGER, ProfileEnum.SECRETAIRE], readonly:false}, component: EditUsersComponent},
  {path: 'view/:id', canActivate: [AuthenticatedGuard], data: {roles: [ProfileEnum.ADMIN, ProfileEnum.PERSONNEL_LABO, ProfileEnum.TOURNEE_MANAGER, ProfileEnum.SECRETAIRE], readonly:true}, component: EditUsersComponent},
  {path: 'edit/:id/zones', canActivate: [AuthenticatedGuard], data: {roles: [ProfileEnum.ADMIN, ProfileEnum.PERSONNEL_LABO]}, component: PreleveurAffectationZoneComponent},
  {path: 'edit/:id/samples', canActivate: [AuthenticatedGuard], data: {roles: [ProfileEnum.ADMIN, ProfileEnum.SECRETAIRE]}, component: UserSampleListComponent},
  {path: 'edit/:id/samples/create', canActivate: [AuthenticatedGuard], data: {roles: [ProfileEnum.ADMIN, ProfileEnum.SECRETAIRE]}, component: UserSampleEditComponent},
  {path: 'edit/:id/samples/:sampleId', canActivate: [AuthenticatedGuard], data: {roles: [ProfileEnum.ADMIN, ProfileEnum.SECRETAIRE]}, component: UserSampleEditComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes),],
  exports: [RouterModule],
})
export class UsersRoutingModule {}
