import {ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatRadioChange} from '@angular/material/radio';
import {MatStepper} from '@angular/material/stepper';
import {Labo} from '@domain/labo/Labo';
import {Check, Dateslot, Sample, Timeslot} from '@domain/samples/sample';
import {Adress, AssociationModeEnum, User} from '@domain/user/user';
import {Actions, ofActionSuccessful, Select, Store} from '@ngxs/store';
import {GeoApiService} from '@service/geoapi.service';
import {LaboService} from '@service/labo.service';
import {
  CreateNewSample,
  CreateSample,
  GetNearestLaboForSample,
  GetNearestLaboForSampleSuccess,
  GetSampleTimeSlots,
  SampleCreationPatch, UpdateTimeSlotsSearchMode,
} from '@states/samples/samples.actions';
import {SamplesStateSelector} from '@states/samples/samples.selectors';
import {GetDomicileAddress, LoadOneUser, SetDomicileAddress} from '@states/user/users.actions';
import {UsersStateSelector} from '@states/user/users.selectors';
import {last} from 'lodash';
import {DateTime} from 'luxon';
import {firstValueFrom, Observable, Subject, takeUntil} from 'rxjs';

@Component({
  selector: 'app-user-sample-edit',
  templateUrl: './user-sample-edit.component.html',
  styleUrls: ['./user-sample-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSampleEditComponent implements OnInit, OnDestroy {
  otherAddress: FormGroup;
  adresse: FormGroup;
  prescription: FormGroup;
  checks: FormGroup;
  time: FormGroup;
  confirm: FormGroup;
  forms: FormGroup[] = [];
  @Select(UsersStateSelector.domicile) domicile$: Observable<Adress>;
  @Select(SamplesStateSelector.currentSampleCreation) sample$: Observable<Sample>;
  @Select(SamplesStateSelector.sampleDatelots) dateslots$: Observable<Dateslot[]>;
  @Select(SamplesStateSelector.labo) labo$: Observable<Labo>;
  @Select(SamplesStateSelector.timeslotsSearchMode) timeslotsSearchMode$: Observable<AssociationModeEnum>;
  @Select(UsersStateSelector.currentUser) patient$: Observable<User>;
  @ViewChild('stepper', {static: true}) stepper: MatStepper;
  associationModeEnum: typeof AssociationModeEnum = AssociationModeEnum;
  private destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    public dialogRef: MatDialogRef<UserSampleEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {id: string; adresse: Adress | undefined; associatedPreleveur: User},
    private store: Store,
    private _geoApi: GeoApiService,
    private laboService: LaboService,
    private actions$: Actions
  ) {}

  ngOnInit(): void {
    this.createForms();
    this.initSampleCreation();
    this.actions$.pipe(ofActionSuccessful(GetNearestLaboForSampleSuccess), takeUntil(this.destroy)).subscribe((params) => {
      if (this.stepper?.selected && params.labos?.length > 0 && this.stepper.selectedIndex === 0) {
        this.stepper.selected.completed = true;
        this.stepper.next();
      }
    });
  }
  createForms(): void {
    this.adresse = new FormGroup({
      id: new FormControl(),
      adr1: new FormControl('', Validators.required),
      adr2: new FormControl(''),
      cp: new FormControl('', [Validators.required]),
      commentaire: new FormControl(''),
      ville: new FormControl('', [Validators.required]),
      pays: new FormControl('FR', [Validators.required]),
      domicile: new FormControl(true),
    });

    this.otherAddress = new FormGroup({
      id: new FormControl(),
      adr1: new FormControl('', Validators.required),
      adr2: new FormControl(''),
      cp: new FormControl('', [Validators.required]),
      commentaire: new FormControl(''),
      ville: new FormControl('', [Validators.required]),
      pays: new FormControl('FR', [Validators.required]),
      domicile: new FormControl(false),
    });

    this.prescription = new FormGroup(
      {
        hasPrescription: new FormControl(null, [Validators.required]),
        hasHomePrescription: new FormControl(),
        hasRenewablePrescription: new FormControl(),
        laboHasRx: new FormControl(),
      },
      [this.prescriptionRequired]
    );

    this.checks = new FormGroup(
      {
        addChecks: new FormControl(),
        selectedChecks: new FormControl([]),
      },
      {validators: this.checksRequired(this.prescription)}
    );

    this.time = new FormGroup(
      {
        dateSample: new FormControl([], [Validators.required]),
        desiredStartTime: new FormControl('', [Validators.required]),
        desiredEndTime: new FormControl(''),
        customslot: new FormControl(false, [Validators.required]),
        customslotTolerance: new FormControl(null),
        acceptedCustom: new FormControl(false),
        searchMode: new FormControl(AssociationModeEnum.STRICT),
      },
      [this.timeSlotValid]
    );

    this.confirm = new FormGroup({
      accepted: new FormControl(false, [Validators.required]),
    });

    this.forms.push(this.adresse, this.prescription, this.checks, this.time);
  }

  async initSampleCreation(): Promise<void> {
    this.store.dispatch(new LoadOneUser(this.data.id));
    await firstValueFrom(this.store.dispatch(new CreateNewSample()));
    if (!this.data.adresse) {
      await firstValueFrom(this.store.dispatch(new GetDomicileAddress(this.data.id)));
    } else {
      await firstValueFrom(this.store.dispatch(new SetDomicileAddress(this.data.adresse)));
    }
    const domicile: Adress = await firstValueFrom(this.domicile$);
    this.adresse.setValue(domicile);
  }

  async locationChange(event: MatRadioChange): Promise<void> {
    if (event.value) {
      const domicile: Adress = await firstValueFrom(this.domicile$);
      this.adresse.setValue(domicile);
    } else {
      this.adresse.setValue(this.otherAddress.value);
    }
  }
  checksRequired(prescription: AbstractControl): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const hasPrescription: boolean = prescription.get('hasPrescription')?.value;
      const addChecks: boolean = control.get('addChecks')?.value;
      const selectedChecks: Check[] = control.get('selectedChecks')?.value;
      if ((hasPrescription && addChecks !== false && selectedChecks?.length === 0) || (!hasPrescription && selectedChecks?.length === 0)) {
        return {required: true};
      }
      return null;
    };
  }

  prescriptionRequired(control: AbstractControl): ValidationErrors | null {
    const hasPrescription: boolean = control.get('hasPrescription')?.value;
    const hasHomePrescription: boolean = control.get('hasHomePrescription')?.value;
    const hasRenewablePrecription: boolean = control.get('hasRenewablePrescription')?.value;
    const laboHasRx: boolean = control.get('laboHasRx')?.value;
    if (hasPrescription && (hasHomePrescription === null || hasRenewablePrecription === null || (hasRenewablePrecription && laboHasRx === null))) {
      return {required: true};
    }
    return null;
  }

  timeSlotValid(control: AbstractControl): ValidationErrors | null {
    const min: string = '05:00:00';
    const max: string = '21:00:00';
    const customslot: boolean = control.get('customslot')?.value;
    const acceptedCustom: boolean = control.get('acceptedCustom')?.value;
    const tolerance: number = control.get('customslotTolerance')?.value;
    const startTime: string = control.get('desiredStartTime')?.value;
    if (customslot && (!acceptedCustom || !tolerance)) {
      return {acceptedRequired: true};
    }
    if (customslot && startTime) {
      const inBound: boolean = DateTime.fromFormat(startTime, 'HH:mm') >= DateTime.fromFormat(min, 'HH:mm:ss') && DateTime.fromFormat(startTime, 'HH:mm') <= DateTime.fromFormat(max, 'HH:mm:ss');
      if (!inBound) {
        return {minMax: true};
      }
    } else if (customslot) {
      return {minMax: true};
    }
    return null;
  }

  getChecks(checks: Check[]): void {
    this.checks.get('selectedChecks')?.setValue(checks);
  }

  async validateAddress(): Promise<void> {
    if (this.adresse.valid) {
      const adresse: Adress = this.adresse.value;
      this.store.dispatch(new GetNearestLaboForSample(this.data.id, adresse));
    }
  }

  validatePrescription(): void {
    if (this.prescription.valid) {
      if (this.prescription.get('hasPrescription')?.value !== true) {
        this.prescription.get('hasHomePrescription')?.setValue(false);
        this.prescription.get('hasRenewablePrescription')?.setValue(false);
        this.prescription.get('laboHasRx')?.setValue(false);
      }
      if (this.prescription.get('hasRenewablePrescription')?.value === false) {
        this.prescription.get('laboHasRx')?.setValue(false);
      }
      const sample: Sample = {
        hasRx: this.prescription.get('hasPrescription')?.value,
        homeRx: this.prescription.get('hasHomePrescription')?.value !== undefined ? this.prescription.get('hasHomePrescription')?.value : false,
        renewableRx: this.prescription.get('hasRenewablePrescription')?.value !== undefined ? this.prescription.get('hasRenewablePrescription')?.value : false,
        transmittedLaboRx: this.prescription.get('laboHasRx')?.value !== undefined ? this.prescription.get('laboHasRx')?.value : false,
      };
      this.store.dispatch(new SampleCreationPatch(sample));
    }
  }

  validateChecks(): void {
    if (this.checks.valid) {
      if (this.checks.get('addChecks')?.value === false) {
        this.checks.get('selectedChecks')?.setValue([]);
      }
      const sample: Sample = {
        checks: this.checks.get('selectedChecks')?.value,
      };

      this.store.dispatch(new GetSampleTimeSlots(AssociationModeEnum.STRICT, 7));
      this.store.dispatch(new SampleCreationPatch(sample));
    }
  }

  customRedirect(dateslot: any): void {
    this.time.get('dateSample')?.setValue(dateslot.date);
    this.time.get('customslotTolerance')?.setValue(null);
    this.time.get('customslot')?.setValue(true);
    this.time.get('acceptedCustom')?.setValue(false);
    this.time.get('desiredStartTime')?.setValue(null);
  }

  validateCustomSlot(): void {
    const sample: Sample = {
      dateSample: this.time.get('dateSample')?.value,
      desiredStartTime: this.time.get('desiredStartTime')?.value + ':00',
      desiredEndTime: undefined,
      tolerance: this.time.get('customslotTolerance')?.value,
    };
    this.time.get('acceptedCustom')?.setValue(false);
    this.time.get('customslot')?.setValue(false);
    this.store.dispatch(new SampleCreationPatch(sample));
  }

  selectSchedule(date: Date, slot: Timeslot): void {
    this.time.get('dateSample')?.setValue(date);
    this.time.get('desiredStartTime')?.setValue(slot.start);
    this.time.get('desiredEndTime')?.setValue(slot.end);
    this.time.get('customslot')?.setValue(false);

    if (this.time.valid && this.stepper && this.stepper.selected) {
      const sample: Sample = {
        dateSample: this.time.get('dateSample')?.value,
        desiredStartTime: this.time.get('desiredStartTime')?.value,
        desiredEndTime: this.time.get('desiredEndTime')?.value,
        tolerance: undefined,
      };
      this.store.dispatch(new SampleCreationPatch(sample));
      this.stepper.selected.completed = true;
      this.stepper.next();
    }
  }

  async loadMore(): Promise<void> {
    const dateslots: Dateslot[] = await firstValueFrom(this.dateslots$);
    const searchMode: AssociationModeEnum = await firstValueFrom(this.timeslotsSearchMode$);
    this.store.dispatch(new GetSampleTimeSlots(searchMode, 7, last(dateslots)?.date));
    const scroll: HTMLElement | null = document.getElementById('scroll');
    if (scroll) {
      scroll.scrollTop = scroll.scrollHeight;
    }
  }

  async updateSearchMode(event: MatRadioChange): Promise<void> {
    await this.store.dispatch(new UpdateTimeSlotsSearchMode(event.value));
    const searchMode: AssociationModeEnum = await firstValueFrom(this.timeslotsSearchMode$);
    this.store.dispatch(new GetSampleTimeSlots(searchMode, 7));
    const scroll: HTMLElement | null = document.getElementById('scroll');
    if (scroll) {
      scroll.scrollTop = scroll.scrollHeight;
    }
  }

  create(): void {
    this.store.dispatch(new CreateSample());
    this.dialogRef.close();
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

  async next(): Promise<void> {
    if (this.stepper && this.stepper.selected) {
      // validation de l'étape
      if (this.stepper.selectedIndex < this.stepper.steps.length && this.forms[this.stepper.selectedIndex].valid) {
        switch (this.stepper.selectedIndex) {
          case 0:
            await this.validateAddress();
            break;
          case 1:
            await this.validatePrescription();
            break;
          case 2:
            await this.validateChecks();
            break;
          case 3:
            await this.validateCustomSlot();
            break;
          default:
            break;
        }
        // completed + next()
        //Etape 1 fait dans un subscribe dans le ngOnInit
        if (this.stepper.selectedIndex !== 0) {
          this.stepper.selected.completed = true;
          this.stepper.next();
        }
      }
    }
  }

  async back(): Promise<void> {
    if (this.stepper && this.stepper.selected) {
      // reset du formulaire courant
      if (this.stepper.selectedIndex < this.stepper.steps.length) {
        this.forms[this.stepper.selectedIndex].reset();
      }
      // changement de l'étape précendante à "editable : true"
      if (this.stepper.steps.get(this.stepper.selectedIndex - 1)) {
        this.stepper.steps.get(this.stepper.selectedIndex - 1)!.editable = true;
        this.stepper.selected.completed = false;
        // changement à l'étape précédente + désactivation "completed + editable"
        this.stepper.previous();
        this.stepper.selected.completed = false;
        this.stepper.selected.editable = false;
      }
    }
  }
}
