<ng-container *ngIf="samples$ | async as samples">
  <ng-container *ngIf="labo$ | async as labo">
    <ng-container *ngIf="paginationOption$ | async as paginationOption ">
      <ng-container *ngIf="filter$ | async as filter">
        <div class="header row align-items-center">
          <button type="button" class="col-auto px-3" mat-button (click)="goBack()">
            <mat-icon aria-hidden="false" aria-label="go back icon">arrow_back</mat-icon>
          </button>
          <div class="col-auto me-auto">
            <div class="icon d-inline-block align-items-center">
              <img src="../../../../assets/img/laboratory.svg" alt="laboratory icon">
            </div>
            <mat-label class="px-3">{{labo.name}}</mat-label>
          </div>
          <div class="col-auto justify-content-end me-2"> {{samples.totalElements}} Rdv</div>
        </div>

        <div class="ms-auto row mt-3 mb-3 me-3" [formGroup]="filterGroup">
          <div class="title col-auto align-self-baseline justify-content-start ms-3 me-auto">
            Rdv du {{filter.date | toDateTime: 'EEEE dd LLLL' }}
          </div>
          <app-searchbar class="col-4" [placeholder]="'samples.search' | transloco" [search$]="filterPatientName$" (valueChange)="changeFilter($event)"></app-searchbar>
          <mat-form-field appearance="fill" class="col-2">
            <mat-select multiple formControlName="status" [placeholder]="'samples.allStatus' | transloco">
              <mat-select-trigger>
                <span *ngIf="filter?.status?.length === statusEnumArray.length; else specificOption "> {{ 'samples.allStatus' | transloco }}</span>
                <ng-template #specificOption>
                  <span>{{"Status: "}}</span>
                  <span>{{getFirstStatus(filter?.status)}}</span>
                  <span class="numberStatus">{{getNumberStatus(filter?.status)}}</span>
                </ng-template>
              </mat-select-trigger>
              <mat-option *ngFor="let status of statusEnumArray" [value] = "status">{{'samples.status.' + status | transloco}}</mat-option>
              <div class="mt-1 mb-1 d-flex flex-column justify-content-center align-items-center resetFilter">
                <label *ngIf="filterGroup.get('status')?.value?.length>0" (click)="resetFilter()"> {{'samples.resetFilter' | transloco }}</label>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="row m-3">
          <div class="col-5 table-header justify-content-center" (click)="updateSort('patientName')" [ngClass]="{'sortBy': paginationOption.sortByColumnName === 'patientName'}">
            <span>{{'samples.table.patient' | transloco }}</span>
            <mat-icon *ngIf="paginationOption.sortByColumnName=== 'patientName' && paginationOption.sortDirection === 'DESC'; else asc" class="arrow">arrow_downward</mat-icon>
            <ng-template #asc>
              <mat-icon class="arrow">arrow_upward</mat-icon>
            </ng-template>
          </div>
          <div class="col-5 table-header">
            <span>{{'samples.table.bilan' | transloco}}</span>
          </div>
          <div class="col-1 table-header d-flex justify-content-center" (click)="updateSort('status')" [ngClass]="{'sortBy': paginationOption.sortByColumnName=== 'status'}">
            <span> {{'samples.table.status' | transloco }} </span>
            <mat-icon *ngIf="paginationOption.sortByColumnName=== 'status' && paginationOption.sortDirection === 'DESC'; else asc" class="arrow">arrow_downward</mat-icon>
          </div>
        </div>

        <ng-container *ngIf="samplesLoaded$ | async else loadingSamplesBlock">
          <ng-container *ngIf="samples.content.length > 0 else noSample">
            <div *ngFor="let sample of samples.content" class="row row-table ms-3 me-3 mt-1 mb-1 align-items-center">
              <div class="col-5 fw-bold justify-content-center">{{sample.user?.lastname && sample.user?.firstname ?  sample.user?.firstname + ' ' + sample.user?.lastname?.toUpperCase() : ''}}</div>
              <div class="col-5 d-flex justify-content-start align-items-center">
                <img *ngIf="sample.hasRx && !hasUploadedPresciption(sample)" class="icon_check me-1" src="assets/img/files.svg" alt="ordonnance non uploadée">
                <div *ngIf="sample.hasRx && hasUploadedPresciption(sample)">
                  <img class="icon_check me-1" src="assets/img/prescription-ok.svg" alt="ordonnance uploadée">
                  <button mat-icon-button (click)="downloadPrescription(sample)" class="me-3">
                    <mat-icon color="accent">download</mat-icon>
                  </button>
                </div>
                <img *ngIf="!sample.hasRx" class="icon_check me-1" src="assets/img/prescription-missing.svg" alt = "absence d'ordonnance ">
                <label> {{getChecks(sample)}} </label>
              </div>
              <div class="col-1 d-flex justify-content-center" [ngSwitch]="sample?.status?.status">
                <div *ngSwitchCase="statusEnum.PENDING">
                  <button class="status_to_save" type="button" mat-raised-button color="primary" (click) = "updateStatus(sample)">{{'samples.status.toSave' | transloco}}</button>
                </div>
                <div *ngSwitchCase="statusEnum.DONE" class="status_done">
                  {{'samples.status.done' | transloco}}
                </div>
                <div *ngSwitchCase="statusEnum.CANCEL" class="status_cancel">
                  {{'samples.status.cancel' | transloco}}
                </div>
                <div *ngSwitchDefault class="status_saved">
                  {{'samples.status.saved' | transloco}}
                </div>
              </div>
              <div class="col-auto ms-auto">
                <button mat-icon-button (click)="goToUserDetails(sample.user?.id)">
                  <mat-icon>arrow_forward</mat-icon>
                </button>
              </div>
            </div>

            <mat-paginator [length]="paginationOption?.length" [pageSize]="paginationOption.pageSize"
                           [pageIndex]="paginationOption.pageIndex"  [pageSizeOptions]="paginationOption?.pageSizeOption || [10,20,50]"
                           (page)="onChangePage($event)"></mat-paginator>
          </ng-container>
          <ng-template #noSample>
            <div class="w-100 d-flex justify-content-center noresult">
              {{'samples.noresult' | transloco}}
            </div>
          </ng-template>
        </ng-container>
        <ng-template #loadingSamplesBlock>
          <div class="w-100 d-flex justify-content-center samplesLoader">
              <div class="loader">
              </div>
          </div>
      </ng-template>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
