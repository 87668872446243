import { Labo, LaboForm, LaboPagination, LaboRdvStatusCount, ZonePreleveur } from '@domain/labo/Labo';
import {Check, CheckFilter} from '@domain/samples/sample';
import {User} from '@domain/user/user';
import {UserFilter} from '@features/users/users-filter';
import {AddressNormalized, SearchAddressResult} from '@utils/coordinates/coordinates';
import {Pagination} from '@utils/pagination/Pagination';
import {PaginationOption} from '@utils/pagination/PaginationOption';
import { FeatureCollection } from 'geojson';
import { LabosActions } from './labos.actions.enum';


const PREFIX: string = '[Labos] ';

export class SearchLabos {
  static readonly type: string = PREFIX + LabosActions.SEARCH_LABOS;
  constructor() {}
}

export class SearchLabosSuccess {
  static readonly type: string = PREFIX + LabosActions.SEARCH_LABOS_SUCCESS;
  constructor(public labos: LaboPagination) {}
}

export class SearchLabosFailure {
  static readonly type: string = PREFIX + LabosActions.SEARCH_LABOS_FAILURE;
  constructor(public error: string) {}
}

export class LoadLaboRdvStatusCounts {
  static readonly type: string = PREFIX + LabosActions.LOAD_LABO_RDV_STATUS_COUNTS;
  constructor(public fromDate: Date, public toDate: Date) {}
}

export class LoadLaboRdvStatusCountsSuccess {
  static readonly type: string = PREFIX + LabosActions.LOAD_LABO_RDV_STATUS_COUNTS_SUCCESS;
  constructor(public counts: LaboRdvStatusCount[]) {}
}

export class LoadLaboRdvStatusCountsFailure {
  static readonly type: string = PREFIX + LabosActions.LOAD_LABO_RDV_STATUS_COUNTS_FAILURE;
  constructor(public error: string) {}
}

export class LoadOneLabo {
  static readonly type: string = PREFIX + LabosActions.LOAD_ONE_LABO;
  constructor(public id: string) {}
}

export class LoadOneLaboSuccess {
  static readonly type: string = PREFIX + LabosActions.LOAD_ONE_LABO_SUCCESS;
  constructor(public labo: Labo) {}
}

export class LoadOneLaboFailure {
  static readonly type: string = PREFIX + LabosActions.LOAD_ONE_LABO_FAILURE;
  constructor(public error: string) {}
}

export class LoadOneNewLabo {
  static readonly type: string = PREFIX + LabosActions.LOAD_ONE_NEW_LABO;
}

export class SearchAddress {
  static readonly type: string = PREFIX + LabosActions.SEARCH_ADDRESS;
  constructor(public labo: AddressNormalized) {}
}

export class SearchAddressSuccess {
  static readonly type: string = PREFIX + LabosActions.SEARCH_ADDRESS_SUCCESS;
  constructor(public searchResults: SearchAddressResult[]) {}
}

export class SearchAddressFailure {
  static readonly type: string = PREFIX + LabosActions.SEARCH_ADDRESS_FAILURE;
  constructor(public error: string) {}
}

export class ChangeCouvertureTypeLabo {
  static readonly type: string = PREFIX + LabosActions.CHANGE_COUVERTURE_TYPE_LABO;
  constructor(public coverType: string) {}
}

export class ChangeCouvertureLabo {
  static readonly type: string = PREFIX + LabosActions.CHANGE_COUVERTURE_LABO;
  constructor(public featureCollection: FeatureCollection) {}
}

export class SaveOneLabo {
  static readonly type: string = PREFIX + LabosActions.SAVE_ONE_LABO;
  constructor(public labo: LaboForm) {}
}

export class SaveOneLaboSuccess {
  static readonly type: string = PREFIX + LabosActions.SAVE_ONE_LABO_SUCCESS;
}

export class SaveOneLaboFailure {
  static readonly type: string = PREFIX + LabosActions.SAVE_ONE_LABO_FAILURE;
  constructor(public error: string) {}
}

export class ConfirmDeleteLabo {
  static readonly type: string = PREFIX + LabosActions.CONFIRM_DELETE_LABO;
  constructor(public id: string) {}
}

export class DeleteOneLabo {
  static readonly type: string = PREFIX + LabosActions.DELETE_ONE_LABO;
  constructor(public id: string) {}
}

export class DeleteOneLaboSuccess {
  static readonly type: string = PREFIX + LabosActions.DELETE_ONE_LABO_SUCCESS;
  constructor() {}
}

export class DeleteOneLaboFailure {
  static readonly type: string = PREFIX + LabosActions.DELETE_ONE_LABO_FAILURE;
  constructor(public error: string) {}
}

export class GetPreleveurs {
  static readonly type: string = PREFIX + LabosActions.GET_PRELEVEURS;
  constructor(public filter: UserFilter) {}
}

export class GetPreleveursSuccess {
  static readonly type: string = PREFIX + LabosActions.GET_PRELEVEURS_SUCCESS;
  constructor(public preleveurs: User[]) {}
}

export class GetPreleveursFailure {
  static readonly type: string = PREFIX + LabosActions.GET_PRELEVEURS_FAILURE;
  constructor(public error: string) {}
}

export class LoadChecks {
  static readonly type: string = PREFIX + LabosActions.LOAD_CHECKS
  constructor(public withReload: boolean = true) {
  }
}


export class LoadCheckSuccess {
  static readonly type: string = PREFIX + LabosActions.LOAD_CHECKS_SUCCESS
  constructor(public checks: Pagination<Check>) {
  }
}

export class LoadChecksFailure {
  static readonly type: string = PREFIX + LabosActions.LOAD_CHECKS_FAILURE;
  constructor(public error: string) {}
}

export class UpdateChecksFilter {
  static readonly type = PREFIX + LabosActions.UPDATE_CHECKS_FILTER
  constructor(public filter: CheckFilter, public pagination?: PaginationOption) {
  }
}

export class UpdateChecksPagination {
  static readonly type = PREFIX + LabosActions.UPDATE_CHECKS_PAGINATION
  constructor(public paginationOption: PaginationOption) {
  }
}

export class CreateCheck {
  static readonly type = PREFIX + LabosActions.CREATE_CHECK
  constructor(public check: Check) {
  }
}

export class CreateCheckSuccess {
  static readonly type = PREFIX + LabosActions.CREATE_CHECK_SUCCESS
}

export class CreateCheckFailure {
  static readonly type = PREFIX + LabosActions.CREATE_CHECK_FAILURE
  constructor(public error: any) {
  }
}


export class UpdateCheck {
  static readonly type = PREFIX + LabosActions.UPDATE_CHECK
  constructor(public check: Check) {
  }
}

export class UpdateCheckSuccess {
  static readonly type = PREFIX + LabosActions.UPDATE_CHECK_SUCCESS
}

export class UpdateCheckFailure {
  static readonly type = PREFIX + LabosActions.CREATE_CHECK_FAILURE
  constructor(public error: any) {
  }
}

export class ConfirmDeleteCheck {
  static readonly type = PREFIX + LabosActions.CONFIRM_DELETE_CHECK
  constructor(public id: string) {
  }
}

export class DeleteCheck {
  static readonly type = PREFIX + LabosActions.DELETE_CHECK
  constructor(public id: string) {
  }
}

export class DeleteCheckSuccess {
  static readonly type = PREFIX + LabosActions.DELETE_CHECK_SUCCESS
}

export class DeleteCheckFailure {
  static readonly type = PREFIX + LabosActions.CREATE_CHECK_FAILURE
  constructor(public error: any) {
  }
}

export class SaveZonePreleveur {
  static readonly type = PREFIX + LabosActions.SAVE_ZONE_PRELEVEUR
  constructor(public laboId: string, public zones: ZonePreleveur[]) {}
}

export class SaveZonePreleveurSuccess {
  static readonly type = PREFIX + LabosActions.SAVE_ZONE_PRELEVEUR_SUCCESS
  constructor() {}
}

export class SaveZonePreleveurFailure {
  static readonly type = PREFIX + LabosActions.SAVE_ZONE_PRELEVEUR_FAILURE
  constructor(public error: any) {}
}

export class LoadPreleveursOnZone {
  static readonly type = PREFIX + LabosActions.LOAD_PRELEVEURS_ON_ZONE
  constructor(public zoneId: string) {}
}

export class LoadPreleveursOnZoneSuccess {
  static readonly type = PREFIX + LabosActions.LOAD_PRELEVEURS_ON_ZONE_SUCCESS
  constructor(public zoneId: string, public preleveurs: User[]) {}
}

export class LoadPreleveursOnZoneFailure {
  static readonly type = PREFIX + LabosActions.LOAD_PRELEVEURS_ON_ZONE_FAILURE
  constructor(public error: any, public zoneId: string) {}
}

export class ClearPreleveursOnZone {
  static readonly type = PREFIX + LabosActions.CLEAR_PRELEVERS_ON_ZONE
  constructor() {}
}