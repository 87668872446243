import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';
import {LabosState} from '@states/labos/labos.state';
import {UsersState} from '@states/user/users.states';
import {errorInterceptorProvider} from '@utils/interceptor/error.interceptor';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {MatPaginatorIntlTransloco} from './mat-paginator-intl-transloco';
import {initializeKeycloak} from './security/keycloak.initializer';
import {TranslocoRootModule} from './transloco-root.module';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {environment} from '@environment/environment';
import {NgxsModule, Store} from '@ngxs/store';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {NgxsRouterPluginModule} from '@ngxs/router-plugin';
import {GlobalState} from '@states/global/global.state';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { initializeNotifications } from './shared/notifications/notification.initializer';
import { NotificationModule } from './shared/notifications/notification.module';
import { CookieService } from 'ngx-cookie-service';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { CustomDateAdapter } from '@utils/dateAdapter/CustomDateAdapter';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatInputModule } from '@angular/material/input';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';

registerLocaleData(localeFr);

const initializeApp = (keycloakService: KeycloakService, httpClient: HttpClient, store: Store, cookieService: CookieService) => {
  return async (): Promise<void> => {
    const keycloak = await initializeKeycloak(keycloakService, httpClient);
    if (keycloak && await keycloakService.isLoggedIn()) {
      initializeNotifications(store, keycloakService, cookieService);
    }
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    NgxsModule.forRoot([GlobalState, UsersState, LabosState], {
      developmentMode: !environment.production,
    }),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({
      maxAge: 25,
      disabled: environment.production,
    }),
    AppRoutingModule,
    HttpClientModule,
    KeycloakAngularModule,
    TranslocoRootModule,
    MatSnackBarModule,
    NotificationModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatInputModule,
    MatButtonToggleModule,
    MatIconModule,
    MatBadgeModule,

  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [KeycloakService, HttpClient, Store, CookieService],
    },
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR',
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'fr-FR',
    },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorIntlTransloco,
    },
    errorInterceptorProvider,
    CookieService,
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
