import {Labo} from '@domain/labo/Labo';
import {Dateslot, Sample, Timeslot, Document, DocumentTypeEnum} from '@domain/samples/sample';
import {Adress, LaboFormUser, ResultDiffusion, User, UserForm, UserZone} from '@domain/user/user';
import {UserFilter} from '@features/users/users-filter';
import {Select, Selector} from '@ngxs/store';
import {UsersState, UsersStatesModel} from '@states/user/users.states';
import {Pagination} from '@utils/pagination/Pagination';
import {PaginationOption} from '@utils/pagination/PaginationOption';



export class UsersStateSelector {
  @Selector([UsersState])
  static currentUser(state: UsersStatesModel): UserForm | undefined {
    return state.currentUser;
  }
  @Selector([UsersState])
  static filteredUsers(state: UsersStatesModel): User[] | undefined {
    return state.filteredUser;
  }

  @Selector([UsersState])
  static possibleLabos(state: UsersStatesModel): Labo[] | undefined {
    return state.possibleLabos?.filter(l => !state.chosenLabos!.some(cl => cl.id === l!.id!));
  }

  @Selector([UsersState])
  static chosenLabos(state: UsersStatesModel): LaboFormUser[] | undefined {
    return state.chosenLabos;
  }

  @Selector([UsersState])
  static paginationOption(state: UsersStatesModel): PaginationOption {
    return state.paginationOptions;
  }

  @Selector([UsersState])
  static userFilter(state: UsersStatesModel): UserFilter {
    return state.userFilter
  }

  @Selector([UsersState])
  static userFilterName(state: UsersStatesModel): string | undefined {
    return state.userFilter.name
  }

  @Selector([UsersState])
  static filteredUsersLoaded(state: UsersStatesModel): boolean | undefined {
    return state.filteredUsersLoaded;
  }

  @Selector([UsersState])
  static affectedZones(state: UsersStatesModel): UserZone[] | undefined {
    return state.affectedZones;
  }

  @Selector([UsersState])
  static domicile(state: UsersStatesModel): Adress | undefined {
    return state.domicileUserSamples;
  }

  @Selector([UsersState])
  static userSamples(state: UsersStatesModel): Pagination<Sample> {
    return state.userSamples;
  }

  @Selector([UsersState])
  static documents(state: UsersStatesModel): Document[] | undefined {
    return state.userDocuments;
  }

  @Selector([UsersState])
  static hasIdCard(state: UsersStatesModel): boolean {
    const documents: Document[] | undefined = state.userDocuments;
    if (documents) {
      return documents?.filter(doc => doc.documentType === DocumentTypeEnum.ID_CARD)?.length > 0;
    }
    return false;
  }

  @Selector([UsersState])
  static hasHealthCard(state: UsersStatesModel): boolean {
    const documents: Document[] | undefined = state.userDocuments;
    if (documents) {
      return documents?.filter(doc => doc.documentType === DocumentTypeEnum.HEALTH_CARD)?.length > 0;
    }
    return false;
  }

  @Selector([UsersState])
  static hasHealthAttestation(state: UsersStatesModel): boolean {
    const documents: Document[] | undefined = state.userDocuments;
    if (documents) {
      return documents?.filter(doc => doc.documentType === DocumentTypeEnum.HEALTH_ATTESTATION)?.length > 0;
    }
    return false;
  }

  @Selector([UsersState])
  static hasInsuranceCard(state: UsersStatesModel): boolean {
    const documents: Document[] | undefined = state.userDocuments;
    if (documents) {
      return documents?.filter(doc => doc.documentType === DocumentTypeEnum.INSURANCE_CARD)?.length > 0;
    }
    return false;
  }

  @Selector([UsersState])
  static resultDiffusion(state: UsersStatesModel): ResultDiffusion | undefined {
    return state.userResultDiffusion;
  }

  @Selector([UsersState])
  static filteredPreleveurs(state: UsersStatesModel): Pagination<User> {
    return state.preleveursList;
  }

  @Selector([UsersState])
  static preleveursPaginationOption(state: UsersStatesModel): PaginationOption {
    return state.preleveurPaginationOption;
  }

  @Selector([UsersState])
  static preleveursFilter(state: UsersStatesModel): string {
    return state.preleveurFilter;
  }

  @Selector([UsersState])
  static currentAssociatedPreleveur(state: UsersStatesModel): User | null {
    return state.currentAssociatedPreleveur;
  }
}
