import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

class DialogData {
}

@Component({
  selector: 'dialog-delete',
  templateUrl: 'dialog-confirmation-component.html',
})
export class DialogConfirmationComponent {
  constructor(public dialogRef: MatDialogRef<DialogConfirmationComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {title: string, content: string},
  ) {}

  decline(): void {
    this.dialogRef.close(false);
  }

  accept(): void {
    this.dialogRef.close(true);
  }
}

