import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Nurse, NurseFilter} from '@domain/nurse/Nurse';
import {environment} from '@environment/environment';
import {NO_SNACKBAR_CONTEXT} from '@utils/interceptor/error.interceptor';
import {Pagination} from '@utils/pagination/Pagination';
import {PaginationOption, SortDirectionEnum} from '@utils/pagination/PaginationOption';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NurseService {
  constructor(public http: HttpClient) {}

  get(uuid: string, showErrorMessage: boolean = true): Observable<Nurse> {
    return this.http.get(environment.api + '/nurses/' + uuid, {context: NO_SNACKBAR_CONTEXT});
  }

  create(nurse: Nurse, showErrorMessage: boolean = true): Observable<Nurse> {
    return this.http.post(environment.api + '/nurses', nurse,{context: NO_SNACKBAR_CONTEXT});
  }

  update(uuid: string, nurse: Nurse): Observable<Nurse> {
    return this.http.put(environment.api + '/nurses/' + uuid, nurse, {context: NO_SNACKBAR_CONTEXT});
  }

  delete(uuid: string): Observable<Nurse> {
    return this.http.delete(environment.api + '/nurses/' + uuid, {context: NO_SNACKBAR_CONTEXT});
  }

  findByFiltersPaging(filters: NurseFilter, paginationOptions: PaginationOption): Observable<Pagination<Nurse>> {
    let param_str: string[] = [];
    if (filters.search && filters.search?.length > 0){
      param_str.push('search~' + filters.search);
    }
    let params: HttpParams = new HttpParams()
      .set('page', paginationOptions.pageIndex!)
      .set('size', paginationOptions.pageSize!);

    if (paginationOptions.sortByColumnName && paginationOptions.sortByColumnName !== 'address') {
      params = params.set( 'sort' ,paginationOptions.sortDirection === SortDirectionEnum.ASC ? paginationOptions.sortByColumnName : '-' + paginationOptions.sortByColumnName)
    }
    else if (paginationOptions.sortByColumnName && paginationOptions.sortByColumnName === 'address') {
      const sort_str: string =
        paginationOptions.sortDirection === SortDirectionEnum.ASC ? 'postalCode,city,address1' : '-postalCode,-city,-address1';
      params = params.set('sort', sort_str);
    }
    if (param_str.length>0){
      params = params.set('q', param_str.join(','));
    }

    return this.http.get<Pagination<Nurse>>(environment.api + '/nurses', {params, context: NO_SNACKBAR_CONTEXT});
  }

  checkUniqueness(name: string, address: string, postalCode: string, city: string): Observable<boolean> {
    const params: HttpParams = new HttpParams()
      .set("name", name)
      .set("address", address)
      .set("postalCode", postalCode)
      .set("city", city);
    return this.http.get<boolean>(environment.api + '/nurses/check-uniqueness', {params, context: NO_SNACKBAR_CONTEXT});
  }
}
