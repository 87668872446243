<mat-form-field appearance="fill">
  <button *ngIf="!(search$ | async)" matPrefix mat-icon-button aria-label="Search" (click)="searchInput.focus()">
    <mat-icon>search</mat-icon>
  </button>
  <mat-label> {{ placeholder }}</mat-label>
  <input #searchInput matInput type="text" [ngModel]="search$ | async" (ngModelChange)="onChange($event)">
  <button *ngIf="search$ | async" matSuffix mat-icon-button aria-label="Clear" (click)="resetSearch()">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
