<ng-container *ngIf="cabinet$ | async as cabinet">
  <ng-container *ngIf="cabinetPreleveurs$ | async as cabinetPreleveurs">
    <form (ngSubmit)="onSubmit()">
      <div class="header row align-items-center">
        <button type="button" class="col-auto px-3" mat-button (click)="goBack()">
          <mat-icon aria-hidden="false" aria-label="go back icon">arrow_back</mat-icon>
        </button>
        <div class="col-auto me-auto">{{'cabinets.' + (cabinet?.id ? 'updateHeader' : 'creationHeader') | transloco}}
          <label *ngIf="cabinet?.id"> {{cabinet.name}}</label>
        </div>
        <button type="button" mat-button class="col-auto delete align-items-center " *ngIf="cabinet?.id && cabinet.id"
                (click)="deleteCabinet(cabinet.id)">
          <mat-icon class="align-self-center mb-1">delete</mat-icon>
          {{'cabinets.delete' | transloco}}
        </button>
        <button  mat-flat-button class="height-75 col-auto ms-3 me-5 justify-content-end helveticaBold13"
                [disabled]="!name.valid || cabinetPreleveurs.length == 0"
                type="submit">{{'app.save' | transloco}}</button>
      </div>

      <div class="formGroup row align-items-center">
        <div class="col-auto icon mt-3 mb-3 ms-4 pt-2 col-1">
          <img src="assets/img/laboratory.svg" alt="icon">
        </div>
        <mat-form-field appearance="fill" class="col-3 mt-3 ms-5">
          <mat-label>{{'cabinets.name' | transloco}}</mat-label>
          <input matInput [formControl]="name" placeholder="{{'cabinets.name' | transloco }}">
        </mat-form-field>
      </div>
      <div class="d-flex flex-row justify-content-around">
        <div class="col-5 formGroup">
          <div class="m-3">
            <p class="title">{{'cabinets.cabinetPreleveurs' | transloco}}</p>
            <div cdkDropList id="cabinetPreleveurs" cdkDropListConnectedTo="allPreleveurs" class="list"
                 (cdkDropListDropped)="drop($event)">
              <div *ngFor="let preleveur of cabinetPreleveurs" class="item">
                <div>
                  {{preleveur.firstname + " " + preleveur.lastname}}
                </div>
                <button type="button" mat-icon-button (click)="deletePreleveur(preleveur.id)">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
              <div *ngIf="cabinetPreleveurs.length == 0"
                   class="fw-bold d-flex justify-content-center alig">{{'cabinets.noPreleveurs' | transloco}}</div>
            </div>
          </div>
        </div>
        <div class="col-5 formGroup">
          <div class="m-3">
            <p class="title">{{'cabinets.addPreleveurs' | transloco}}</p>
            <p class="subtitle"> {{'cabinets.drag' | transloco}}</p>
            <app-searchbar [placeholder]="'cabinets.searchPreleveur' | transloco " [search$]="preleveursFilter$"
                           (valueChange)="updateFilter($event)"></app-searchbar>
            <ng-container *ngIf="filteredPreleveurs$ | async as filteredPreleveurs">
              <ng-container *ngIf="paginationOption$ | async as paginationOption">
              <div id="allPreleveurs" cdkDropList cdkDropListConnectedTo="cabinetPreleveurs"
                   class="list">
                <div *ngFor="let preleveur of filteredPreleveurs.content" cdkDrag [cdkDragData]="preleveur"
                     class="item move-cursor" [cdkDragDisabled]="notInPredicate(preleveur, cabinetPreleveurs$ | async)">
                  {{preleveur.firstname + " " + preleveur.lastname}}
                </div>
                <div *ngIf="filteredPreleveurs.content.length == 0">{{'cabinets.noPreleveursFound' | transloco}}</div>
                <mat-paginator [length]="paginationOption?.length" [pageSize]="paginationOption.pageSize"
                               [pageIndex]="paginationOption.pageIndex"  [pageSizeOptions]="paginationOption?.pageSizeOption || [10,20,30]"
                               (page)="onChangePage($event)"></mat-paginator>
              </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
</ng-container>
