import {HttpClient, HttpContext, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FixedSchedule, Labo, LaboPagination, LaboRdvStatusCount, ZonePreleveur} from '@domain/labo/Labo';
import {Check, CheckFilter} from '@domain/samples/sample';
import {User} from '@domain/user/user';
import {environment} from '@environment/environment';
import {UserFilter} from '@features/users/users-filter';
import {IS_ERROR_SNACKBAR_ENABLED, NO_SNACKBAR_CONTEXT} from '@utils/interceptor/error.interceptor';
import {Pagination} from '@utils/pagination/Pagination';
import {PaginationOption, SortDirectionEnum} from '@utils/pagination/PaginationOption';
import {Observable} from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class LaboService {
  constructor(public http: HttpClient) {}

  search(): Observable<LaboPagination> {
    return this.http.get<LaboPagination>(environment.api + '/labos',
      {
        params: {sort: "name"},
        context: new HttpContext().set(IS_ERROR_SNACKBAR_ENABLED, true)
      }
    )
  }

  get(uuid: string, showErrorMessage: boolean = true): Observable<Labo> {
    return this.http.get<Labo>(environment.api + '/labos/' + uuid, {context: new HttpContext().set(IS_ERROR_SNACKBAR_ENABLED, true)})
  }

  create(labo: Labo, showErrorMessage: boolean = true): Observable<Labo> {
    return this.http.post(environment.api + '/labos/', labo, {context: new HttpContext().set(IS_ERROR_SNACKBAR_ENABLED, true)});
  }

  update(uuid: string, labo: Labo, showErrorMessage: boolean = true): Observable<Labo> {
    return this.http.put(environment.api + '/labos/' + uuid, labo, {context: new HttpContext().set(IS_ERROR_SNACKBAR_ENABLED, true)});
  }
  delete(uuid: string, showErrorMessage: boolean = true): Observable<Object> {
    return this.http.delete(environment.api + '/labos/' + uuid, {context: new HttpContext().set(IS_ERROR_SNACKBAR_ENABLED, true)});
  }

  findByFiltersPaging(filter: string, paginationOption: PaginationOption): Observable<Pagination<Labo>> {
    return this.http.get<Pagination<Labo>>(environment.api + '/labos', {
      params: {
        q: filter ? 'search~' + filter : '',
        page: paginationOption.pageIndex!,
        size: paginationOption.pageSize!,
      },
      context: NO_SNACKBAR_CONTEXT,
    });
  }

  saveZonesPreleveurs(laboId: string, zones: ZonePreleveur[]): Observable<void> {
    var laboPatch: Labo = {
      id: laboId,
      zonesPreleveurs: zones
    }
    return this.http.patch<void>(environment.api + '/labos/' + laboId, laboPatch, {context: new HttpContext().set(IS_ERROR_SNACKBAR_ENABLED, true)});
  }

  getPreleveurs(filter: UserFilter): Observable<Pagination<User>> {
    let params: string[] = [];
    if (filter.laboId != null) {
      params.push('laboId:' + filter.laboId);
    }

    params.push('profile:PRELEVEUR')

    let httpParams: HttpParams = new HttpParams()
      .set('q', params.join(','));

    return this.http.get<Pagination<User>>(environment.api + '/users', {
      params: httpParams,
      context: NO_SNACKBAR_CONTEXT,
    })
  }

  loadChecks(filter: CheckFilter, paginationOption: PaginationOption): Observable<Pagination<Check>> {
    let params: string[] = [];
    if (filter.laboId != null) {
      params.push('laboId:' + filter.laboId);
    }

    if(filter.search) {
      params.push('search~' + filter.search)
    }

    let httpParams: HttpParams = new HttpParams()
      .set('q', params.join(','))
      .set('page', paginationOption.pageIndex!)
      .set('size', paginationOption.pageSize!);

    if (paginationOption.sortByColumnName) {
      httpParams = httpParams.set('sort', (paginationOption.sortDirection === SortDirectionEnum.ASC ? paginationOption.sortByColumnName : '-' + paginationOption.sortByColumnName))
    }

    return this.http.get<Pagination<Check>>(environment.api + '/checks', {
      params: httpParams,
      context: NO_SNACKBAR_CONTEXT,
    })
  }

  createCheck(check: Check): Observable<Check> {
    return this.http.post<Check>(environment.api + '/checks', check, {
      context: NO_SNACKBAR_CONTEXT,
    })
  }

  updateCheck(id: string, check: Check): Observable<Check> {
    return this.http.put<Check>(environment.api + `/checks/${id}`, check, {
      context: NO_SNACKBAR_CONTEXT,
    })
  }

  deleteCheck(id: string): Observable<void> {
    return this.http.delete<void>(environment.api + `/checks/${id}`, {context: new HttpContext().set(IS_ERROR_SNACKBAR_ENABLED, true)});
  }

  findNearest(lat: string | number, lon: string | number, max?: number): Observable<Labo[]> {
    let params: HttpParams = new HttpParams().set('lat', lat.toString()).set('lon', lon.toString());
    if (max) {
      params = params.set('max', max);
    }
    return this.http.get<Labo[]>(environment.api + '/labos/find-nearest', {params});
  }

  getFixedSchedule(laboId: string): Observable<FixedSchedule[]> {
    return this.http.get<FixedSchedule[]>(environment.api + '/labos/' + laboId + '/fixed-schedule', {context: NO_SNACKBAR_CONTEXT});
  }
}

