import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig, MatSnackBarDismiss} from '@angular/material/snack-bar';
import {TranslocoService} from '@ngneat/transloco';
import {firstValueFrom} from 'rxjs';

export interface SnackbarOption {
  config?: MatSnackBarConfig;
  action?: string;
  doNotUseTranslateService?: boolean;
}

/**
 * Snackbar service
 */
@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  /**
   * Constructor
   */
  constructor(private _snackbarService: MatSnackBar, private _translator: TranslocoService) {}

  /**
   * Dismiss all snackbars
   */
  dismissAll(): void {
    this._snackbarService.dismiss();
  }

  /**
   * Show info snackbar
   */
  showInfo(message: string, options?: SnackbarOption): Promise<MatSnackBarDismiss> {
    return this._showSnackbar('info', message, options);
  }

  /**
   * Show warning snackbar
   */
  showWarning(message: string, options?: SnackbarOption): Promise<MatSnackBarDismiss> {
    return this._showSnackbar('warning', message, options);
  }

  /**
   * Show error snackbar
   */
  showError(message: string, options?: SnackbarOption): Promise<MatSnackBarDismiss> {
    return this._showSnackbar('error', message, options);
  }

  /**
   * Show success snackbar
   */
  showSuccess(message: string, options?: SnackbarOption): Promise<MatSnackBarDismiss> {
    return this._showSnackbar('success', message, options);
  }

  /**
   * Show snackbar of type with option
   */
  private _showSnackbar(type: string, message: string, options?: SnackbarOption): Promise<MatSnackBarDismiss> {
    if (!options) {
      options = {
        config: {},
        action: 'app.close',
        doNotUseTranslateService: false,
      };
    }
    if (!options.doNotUseTranslateService) {
      message = this._translator.translate(message);
    }
    if (!options.config) {
      options.config = new MatSnackBarConfig();
    }
    if (options.config.duration === null || options.config.duration === undefined) {
      options.config.duration = 3000;
    }
    options.config.panelClass = 'snack-' + type;
    return firstValueFrom(
      this._snackbarService.open(message, this._translator.translate(options.action!), options.config).afterDismissed()
    );
  }
}
