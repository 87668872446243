import {DayEnum, Labo} from '@domain/labo/Labo';
import {AssociationModeEnum, User} from '@domain/user/user';
import {InLaboSampleCount, Sample, SampleInfos, SamplesFilter, StatusSampleEnum, Check, Dateslot} from '@domain/samples/sample';
import {Selector} from '@ngxs/store';
import {SamplesState, SamplesStatesModel} from '@states/samples/samples.state';
import {Pagination} from '@utils/pagination/Pagination';
import {PaginationOption} from '@utils/pagination/PaginationOption';
import {CutHoursPipe, Disponibility} from '@utils/pipe/cut-hours.pipe';
import { DateTime } from 'luxon';

export class SamplesStateSelector {
  @Selector([SamplesState])
  static filteredSamples(state: SamplesStatesModel): Pagination<Sample> | undefined {
    return state.filteredSamples;
  }

  @Selector([SamplesState])
  static inLaboSampleCount(state: SamplesStatesModel): InLaboSampleCount | undefined {
    return {
      pending: state.filteredSamples?.content.filter(s => s.status?.status === StatusSampleEnum.PENDING && s.inLabo).length!,
      confirmed: state.filteredSamples?.content.filter(s => s.status?.status === StatusSampleEnum.CONFIRM && s.inLabo).length!,
    };
  }

  @Selector([SamplesState])
  static pendingHomeSamples(state: SamplesStatesModel): Sample[] | undefined {
    return state.filteredSamples?.content.filter(s => s.status?.status === StatusSampleEnum.PENDING && !s.inLabo);
  }

  @Selector([SamplesState])
  static affectedConfirmedHomeSamples(state: SamplesStatesModel): Sample[] | undefined {
    const possibleStatus: StatusSampleEnum[] = [
      StatusSampleEnum.CONFIRM,
      StatusSampleEnum.AFFECTED,
      StatusSampleEnum.INPROGRESS,
      StatusSampleEnum.UNDERWAY,
      StatusSampleEnum.TOLABO,
      StatusSampleEnum.TOVALIDATE,
      StatusSampleEnum.DONE,
    ];
    return state.filteredSamples?.content.filter(s => (possibleStatus.includes(s.status?.status!)) && !s.inLabo);
  }

  @Selector([SamplesState])
  static affectedHomeSamples(state: SamplesStatesModel): Sample[] | undefined {
    return state.filteredSamples?.content.filter(s => s.status?.status === StatusSampleEnum.AFFECTED);
  }

  @Selector([SamplesState])
  static filteredSamplesLoaded(state: SamplesStatesModel): boolean | undefined {
    return state.filteredSamplesLoaded;
  }

  @Selector([SamplesState])
  static labo(state: SamplesStatesModel): Labo | undefined {
    return state.labo;
  }

  @Selector([SamplesState])
  static paginationOption(state: SamplesStatesModel): PaginationOption {
    return state.paginationOption;
  }

  @Selector([SamplesState])
  static filter(state: SamplesStatesModel): SamplesFilter {
    return state.samplesFilter;
  }

  @Selector([SamplesState])
  static filterPatientName(state: SamplesStatesModel): string | undefined {
    return state.samplesFilter.patientName;
  }

  @Selector([SamplesState])
  static currentPreleveur(state: SamplesStatesModel): User | undefined {
    return state.preleveur;
  }


  @Selector([SamplesStateSelector.preleveurSchedule])
  static sortedSamples(dispo: Disponibility[]): Sample[] {
    const samplesSorted: Sample[] = [];
    dispo.forEach(d => {
      samplesSorted.push(...d.samples!)
    })
    return samplesSorted;
  }

  @Selector([SamplesState])
  static unaffectedSamples(state: SamplesStatesModel) {
    return state.filteredSamples?.content.filter(s => !s.preleveur)
  }

  @Selector([SamplesState])
  static sampleOrderModified(state: SamplesStatesModel): boolean {
    return state.sampleOrderModified
  }

  @Selector([SamplesState])
  static preleveurSchedule(state: SamplesStatesModel): Disponibility[] {
    const preleveur = state.preleveur;
    const samples = state.filteredSamples?.content!
    const date = state.date!;
    const cutHoursPipe = new CutHoursPipe()

    const sort = (a: string[], b: string[]) => {
      if (a[0] < b[0]) return -1
      if (a[0] > b[0]) return 1
      return 0;
    }

    const day = date!.setLocale('en-GB').weekdayLong.toUpperCase() as DayEnum;

    const toInt = (hour: string) => parseInt(hour!.substring(0, hour.indexOf(':')));

    if(preleveur?.aggregatedExceptions?.filter(e => DateTime.fromISO(e.date).equals(date)).length) {
      const computed: string[][] = preleveur.exceptions!.filter(e => DateTime.fromISO(e.date).equals(date)).reduce<string[][]>((acc, exc) => {
        acc.push([exc.start!, exc.end!])
        return acc;
      }, [])

      let final: string[][] = computed!.sort(sort).reduce<string[][]>((acc, c) => {
        if(acc.some(a => a[0] === c[0] && a[1] === c[1])) {
          return acc;
        }
        const middle = acc!.find(accIt => !(accIt[0] > c[1] || accIt[1] < c[0]))
        if(middle) {
          middle[0] = toInt(middle[0]) > toInt(c[0]) ? c[0] : middle[0];
          middle[1] =  toInt(middle[1]) > toInt(c[1]) ? middle[1] : c[1];
        } else {
          acc.push([c[0], c[1]])
        }
        return acc;
      }, [])

      return cutHoursPipe.transform(final, samples)
    }

    const computed: string[][] | undefined = preleveur?.disponibilities!.filter(d => d.day === day).reduce<string[][]>((acc, exc) => {
      acc.push([exc.start!, exc.end!])
      return acc;
    }, [])
    let final: string[][] = computed!.sort(sort).reduce<string[][]>((acc, c) => {
      if(acc.some(a => a[0] === c[0] && a[1] === c[1])) {
        return acc;
      }
      const middle = acc!.find(accIt => !(accIt[0] > c[1] || accIt[1] < c[0]))
      if(middle) {
        middle[0] = toInt(middle[0]) > toInt(c[0]) ? c[0] : middle[0];
        middle[1] =  toInt(middle[1]) > toInt(c[1]) ? middle[1] : c[1];
      } else {
        acc.push([c[0], c[1]])
      }
      return acc;
    }, [])
   return cutHoursPipe.transform(final, samples)
  }

  @Selector([SamplesState])
  static checks(state: SamplesStatesModel): Check[] | undefined {
    return state.checks;
  }

  @Selector([SamplesState])
  static currentSampleCreation(state: SamplesStatesModel): Sample | undefined {
    return state.sampleCreation;
  }

  @Selector([SamplesState])
  static sampleDatelots(state: SamplesStatesModel): Dateslot[] | undefined {
    return state.sampleDateSlots;
  }

  @Selector([SamplesState])
  static samplesDayPlanning(state: SamplesStatesModel): SampleInfos[] | undefined {
    return state.samplesByDay;
  }

  @Selector([SamplesState])
  static planningFilter(state: SamplesStatesModel): SamplesFilter {
    return state.planningFilter;
  }

  @Selector([SamplesState])
  static sampleInfos(state: SamplesStatesModel): Sample {
    return state.sampleInfo;
  }

  @Selector([SamplesState])
  static dayPlanningLoaded(state: SamplesStatesModel): boolean {
    return state.planningByDayLoaded;
  }

  @Selector([SamplesState])
  static timeslotsSearchMode(state: SamplesStatesModel): AssociationModeEnum {
    return state.timeslotsSearchMode;
  }
}
