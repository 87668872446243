<div class="header row align-items-center">
  <button type="button" class="col-auto px-3" mat-button (click)="goBack()">
    <mat-icon aria-hidden="false" aria-label="go back icon">arrow_back</mat-icon>
  </button>
  <div class="col-auto me-auto"> {{'users.samples.list' | transloco }}</div>
</div>
<div class="row m-3">
  <div class="col-2 table-header d-flex" (click)="updateSort('noSejour')" [ngClass]="{'sortBy': paginationOptions.sortByColumnName === 'noSejour'}">
    <span>{{'users.samples.table.no_sejour' | transloco }}</span>
    <mat-icon *ngIf="paginationOptions.sortByColumnName=== 'noSejour' && paginationOptions.sortDirection === 'DESC'; else asc" class="arrow">arrow_downward</mat-icon>
    <ng-template #asc>
      <mat-icon class="arrow">arrow_upward</mat-icon>
    </ng-template>
  </div>
  <div class="col-1 table-header d-flex" (click)="updateSort('dateSample')" [ngClass]="{'sortBy': paginationOptions.sortByColumnName=== 'dateSample'}">
    <span> {{'users.samples.table.date' | transloco }} </span>
    <mat-icon *ngIf="paginationOptions.sortByColumnName=== 'dateSample' && paginationOptions.sortDirection === 'DESC'; else asc" class="arrow">arrow_downward</mat-icon>
  </div>
  <div class="col-1 table-header">
    <span>{{'users.samples.table.schedule' | transloco}}</span>
  </div>
  <div class="col-1 table-header d-flex justify-content-center" (click)="updateSort('inLabo')" [ngClass]="{'sortBy': paginationOptions.sortByColumnName === 'inLabo'}">
    <span>{{'users.samples.table.inLabo' | transloco }}</span>
    <mat-icon *ngIf="paginationOptions.sortByColumnName=== 'inLabo' && paginationOptions.sortDirection === 'DESC'; else asc" class="arrow">arrow_downward</mat-icon>
    <ng-template #asc>
      <mat-icon class="arrow">arrow_upward</mat-icon>
    </ng-template>
  </div>
  <div class="col-2 table-header">
    <span>{{'users.samples.table.adresse' | transloco}}</span>
  </div>
  <div class="col-2 table-header d-flex justify-content-center" (click)="updateSort('status')" [ngClass]="{'sortBy': paginationOptions.sortByColumnName === 'status'}">
    <span>{{'users.samples.table.status' | transloco }}</span>
    <mat-icon *ngIf="paginationOptions.sortByColumnName=== 'status' && paginationOptions.sortDirection === 'DESC'; else asc" class="arrow">arrow_downward</mat-icon>
    <ng-template #asc>
      <mat-icon class="arrow">arrow_upward</mat-icon>
    </ng-template>
  </div>
  <div class="col-2 table-header">
    <span>{{'users.samples.table.prescription' | transloco}}</span>
  </div>
  <div>

  </div>
</div>
<ng-container *ngIf="samples$ | async as samples">
  <div *ngFor="let sample of samples.content" class="row row-table ms-3 me-3 mt-1 mb-1 align-items-center">
    <div class="col-2">{{sample.noSejour}}</div>
    <div class="col-1"> {{sample?.dateSample | date : 'dd - MM - yyyy'}}</div>
    <div class="col-1" [class.schedule-ok] = "sample?.startTime">{{getSchedule(sample)}}</div>
    <div class="col-1 d-flex justify-content-center">
      <mat-icon *ngIf="!sample.inLabo" color="primary">check</mat-icon>
    </div>
    <div class="col-2">{{sample?.adr1 + ', ' + sample?.cp + ' ' + sample?.ville}}</div>
    <div class="col-2 d-flex justify-content-center" [ngSwitch]="sample?.status?.status">
      <div *ngSwitchCase="statusEnum.PENDING" class="status_to_save">
        {{'samples.status.PENDING' | transloco}}
      </div>
      <div *ngSwitchCase="statusEnum.DONE" class="status_done">
        {{'samples.status.done' | transloco}}
      </div>
      <div *ngSwitchCase="statusEnum.CANCEL" class="status_cancel">
        {{'samples.status.cancel' | transloco}}
      </div>
      <div *ngSwitchDefault class="status_saved">
        {{'samples.status.saved' | transloco}}
      </div>
    </div>
    <div class="col-2 d-flex justify-content-start align-items-center">
      <img *ngIf="sample.hasRx && !hasUploadedPresciption(sample)" class="icon_check me-1" src="assets/img/files.svg" alt="ordonnance non uploadée">
      <div *ngIf="sample.hasRx && hasUploadedPresciption(sample)">
        <img class="icon_check me-1" src="assets/img/prescription-ok.svg" alt="ordonnance uploadée">
        <button mat-icon-button (click)="downloadPrescription(sample)" class="me-3">
          <mat-icon color="accent">download</mat-icon>
        </button>
      </div>
      <img *ngIf="!sample.hasRx" class="icon_check me-1" src="assets/img/prescription-missing.svg" alt = "absence d'ordonnance ">
      <label> {{getChecks(sample)}} </label>
    </div>
    <div class="col-auto ms-auto">
      <button mat-icon-button (click)="cancelSample(sample?.id)" *ngIf="sample.status?.status === statusEnum.PENDING || sample.status?.status === statusEnum.AFFECTED || sample.status?.status === statusEnum.CONFIRM ">
        <mat-icon color="warn">close</mat-icon>
      </button>
    </div>
  </div>
</ng-container>
<mat-paginator [length]="paginationOptions?.length" [pageSize]="paginationOptions.pageSize"
               [pageIndex]="paginationOptions.pageIndex"  [pageSizeOptions]="paginationOptions?.pageSizeOption || [10,20,50]"
               (page)="onChangePage($event)"></mat-paginator>
